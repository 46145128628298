
import * as React from 'react';
import { connect } from 'react-redux';
import { cloneObject } from '../../helpers';

import './TableLogs.scss';
// import { DataGrid } from '@material-ui/data-grid';
import Toolbar from "./Toolbar"
import TableMaterial from "./../Table/TableMaterial"
import moment from 'moment';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import IconButton from '@material-ui/core/IconButton';


class Account extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            data: [],
            version: "",
            dateUpdate: 0
        }

    }

    componentDidUpdate(prevProps) {
        let filterString = JSON.stringify(this.props.filter || {}) +"-"+this.props.version
        if (this.state.filterStr != filterString || ( this.props.version && prevProps.version!=this.props.version)) {
            this.setState({ filterStr: filterString ,data:[],version:"" })
        }

    }

    componentDidMount() {

    }



    render() {

        const columns = [
            { collapse: true },
            // {field:"_id",name:"_id"},
            {
                field: "date", name: "Date", render: (obj) => {
                    return moment(obj.date).format("DD/MM/YYYY HH:mm:ss")
                }
            },
            { field: "user", name: "User" },
            { field: "event", name: "event" },
            { field: "numVersion", name: "numVersion" },
            // {field:"nbDifference",name:"levelDifference"},
            { field: "relationId", name: "relationId" }
        ]
        if (this.props.openLink) {
            columns.push({
                field: "", noSort: true, name: "Link", render:  (rowData)=> {
                    let url  = ""
                    if (rowData.relationId && rowData.relationId.indexOf("bookingId")!=-1) url = "/oneTransaction/" + rowData.relationId.split(":")[1]
                    return <a href={url} target="_blank"><IconButton onClick={() => {
                    }}>
                        <FolderOpenIcon />
                    </IconButton>
                    </a>
                }
            })
        }

        return <div className={"container-table-logs"}>
            {this.props.activeToolbarFilter ? <Toolbar
                url={'/crm/logs/search'}
                timeout={this.props.timeout || 2*60000} //12000
                filter={this.props.filter}
                version={this.state.filterStr}
                onUpdate={function (data, error) {
                    if (data) {
                        if (this.state.version != data.version) {
                            this.setState({ data: data.data, version: data.version, dateUpdate: Date.now() })
                        }
                        else {
                            this.setState({ dateUpdate: Date.now() })
                        }
                    }

                }.bind(this)}

            /> : null}
            <TableMaterial
                className={"table-logs"}

                columns={columns}
                functionCollapse={(row) => {
                    let array = []
                    for (let key in row.updateData) {
                        for (let l of row.updateData[key]) {
                          
                            for (let prop of ["before","after"]) {
                                if (typeof l[prop] === "object") l[prop] = JSON.stringify(l[prop])
                                if (typeof l[prop] ==="boolean") l[prop] = String(l[prop])
                            }
                            array.push({ ...l, difference: key })

                        }
                    }
                    return array
                }}
                subColumn={[
                    { field: "difference", name: "difference" },
                    { field: "key", name: "key" },
                    { field: "before", name: "before" },
                    { field: "after", name: "after" },

                ]}
                data={this.state.data}
            />

        </div>



    }
}




const mapStateToProps = (state) => {
    return { booking: state.booking, base: state.data };
};

export default connect(mapStateToProps, {})(Account);