import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { Component, version } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { parseQuery, _fetch } from "./../../helpers"
// export default function AlertDialog(props) {
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DateTimeContainer from "./../Utils/DateTimeContainer"
import CheckBoxCombigo from "./../Utils/Select_CheckBox"
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker, DatePicker, DateTimePicker } from "@material-ui/pickers";
import moment from "moment";
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

import ListNotes from "./../../Components/ListNotes/ListNotes"
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import IconButton from '@material-ui/core/IconButton';
import MinimizeIcon from '@mui/icons-material/Minimize';
import CloseIcon from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';



import FormHelperText from '@material-ui/core/FormHelperText';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import TableMaterial from "./../Table/TableMaterial"

import TooltipCombigo from "./../Utils/TooltipCombigo"
//https://github.com/dmfilipenko/timezones.json/blob/master/timezones.json


const listCategory =[
    { id: "CANCEL_VOLUNTARY", title: "CANCEL_VOLUNTARY" },
    { id: "DENIED_BOARDED", title: "DENIED_BOARDED" },
    { id: "DISRUPTED_CONNECTION", title: "DISRUPTED_CONNECTION" },
    { id: "MODIFICATION", title: "MODIFICATION" },
    { id: "OPTIONS", title: "OPTIONS" },
    { id: "ERROR", title: "ERROR" },
    { id: "OTHER", title: "OTHER" },
    { id: "BANK", title: "BANK" },
]



function calculTimeKeep(dateLimit) {

    return "?"
    let dateUtc = Number(moment(dateLimit.value).tz(dateLimit.tz).utc().format("x"))
    let difference = Math.round((dateUtc - (Date.now() + 2 * 60000)) / 60000)
    if (difference < 0) {
        return 0 + " minutes"
    }
    let hour = Math.floor(difference / 60)
    let minutes = difference - hour * 60
    return hour + " h " + minutes

}


class ModalVoucher extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            expand_0: true,
            expand_1: false,
            expand_2: true,
            snackMessage: null,
            errorMessage: null,
            openModalNext: false,

            bill: {
                statusPayment: "CREATED", // OPENED, PAID
                billId: String(Date.now() + "-" + String(Math.round(Math.random() * 10000000000))),
                price: { currency: "EUR" },
                locale: "en",
                notes: { data: [], version: "init" },
                status: "ACTIVE",
                version: "init",
                accountParentId:"",
                numVersion: 0,
                dateLimitPayment: { value: new Date(Date.now() + 86400000 * 4).getTime(), tz: "Europe/Paris" },
                ...props.data
            }

        }
        this.listTimezone = ["Europe/Paris", "America/New_York", "America/Bogota", "Asia/Bangkok", "Africa/Casablanca", "Europe/London", "Europe/Lisbon", "Europe/Athens", "America/Montreal", "America/Los_Angeles", "America/Buenos_Aires", "America/Mexico_City", "Atlantic/Cape_Verde", "Asia/Dubai", "Australia/Sydney", "Asia/Shanghai", "Pacific/Honolulu", "America/Guadeloupe", "Asia/Tokyo"]
        this.listTimezone.sort((a, b) => {
            return a.localeCompare(b)
        })
        this.currency = this.props.base.currency
        // console.log("props.data",props.data)

    }

    componentDidMount(){

        //     .concat([{id:"",name:"---------"}]).concat(listCategory.map((o)=>{
        //     return {
        //         id:"new:"+o.id,
        //         name:"New "+ o.id
        //     }
        // }))
   

    }


    componentDidUpdate(prevProps) {

        // console.log("this.props",this.props)
    
        if (this.props.version != prevProps.version || this.props.data.billId != prevProps.data.billId || this.props.data.billReference != prevProps.data.billReference) {
            // console.log("OK this.props.data", this.props.data)

            this.setState({
                bill: {
                    statusPayment: "CREATED", // OPENED, PAID
                    version: "init",
                    billId: String(Date.now() + "-" + String(Math.round(Math.random() * 10000000000))),
                    price: { currency: "EUR" },
                    locale: "en",
                    notes: { data: [], version: "init" },
                    status: "ACTIVE",
                    accountParentId:"",
                    numVersion: 0,
                    dateLimitPayment: { value: new Date(Date.now() + 86400000 * 4).getTime(), tz: "Europe/Paris" },
                    ...this.props.data
                }
            })
        }

    }


    onChange = (objInput) => {
        let obj = this.state.bill || {}
        for (let key in objInput) {
            obj[key] = objInput[key]

        }
        this.setState({ bill: obj })
    }




    onSave = () => {

        let price = Number(String(this.state.bill.price.amount || "").replace(",", "."))

        if (!this.state.bill.price.amount || isNaN(price)) {
            return this.setState({ errorMessage: "Erreur avec le prix spécifié" })
        }

        if (!this.state.bill.price.currency) {
            return this.setState({ errorMessage: "Erreur avec la devise" })
        }
        if (!this.state.bill.typeBill) {
            return this.setState({ errorMessage: "Erreur avec le type non spécifié" })
        }
        if (!this.state.bill.status) {
            return this.setState({ errorMessage: "Erreur avec le status" })
        }

        return true
    }


    render() {

        const isPaid = this.state.bill.status.indexOf("PAID" )!=-1? true : false
        const timeKeep = calculTimeKeep(this.state.bill.dateLimitPayment)
        const flagNewBill = this.props.newBill ? true : false


        const listRecap = [
            {
                title: "Général",
                render: <>
                    <Grid spacing={5} direction={"column"} >

                        <FormControl style={{ width: "100%" }}  >
                            {isPaid ? <TextField
                                label="Status"
                                disabled={true}
                                value={this.state.bill.status}
                                style={{ flexGrow: 1 }}
                            /> :
                                <CheckBoxCombigo
                                    className={""}
                                    styleSelect={{ width: "100%" }}
                                    label={"Status"}
                                    list={["ACTIVE", "INACTIVE", "CANCEL","REFUND"]}
                                    multiple={false}
                                    chips={false}
                                    value={this.state.bill.status}
                                    onChange={(value) => {
                                        this.onChange({ status: value })
                                    }}
                                />}

                        </FormControl>


                        <FormControl style={{ width: "100%" }}  >
                            {isPaid ? <TextField
                                label="Type"
                                disabled={true}
                                value={this.state.bill.typeBill}
                                style={{ flexGrow: 1 }}
                            /> :
                                <CheckBoxCombigo
                                    className={""}
                                    styleSelect={{ width: "100%" }}
                                    label={"Type"}
                                    list={["MODIFICATION_TRIP", "MODIFICATION_PASSENGER", "OPTIONS", "OTHER"]}
                                    multiple={false}
                                    chips={false}
                                    value={this.state.bill.typeBill}
                                    onChange={(value) => {
                                        this.onChange({ typeBill: value })
                                    }}
                                />
                            }
                        </FormControl>

                        <FormControl style={{ width: "100%" }}  >
                            <CheckBoxCombigo
                                className={""}
                                getLabel={(obj) => {
                                    return obj.name
                                }}
                                styleSelect={{ width: "100%" }}
                                label={"Category Comptabilite"}
                                list={this.props.listParentId || []}
                                multiple={false}
                                chips={false}
                                getMatching={(option) => {
                                    return option.id
                                }}
                                value={this.props.listParentId?this.props.listParentId.filter((o)=>{
                                    return o.id===this.state.bill.accountParentId
                                })[0]:{}}
                                onChange={(value) => {
                                    this.onChange({ accountParentId: value.id })
                                }}
                            />
                        </FormControl>

                        <FormControl style={{ width: "100%" }}  >
                            {isPaid ? <TextField
                                label="Amount Paid"
                                disabled={true}
                                value={this.state.bill.amountPaid}
                                style={{ flexGrow: 1 }}
                            /> :
                                null
                            }
                        </FormControl>


                        <FormControl style={{ width: "100%" }}>
                            <div style={{ display: "flex", "flexDirection": "row" }}>
                                <TextField label="Amount Init"
                                    value={isPaid ? this.state.bill.price.amount + " " + this.state.bill.price.currency : (this.state.bill.price ? this.state.bill.price.amount : undefined)}
                                    style={{ flexGrow: 1 }}
                                    disabled={isPaid}
                                    onChange={(ev) => {
                                        let str = ev.target.value != undefined ? ev.target.value : ""
                                        if (str) {
                                            let temp = ""
                                            for (let k = 0; k < str.length; k++) {
                                                if ("0123456789.,".indexOf(str[k]) != -1) {
                                                    temp += str[k]
                                                }
                                            }
                                            str = temp
                                        }


                                        this.onChange({ price: { amount: str ? str : "", currency: this.state.bill.price ? this.state.bill.price.currency : undefined } })
                                    }} />
                                {isPaid ? null :
                                    <CheckBoxCombigo
                                        className={""}
                                        styleSelect={{ width: "80px" }}
                                        label={"€,$,..."}
                                        list={this.currency}
                                        multiple={false}
                                        chips={false}
                                        value={this.state.bill.price ? this.state.bill.price.currency : undefined}
                                        onChange={(value) => {
                                            this.onChange({ price: { currency: value, amount: this.state.bill.price.amount } })
                                        }}
                                    />
                                }
                            </div>
                            {/* <FormHelperText id="my-helper-text">Montant du voucher en % ou dans une monnaie</FormHelperText> */}
                        </FormControl>



                        {<FormControl style={{ width: "100%" }}>
                            <TextField label="bookingReference"
                                value={this.state.bill.bookingReference}
                                disabled={isPaid || this.state.bill.bookingReference ? true : false}
                                onChange={(ev) => {
                                    this.onChange({ bookingReference: ev.target.value })
                                }} />
                            {/* <FormHelperText id="my-helper-text">Si ce voucher est lié a une réservation</FormHelperText> */}
                        </FormControl>}

                        {<FormControl style={{ width: "100%" }}>
                            {isPaid ? <TextField
                                label=""
                                disabled={true}
                                value={this.state.bill.locale}
                                style={{ flexGrow: 1 }}
                            /> :
                                <CheckBoxCombigo
                                    className={""}
                                    styleSelect={{ width: "100%" }}
                                    label={"Locale"}
                                    list={["en", "fr", "es"]}
                                    multiple={false}
                                    chips={false}
                                    value={this.state.bill.locale}
                                    onChange={(value) => {
                                        this.onChange({ locale: value })
                                    }}
                                />
                            }
                        </FormControl>}



                        <FormControl style={{ width: "100%" }}>
                            <div style={{ display: "flex", "flexDirection": "row" }}>

                                {isPaid ? <TextField
                                    label="date Limit Payment"
                                    disabled={true}
                                    value={moment(this.state.bill.dateLimitPayment.value).format("DD/MM/yyyy HH:mm")}
                                    style={{ flexGrow: 1 }}
                                /> :
                                    <DateTimeContainer
                                        DateObj={DateTimePicker}
                                        label={"date Limit Payment"}
                                        autoOk={true}
                                        value={this.state.bill.dateLimitPayment.value || ""}
                                        format={"DD/MM/yyyy HH:mm"}
                                        onChange={(newValue, h) => {
                                            this.onChange({ dateLimitPayment: { value: moment(newValue), tz: this.state.bill.dateLimitPayment.tz } })
                                        }}
                                        invalidLabel=""
                                        invalidDateMessage={null}
                                        variant={"inline"}
                                        ampm={false}
                                        TextFieldComponent={(props) => {
                                            // console.log(props)
                                            return <TextField {...props}
                                                value={this.state.bill.dateLimitPayment.value ? moment(this.state.bill.dateLimitPayment.value).format("DD/MM/yyyy HH:mm") : ""} />
                                        }}
                                    />}

                                {isPaid ? <TextField
                                    label="Timezone"
                                    disabled={true}
                                    value={this.state.bill.dateLimitPayment.tz}
                                    style={{ flexGrow: 1 }}
                                /> :
                                    <CheckBoxCombigo
                                        className={""}
                                        styleSelect={{ width: "140px" }}
                                        label={"Timezone"}
                                        list={this.listTimezone}
                                        multiple={false}
                                        chips={false}
                                        value={this.state.bill.dateLimitPayment ? this.state.bill.dateLimitPayment.tz : undefined}
                                        onChange={(value) => {
                                            this.onChange({ dateLimitPayment: { value: this.state.bill.dateLimitPayment.value, tz: value } })
                                        }}
                                    />
                                }



                            </div>
                            {isPaid ? <></> : <FormHelperText id="my-helper-text">{
                                "Temps restant pour payer : " + timeKeep + ""
                            }</FormHelperText>}

                        </FormControl>
                        <FormControl style={{ width: "100%" }} required={true}>
                            <TextField label="Commentaire Interne"
                                rows={2}
                                multiline
                                value={this.state.bill.comment}
                                onChange={(ev) => {
                                    this.onChange({ comment: ev.target.value })
                                }} />
                            {/* <FormHelperText id="my-helper-text">{"Message sur la page Combigo, c'est facultatif"}</FormHelperText> */}
                        </FormControl>

                        <FormControl style={{ width: "100%" }} required={true}>
                            <TextField label="Mail Client"
                                value={this.state.bill.mail}
                                onChange={(ev) => {
                                    this.onChange({ mail: ev.target.value })
                                }} />
                            {/* <FormHelperText id="my-helper-text">{"Message sur la page Combigo, c'est facultatif"}</FormHelperText> */}
                        </FormControl>

                        <FormControl style={{ width: "100%" }} required={true}>
                            <TextField label="Message Client"
                                rows={3}
                                disabled={isPaid}
                                multiline
                                id="my-input"
                                value={this.state.bill.messageCustomer}
                                onChange={(ev) => {
                                    this.onChange({ messageCustomer: ev.target.value })
                                }} />

                            <FormHelperText id="my-helper-text">{"Message sur la page Combigo, c'est facultatif"}</FormHelperText>
                        </FormControl>

                    </Grid>
                </>
            },
            {
                title: "Notes" + (this.state.bill.notes.data.length ? ' (' + this.state.bill.notes.data.length + ")" : "")
                , render: <>
                    <ListNotes
                        notes={this.state.bill.notes}
                        id={this.state.bill.billId}
                        version={this.state.bill.billId + "-" + this.state.bill.notes.version}
                        onChange={(data) => {
                            return new Promise((resolve, reject) => {
                                console.log("data", data)
                                this.setState({
                                    bill: {
                                        ...this.state.bill,
                                        notes: {
                                            version: String(Date.now() + "-" + Math.random()),
                                            data: data.data
                                        }
                                    }
                                }, () => {
                                    resolve(true)
                                })
                            })

                        }}
                    />
                </>
            },
            {
                title: "Actions",
                active: flagNewBill ? false : true,
                render: <Grid>
                    <div>

                        {this.state.bill.status === "ACTIVE" ? <Button size='small' color="primary"
                            onClick={(ev) => {
                                this.setState({ openModalNext: "mail_bill_pay" })
                            }}>
                            <span style={{ color: "#3333cc" }}>{
                                "Envoyé par email"}
                            </span>

                        </Button> : null}

                        {this.state.bill.status.indexOf("PAID")!=-1 ? <Button size='small' color="primary"
                            onClick={(ev) => {
                                this.setState({ openModalNext: "mail_bill_confirmation" })
                            }}>
                            <span style={{ color: "#3333cc" }}>
                                {"Envoyé confirmation"}
                            </span>

                        </Button> : null}
                        {/* {this.state.bill.status === "PAID" ? <Button size='small' color="primary"
                            onClick={(ev) => {
                                // this.setState({ openModalNext: "mail_refund" })
                                alert("Désolé ce n'est pas encore pret, il faut le faire à la main")
                            }}>
                            <span style={{ color: "#3333cc" }}>
                                {"Mail to Refund"}
                            </span>

                        </Button> : null} */}
                        {this.state.bill.status.indexOf("PAID")!=-1  ? <Button size='small' color="primary"
                            onClick={(ev) => {
                                // this.setState({ openModalNext: "mail_refund" })
                                this.props.onSave({
                                    
                                    ...this.state.bill,
                                    status:"PAID_CLOSED"

                                }, (response, responseJson) => {
                                    if (response.status === 200) {
                                        this.setState({ snackMessage: "Ok c'est fini!" })
                                        this.props.onClose()
                                    }
                                    else {
                                        this.setState({ errorMessage: "Erreur, veuillez réessayer" })
                                    }
                                })
                            }}>
                            <span style={{ color: "#3333cc" }}>
                                {"Closed the bill"}
                            </span>

                        </Button> : null}
                    </div>

                </Grid>
            },

            {
                title: "Logs",
                active: flagNewBill ? false : true,
                render: <>
                    <div>

                        <TableMaterial
                            className={"bill-table-logs"}

                            columns={[
                                {
                                    field: "date", name: "Date", render: (obj) => {
                                        return moment(obj.date).format("DD/MM/YYYY HH:mm")
                                    }
                                },
                                {
                                    field: "status", name: "status", render: (obj) => {
                                        return obj.status + (obj.user ? " " + obj.user : "")
                                    }
                                },
                                { field: "device", name: "device" },

                                {
                                    field: "navigator", name: "navigator", render: (obj) => {
                                        return obj.navigator ? obj.navigator.userAgent : ""
                                    }
                                },

                                {
                                    field: "ip", name: "ip", render: (obj) => {
                                        return obj.ip || ""
                                    }
                                },
                                {
                                    field: "ip city", name: "ip city", render: (obj) => {
                                        return obj.ip_localisation ? obj.ip_localisation.city : ""
                                    }
                                },
                            ]}

                            // functionCollapse={(row) => {
                            //     return row.logs || []
                            // }}
                            // subColumn={[
                            //     { field: "date", name: "date" },


                            // ]}

                            data={this.state.bill.logs || []}
                        />
                    </div>
                </>
            },
        ]





        return <>
            <CombigoSnackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={this.state.errorMessage !== null || this.state.snackMessage !== null}
                onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
                variant={this.state.errorMessage != null ? 'error' : "success"}
                autoHideDuration={10000}
                message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
            />
            <div>



                <Dialog
                    open={this.state.openModalNext ? true : false}
                    onClose={() => {
                        this.setState({ openModalNext: null })
                    }}
                >
                    <DialogTitle id="alert-dialog-title">
                        {this.state.openModalNext === "mail_bill_pay" ? "Envoyé mail facture" : "Envoyé mail confirmation facture"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.openModalNext === "mail_bill_pay" ? "Voulez vous envoyé un mail avec le lien pour régler la facture au client "
                                : "Voulez vous envoyé un mail du reception du paiement de la facture au client la facture au client "} {this.state.bill.mail}

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            this.setState({ openModalNext: null })
                        }} color="primary">
                            {"Annuler"}
                        </Button>
                        <Button onClick={() => {
                            if (!this.state.bill.mail) {
                                return this.setState({ errorMessage: "Email is not complete" })
                            }
                            this.props.sendMail({
                                user: this.props.nameUser,
                                action: this.state.openModalNext,
                                // price: this.state.bill.price,
                                mail: this.state.bill.mail,
                                // locale: this.state.bill.locale,
                                // billId: this.state.bill.billId,
                                // bookingId: this.state.bill.bookingId,
                                // bookingReference: this.state.bill.bookingReference

                            }, (response, responseJson) => {
                                if (response.status === 200) {
                                    this.setState({ snackMessage: "Mail send", openModalNext: null })
                                }
                                else {
                                    this.setState({ errorMessage: "No possible to send the message" })
                                }
                            })

                        }} color="primary" autoFocus >
                            {this.state.openModalNext === "mail_bill_pay" ? "Envoyer" : "Envoyer"}

                        </Button>
                    </DialogActions>
                </Dialog>



                <Dialog
                    open={this.props.open}
                    onClose={() => {
                        this.props.onClose()
                    }}
                    className={"create-bill-modal"}
                >


                    <div style={{ display: "flex", "flewDirection": "row", borderBottom: "1px solid grey" }}>


                        <DialogTitle >
                            <div style={{ "display": "flex" }}>
                                <div style={{ marginRight: "20px" }}>{"Facture"}</div>

                                <Stack direction="row" spacing={1}>
                                    {this.state.bill.statusPayment && !flagNewBill ? <Chip label={this.state.bill.statusPayment} /> : null}
                                </Stack>
                            </div>
                        </DialogTitle>

                        <div style={{ "flexGrow": 10 }}></div>

                        {/* <div style={{ flexGrow: 1 }}></div> */}
                        <IconButton color="secondary"
                            // size="large"
                            onClick={() => {
                                navigator.clipboard.writeText("https://www.combigo.com/bill/init?id=" + this.state.bill.billId);
                                this.setState({ snackMessage: "Url est copié dans le presse papier!" })
                            }}  >
                            <ContentPasteIcon />
                        </IconButton>
                        {/* <IconButton onClick={() => {
                            if (this.props.onClose) this.props.onClose()

                        }}>
                            <MinimizeIcon />
                        </IconButton> */}
                        <IconButton onClick={() => {
                            // this._reset()
                            if (this.props.onClose) this.props.onClose()

                        }}>
                            <CloseIcon />
                        </IconButton>

                    </div>

                    <DialogContent>

                        {listRecap.map(function (obj, index) {
                            if (obj.active === false) return null
                            return <Accordion expanded={this.state["expand_" + index]} key={index}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    onClick={() => {
                                        let obj = {}
                                        obj["expand_" + index] = !this.state["expand_" + index]
                                        this.setState(obj)
                                    }}
                                >
                                    <Typography className={""}>{obj.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {obj.render}
                                </AccordionDetails>
                            </Accordion>
                        }.bind(this))}
                        {/* 
                        <DialogContentText className={"modal-voucher"}
                            id="alert-dialog-description" style={{ display: "flex", flexDirection: "column" }}>
                            {this.state.bill.createdBy ? <Grid>
                                <span>{"Created by " + this.state.bill.createdBy.id + " le " + moment(this.state.bill.createdBy.date).format()}</span>
                            </Grid> : null}
                            {this.state.bill.modifiedBy ? <Grid>
                                <span>{"Modified by " + this.state.bill.modifiedBy.id + " le " + moment(this.state.bill.modifiedBy.date).format()}</span>
                            </Grid> : null}
                        </DialogContentText> */}

                    </DialogContent>


                    <DialogActions>
                        <Button onClick={() => {
                            this.props.onClose()
                        }} color="primary">
                            {"Annuler"}
                        </Button>
                        <Button onClick={() => {
                            if (this.onSave() === true) {
                                this.props.onSave({
                                    newBill: flagNewBill,
                                    "createdBy": {
                                        id: this.props.nameUser,
                                        date: Date.now(),
                                        ...this.state.bill.createdBy,
                                    },
                                    "modifiedBy": {
                                        ...this.state.bill.modifiedBy,
                                        id: this.props.nameUser,
                                        date: Date.now(),
                                    },
                                    logs: [
                                        {
                                            date: Date.now(),
                                            status: "CREATED",
                                            comment: "Created by " + this.props.nameUser,
                                            user: this.props.nameUser
                                        }
                                    ],
                                    ...this.state.bill,

                                }, (response, responseJson) => {
                                    if (response.status === 200) {


                                        this.setState({ snackMessage: "Ok to save bill" })
                                        this.props.onClose()

                                    }
                                    else {
                                        this.setState({ errorMessage: "No possible to save bill" })
                                    }
                                    // console.log(response,responseJson)
                                })
                            }

                        }} color="primary" autoFocus>
                            {flagNewBill ? "Créer" : "Enregistrer"}
                        </Button>
                    </DialogActions>
                </Dialog>

            </div >
        </>

    }
}



const mapStateToProps = (state) => {
    return { locale: state.user.locale, nameUser: state.user.name, base: state.data };
};

export default connect(mapStateToProps, {

})(withRouter(ModalVoucher));


/*






 <Grid className={"block"} >
                        <CheckBoxCombigo
                            className={"select-status"}
                            styleSelect={{ minWidth: "100%" }}

                            label={"Status"}
                            list={["ACTIVE", "INACTIVE", "CANCEL"]}
                            multiple={false}
                            value={this.state.bill.status}
                            onChange={(value) => {
                                this.onChange("status",value)
                            }}
                        ></CheckBoxCombigo>
                    </Grid>
                    <Grid className={"block"}>
                        <FormControl className={"select-status"}>
                            <InputLabel id="demo-simple-select-label">{"Status Customer"}</InputLabel>
                            <Input
                                id="demo-simple-select-label"
                                labelId="demo-simple-select-label"
                                style={{ "color": "black" }}
                                value={this.state.statusCustomer || "."}
                                disabled={true}
                            />
                        </FormControl>
                    </Grid>
                    <Grid className={"block"}>
                        <FormControl className={"select-status"}>
                            <InputLabel id="demo-simple-select-label">{"Status User"}</InputLabel>
                            <Input
                                id="demo-simple-select-label"
                                labelId="demo-simple-select-label"
                                style={{ "color": "black" }}
                                value={this.state.statusUser || "."}
                                disabled={true}
                            />
                        </FormControl>
                    </Grid>
                    <Grid className={"block"}>
                        <FormControl className={"select-status"}>
                            <InputLabel id="demo-simple-select-label">{"Commentaire"}</InputLabel>
                            <Input
                                id="demo-simple-select-label"
                                labelId="demo-simple-select-label"
                                style={{ "color": "black" }}
                                value={this.state.comment}
                                placeholder={"..."}
                                onChange={(ev) => {
                                    this.onChange("comment",ev.target.value )

                                }}
                            />
                        </FormControl>
                       
                        </Grid>
                        <Grid className={"block"}>
                            <DateTimeContainer
                                DateObj={DateTimePicker}
                                autoOk
                                variant="dialog"
                                invalidDateMessage={""}
                                inputVariant="standard"
                                label="Date Rappel"
                                format="DD/MM/yyyy hh:mm"
                                value={moment(this.state.date * 1000)}
                                TextFieldComponent={
                                    (props) =>
                                        <TextField {...props}
                                            className={"text-input "}
                                            variant={"standard"} />
                                }
                                InputAdornmentProps={{ position: "start" }}
                                onChange={(date, value) => {
                                    let time = new Date(moment(date).format("YYYY-MM-DDThh:mm") + ":00+00:00") / 1000
                                  //  this.onChange("comment",ev.target.value )
    
                                    // this.onChange("dateRappel", time)
                                }}
                            />
                        </Grid>
    
    




*/