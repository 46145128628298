import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import moment from "moment";
import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';
import Tags from './../../Components/Utils/EditTags';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { parseQuery, _fetch } from "./../../helpers"
import DragAndDrop from "./../../lib/DrapAndDropFile/DragAndDrop"

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import EditorText from "./../EditorText/EditorText"

import TooltipCombigo from "./../Utils/TooltipCombigo"
import TextField from '@material-ui/core/TextField';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker, DatePicker, DateTimePicker } from "@material-ui/pickers";
import SelectCheckBoxCombigo from "./../Utils/Select_CheckBox"
import Checkbox from '@material-ui/core/Checkbox';
import DateTimeContainer from "./../Utils/DateTimeContainer"
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import "./ModalTasks.scss"



class ModalTasks extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            task: {},
            // expand_0: true,
            listUsers: [],
            ready: false,
            errorMessage: null,
            snackMessage: null
        }
        this.version = props.version


        // this.anchorElRef =React.createRef();
    }

    componentDidMount() {

        let listUsers = [{ title: "ALL_TEAM", id: "ALL_TEAM" }]
        for (let l of this.props.base.users) {
            if (l.role.includes("AUTO")) continue
            if (l.role.includes("DISABLED")) continue
            if (l.role.includes("INACTIVE")) continue

            listUsers.push({ id: l.id, title: l.name || l.title })
        }
        this.setState({ ready: true, listUsers: listUsers, task: this._createTask(this.props.task) })

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.version != this.props.version) {
            this.version = this.props.version
            this.setState({ task: this._createTask(this.props.task) })
        }
    }

    _createTask = (task) => {
        if (task) {
            this.setState({ mode: "view" })
            return task
        }
        this.setState({ mode: "created" })
        let _id = "t-" + String(Date.now() + "-" + Math.round(Math.random() * 1000000000) + "-" + Math.round(Math.random() * 100000))
        return {
            taskId: _id,
            _id: _id,
            resume: "",
            content: "",
            source: "",
            tags: [],
            assignTo: [],
            relationId: this.props.relationId,
            taskLink: [],
            // typeTask: "ONE_SHOT",
            priority: "NORMAL",
            status: "OPEN",
            files: [],
            created: {
                date: Date.now(),
                idUser: this.props.nameUser
            },
            modified: {
                date: Date.now(),
                idUser: this.props.nameUser
            },
            dateLimit: "",
            automation: [
                // {conditions:}
            ],
            dateOpenAt: "",
            private: false,
            modeCreated: true,
            numVersion: 0,
            _hashVersion: "",
            comments: [
                // {
                //     content:"",
                //     created:{
                //         date: Date.now(),
                //         idUser: this.props.nameUser
                //     }
                // }
            ]
        }
    }



    _resumeTask = (data = "") => {

        let resumeTask = data.replace(/<[^>]+>/g, '').trim()
        while (resumeTask.indexOf("&nbsp;") != -1) resumeTask = resumeTask.replace("&nbsp;", " ")
        while (resumeTask.indexOf("  ") != -1) resumeTask = resumeTask.replace("  ", " ").trim()

        if (resumeTask > 40) {
            resumeTask = resumeTask.substr(0, 40)
        }
        if (this.state.resumeTask != resumeTask) {
            this.setState({ resumeTask: resumeTask })
        }
        return resumeTask
    }



    onChange = (obj) => {
        let task = { ...this.state.task }
        for (let key in obj) task[key] = obj[key]
        this.setState({ task: task })
    }



    _saveTask() {
        let task = { ...this.state.task, resume: this.state.task.resume || this.state.resumeTask }
        // console.log("task",task)
        if (!task.content){
          return   this.setState({errorMessage:"No description!"})
        }
        if (!task.tags || task.tags.length===0){
            return   this.setState({errorMessage:"No tags!"})
          }
        request.call(this, {
            data: {
                ...task,
            },
            taskId: task.taskId
        }, "/crm/tasks/update")


        function request(body, url) {
            _fetch(process.env.REACT_APP_URL + url, {
                "timeout": 10000,
                "nbRetry": 1,
                "method": "post",
                "headers": {},
                "body": body
            })
                .then(function (response) {
                    if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                    let data = response.json()
                    this.setState({ snackMessage: "La tache a été crée" })
                    if (this.props.onUpdate) this.props.onUpdate(data)
                    this.setState({task: this._createTask(this.props.task)})
                }.bind(this))
                .catch(function (e) {
                    console.error("Error create task", e);
                    this.setState({ errorMessage: "La tache n'a pas pu être crée, veuillez réessayer" })
                    if (this.props.onUpdate) this.props.onUpdate(undefined, { error: true })

                }.bind(this));
        }


    }


    render() {

        if (!this.state.ready) return null

        return (
            <>
                
                <Dialog
                    className={"modal-task"}
                    open={this.props.open || false}
                    onClose={this.props.onClose}
                    maxWidth={"xl"}
                >

                    <Grid className={"title-container"}>

                        <Grid className={"text-container"}>
                            <span className={"title"}>{"Création d'une Task"}</span>
                            {<span className={"subtitle"}>{"Référence (" + this.state.task._id + ")"}</span>}
                        </Grid>
                        <div style={{ flexGrow: 1 }}></div>
                        <Grid>
                            <IconButton size="small" onClick={this.props.onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>


                    <DialogContent>


                        <Grid style={{ width: "100%" }} className={"line"}>
                            <EditorText
                                configEditor={{
                                    maxHeight: "200px",
                                    height: "200px"
                                }}
                                version={this.state.task._id + "-" + this.version}
                                onChange={(value) => {
                                    this._resumeTask(value)
                                    this.onChange({ content: value })
                                }}
                                value={this.state.task.content}
                            />
                        </Grid>


                        <Grid className={"line"} style={{ width: "100%" }}>
                            <TextField
                                className={""}
                                style={{ width: "100%" }}
                                label={"Resume"}
                                value={this.state.task.resume}
                                placeholder={this.state.resumeTask}
                                onChange={(ev) => {
                                    this.onChange({ resume: ev.target.value })
                                }}
                            />
                        </Grid>
                        {/* <Grid style={{ width: "100%" }} className={"line"} >
                            <TextField
                                className={""}
                                label={"RelationId"}
                                value={this.state.task.relationId}
                                onChange={(value) => {
                                    this.onChange({ relationId: value })
                                }}
                            />
                        </Grid> */}
                        <Grid container direction={"row"}>
                            <Grid className={"line"} xs={12} sm={12} spacing={2}>
                                <Tags className={""}
                                    label={"Tags"}
                                    style={{ width: "100%" }}
                                    multiple={true}
                                    value={this.props.base.task.listTag.filter((o) => {
                                        return this.state.task.tags.includes(o.id) ? true : false
                                    })}
                                    list={this.props.base.task.listTag}
                                    onChange={(value) => {
                                        let newValue = value.map((val) => { return val.id })
                                        this.onChange({ tags: newValue })
                                    }}
                                />
                            </Grid>



                        </Grid>
                        <Grid container direction={"row"}>

                            <Grid className={"line"} xs={6} sm={6}>

                                <Tags
                                    style={{ width: "90%" }}
                                    className={""}
                                    label={"Assigné pour :"}
                                    multiple={true}
                                    value={this.state.listUsers.filter((o) => {
                                        return this.state.task.assignTo.includes(o.id) ? true : false
                                    })}
                                    list={this.state.listUsers}
                                    onChange={(value) => {
                                        let newValue = value.map((val) => { return val.id })
                                        this.onChange({ assignTo: newValue })
                                    }}
                                />
                            </Grid>

                            <Grid xs={6} sm={6} style={{ width: "100%" }} className={"line"} >
                                <SelectCheckBoxCombigo
                                    className={""}
                                    style={{ width: "90%" }}

                                    label={"Elements déclencheurs ?"}
                                    multiple={false}
                                    getLabel={(option) => { return option.title }}
                                    getMatching={(option) => { return option.id }}
                                    value={this.state.task.source}
                                    list={this.props.base.task.source}
                                    onChange={(value) => {
                                        this.onChange({ source: value.id })
                                    }}
                                />
                            </Grid>
                        </Grid>





                        <Grid container direction={"row"}>

                            <Grid xs={4} sm={4} className={"line"} >
                                <SelectCheckBoxCombigo
                                    className={""}
                                    style={{ width: "90%" }}

                                    label={"Status"}
                                    multiple={false}
                                    getLabel={(option) => { return option.title }}
                                    getMatching={(option) => { return option.id }}
                                    value={this.state.task.status}
                                    list={this.props.base.task.status}
                                    onChange={(value) => {
                                        this.onChange({ status: value.id })
                                    }}
                                />
                            </Grid>

                            {/* <Grid xs={4} sm={4} className={"line"} >
                                <SelectCheckBoxCombigo
                                    className={""}
                                    style={{ width: "90%" }}
                                    label={"Type Task"}
                                    multiple={false}
                                    getLabel={(option) => { return option.title }}
                                    getMatching={(option) => { return option.id }}
                                    value={this.state.task.typeTask}
                                    list={this.props.base.task.typeTask}
                                    onChange={(value) => {
                                        this.onChange({ typeTask: value.id })
                                    }}
                                />
                            </Grid> */}


                            <Grid xs={4} sm={4} className={"line"} >
                                <SelectCheckBoxCombigo
                                    className={""}
                                    style={{ width: "90%" }}
                                    label={"Priorité"}
                                    multiple={false}
                                    getLabel={(option) => { return option.title }}
                                    getMatching={(option) => { return option.id }}
                                    value={this.state.task.priority}
                                    list={this.props.base.task.priority}
                                    onChange={(value) => {
                                        this.onChange({ priority: value.id })
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container direction={"row"}>

                            <Grid xs={4} sm={4} className={"line"} >




                                {
                                    <DateTimeContainer
                                        DateObj={DateTimePicker}
                                        label={"Date Maximum"}
                                        autoOk={true}
                                        value={this.state.task.dateLimit || ""}
                                        format={"DD/MM/yyyy HH:mm"}
                                        onChange={(newValue, h) => {
                                            this.onChange({ dateLimit: moment(newValue) })
                                        }}
                                        invalidLabel=""
                                        invalidDateMessage={null}
                                        variant={"inline"}
                                        ampm={false}
                                        style={{ width: "90%" }}
                                        TextFieldComponent={(props) => {
                                            return <TextField {...props}
                                            // value={this.state.task.dateLimit ? this.state.task.dateLimit : ""}
                                            />
                                        }}
                                    />
                                }

                            </Grid>

                            <Grid xs={4} sm={4} className={"line"} >
                                {this.state.task.status && this.state.task.status == "OPEN_AT" ?
                                    < DateTimeContainer
                                        DateObj={DateTimePicker}
                                        label={"Date Open at"}
                                        autoOk={true}
                                        value={this.state.task.dateOpenAt || ""}
                                        format={"DD/MM/yyyy HH:mm"}
                                        onChange={(newValue, h) => {
                                            this.onChange({ dateOpenAt: moment(newValue) })
                                        }}
                                        invalidLabel=""
                                        invalidDateMessage={null}
                                        variant={"inline"}
                                        ampm={false}
                                        style={{ width: "90%" }}
                                        TextFieldComponent={(props) => {
                                            return <TextField {...props}
                                            // value={this.state.task.dateOpenAt ? this.state.task.dateOpenAt : ""}
                                            />
                                        }}
                                    />

                                    : null}
                            </Grid>
                        </Grid>


                        <Grid container direction={"row"}>
                            <Grid xs={4} sm={4} className={"line"} >
                                <FormControlLabel
                                    control={<Checkbox
                                        style={{ color: "#33c" }}
                                        checked={this.state.task.private}
                                        onChange={(ev, value_) => {
                                            this.onChange({ private: value_ })
                                        }
                                        }
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />}
                                    label="Tâche Privée"
                                />
                            </Grid>

                        </Grid>

                        <Grid container direction={"row"}>
                            <DragAndDrop

                                dropzoneText={"Pièces jointes"}
                                mode={"edit"}

                                files={this.state.task.files}
                                onAdd={(files, addNewFiles) => {
                                    this.onChange({ files: files })
                                }}
                                onDelete={(files, file, index) => {
                                    this.onChange({ files: files })

                                }}
                            />
                        </Grid>


                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.onClose} color="primary">
                            {"Annuler"}
                        </Button>
                        <Button onClick={() => {

                            if (this._saveTask) this._saveTask()
                        }} color="primary" autoFocus>
                            {"Créer"}
                        </Button>
                    </DialogActions>
                </Dialog>

                <CombigoSnackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.errorMessage !== null || this.state.snackMessage !== null}
                    onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
                    variant={this.state.errorMessage != null ? 'error' : "success"}
                    autoHideDuration={6000}
                    message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
                />
            </>
        );
    }

}




const mapStateToProps = (state) => {
    return { locale: state.user.locale, nameUser: state.user.name, base: state.data };
};


export default connect(mapStateToProps, {

})(withRouter(ModalTasks));