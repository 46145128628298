
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { injectIntl } from 'react-intl';

import Tooltip from '@material-ui/core/Tooltip';
import { withRouter } from 'react-router-dom';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

import Avatar from '@material-ui/core/Avatar';
import "./Avatar.scss"





class Results extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }




    componentDidMount() {

    }



    render() {

        let unique=new Set()
        return <div className={"display-avatar " + this.props.className}>
            <AvatarGroup className={" avatar-container "} max={this.props.max || 5}>
                {this.props.list && this.props.list.map((user) => {
                    if (unique.has(user.name)) return null
                    unique.add(user.name)
                    return <Tooltip
                        title={user.name}
                    >
                        <Avatar className={"avatar-combigo"}>{user.symbol} </Avatar>
                    </Tooltip>

                })}
            </AvatarGroup>
        </div>


    }

}


export default injectIntl(withRouter(Results));