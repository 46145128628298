
import * as React from 'react';
import { connect } from 'react-redux';
import ModelTable from "./../Table/modelTable"
import './TableAccount.scss';
import moment from "moment";
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';
import Row from "./Row"
import Edit from '@material-ui/icons/Edit';
import AddBox from '@material-ui/icons/AddBox';
import { cloneObject, isMobile } from '../../helpers';
import { createEditRow, noEditRow, addCallback } from "./../Table/helpersTable"
import SelectHistory from "./../Table/select_history"

const columns = [
    {
        "label": "N°",
        getValueTable: (o) => {
            return o.posIndex
        },
        custom: {
            type: "input",
            props: {
                style: { width: "20px" }
            },
            onlyView: true
        },
        options: {
            sort: false
        }
    },
    {
        "label": "Category",
        getValueTable: (o) => { return o.category },
        setValueTable: (row, val) => {
            row.category = val
        },

        custom: {
            type: "list",
            props: {
                disableClearable: true,
                props: { style: { maxWidth: "100px" } }
            },

            getValue: function (obj) {
                return obj && obj.id ? obj.id : obj
            },
            // format: function (obj) {
            //     return obj ? obj.title : ""
            // },
            list: [
                { id: "INIT", title: "INIT" },
                { id: "CANCEL_VOLUNTARY", title: "CANCEL_VOLUNTARY" },
                { id: "DENIED_BOARDED", title: "DENIED_BOARDED" },
                { id: "DISRUPTED_CONNECTION", title: "DISRUPTED_CONNECTION" },
                { id: "MODIFICATION", title: "MODIFICATION" },
                { id: "OPTIONS", title: "OPTIONS" },
                // { id: "BOOKING_KO", title: "Booking Ko" },
                { id: "ERROR", title: "ERROR" },
                { id: "OTHER", title: "OTHER" },
                { id: "BANK", title: "BANK" },


            ],
        },
        options: {
            sort: false
        }
    },
    {
        "label": "Credit", getValueTable: (o) => {
            return "+ " + String((Math.floor(Number(o.credit) * 100) / 100)) + " €"
        }
    },
    {
        "label": "Debit", getValueTable: (o) => {
            return "- " + String((Math.floor(Number(o.debit) * 100) / 100))+ " €"
        }
    },
    {
        "label": "Marge",
        getValueTable: (o) => {
            let margin = o.credit || o.debit ? Math.floor(((o.credit || 0) - (o.debit || 0)) * 100) / 100 : undefined
            return margin !== undefined ? margin +" €"+ (o.credit?" (" + (Math.round(margin / (o.credit || 0) * 1000) / 10) + "%)":"") : ""
        }
    },
    {
        "label": "€ Waiting", getValueTable: (o) => {
            if (!o.waiting) return null
            let coefficient = o.waiting < 0 ? "- " : ""
            return coefficient + String((Math.floor(Math.abs(o.waiting) * 100) / 100))
        }
    },
    {
        "label": "Description",
        setValueTable: (row, val) => {
            row.comment = val
        },
        getValueTable: (o) => { return o.comment },
        custom: {
            type: "input",
            props: { multiline: true }
        },
        options: {
            sort: false
        }
    },
    {
        "label": "",
        getValueTable: (o) => { return o },
        custom: {
            // type:"action",
            onlyEdit: true,
            type: "action_delete",
        },
        options: {
            sort: false
        }
    },
    // {
    //     "label": "Ref",
    //     getValueTable: (o) => { return o.id }
    // },

]


const subColumns = [
    {
        "label": "sens",
        getValueTable: (o) => {
            return o.sens
        },
        setValueTable: (row, val) => {
            row.sens = val
        },
        custom: {
            type: "list",
            props: {
                disableClearable: true,
                style: { maxWidth: "150px", minWidth: "150px" }
            },
            propsInput: { style: { minWidth: "100px" } },
            list: [
                { id: "Client->Combigo", title: "Client->Combigo" },
                { id: "Combigo->Client", title: "Combigo->Client" },
                { id: "Transporteur->Combigo", title: "Transporteur->Combigo" },
                { id: "Combigo->Transporteur", title: "Combigo->Transporteur" },
                { id: "Combigo->*", title: "Combigo->*" },
                { id: "*->Combigo", title: "*->Combigo" },
                { id: "Transporteur->Client", title: "Transporteur->Client" },
                { id: "Client->Transporteur", title: "Client->Transporteur" },
            ],
        },
        options: {
            sort: false
        }
    },
    {
        "label": "type",
        getValueTable: (o) => { return o.type },
        setValueTable: (row, val) => {
            row.type = val
        },
        custom: {
            type: "list",
            props: {
                disableClearable: true,
                style: { maxWidth: "110px", minWidth: "110px" }
            },
            propsInput: { style: { minWidth: "110px" } },

            // updateData: (value) => {
            //     return value.id
            // },
            list: [
                { id: "TICKET", title: "TICKET" },
                { id: "REFUND", title: "REFUND" },
                { id: "VOUCHER", title: "VOUCHER" },
                { id: "OPTION", title: "OPTION" },
                { id: "FEE", title: "FEE" },
                { id: "BAGGAGE", title: "BAGGAGE" },
                // { id: "CUSTOMER_SUCCESS", title: "CUSTOMER_SUCCESS" },
                // { id: "SERVICES", title: "SERVICES" },
                { id: "ERROR_COMBIGO", title: "ERROR_COMBIGO" },
                { id: "LITIGE", title: "LITIGE" },
                { id: "BUNDLE", title: "BUNDLE" },





            ],
        }
        ,
        options: {
            sort: false
        }
    },
    {
        "label": "amountEUR",
        setValueTable: (row, val) => {
            row.amountEUR = val
        },
        getValueTable: (o) => {
            return o.amountEUR
        },
        getReadTable: (o) => {
            let coefficient = o.sens.indexOf("Combigo->") != -1 ? "- " : (o.sens.indexOf("->Combigo") != -1 ? "+ " : "")
            if (o.amountEUR < 0) coefficient = ""
            return o.amountEUR && coefficient ? (coefficient + o.amountEUR).trim() : o.amountEUR
        },
        custom: {

            type: "input",
            props: {
                style: { maxWidth: "100px" }
            }
        },
        options: {
            sort: false
        }
    },
    {
        "label": "source",
        getValueTable: (o) => { return o.source },
        setValueTable: (row, val) => {
            row.source = val
        },
        custom: {
            type: "autocomplete_create",
            props: {
                disableClearable: true,
                style: { maxWidth: "150px", minWidth: "110px" }
            },
            format: function (obj) {
                return obj ? obj.title : ""
            }
        },
        options: {
            sort: false
        }
    },
    {
        "label": "booking_reference",
        setValueTable: (row, val) => {
            row.booking_reference = val
        },
        getValueTable: (o) => { return o.booking_reference },
        custom: {
            type: "input",
        },
        options: {
            sort: false
        }
    },
    {
        "label": "comment",
        getValueTable: (o) => { return o.comment },
        setValueTable: (row, val) => {
            row.comment = val
        },
        custom: {
            type: "input",
            props: { multiline: true }
        },
        options: {
            sort: false
        }
    },
    {
        "label": "Not Sure",
        setValueTable: (row, val) => {
            row.amountIncertain = val
        },
        getValueTable: (o) => { return o.amountIncertain },
        custom: {
            type: "checkbox",
        },
        options: {
            sort: false
        }
    },

    {
        "label": "Waiting",
        setValueTable: (row, val) => {
            row.waiting = val
        },
        getValueTable: (o) => { return o.waiting },
        custom: {
            type: "checkbox",
        },
        options: {
            sort: false
        }
    },

    {
        "label": "files",
        getValueTable: (o) => { return o.files },
        setValueTable: (row, val) => {
            row.files = val
        },
        custom: {
            type: "files",
            props: {
                style: { maxWidth: "80px" }
            }
        },
        options: {
            sort: false
        }
    },
    {
        "label": "date",
        getValueTable: (o) => { return o.date },
        setValueTable: (row, val) => {
            row.date = val
        },
        custom: {
            type: "date",
            props: { style: { maxWidth: "100px" } }
        },
        options: {
            sort: false
        }
    },
    {
        "label": "Paiement",
        getValueTable: (o) => { return o.paymentType },
        setValueTable: (row, val) => {
            row.paymentType = val
        },
        custom: {
            type: "list",
            props: {
                disableClearable: true,
                style: { maxWidth: "110px", minWidth: "110px" }
            },
            propsInput: { style: { minWidth: "110px" } },

            // updateData: (value) => {
            //     return value.id
            // },
            list: [
                { id: "CB_QONTO", title: "CB_QONTO" },
                { id: "CB_REVOLUT", title: "CB_REVOLUT" },

                { id: "CB_BANQUE_POPULAIRE", title: "CB_BANQUE_POPULAIRE" },
                { id: "CB_STRIPE", title: "CB_STRIPE" },

                { id: "DUFFEL_WALLET", title: "DUFFEL_WALLET" },
                // { id: "SERVICES", title: "SERVICES" },
                { id: "AER_TICKET_WALLET", title: "AER_TICKET_WALLET" },
                { id: "TRAVELFUSION_WALLET", title: "TRAVELFUSION_WALLET" },
                { id: "KIWI_WALLET", title: "KIWI_WALLET" },
                // { id: "CB_BNP", title: "CB_BNP" },
                { id: "WALLET_TRANSPORTER", title: "WALLET_TRANSPORTER" },

                { id: "VOUCHER_AIRLINE", title: "VOUCHER_AIRLINE" },
                {id:"VIR_QONTO","title":"VIR_QONTO"},
                {id:"VIR_BANQUE_POPULAIRE","title":"VIR_BANQUE_POPULAIRE"},
                {id:"PRLV_BANQUE_POPULAIRE","title":"PRLV_BANQUE_POPULAIRE"},
                { id: "CB_IXARIS", title: "CB_IXARIS" },

                // { id: "CB_ANYTIME", title: "CB_ANYTIME" },

                // { id: "VIREMENT", title: "VIREMENT" },

            ],
        }
        ,
        options: {
            sort: false
        }
    },
    // {
    //     "label": "",
    //     getValueTable: (o) => { return o },
    //     setValueTable:(row,val)=>{
    //     },
    //     custom: {
    //         // type:"action",
    //         onlyEdit: true,
    //         type: "action_move",
    //     },
    //     options: {
    //         sort: false
    //     }
    // },
    {
        "label": "",
        getValueTable: (o) => { return o },
        setValueTable: (row, val) => {
        },
        custom: {
            // type:"action",
            onlyEdit: true,
            type: "action_delete",
        },
        options: {
            sort: false
        }
    },
    // {
    //     "label": "Parent",

    //     getValueTable: (o) => { 
    //         if (o.parentIdTemp ===undefined){
    //             return o.parentId
    //         }
    //         return o.parentIdTemp 
    //      },
    //     setValueTable: (row, val) => {
    //         console.log("Val",val)
    //         row.parentIdTemp = val
    //         //return row
    //     },

    //     custom: {
    //         // type:"action",
    //         onlyEdit: true,
    //         type: "list",
    //         onClickOption:(a,b,c)=>{
    //             console.log(a,b,c)
    //             // row.parentId =  row.parentIdTemp
    //             // row.parentIdTemp = undefined
    //         },
    //         updateData: (value) => {
    //             console.log("value",value)
    //             return value.id
    //         },
    //         props: {
    //             disableClearable: true,
    //             style: { maxWidth: "150px", minWidth: "150px" }
    //         },
    //         propsInput: { style: { minWidth: "100px" } },
    //         list: [         ]
    //     },
    //     options: {
    //         sort: false
    //     }
    // },
]




let listOTA = [
    {
        name: "Aer Ticket", idSya: "AER_TICKET", type: "OTA"
    },
    {
        name: "Ulysse Travel", idSya: "ULYSSE", type: "OTA"
    },
    {
        name: "Trenes", idSya: "TRENES", type: "OTA"
    },
    {
        name: "Expedia", idSya: "EXPEDIA", type: "OTA"
    },
    {
        name: "Gotogate", idSya: "GOTOGATE", type: "OTA"
    },
    {
        name: "BudgetAir", idSya: "BUDGETAIR", type: "OTA"
    },
    {
        name: "Tripair", idSya: "TRIPAIR", type: "OTA"
    },
    {
        name: "MyTrip", idSya: "MY_TRIP", type: "OTA"
    },
    {
        name: "Trip.com", idSya: "TRIP_COM", type: "OTA"
    },
    {
        name: "Booking", idSya: "BOOKING", type: "OTA"
    },
    {
        name: "Kiwi", idSya: "KIWI", type: "OTA"
    },
    {
        name: "Go Voyages", idSya: "GO_VOYAGES", type: "OTA"
    },
    {
        name: "Last Minute", idSya: "LAST_MINUTE", type: "OTA"
    },
    {
        name: "Oui Sncf", idSya: "OUI_SNCF", type: "OTA"
    },
    {
        name: "SNCF Connect", idSya: "SNCF_CONNECT", type: "OTA"
    },
    {
        name: "Trainline", idSya: "TRAINLINE", type: "OTA"
    },
    {
        name: "Omio", idSya: "OMIO", type: "OTA"
    },
    {
        name: "MisterFly", idSya: "MISTERFLY", type: "OTA"
    },
    {
        name: "Edreams", idSya: "EDREAMS", type: "OTA"
    },
    {
        name: "Busbud", idSya: "BUSBUD", type: "OTA"
    },
    {
        name: "Distribusion", idSya: "DISTRIBUSION", type: "NDC"
    },
    {
        name: "Travelfusion", idSya: "TF", type: "NDC"
    },
    {
        name: "Duffel", idSya: "DUFFEL", type: "NDC"
    },
    // {
    //     name: "Save A Train", idSya: "SAT", type: "OTA"
    // },
    {
        name: "SNCB", idSya: "SNCB", type: "OTA"
    },
    {
        name: "NSI", idSya: "NSI", type: "OTA"
    },
    {
        name: "Rail Europe", idSya: "RAIL_EUROPE", type: "OTA"
    },
    {
        name: "Combigo", idSya: "COMBIGO", type: "OTA"
    },
    {
        name: "Primer", idSya: "PRIMER", type: ""
    },
    {
        name: "Ingenico", idSya: "INGENICO", type: ""
    },
    {
        name: "Stripe/Primer", idSya: "STRIPE_PRIMER", type: ""
    }
    , {
        name: "Ingenico/Primer", idSya: "INGENICO_PRIMER", type: ""
    }
    , {
        name: "Stripe", idSya: "STRIPE", type: ""
    }
    ,
    {
        name: "Client de Combigo", idSya: "CLIENT", type: ""
    },
    {
        name: "DirectFerries", idSya: "DIRECT_FERRIES", type: "OTA"
    },
    {
        name: "Opodo", idSya: "OPODO", type: "OTA"
    },
    {
        name: "Qonto Combigo", idSya: "QONTO_COMBIGO", type: "BANK"
    },
    {
        name: "BNP Combigo", idSya: "BNP_COMBIGO", type: "BANK"
    },
    {
        name: "BP Combigo", idSya: "BP_COMBIGO", type: "BANK"
    },
    {
        name: "Koala", idSya: "KOALA_INSURANCE", type: "INSURANCE"
    },
]







function getDataTable(data) {

    if (data && data.data) {
        return data.data
    }
    else if (typeof data === "object") {
        return data
    }
    return []
}

function getDataVersion(data) {

    if (data && data.version) {
        return data.version
    }
    return ""
}



const options = {
    filterType: 'checkbox',
    print: false,
    display: false,
    rowsPerPage: 20,
    expandableRows: false,
    selectableRows: 'none',
    selectableRowsHideCheckboxes: false,
    download: false,
    filter: false,
    search: false,
    viewColumns: false,
};



class Account extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            refresh: false,
            mode: "view",
            openRow: {},
            errorMessage: null,
            snackMessage: null,
            dataEdit: null
        }




        subColumns[3].custom.list = []
        for (let line of this.props.base.carrier.concat(listOTA)) {
            subColumns[3].custom.list.push({ title: line.name + (line.code && line.code.iata ? " (" + line.code.iata + ")" : "") + (line.type == "OTA" ? " (OTA)" : ""), name: line.name, idSya: line.idSya, idP: line.idP, type: "transporter" })
        }

        subColumns[3].custom.list.sort(function (a, b) {
            return a.title.localeCompare(b.title)
        })


        addCallback(columns, { delete: this.deleteLine })
        addCallback(subColumns, { delete: this.deleteLine })



        this.columnsNoEdit = noEditRow(columns)
        this.columnsEdit = createEditRow(columns, (data, value, ev, type) => {
            //console.log("ici", data, value, ev, type)

            // let value1 = ev.tableData[ev.rowIndex][ev.columnData.name]
            // let value2 = ev.rowData[ev.columnIndex]
            // if (typeof value1 == "object") value1 = JSON.stringify(value1)
            // if (typeof value2 == "object") value2 = JSON.stringify(value2)

            // if (value1 != value2) {
            //     this.state.dataEdit[ev.rowIndex][ev.columnData.name] = ev.rowData[ev.columnIndex]
            //     this.setState({ dataEdit: this.state.dataEdit })
            // }
        })
      
        this.subColumnsNoEdit = noEditRow(subColumns)
     
        this.subColumnsEdit = createEditRow(subColumns, (data, value, ev, type) => {
            // console.log("ici", data, value, ev, type)
            // let value1 = ev.tableData[ev.rowIndex][ev.columnData.name]
            // let value2 = ev.rowData[ev.columnIndex]
            // if (typeof value1 == "object") value1 = JSON.stringify(value1)
            // if (typeof value2 == "object") value2 = JSON.stringify(value2)

            // if (value1 != value2) {
            //     this.state.dataEdit[ev.rowIndex][ev.columnData.name] = ev.rowData[ev.columnIndex]
            //     this.setState({ dataEdit: this.state.dataEdit })
            // }
        })

        this.numVersion = this.props.data.numVersion




    }


    deleteLine = (row) => {

        let onlyChild = row.parentId ? true : false
        let parentId
        let dataFilter = this.state.dataEdit.filter(function (obj, index) {
            if (obj.id && obj.id === row.id) {
                parentId = onlyChild ? undefined : obj._id
                return false
            }
            if (obj._id && obj._id === row._id) {
                parentId = onlyChild ? undefined : obj._id
                return false
            }
            if (!onlyChild) {
                if (obj._id && obj._id === row.parentId) return false //  a revoir
                if (obj.parentId && obj.parentId === row.parentId) return false //  a revoir
            }
            return true
        })
        if (parentId) {
            dataFilter = dataFilter.filter(function (obj, index) {
                if (parentId === obj.parentId) {
                    return false
                }
                return true
            })
        }

        // console.log("this.state.dataEdit=",this.state.dataEdit,"row=",row)
        this.setState({
            dataEdit: dataFilter
        })
    }

    componentDidUpdate() {
        if (this.id != this.props.booking.bookingId || this.numVersion != this.props.data.numVersion) {
         
            if (this.id != this.props.booking.bookingId ){
                if (this.props.onChangeMode) this.props.onChangeMode("view")
                this.setState({ mode: "view", panelOpenRow: false, dataEdit: null, editIndex: null, versionEdit: null,openRow:{} })
            }
            this.numVersion = this.props.data.numVersion
            this.id = this.props.booking.bookingId
            this.setState({refresh:true})

        }
    }

    componentDidMount() {

    }


    _id = () => {
        return Math.floor((Date.now() - 1614014467825) / 60000)
    }

    addLine = (type) => {
        let line
        if (type == "empty") {
            line = Object.assign({idUser:this.props.nameUser,date:Date.now()}, this.props.modelLineAddEmpty || {})
        }
        else line = Object.assign({idUser:this.props.nameUser,date:Date.now()}, this.props.modelLineAdd || {})



        let openRow = { ...this.state.openRow }
        //if (!line.id) line.id = this._id() + "." + String(Math.round(Math.random() * 100000))
       //// line.secretId = this._id() + "." + String(Math.round(Math.random() * 100000))
        if (! line._id) line._id = this._id() + "." + String(Math.round(Math.random() * 10000000))

        if (line._id)openRow[line._id] = true
        //if (line.id) openRow[line.id] = true

        this.setState({ dataEdit: this.state.dataEdit.concat([line]), mode: "edit", openRow: openRow }, () => {
            this.addSubLine(undefined, line._id)
        })

    }



    addSubLine = (type, parentId) => {
        let line
        if (type == "empty") {
            line = Object.assign({idUser:this.props.nameUser}, this.props.modelLineAddEmpty || { date: moment() })
        }
        else line = Object.assign({idUser:this.props.nameUser}, this.props.modelLineAdd || { date: moment() })

        if (parentId) line.parentId = parentId
        line.id = this._id() + "." + String(Math.round(Math.random() * 10000000))
       // line.secretId = this._id() + "." + String(Math.round(Math.random() * 100000))
        this.setState({ dataEdit: this.state.dataEdit.concat([line]), mode: "edit" })
    }



    edit = (cb) => {
        let dataEdit = cloneObject(getDataTable(this.props.data))

        // for (let line of dataEdit) {
        //     line.secretId = (Date.now() - 1614014467825) + "." + String(Math.round(Math.random() * 1000000))
        // }
       // console.log("Version edit " + getDataVersion(this.props.data))
        this.setState({ dataEdit: dataEdit, mode: "edit", versionEdit: getDataVersion(this.props.data) }, cb)
        if (this.props.onChangeMode) this.props.onChangeMode("edit")
    }



    changeValue = (value, rowData, columnData, indexColumn) => {
        // console.log("Modification " ,value,rowData,columnData,indexColumn)
       
        let flagWaiting=false

        let data = this.state.dataEdit.map((o) => {
            if ( (o.id && o.id===rowData.id)|| (o._id && o._id===rowData._id)){
                //console.log("rowdata",rowData,"value",value)
                if ( value==="Transporteur->Combigo" && rowData.waiting===undefined){
                    rowData.waiting=true
                    flagWaiting=true
                   // console.log(rowData)
                }
                return rowData
            }
           
            // if (o.secretId === rowData.secretId) {
            //     return rowData
            // }
            return o
        })

        if (columnData.setValueTable) {
            columnData.setValueTable(rowData, value)
        }
        if (flagWaiting){
            this.setState({refresh:true})
        }

        this.setState({ dataEdit: data })

    }


    _checkIsValid = (data) => {

        let list = new Set()
        for (let l of data) {
            if (l.parentId) {
                if (!l.type || !l.sens) {
                    return false
                }
            }
            else {
                if (!l.category) {
                    return false
                }
                list.add(l._id)
            }
        }

        let d = []
        for (let l of data) {
            if (l.parentId) {
                if (!list.has(l.parentId)) continue
            }
            d.push(l)
        }

        return d
    }

    save = async (data) => {

        let newData = this._checkIsValid(data)
        if (!newData) {
            return this.setState({ errorMessage: "Certaines lignes sont incomplètes" })
        }

        if (this.state.versionEdit != getDataVersion(this.props.data)) {
            console.log("Probleme version " + getDataVersion(this.props.data) + " vs " + this.state.versionEdit)

            this.setState({ errorMessage: "Impossible de sauvegarder, malheureusement quelqu'un a modifié ces données et vous devez sauvegarder ce que vous avez saisi, puis faire annuler et de nouveau editer et sauvegarder afin de voir les dernièeres informations saisies." })
            return
        }



        if ((await this.props.onChange({
            data: newData.map((line) => {
                return {
                    ...line,
                   // secretId: undefined
                }
            }),
            typeUpdate: "table_data",
            lastVersion: this.state.versionEdit
        })) == true) {
            if (this.props.onChangeMode) this.props.onChangeMode("view")
            this.setState({ mode: "view", panelOpenRow: false, dataEdit: null, editIndex: null, versionEdit: null })
        }
        else {
            return this.setState({ errorMessage: "Erreur lors de la sauvegarde, veuillez recommencer" })
        }
    }


    _onClickOpen = ()=>{

    }


    _calculDetails = (data) => {
        let map = {}
        let posIndex = 0
        let dataOutput = []
        let exist = new Set()
        let list = []
        // for (let row of data) {
        //     if (row.category && row._id) {
        //         exist.add(row._id || "")
        //     }
        // }

        for (let row of data) {

            // if ( ((!row.parentId || !exist.has(row.parentId || "")) && !row.category && !row._id)) {
            //     row.parentId = "?"
            // }
            if (row.parentId) {


                // if ( !exist.has(row.parentId || "")) {
                //     row.parentId = "?"
                //     if (!map["?"]) {
                //         map["?"] = { row: { category: "?", _id: "?", comment: "non classé" }, credit: 0, debit: 0, waiting: 0, details: [], posIndex: ++posIndex }
                //         dataOutput.push(map["?"])
                //         list.push({ id: "?", title: "?" })
                //     }
                // }

                if (!map[row.parentId]) map[row.parentId] = { row: undefined, credit: 0, debit: 0, waiting: 0, details: [], posIndex: ++posIndex }
                map[row.parentId].details.push(row)
                if (row.amountEUR) {
                    let value = Number(String(row.amountEUR).replace(" ","").replace(" ","").replace("€",""))
                    let sens = row.sens || "Combigo->"
                    if (sens.indexOf("Combigo->") != -1) {
                        map[row.parentId].debit += value
                        if (row.waiting) map[row.parentId].waiting -= value

                    }
                    else if (sens.indexOf("->Combigo") != -1) {
                        map[row.parentId].credit += value
                        if (row.waiting) map[row.parentId].waiting += value

                    }
                }
            }
            else {
                if (row._id) {
                    if (!map[row._id]){
                        map[row._id] = { row: undefined, credit: 0, debit: 0, waiting: 0, details: [], posIndex: ++posIndex }

                    }
                    map[row._id].row = row
                    dataOutput.push(map[row._id])
                    list.push({ id: row._id, title: posIndex + " " + (row.category || "") })

                }
            }
        }

        return { data: dataOutput, list: list }
    }

   

    render() {



        if (this.state.refresh) {
            this.setState({ refresh: false })
        }

        if (this.state.refresh) {
            return null
        }

        // mode edition ou un mode read
        let dataLine = this.state.mode == "edit" ? this.state.dataEdit : getDataTable(this.state.dataTemp || this.props.data)

        // pour le versionning
        if (this.state.dataView != null && this.state.dataView) {
            dataLine = this.state.dataView //.slice()
        }


        const disabledModification = this.props.disabledModification || this.state.disabledModification

        for (let line of dataLine) {
            if (line.source != undefined && !line.source.idSya) {
                line.source = undefined
            }
        }

        const dataTempLine = this._calculDetails(dataLine)

        
        // if (dataTempLine && dataTempLine.list && this.state.mode === "edit"){
        //     subColumns[11].custom.list=[]
        //     for (let l of dataTempLine.list){
        //         subColumns[11].custom.list.push(l)
        //     }  
        //     let strColumn = JSON.stringify(subColumns[11].custom.list)
        //     if (strColumn!=this.state.custom_list){
        //         this.setState({custom_list:strColumn}) 
        //         console.log("data",dataTempLine)
        //     }
        // }

       // console.log("subColumns[11].custom.list", subColumns[11].custom.list)
        //  console.log("dataLine",dataLine,"dataTempLine",dataTempLine)

        return <div className={"container-table"} style={{overflow:"auto"}}>
            <Grid >
                {/* <Button onClick={this._refresh} style={{ color: '#33c' }} variant="outlined">{"Refresh"}</Button>
                <Button onClick={() => { window.alert("BIENTOT") }} style={{ color: '#33c' }} variant="outlined">{"Create Facture"}</Button> */}
            </Grid>
            <div>
                <TableContainer>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                {columns.map((column, index) => {
                                    if (this.state.mode === "view" && column.custom && column.custom.onlyEdit) return null
                                    if (this.state.mode === "edit" && column.custom && column.custom.onlyView) return null


                                    return <TableCell key={index}>{column.label}</TableCell>
                                })}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                           
                            {dataTempLine && dataTempLine.data && dataTempLine.data.map((rowRaw) => {
                                let row = { ...rowRaw.row, credit: rowRaw.credit, debit: rowRaw.debit, waiting: rowRaw.waiting, posIndex: rowRaw.posIndex }
                                if (row.parentId) return null
                                const mode = this.state.editIndex ? (this.state.editIndex === row._id ? "edit" : "view") : this.state.mode



                                return <Row key={row.id}

                                    onChange={(value, rowData, columnData, columnIndex) => {
                                        this.changeValue(value, rowData, columnData, columnIndex)
                                    }}
                                    version={this.state.custom_list}
                                    disabledModification={disabledModification}
                                    
                                    open={this.state.openRow[row._id || row.id]===false?false:true}

                                    onClickOpen={()=>{

                                        let openRow = {
                                            ...this.state.openRow
                                        }
                                        if (this.state.openRow[row._id || row.id]===false){
                                            openRow[row._id || row.id]  = true
                                        }
                                        else{
                                            openRow[row._id || row.id]  =false
                                        }
                                        // openRow[row._id || row.id]  = ! this.state.openRow[row._id || row.id]
                                        this.setState({
                                            openRow:{
                                                ...openRow,

                                            }
                                        })
                                    }}
                                    details={rowRaw.details.filter((l) => {
                                        return row._id == l.parentId
                                    })}

                                    addLineSub={() => {
                                        this.addSubLine(undefined, row._id)
                                    }}

                                    onChangeMode={(mode_, cb) => {
                                        if (mode_ === "edit") {

                                            this.edit(() => {
                                                if (cb) cb()
                                                this.setState({ editIndex: row._id })
                                            })

                                        }
                                        else {
                                            this.setState({ editIndex: null, mode: "view" })
                                        }
                                    }}

                                    onSave={() => {
                                        this.save(this.state.dataEdit)

                                    }}

                                    columns={mode !== "edit" ? this.columnsNoEdit : this.columnsEdit}
                                    
                                    mode={mode}
                                    
                                    subColumns={mode !== "edit" ? this.subColumnsNoEdit : this.subColumnsEdit}

                                    row={row}

                                />
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <CombigoSnackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={this.state.errorMessage !== null || this.state.snackMessage !== null}
                onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
                variant={this.state.errorMessage != null ? 'error' : "success"}
                autoHideDuration={6000}
                message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
            />
            {this.props.readonly ? null : <div className={"footer-table-collapse"} >
                {disabledModification ? null : <IconButton onClick={() => {
                    this.edit(() => {
                        this.setState({ mode: "edit", editIndex: null })
                    })

                }}
                    size="small"
                    disabled={this.state.mode != "edit" ? false : true}>
                    <Edit />
                </IconButton>}
                {disabledModification ? null : <IconButton size="small"
                    onClick={() => {
                        if (this.state.mode == "edit") {
                            this.addLine()
                        }
                        else {
                            this.edit(() => {
                                this.setState({ mode: "edit", editIndex: null }, function () {
                                    this.addLine()
                                })
                            })

                        }
                    }}>

                    <AddBox />
                </IconButton>}

                {/* {this.props.modelLineAddEmpty ? <IconButton size="small"
                    text="Empty"
                    onClick={() => {
                        if (this.state.mode == "edit") {
                            this.addLine("empty")
                        }
                        else {
                            this.edit(() => {
                                this.setState({ mode: "edit", editIndex: null }, function () {
                                    this.addLine("empty")
                                })
                            })

                        }
                    }}>
                    <AddBox />
                    {"empty"}
                </IconButton> : null} */}

                {disabledModification ? null : this.state.mode == "edit" && <Button size="small" onClick={() => {
                    this.save(this.state.dataEdit)
                }}>
                    {"Sauvegarde"}
                </Button>}
                {disabledModification ? null : this.state.mode == "edit" && <Button size="small" onClick={() => {
                    if (this.props.onChangeMode) this.props.onChangeMode("view")

                    this.setState({ mode: "view", dataEdit: null, editIndex: null })

                }}>
                    {"Annuler"}
                </Button>}

                <div style={{ flexGrow: 1 }}></div>

                {<SelectHistory
                    style={{ minWidth: "100px" }}
                    version={this.props.booking.bookingId}
                    filter={{ relationId: "bookingId:" + this.props.booking.bookingId, event: "account2" }}
                    historyData={(data, error, flagReset) => {
                        if (flagReset) {
                            return this.setState({ dataTemp: null, disabledModification: false })
                        }
                        if (!data) {
                            return this.setState({ disabledModification: true, dataTemp: [] })
                        }
                        if (data.rawData && typeof data.rawData === "string") data.rawData = JSON.parse(data.rawData)
                        if (data.rawData) this.setState({ dataTemp: data.rawData.account2, disabledModification: true })
                    }}
                />}




            </div>}
        </div>
    }
}




const mapStateToProps = (state) => {
    return { booking: state.booking, base: state.data , locale: state.user.locale, nameUser: state.user.name};
};

export default connect(mapStateToProps, {})(Account);



