import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import { setUser } from '../Store/actions/user';
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { _fetch } from '../helpers';

const options = [{ name: "Qui êtes vous ?", id: "" }]


class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: options[0],
      value: options[0].name,
      idValue: "",
      apikey: localStorage.getItem("x-api-key")
    }
  }


  onChange = (ev) => {
    if (ev.target.value.id != "") {
      this.setState({ selection:ev.target.value,value: ev.target.value.name, idValue: ev.target.value.id })
    }
  };


  onSave = () => {
    localStorage.setItem("user3", this.state.selection.id)
    localStorage.setItem("x-api-key", this.state.apikey)
    this.props.setUser(this.state.selection.id)
    this.props.history.push("/")
    window.location.reload()
  }


  render() {

    let listUser = this.props.listUser.filter((o)=>{
      if (!o) return false
      if (o.role.includes("AUTO")) return false
      if (o.role.includes("DISABLED")) return false
      if (o.role.includes("INACTIVE")) return false
      return true
    })

    return <div className={""} style={{ "height": "100vh", display: "flex", "width": "100%" }}>
      <div style={{ margin: "auto", display: "flex", flexDirection: "column" }}>

        <Select
          // className={"select-mode-search"}
          label={"Name Utilisateur"}
          value={this.state.selection}
          onChange={this.onChange}
          style={{ width: "250px",marginBottom:"20px" }}
        >
          {options.concat(listUser).map(function (obj) {
            return <MenuItem value={obj}>{obj.name}</MenuItem>
          })}
        </Select>

        <TextField
          label={"Mot de Passe"}
          value={this.state.apikey}
          onChange={(ev) => {

            this.setState({ apikey: ev.target.value })
          }}
          variant="standard"
          style={{ width: "250px", marginBottom: "20px" }}
        />

        <Button size='small' color="primary" onClick={(ev) => {

          _fetch(process.env.REACT_APP_URL + '/crm/connexion', {
            "timeout": 2000,
            "nbRetry": 1,
            "method": "get",
            "headers": {
              "x-api-key":this.state.selection.id + ":" + this.state.apikey
            }
          })
            .then(function (response) {
              if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
              alert("Bienvenue dans votre espace!")
              this.onSave()
            }.bind(this))
            .catch(function (e) {
              alert("Error Connexion (Mot de passe ou login incorrect)")
              console.error("Error login", e)
            }.bind(this));

        }}>{"Connexion"}</Button>

      </div>




    </div>
  }
}




const mapStateToProps = (state) => {
  return { nameUser: state.user.name, listUser: state.data.users };
};

export default connect(mapStateToProps, { setUser })(withRouter(Body));