import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import SelectCheckBoxCombigo from "./../Utils/Select_CheckBox"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { _fetch, parseQuery } from '../../helpers';

import Progress from './../Components/Progress';
import "./modalcancelcustomer.scss"

const steps = ['Select Cancellation', "Simulation", "Confirmation"];

class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            loading: false,
            action: null,
            buttonDisabled: false,
            nextCancelPlus: null,
            errorMessage: "",
            cancelPlusObj: null,
            cancelPlus: null,
            stepFinal: false
        }
    }

    componentDidMount() {



    }


    componentDidUpdate(prevProps) {
        if (prevProps.open === false && this.props.open) {
            this.setState({ disabled: true })
            setTimeout(() => {
                this.setState({ disabled: false })
            }, 8000)
        }
        if (this.props.data.id != prevProps.data.id) {
            this.setState({
                activeStep: 0, data: null, cancelPlusObj: null,
                cancelPlus: null, stepFinal: false
            })
        }
    }



    handleBack = () => {
        this.setState({
            activeStep: Math.max(this.state.activeStep - 1, 0), data: null, cancelPlusObj: null,
            cancelPlus: null, stepFinal: false
        })
    };


    handleNext = () => {
        this.setState({ loading: true, errorMessage: "" })

        if (this.state.activeStep === steps.length - 1) {
            this.props.onClose()
            this.setState({ activeStep: 0, loading: false })
            return
        }
        if (this.state.activeStep === 0) {
            this._cancel_customer(undefined, (response) => {
                this.setState({ data: response.data })
            })

            return
        }


        if (this.state.activeStep === 1) {
            if (!this.state.cancelPlusObj) {
                return this.setState({ loading: false, errorMessage: "Vous devez selectionner une possibilité!" })
            }


            this._cancel_customer({
                selection: this.state.cancelPlusObj,

            }, (response) => {
                console.log("response",response)
                if (response.valid){
                    this.setState({ stepFinal: true,messageFinal:"Ok demande remboursement, status= " + response.data.status+" d'un montant de " + response.data.price.amount  +" "+response.data.price.currency  + " "+(response.data.reasons?"Attention :"+response.Data.reasons:"")})

                }else{
                    this.setState({ stepFinal: false,errorMessage:"Problème lors du refund" })

                }
                // this.setState({data: response.data})
            })

            return
        }

    };



    _cancel_customer = (bodyNext, cb) => {

        this._request("/crm/actions/cancelcustomer", {

            step: this.state.activeStep,
            action: this.state.action,

            bookingId: this.props.bookingId,
            ...bodyNext,
        }, (response) => {
            if (!response) {
                this.setState({ loading: false, errorMessage: "Erreur, veuillez réessayer" })
            }
            else {
                cb(response)
                this.setState({ loading: false, activeStep: Math.min(this.state.activeStep + 1, steps.length - 1), buttonDisabled: true })
                setTimeout(() => {
                    this.setState({ buttonDisabled: false })
                }, 2000)
            }
        })
    }

    reset = function () {
        this.setState({ activeStep: 0 })

    }


    _request = (url = '/', body, cb) => {

        _fetch(process.env.REACT_APP_URL + url, {
            "timeout": 20000,
            "nbRetry": 3,
            "method": "post",
            "headers": {},
            "body": {
                ...body
            }
        })
            .then(function (response) {
                if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                let data = response.json()
                cb(data, response)
            }.bind(this))
            .catch(function (e) {
                cb(undefined, undefined, e)
                console.error("Error modal cancel ", e);
                throw e
            }.bind(this));
    }






    render() {

        // console.error("this.props.listParent=",this.props.listParent)

        return (<div>
            <Dialog open={this.props.open}
                className={"modal-cancelcustomer"}
                onClose={() => {
                    this.props.onClose()
                    this.reset()
                }} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    {"Cancel Customer"}
                </DialogTitle>
                <DialogContent>

                    <Box sx={{ width: '100%' }} className={"content-box"} >
                        <Stepper activeStep={this.state.activeStep}>

                            {steps.map((label, index) => {

                                return (
                                    <Step key={label}>
                                        <StepLabel >{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        <div style={{ height: "20px" }}></div>
                        {this.state.loading ? <>
                            <Progress></Progress>
                            <Button onClick={() => {
                                this.props.onClose()
                            }}>
                                {'Annuler'}
                            </Button>
                        </> : null}
                        {this.state.stepFinal?<div>
                            {this.state.messageFinal}
                            <Button onClick={() => {
                                this.props.onClose()
                            }}>
                                {'Fermer'}
                            </Button>
                        </div>:null}
                        {this.state.loading ? null : <>
                            {this.state.activeStep === 0 ? <div>
                                <FormControl>
                                    <FormLabel id="demo-radio-buttons-group-label" >{"Que souhaitez vous faire ?"}</FormLabel>
                                    <div style={{ marginBottom: "10px" }}>{"Tarif " + (this.props.booking && this.props.booking.bundle.data && this.props.booking.bundle.data.id && this.props.booking.bundle.data.id ? this.props.booking.bundle.data.id : "BASIC")}</div>

                                    <RadioGroup
                                        defaultValue=""
                                        name="radio-buttons-group"
                                        onClick={(ev, value) => {

                                            this.setState({ action: ev.target.value })
                                        }}
                                    >
                                        <FormControlLabel
                                            value="cancel_plus"
                                            control={<Radio />}
                                            label="Annulation avec Combigo Plus"
                                        // disabled={this.props.booking && this.props.booking.bundle.data && this.props.booking.bundle.data.id && this.props.booking.bundle.data.id === "BASIC" ? true : false}
                                        />
                                        <FormControlLabel value="cancel_transporter" control={<Radio />} label="Annulation Transporter" />
                                        {/* <FormControlLabel value="other" control={<Radio />} label="Other" /> */}
                                    </RadioGroup>

                                </FormControl>

                            </div> : null}
                            {this.state.activeStep === 1 ? <div>
                                {this.state.action === "cancel_plus" ? <div className={"cancel_plus"}>
                                    <FormControl>

                                        <div className='title'>{"Liste des possibilités pour annuler : "}</div>
                                        <RadioGroup
                                            defaultValue=""
                                            name="radio-buttons-group"
                                            onClick={(ev, value) => {
                                                let lineSelected = this.state.data.filter((o) => {
                                                    return o.id === ev.target.value ? true : false
                                                })[0]
                                                this.setState({ cancelPlus: ev.target.value, cancelPlusObj: lineSelected })
                                            }}
                                        >
                                            {this.state.data.map((line) => {
                                                return <div className={"proposition"}>
                                                    <FormControlLabel
                                                        value={line.id}
                                                        control={<Radio fill="#33c" style={{ color: "#33c" }} />}
                                                        label={<div>
                                                            <div style={{ color: "#33c", fontWeight: "600" }} >{line.id}</div>
                                                            <div>{"Price Refundable : " + line.conditions.amountRefundable.amount + " " + line.conditions.amountRefundable.currency}</div>
                                                            <div>{"Deadline : " + line.conditions.deadline}</div>
                                                        </div>}
                                                    // disabled={this.props.booking && this.props.booking.bundle.data && this.props.booking.bundle.data.id && this.props.booking.bundle.data.id === "BASIC" ? true : false}
                                                    >


                                                        {/* {JSON.stringify(line)} */}
                                                    </FormControlLabel>

                                                </div>
                                            })}
                                        </RadioGroup>

                                    </FormControl>

                                </div> : null}




                            </div> : null}

                        

                            <div style={{ flex: 1 }}></div>

                            <React.Fragment>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Button
                                        color="inherit"
                                        disabled={this.state.activeStep === 0 || this.state.buttonDisabled}
                                        onClick={this.handleBack}
                                        sx={{ mr: 1 }}
                                    >
                                        {"Back"}
                                    </Button>
                                    <Box sx={{ flex: '1 1 auto' }} />

                                    <Button onClick={this.handleNext} disabled={this.state.buttonDisabled}>
                                        {this.state.activeStep === steps.length - 1 ? 'Fermer' : 'Suivant'}
                                    </Button>
                                </Box>
                            </React.Fragment>
                            {this.state.errorMessage ? <div className='errorMessage'>{this.state.errorMessage}</div> : null}


                        </>}


                    </Box>







                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={() => {
                        this.props.onClose()
                        this.reset()
                    }} color="primary">
                        {"Cancel"}
                    </Button>
                    <Button
                        disabled={this.state.disabled}
                        onClick={() => {

                            let valueAmount = Number(String(this.state.amount).trim().replace(",", "."))
                            if (isNaN(valueAmount)) {
                                return this.props.onError("Valeur du montant n est pas un nombre")
                            }

                            if (!valueAmount) {
                                return this.props.onError("Valeur du montant est 0")
                            }
                            if (!this.state.description) {
                                return this.props.onError("Vous devez mettre une raison")
                            }
                            if (!this.state.parentId) {
                                return this.props.onError("Vous devez choisir une category")
                            }
                            this.setState({ disabled: true }, () => {
                                this.props.onSave({ description: this.state.description, amount: valueAmount, id: this.props.data.id, provider: this.props.data.provider, parentId: this.state.parentId ? this.state.parentId.id : undefined }, () => {
                                    setTimeout(() => {
                                        this.setState({ disabled: false })
                                    }, 10000)
                                })
                            })



                        }} color="primary">
                        {this.props.btnValid || "Rembourser"}
                    </Button>
                </DialogActions> */}
            </Dialog>
        </div>
        );
    }
}

export default Modal;