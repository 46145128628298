// import React from 'react';
import React, { useState } from 'react';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { amber, green } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import "./Snackbar.scss"

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
  combigo: InfoIcon,
  update: AutorenewIcon,
};

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: "#FF6966",//theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  combigo: {
    backgroundColor: "#3333cc",
    maxWidth: 360,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  combigo_update: {
    backgroundColor: "white",//"#c1c1f0",
    // maxWidth: 360,
    boxShadow: "none",
    // '& > * + *': {
    //   marginTop: theme.spacing(2),
    // },
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: "10px",
    // marginRight: theme.spacing(1),
  },
  message: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
  },
  button_combigo: {
    backgroundColor: "none", //"rgba(255,255,255,0.1)",
    color: "white",
    fontSize: "0.875rem",
    "fontWeight": 500,
    textTransform: 'none', // removes uppercase transformation
    // transition:"all 0.15s ease-in-out 0s !important",
    // transition:"background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 200ms ease-out!important",
    '&:hover': { // changes colors for button hover state
      backgroundColor: "rgba(255,255,255,0.2)",
      // transform: "scale(1.05)",
      //backgroundColor:"#3333cc"
    },
  }
}));

export const CombigoSnackbar = (props) => {
  const classes = useStyles1();
  const { className, message, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <Snackbar {...other} className={"snackbar " + props.className} >
      <SnackbarContent
        className={clsx(classes[variant], className)}
        message={
          <span id="client-snackbar" className={classes.message}>
            {Icon ? <Icon className={clsx(classes.icon, classes.iconVariant)} /> : null}
            {message}
          </span>
        }
        action={props.action}
      />
    </Snackbar>
  );
}







export const CombigoUpdate = (props) => {
  const classes = useStyles1();
  const { className, message, messageButton, messageButton2, actionButton2, actionButton, variant, ...other } = props;
  // const Icon = variantIcon[variant];
  // const [tStart, setStart] = useState(0);

  // if (props.open===false){
  //   if (tStart!=0){
  //     setStart(0)
  //   }
  // }
  // if (tStart === 0) {
  //   setTimeout(() => {
  //     setStart(1)
  //   }, 5000)
  // }

  return (
    <Snackbar {...other} className={"snackbar combigo_update " + props.className} >
      <SnackbarContent
        className={clsx(classes["combigo_update"], className)}
        // message={
        //   <span id="client-snackbar" className={classes.message}>
        //     {Icon?<Icon className={clsx(classes.icon, classes.iconVariant)} />:null}
        //     {message}
        //   </span>
        // }

        action={
          <>

            <Button
              className={"button-snackbar"}
              color="primary"
              size="medium"
              startIcon={<AutorenewIcon className={"image "} />} /*   + (tStart===0 && false?"animation ":"") */
              variant="contained"
              // className={clsx(classes["button_" + variant])}
              onClick={()=>{
                if (props.onClick) props.onClick()
                if (props.onClose) props.onClose()
              }}  >
              {" | "+ (props.nb!=undefined?props.nb+" update"+(props.nb>1?"s":""):"update")}
            </Button>






          </>
        }

      />

    </Snackbar >
  );
}


export const CombigoSnackbarButton = (props) => {
  const classes = useStyles1();
  const { className, message, messageButton, messageButton2, actionButton2, actionButton, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <Snackbar {...other} className={"snackbar " + props.className} >
      <SnackbarContent
        className={clsx(classes[variant], className)}
        message={
          <span id="client-snackbar" className={classes.message}>
            {Icon ? <Icon className={clsx(classes.icon, classes.iconVariant)} /> : null}
            {message}
          </span>
        }

        action={
          <>
            {props.action ? props.action : null}
            {props.listAction ? <div>

              {props.listAction.map((line) => {
                return <Button color="secondary"
                  size="large"
                  startIcon={line.startIcon ? line.startIcon : null}
                  endIcon={line.endIcon ? line.endIcon : null}

                  className={variant ? clsx(classes["button_" + variant], line.className) : line.className}
                  onClick={line.onClick}  >
                  {line.text}
                </Button>
              }
              )}

            </div> : null}



          </>
        }

      />

    </Snackbar>
  );
}