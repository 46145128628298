
import React from 'react';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { parseQuery, _fetch } from "./../../helpers"


// import ModalMail from "./ModalMail"
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Select_CheckBox from "./../Utils/Select_CheckBox"
import "./ModalCancel.scss"
import TableSegments from "./../TableSegments/TableSegments"
import MinimizeIcon from '@mui/icons-material/Minimize';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@material-ui/core/IconButton';
import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import EmailForm from "./../EmailForm/EmailForm.js"



class Body extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            selected: null, mail: {}
        }

    }



    componentDidMount() {
        return 
        this.updateList()
    }

    _reset = () => {
        this.setState({ step: 1, selected: null, model: null, priority: null,  formatMail: null, mail: {} })

    }

    componentDidUpdate(prevProps) {
        return
        // console.log(this.props.booking.booking)
        if (this.props.version != prevProps.version) {
            // console.log("Difference version " + this.props.version + " vs " + prevProps.version)
            this.updateList()
        }

    }



    updateList = () => {
        this._reset()

        let segments = new Map()
        for (let seg of this.props.booking.segments.data) {
            segments.set(seg.id, seg)
        }

        this.setState({
            list: this.props.booking.booking.data.map((o) => {

                let first = o.segments && o.segments[0] ? segments.get(o.segments[0].id) : { from: {} }
                let last = o.segments && o.segments[0] ? segments.get(o.segments[o.segments.length - 1].id) : { to: {} }

                return {
                    id: o.id,
                    title: (o.transporter ? o.transporter.name : "") + " " + (first.from.name || "") + (first.from.iata ? " (" + first.from.iata + ")" : "") +
                        " -> " + (last.to.name || "") + (last.to.iata ? " (" + last.to.iata + ")" : "") + " : " + o.booking_code + " " + (o.provider ? "(" + o.provider + ")" : ""),
                    infoBooking: o
                }
            })
        })




    }


    _sendRequest = () => {


        this._request('/crm/logs/search', {
            rawData: true,
            filter: {
                relationId: "bookingId:" + this.props.bookingId,
                event: "segments" //
            }

        }, (data, error) => {


            if (data && data.data) {



            }
        })

    }



    _request = (url = '/crm/logs/search', body, cb) => {

        _fetch(process.env.REACT_APP_URL + url, {
            "timeout": 20000,
            "nbRetry": 2,
            "method": "post",
            "headers": {},
            "body": {
                ...body
            }
        })
            .then(function (response) {
                if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                let data = response.json()
                cb(data)
            }.bind(this))
            .catch(function (e) {
                cb(undefined, e)
                console.error("Error modal cancel" + url, e);
            }.bind(this));
    }





    render() {
        return (
            <>
                <Dialog
                    className={(this.props.className || "") + " modal-cancel"}
                    open={this.props.open || false}
                    onClose={() => {
                        this.props.onClose()
                    }}

                >
                    <div style={{ display: "flex", "flewDirection": "row", borderBottom: "1px solid grey" }}>
                        <DialogTitle>{"Demande Annulation/Modification ? "}</DialogTitle>

                        <div style={{ flexGrow: 1 }}></div>

                        <IconButton onClick={() => {
                            if (this.props.onClose) this.props.onClose()

                        }}>
                            <MinimizeIcon />
                        </IconButton>
                        <IconButton onClick={() => {
                            this._reset()
                            if (this.props.onClose) this.props.onClose()

                        }}>
                            <CloseIcon />
                        </IconButton>
                    </div>

                    {this.state.list ? <>
                        <DialogContent>
                            {this.state.step === 1 ? <>
                                <Grid container item style={{ marginTop: "10px", minWidth: "100px" }} className={""} >
                                    <Select_CheckBox
                                        className={"select-typeMail"}
                                        styleSelect={{ width: "100%" }}
                                        label={"Booking Selected"}
                                        list={this.state.list}
                                        multiple={false}
                                        getLabel={(option) => { return option.title }}
                                        value={this.state.selected}
                                        onChange={(value) => {

                                            this.setState({ selected: value, formatMail: this.state.formatMail ? undefined : (value.provider ? { "id": "PARTNER", title: "Partner" } : { "id": "TRANSPORTER", title: "Transporteur" }) })

                                        }}
                                    ></Select_CheckBox>
                                </Grid>

                                {this.state.selected ? <Grid container item style={{ marginTop: "10px", minWidth: "100px" }} className={""} >
                                    <Select_CheckBox
                                        className={"select-typeMail"}
                                        styleSelect={{ width: "100%" }}
                                        label={"Format Mail"}
                                        list={[
                                            { "id": "PARTNER", title: "Partner" },
                                            { "id": "TRANSPORTER", title: "Transporteur" }
                                        ]}
                                        multiple={false}
                                        getLabel={(option) => { return option.title }}
                                        value={this.state.formatMail}
                                        onChange={(value) => {

                                            this.setState({ formatMail: value })

                                        }}
                                    ></Select_CheckBox>
                                </Grid> : null}

                                <Grid container item style={{ marginTop: "10px", minWidth: "100px" }} className={""} >
                                    <Select_CheckBox
                                        className={"select-typeMail"}
                                        styleSelect={{ width: "100%" }}
                                        label={"Type de Demande"}
                                        list={[
                                            { "title": "Demande Annulation ", "id": "ASK_CANCEL" },
                                            { "title": "Demande Modification ", "id": "ASK_MODIFICATION" },
                                            { "title": "Demande Montant Annulation ", "id": "AMOUNT_CANCEL" },
                                            { "title": "Demande Montant Modification ", "id": "AMOUNT_MODIFICATION" },
                                        ]}
                                        multiple={false}
                                        getLabel={(option) => { return option.title }}
                                        value={this.state.model}
                                        onChange={(value) => {
                                            this.setState({ model: value })
                                        }}
                                    ></Select_CheckBox>
                                </Grid>


                                <Grid container item style={{ marginTop: "10px", minWidth: "100px" }} className={""} >
                                    <FormControlLabel
                                        control={<Checkbox
                                            style={{ color: "#33c" }}
                                            label={"Est ce une demande urgente ?"}
                                            checked={this.state.priority ? true : false}
                                            onChange={(ev, value_) => {
                                                this.setState({ priority: value_ })
                                            }
                                            }
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />}
                                        label={"Est ce une demande urgente ?"}
                                    />
                                </Grid>
                            </> : null}
                            {
                                this.state.step === 2 ? <EmailForm
                                    onChange={(prop, value) => {
                                        let mail = Object.assign({}, this.state.mail)
                                        mail[prop] = value
                                        this.setState({ mail: mail })
                                    }}
                                    listFrom={[{ "title": "travel@combigo.com", name: "Combigo" }]}
                                    listTo={this.state.mail.to}
                                    // listCci={this.state.mail.cci || [{ "title": this.state.mailIntern, "name": "Copy" }]}
                                    subject={this.state.mail.subject}
                                    // attachments={this.state.mail.attachments}
                                    content={this.state.mail.content}
                                    from={this.state.mail.from}
                                    to={this.state.mail.to}
                                    cci={this.state.mail.cci}
                                    id={this.props.version}
                                    version={this.props.version}

                                ></EmailForm> : null
                            }


                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => {

                                if (this.state.step == 2) {
                                    this.setState({ step: 1, message: null })
                                    return
                                }
                                if (this.props.onClose) this.props.onClose()
                                this._reset()

                            }} color="primary">
                                {(this.state.step == 1 ? "Fermer" : "Précédent")}
                            </Button>
                            <Button onClick={() => {
                                this.setState({ step: 2 })
                            }}
                                disabled={this.state.disabledButtonSend}

                                color="primary" >
                                {this.state.step == 1 ? "Visualisation Message" : "Envoyer"}
                            </Button>
                        </DialogActions>
                    </> : null}
                </Dialog>
                <CombigoSnackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.errorMessage !== null || this.state.snackMessage !== null}
                    onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
                    variant={this.state.errorMessage != null ? 'error' : "success"}
                    autoHideDuration={10000}
                    message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
                />

            </>
        );
    }
}




const mapStateToProps = (state) => {
    return { booking: state.booking };
};

export default connect(mapStateToProps, {})(Body);