
import * as React from 'react';
import { connect } from 'react-redux';
import { cloneObject } from '../../helpers';
import './TableBooking.scss';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ModalYesNo from "./../dialogConfirm/ModalYesNo"
import { parseQuery, _fetch } from "./../../helpers"
import Progress from './../Components/Progress';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxCombigo from "./../Utils/Select_CheckBox"
import Input from '@material-ui/core/Input';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import Badge from '@material-ui/core/Badge';
import { ReactComponent as BagageChecked } from "./../../assets/img/Suitcase.svg";
import { ReactComponent as BagageHand } from "./../../assets/img/Hand luggage.svg";
import { ReactComponent as BagageSmall } from "./../../assets/img/Personal item.svg";

const columnsDetails = [
    // { "name": "id", "field": "" },
    { "name": "provider", "field": "" },
    { "name": "fareName", "field": "" },
    { "name": "Vendeur", "field": "" },
    { "name": "amountEUR", "field": "" },
    { "name": "Baggage", "field": "" },
    { "name": "Action", "field": "" },
    { "name": "", "field": "" },
    // { "name": "Opti", "field": "" },

]



function createTooltipOpti(option) {
    //console.log("Tooltip opti ", option)
    if (option && option.params) {
        let typeTrip = (option.params.search || {}).typeTrip || ""
        let mode = typeTrip.indexOf("multi") != -1 ? "Multidest, " : (typeTrip.indexOf("roundtrip") != -1 ? "Return, " : "")
        return (mode + " " + (option.params.comment || "?")).trim()
    }
}




function cleanProductByCombigo(productByCombigo = "", params = {}) {

    let alreadyTag = new Set()
    let mode = []
    if (params && params.search) {
        let typeTrip = (params.search || {}).typeTrip || ""
        let comment = params.comment || ""
        let modeTemp = typeTrip.indexOf("multi") != -1 ? "MUL_F_C" : (typeTrip.indexOf("roundtrip") != -1 ? "RT_F_C" : "")
        if (modeTemp) {
            mode.push(modeTemp + " " + comment)
            alreadyTag.add(modeTemp)
        }
    }

    if (productByCombigo.indexOf("hC") != -1) mode.push("HIDDEN_CITY")
    if (productByCombigo.indexOf("rTf") != -1 && !alreadyTag.has("RT_F_C") && !alreadyTag.has("MUL_F_C")) mode.push("RT_F" + (params ? "_C" : ""))
    if (productByCombigo.indexOf("mDf") != -1 && !alreadyTag.has("MUL_F_C") && !alreadyTag.has("RT_F_C")) mode.push("MUL_F" + (params ? "_C" : ""))
    if (productByCombigo.indexOf("sCp") != -1) mode.push("Split Passengers")
    if (productByCombigo.indexOf("mDr") != -1) mode.push("Create MultiDest")
//    console.log("mode " + productByCombigo + " " + JSON.stringify(params))

    if (mode.length) {
        return mode.join(", ")
    }
    return ""
}



function RowNew(props) {
    const { row, details, open, processing, processing_buy, error, buy_complete, passengers } = props;


    let listDetails = []
    let rangeByLine = {}
    let nbTotal = 0
    if (!processing && !error && details) {

        for (let detail of details) {
            if (detail.book[0] && detail.book[0].options) {
                for (let option of detail.book[0].options) {
                    if (!option.booking) option.booking = {}
                    if (!option.booking.conditions) option.booking.conditions = {}
                    listDetails.push({ option: option, detail: detail })
                    nbTotal++
                }
            }
        }

        listDetails.sort((a, b) => {
            if (!a.option.booking || !b.option.booking) {
                if (!a.price) return 0
                if (!b.price) return 0
                return a.price.amount - b.price.amount
            }
            return a.option.booking.note - b.option.booking.note
        })

        for (let input of listDetails) {
            let option = input.option
            let productByCombigo = cleanProductByCombigo(option.productByCombigo, option.params)
            let priceFinal = Math.round((option.booking && option.booking.price ? option.booking.price.amount : option.price.amount) * 100) / 100
            let id = props.rowId + "-" + (productByCombigo || "")//  (productByCombigo?productByCombigo:option.provider + "" + productByCombigo)

            if (!rangeByLine[id]) rangeByLine[id] = {
                isBookable: 0, id: id, data: [], provider: [],
                productByCombigo: productByCombigo, price_booking: 10000000, price: priceFinal + " €", option: option
            }

            if (!rangeByLine[id].provider.includes(option.provider)) rangeByLine[id].provider.push(option.provider)
            if (option.isBookable === false) {
            }
            else {
                rangeByLine[id].price_booking = Math.min(rangeByLine[id].price_booking, priceFinal)
                rangeByLine[id].isBookable++
            }
            rangeByLine[id].data.push(input)

        }

        listDetails = []

        for (let key in rangeByLine) {
            rangeByLine[key].provider = rangeByLine[key].provider.join(", ")
            rangeByLine[key].nbOption = rangeByLine[key].data.length
            if (rangeByLine[key].price_booking >= 10000000) rangeByLine[key].price_booking = ""
            else rangeByLine[key].price_booking = rangeByLine[key].price_booking + " €"
            listDetails.push(rangeByLine[key])
        }
        //  if (listDetails.length)     props.onSubClick(listDetails[0].id)

    }



    let subColumns = [
        { name: "Provider", field: "provider" },
        { name: "Prix", field: "price" },
        { name: "Prix Auto", field: "price_booking" },

        { name: "Astuce", field: "productByCombigo" },
        // { name: "NbOption", field: "nbOption" }
    ]




    return (
        <React.Fragment>

            <TableRow className={""} style={{ width: "100%", background: "rgba(51, 51, 204, 0.04)" }}>
                <TableCell style={{ borderBottom: "1px solid rgb(51,51,204,0.8)" }}>
                    <IconButton aria-label="expand row" size="small" onClick={(processing_buy ? undefined : props.onClick)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                {props.columns.map((o, index) => {
                    return <TableCell style={{ borderBottom: "1px solid rgb(51,51,204,0.8)" }} key={index}  > {o && o.render ? o.render(row) : row[o.field]}       </TableCell>
                })}

                <TableCell key={"pass"} style={{ borderBottom: "1px solid rgb(51,51,204,0.8)" }} >
                    <CheckBoxCombigo
                        style={{ minWidth: "100%" }}
                        chips={true}
                        label={""}
                        multiple={true}
                        getLabel={(opt) => { return opt ? opt.firstName + " " + opt.lastName + " " + opt.birthDate : "" }}
                        value={props.selectedPassengers}
                        list={props.passengersList}
                        onChange={(value) => {
                            if (open) props.onClick()
                            props.onAddPassenger(value)
                        }}
                    ></CheckBoxCombigo>
                </TableCell>
            </TableRow>

            <TableRow style={{ width: "100%" }}>

                <TableCell style={{ paddingBottom: 0, paddingTop: 0, width: "100%" }} colSpan={columnsDetails.length}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box
                            // margin={1}
                            style={{
                                background: "rgba(128,128,128,0.04)",
                                margin: "10px 10px 10px 20px"
                            }}
                        >
                            {
                                processing ?
                                    <Progress /> :
                                    error ? "Error to search, retry ..." :
                                        <div>
                                            {props.selectedPassengers ?
                                                <span style={{ color: "red" }}>{"Attention, le prix affiché n'est pas le prix pour tous les passagers de la réservation, mais seulement pour les passagers sélectionnés"}</span>
                                                : null}

                                            <Table size="small" >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell key={-1}>{""}</TableCell>
                                                        {subColumns.map((detail, index) => {
                                                            return <TableCell key={index}>{detail.name}</TableCell>
                                                        })}
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {listDetails.length == 0 ? "No data" : null}
                                                    {listDetails.map((lineMaster, index2) => {
                                                        if (lineMaster.data.length == 0) return null

                                                        return <>

                                                            {lineMaster.data.length > 0 ? <TableRow className={""} style={{ width: "100%" }}>
                                                                <TableCell>{
                                                                    lineMaster.isBookable === 0 && lineMaster.nbOption === 1 ? null :

                                                                        <IconButton aria-label="expand row" size="small" onClick={() => {
                                                                            props.onSubClick(lineMaster.id)
                                                                        }}>
                                                                            {!props.subOpen[lineMaster.id] ? <Badge badgeContent={lineMaster.nbOption}
                                                                                color="primary">


                                                                                <KeyboardArrowDownIcon />
                                                                            </Badge> : <KeyboardArrowUpIcon />}
                                                                        </IconButton>
                                                                }
                                                                </TableCell>
                                                                {subColumns.map((o, index) => {
                                                                    return <TableCell key={index}
                                                                        style={{
                                                                            maxWidth: o.field === "productByCombigo" ? "100px" : undefined,
                                                                            fontSize: o.field === "productByCombigo" ? "12px" : undefined
                                                                        }}
                                                                    >
                                                                        {o && o.render ? o.render(row) : lineMaster[o.field]}
                                                                        {/* {o.field==="productByCombigo"?createTooltipOpti(lineMaster.option):""} */}
                                                                    </TableCell>
                                                                })}
                                                            </TableRow> : null}

                                                            <TableRow style={{ width: "100%" }}>

                                                                <TableCell
                                                                    style={{ paddingBottom: 0, paddingTop: 0, width: "100%" }}
                                                                    colSpan={columnsDetails.length}
                                                                >
                                                                    <Collapse in={nbTotal < 8 || props.subOpen[lineMaster.id]} timeout="auto" unmountOnExit>
                                                                        <Box
                                                                            //margin={0} 
                                                                            style={{
                                                                                background: "white", //"rgba(128,128,128,0.03)",
                                                                                margin: "10px 10px 10px 20px"
                                                                            }}
                                                                        >
                                                                            {
                                                                                lineMaster.data.map((input, index) => {

                                                                                    let option = input.option
                                                                                    let detail = input.detail

                                                                                    let baggages = []
                                                                                    let listBaggages = []

                                                                                    let priceInit = Math.round((option.booking && option.booking.price ? option.booking.price.amount : option.price.amount) * 100) / 100
                                                                                    let priceServicesReal = 0
                                                                                    for (let serv of (props.servicesSelected[option.id + "-" + detail.id] || [])) {
                                                                                        priceServicesReal += serv.service.price.amount
                                                                                    }
                                                                                    priceServicesReal = Math.round(priceServicesReal * 100) / 100
                                                                                    let priceFinal = Number(Math.round((Number(priceInit) + Number(priceServicesReal)) * 100) / 100)

                                                                                    if (option.booking.services) {
                                                                                        option.booking.services.sort((a, b) => {
                                                                                            return a.price.amount - b.price.amount
                                                                                        })

                                                                                        const conversionCodeBag = { "HAND_BAG": "HAND", "SMALL_BAG": "SMALL", "CHECKED_BAG": "CHECKED" }
                                                                                        for (let service of option.booking.services) {
                                                                                            if (service.incomplete) continue
                                                                                            if (!service.packages || !service.packages.length) continue
                                                                                            let labelBaggage = []
                                                                                            for (let bag of service.packages) {
                                                                                                if (service.price.amount && bag.code === "CHECKED_BAG" && bag.weight != undefined && bag.weight < 20) {
                                                                                                    continue
                                                                                                }
                                                                                                labelBaggage.push(conversionCodeBag[bag.code] + (bag.weight && bag.code === "CHECKED_BAG" ? " " + bag.weight + "kg " : "")
                                                                                                    //+ (bag.dimensions && bag.dimensions.length ? " " + bag.dimensions.join("x") + "cm " : "")
                                                                                                )
                                                                                            }
                                                                                            if (labelBaggage.length === 0) {
                                                                                                continue
                                                                                            }

                                                                                            let strPassenger = []
                                                                                            if (service.passengerIds && passengers) {
                                                                                                for (let idPass of service.passengerIds) {
                                                                                                    let passenger = passengers[idPass]
                                                                                                    if (passenger) strPassenger.push(passenger.firstName + " " + passenger.lastName + " (" + passenger.age + "ans)")
                                                                                                }
                                                                                            }
                                                                                            strPassenger = strPassenger.join(",")

                                                                                            if (service.price.amount) {
                                                                                                // console.log(option.booking.services)
                                                                                                listBaggages.push({
                                                                                                    title: <div style={{ fontSize: "0.875rem" }}><b>{labelBaggage.join(" / ")}</b> + {" (" + strPassenger + ")"}<b>
                                                                                                        {service.price.amount + " " + service.price.currency +
                                                                                                            (service.fictif || service.fictive ? " (Info Fictif)" : "")}</b></div>,
                                                                                                    service: service, id: detail.id + "-" + service.optionId
                                                                                                })
                                                                                            }
                                                                                            else {
                                                                                                // baggages.push(<>
                                                                                                //     <span>{strPassenger}</span>
                                                                                                //     {/* {labelBaggage} */}
                                                                                                //     {labelBaggage.join(" ").indexOf("SMALL")!=-1?<BagageSmall style={{ height: "30px", width: "30px" }}></BagageSmall>:null}
                                                                                                //     {labelBaggage.join(" ").indexOf("HAND")!=-1?<BagageHand style={{ height: "30px", width: "30px" }}></BagageHand>:null}
                                                                                                //     {labelBaggage.join(" ").indexOf("CHECKED")!=-1?<BagageChecked style={{ height: "30px", width: "30px" }}></BagageChecked>:null}
                                                                                                // </>)
                                                                                                baggages.push(<div style={{ fontSize: "0.875rem" }}>{labelBaggage.join(" / ") + " (" + strPassenger + ")"}<b>{": 0€"}</b></div>)
                                                                                            }
                                                                                        }
                                                                                    }

                                                                                    // console.log("listBaggages",listBaggages)
                                                                                    let productByCombigo = cleanProductByCombigo(option.productByCombigo, option.params)
                                                                                    let priceReal = option.booking && option.booking.priceReal ? option.booking.priceReal.amount : ""

                                                                                    if (priceReal && Math.round(priceReal) != Math.round(priceInit)) {

                                                                                    }
                                                                                    else {
                                                                                        priceReal = ""
                                                                                    }

                                                                                    return <TableRow key={index}
                                                                                        style={{ paddingLeft: "60px" }}
                                                                                    >
                                                                                        {/* <TableCell>{option.id}</TableCell> */}
                                                                                        <TableCell
                                                                                            style={{
                                                                                                width: "10%",
                                                                                                minWidth: "100px",
                                                                                                padding: "5px",
                                                                                                fontSize: "1rem",
                                                                                                color: option.isBookable === false ? "rgba(0, 0, 0, 0.4)" : undefined
                                                                                            }}>{option.provider}</TableCell>
                                                                                        <TableCell style={{
                                                                                            width: "10%",
                                                                                            minWidth: "100px",
                                                                                            padding: "5px",
                                                                                            fontSize: "1rem",
                                                                                            color: option.isBookable === false ? "rgba(0, 0, 0, 0.4)" : undefined
                                                                                        }}>{option.booking.fareName || ""}</TableCell>

                                                                                        <TableCell style={{
                                                                                            width: "60px",
                                                                                            maxWidth: "60px",
                                                                                            fontSize: "1rem",
                                                                                            padding: "5px",
                                                                                            color: option.isBookable === false ? "rgba(0, 0, 0, 0.4)" : undefined,
                                                                                        }}>
                                                                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontWeight: 600,
                                                                                                        fontSize: "1rem",
                                                                                                    }}
                                                                                                >{priceInit + " €"}</span>
                                                                                                {priceReal ? <span style={{
                                                                                                    fontStyle: "italic",
                                                                                                    // textAlign:"center",
                                                                                                    fontSize: "11px",
                                                                                                    marginRight: "10px"
                                                                                                }}>{"Real " + priceReal}</span> : null}
                                                                                            </div>
                                                                                        </TableCell>

                                                                                        {/* <TableCell>{option.booking.conditions.amount_refund_eur || "?"}</TableCell> */}
                                                                                        <TableCell
                                                                                            style={{
                                                                                                width: "10%",
                                                                                                minWidth: "100px",
                                                                                                padding: "5px",
                                                                                                fontSize: "0.875rem",
                                                                                                color: option.isBookable === false ? "rgba(0, 0, 0, 0.4)" : undefined
                                                                                            }}
                                                                                        >{option.booking.owner || ""}</TableCell>


                                                                                        <TableCell
                                                                                            style={{
                                                                                                width: "25%",
                                                                                                minWidth: "100px",
                                                                                                maxWidth: "450px",
                                                                                                padding: "5px",
                                                                                                fontSize: "0.875rem",
                                                                                                overflow: "hidden",
                                                                                                color: option.isBookable === false ? "rgba(0, 0, 0, 0.4)" : undefined
                                                                                            }}
                                                                                        >{<div style={{ display: "flex", flexDirection: "column" }}>
                                                                                            <div className="list-img-bag-option">
                                                                                                {baggages.map((baggage) => {
                                                                                                    return <div style={{
                                                                                                        // fontSize: "0.875rem",
                                                                                                    }}>{baggage}</div>
                                                                                                })}
                                                                                            </div>



                                                                                            {option.isBookable === false ? null : listBaggages.length > 0 ?
                                                                                                <CheckBoxCombigo
                                                                                                    style={{ minWidth: "100%" }}
                                                                                                    chips={true}
                                                                                                    label={"Options"}
                                                                                                    multiple={true}
                                                                                                    getLabel={(opt) => { return opt ? opt.title : "" }}
                                                                                                    getMatching={(opt) => { return opt ? opt.id : "" }}
                                                                                                    value={(props.servicesSelected[option.id + "-" + detail.id] || []).map((o) => {
                                                                                                        return o.id
                                                                                                    })}
                                                                                                    list={listBaggages}
                                                                                                    onChange={(value) => {
                                                                                                        // console.log("On change ",value)
                                                                                                        props.onAddServices(option.id + "-" + detail.id, listBaggages.filter((opt) => {
                                                                                                            return value.includes(opt.id)
                                                                                                        }).map((o) => {
                                                                                                            return {
                                                                                                                service: o.service,
                                                                                                                id: o.id
                                                                                                            }
                                                                                                        }), true)
                                                                                                        // this.onChangeBody({ users: newValue })
                                                                                                    }}
                                                                                                ></CheckBoxCombigo>
                                                                                                : null}
                                                                                        </div>}</TableCell>

                                                                                        <TableCell
                                                                                            style={{
                                                                                                width: "10%",
                                                                                                minWidth: "100px",
                                                                                                // fontSize:"0.875rem",
                                                                                                padding: "5px",
                                                                                                color: option.isBookable === false ? "rgba(0, 0, 0, 0.4)" : undefined
                                                                                            }}

                                                                                        >
                                                                                            {option.isBookable === false ? null :
                                                                                                (buy_complete && !processing_buy ? null : (
                                                                                                    processing_buy ? <Progress /> : (productByCombigo == "HIDDEN_CITY" ? null :
                                                                                                        <Button
                                                                                                            style={{
                                                                                                                color: "#33c",
                                                                                                                borderRadius: "4px",
                                                                                                                backgroundColor: "rgba(51,51,204,0.04)"
                                                                                                            }}
                                                                                                            onClick={() => {
                                                                                                                let amountEUR = option.booking && option.booking.price ? option.booking.price.amount : option.price.amount

                                                                                                                if (isNaN(priceFinal)) return null
                                                                                                                return props.onBuy ? props.onBuy({
                                                                                                                    amountEUR: amountEUR,
                                                                                                                    priceFinal: priceFinal,
                                                                                                                    priceServicesReal: priceServicesReal,
                                                                                                                    ticket: { ...detail, book: [] },
                                                                                                                    services: props.servicesSelected[option.id + "-" + detail.id] || [],
                                                                                                                    option: option
                                                                                                                }) : undefined
                                                                                                            }}
                                                                                                        >{"Go " + (priceFinal + "€")}</Button>))
                                                                                                )
                                                                                            }
                                                                                        </TableCell>
                                                                                        <TableCell style={{
                                                                                            width: "10%",
                                                                                            minWidth: "100px",
                                                                                            padding: "5px",
                                                                                            fontSize: "1rem",
                                                                                            color: option.isBookable === false ? "rgba(0, 0, 0, 0.4)" : undefined
                                                                                        }}>{<Tooltip
                                                                                            title={<div className={"tooltip-opti-helpme-text"}>
                                                                                                {"Date = " + new Date(option.dSearch * 1000).toISOString()}</div>}

                                                                                        ><InfoIcon style={{ width: "20px", height: "20px", fontSize: "20px" }} /></Tooltip>}</TableCell>
                                                                                        {/* <TableCell
                                                                                            style={{
                                                                                                width: "10%",
                                                                                                minWidth: "100px",
                                                                                                maxWidth: "100px",
                                                                                                fontSize: "12px",
                                                                                                padding: "5px", color: option.isBookable === false ? "rgba(0, 0, 0, 0.4)" : undefined
                                                                                            }}
                                                                                        >{
                                                                                                <div>
                                                                                                    <span className={""} style={{ "marginRight": "4px" }}>{productByCombigo}</span>
                                                                                                   
                                                                                                </div>
                                                                                            }</TableCell> */}

                                                                                    </TableRow>
                                                                                })
                                                                            }

                                                                        </Box>
                                                                    </Collapse>
                                                                </TableCell>
                                                            </TableRow>


                                                        </>
                                                    })

                                                    }

                                                </TableBody>
                                            </Table>
                                        </div>
                            }

                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}






class Account extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            data: [],
            details: {},
            open: {},
            subOpen: {},
            services: {},
            rowBuy: null,
            displayModalBuy: false,
            processing_buy: false,
            limitPrice: false,
            running_buy: {},
            selectedPassengers: {},
            errorMessage: null,
            snackMessage: null
        }

        this.requestStarted = {}
        this.idRequest = Date.now()
        this._refresh = this._refresh.bind(this)
        this._request = this._request.bind(this)
        this._update = this._update.bind(this)
        this.onLoadDetails = this.onLoadDetails.bind(this)
        this._status = this._status.bind(this)
        this._orderTicket = this._orderTicket.bind(this)
        this.nbError = 0


    }



    _request(body, route = "booking_auto") {
        return new Promise(function (resolve) {

            let debug = window.debug === "combigo" ? window.location.href + "&debug=1" : ""
            if (!debug) debug = window.location.href

            _fetch(process.env.REACT_APP_URL + '/crm/' + route, {
                "timeout": 15000,
                "method": "post",
                "nbRetry": 1,
                "headers": {},
                "body": {
                    ...body,
                    debug: debug.indexOf("debug=") != -1 ? {
                        href: debug,

                    } : undefined
                }
            })
                .then(function (response) {
                    // console.log("start response booking_auto crm/" + route + " status=" + response.status + " " + route)

                    if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                    let data = response.json()
                    //console.log("end response booking_auto crm/" + route + " status=" + response.status + " " + route + " data=", data)

                    if (data.success) {
                        this.nbError = 0
                        return resolve(data)
                    }
                    throw data
                }.bind(this))
                .catch(function (e) {
                    // if (++this.nbError>2){
                    console.error("Error crm booking_auto crm/" + route, "body=", body, "error", e);
                    this.setState({ errorMessage: "Erreur to search, relancer dans quelques secondes" })
                    //alert("mettre un snackbar pour informer de l'erreur crm/" + route + " body=" + JSON.stringify(body))
                    // }

                    resolve(undefined)
                }.bind(this));
        }.bind(this))
    }



    _refresh() {
        this.idRequest = Date.now()
        this.setState({
            open: {},
            details: {},
            subOpen: {},
            rowBuy: null,
            services: {},
            selectedPassengers: {},
            running_buy: {},
            displayModalBuy: false,
            buttonModalBuy: false,
            limitPrice: false,
            processing_buy: false
        })
        return
    }


    _update() {


        if (this.props.bookingId != this.bookingId || this.props.version != this.version) {
            this.bookingId = this.props.bookingId

            this.setState({
                data: [],
                subOpen: {},
                details: {},
                services: {},
                open: {},
                selectedPassengers: {},
                displayModalBuy: false,
                buttonModalBuy: false,
                limitPrice: false,
                processing_buy: false
            })
            this.requestStarted = {}

            this.version = this.props.version
            this._refresh()
        }

    }


    componentDidUpdate() {
        this._update()
    }

    componentDidMount() {
        this._update()

    }

    onLoadDetails = (row) => {

        if (!row) return null
        let details = { ...this.state.details }
        let running_buy = { ...this.state.running_buy }
        details[row._id] = { processing: true, data: null, message_buy: null, error: false }
        running_buy[row._id] = false

        this.setState({ details: details, running_buy: running_buy })
        // if (this.state.details && this.state.details[row._id]) {
        //     return this.state.details[row._id]
        // }
        let selectedPassengers = this.state.selectedPassengers[row._id] || []
        if (selectedPassengers.length === 0) selectedPassengers = undefined


        let body = {
            transactionId: "check-" + row._id + "-" + this.idRequest,
            bookingId: this.bookingId,
            date: Date.now(),
            batch: row.batch.map((l)=>{
                 return {
                    ...l,
                    bookingId:this.bookingId
                 }
            }),
            passengers: selectedPassengers
        }

        let services = { ...this.state.services }
        services[row._id] = undefined

        this.setState({ services: services })

        this._request({
            data: body,
            action: "check"
        }, "booking_auto").then((response) => { })
        this.requestStarted["check_" + row._id] = body.transactionId

        this._status(body, (response = { status: "error" }, flagOK, bodySend) => {
            if (!bodySend) return
            if (this.requestStarted["check_" + row._id] != bodySend.transactionId) return
            // console.log("Repsonsebody check status" , response)
            let details = { ...this.state.details }
            let mapPassengers = {}
            if (response && response.data && response.data[0]) {
                for (let passenger of response.data[0].passengers) {
                    mapPassengers[passenger.id] = passenger
                }
            }
            details[row._id] = {
                passengers: mapPassengers,
                data: response && response.data && response.data[0] ? response.data[0].data : undefined,
                processing: false,
                error: !response || response.status == "error" || response.status == "unknow" ? "ERROR" : null
            }
            this.setState({
                details: details
            })
        }, 4000, "status_available")


    }


    _orderTicket = (row) => {
        if (!row.selected.option.id) {
            console.error("Erreur with id is undefined option", row.selected.option)
            return alert("Erreur with option id is undefined")
        }
        //return 
        let details = { ...this.state.details }
        let running_buy = { ...this.state.running_buy }

        running_buy[row._id] = true

        details[row._id] = { ...details[row._id], message_buy: null, buy_complete: false }
        this.setState({ details: details, processing_buy: true, running_buy: running_buy })


        let selectedPassengers = this.state.selectedPassengers[row._id] || []
        if (selectedPassengers.length === 0) selectedPassengers = undefined


        let optionSelected = { ...row.selected.option }


        if (row.selected.services) {
            optionSelected.services = (row.selected.services || []).map((o) => {
                return o.service
            })
        }


        optionSelected.priceServicesReal = row.selected.priceServicesReal || 0
        optionSelected.servicesDetail = {
            price: row.selected.priceServicesReal || 0,
            priceEstimated: 0
        }
        optionSelected.priceServicesEstimated = 0
        if (!optionSelected.booking) optionSelected.booking = {}
        let newPriceAsked = row.selected.priceFinal && !isNaN(Number(row.selected.priceFinal)) ?Number(row.selected.priceFinal):row.selected.amountEUR
        let body = {
            transactionId: "order-" + row._id + "-" + this.idRequest,
            bookingId: this.bookingId,
            date: Date.now(),
            passengers: selectedPassengers,

            batch: [
                {
                    ...row.batch[0],
                    bookingId: this.bookingId,
                    ticket: row.selected.ticket,
                    order: {
                        optionSelected: optionSelected,
                        provider: row.selected.option.provider,
                        cart: [
                            {
                                type: "ticket",
                                optionId: optionSelected.id,
                                optionBookingId: optionSelected.bookingId,
                                booking: optionSelected.booking,
                                ticketId: row.selected.ticket.id,
                                productByCombigo: optionSelected.productByCombigo,
                                fareId: optionSelected.booking.fareId || optionSelected.fareId, // fareId qui tient compte du service et du fareName
                                fareName: optionSelected.booking.fareName || optionSelected.fareName || "", // fareId qui tient compte du service et du fareName
                                owner: optionSelected.booking.owner || optionSelected.owner || "", // fareId qui tient compte du service et du fareName

                                //services:row.selected.services,
                                // option:row.selected.option,
                                //ticket:row.selected.ticket,
                                price: {
                                    currency: "EUR",
                                    amount:newPriceAsked ||  row.selected.amountEUR, //row.selected.amountEUR
                                },
                                priceComplete: row.selected.priceFinal && !isNaN(Number(row.selected.priceFinal)) ? {
                                    currency: "EUR",
                                    amount: Number(row.selected.priceFinal), //optionSelected.price.amount + (optionSelected.servicesDetail ? optionSelected.servicesDetail.price : 0)
                                } : undefined
                            }
                        ]
                    }
                }
            ]
        }

        this.requestStarted["order_" + row._id] = body.transactionId


        this._request({
            data: body,
            action: "order"
        }, "booking_auto").then((response) => { })

        this._status(body, (response, flagOK, bodySend) => {

            if (this.requestStarted["order_" + row._id] != bodySend.transactionId) return

            let services = { ...this.state.services }
            services[row._id] = undefined

            this.setState({ services: services })

            // console.log("Repsonsebody order status" , response)
            //console.log("response", response)
            let details = { ...this.state.details }
            details[row._id] = {
                ...this.state.details[row._id],
                data: response && response.data && response.data[0] ? response.data[0].data : null,
                message_buy: null,
                buy_complete: true
            }

            let running_buy = { ...this.state.running_buy }
            running_buy[row._id] = false

            this.setState({
                details: details,
                running_buy: running_buy,
                processing_buy: false
            })
        }, 4000, "status_booking")


    }



    _status = (body, cb, timeout, status = "status", dateLimit = Date.now() + 10 * 60000) => {
        if (Date.now() > dateLimit) {
            return cb(undefined, false)
        }
        setTimeout(() => {
            // console.log("_status request ", body)
            this._request({
                data: body,
                action: status
            }, "booking_auto").then((response) => {

                if (response && response.success) {
                    if (response.status == "started") {

                        this._status(body, cb, timeout, status, dateLimit)

                        return
                    }
                    cb(response, true, body)
                }
                else {
                    cb(response, false, body)
                }
            }).catch((e) => {
                this._status(body, cb, timeout, status, dateLimit)
            })
        }, timeout)
    }



    render() {

        //  if (!this.state.data) return null



        //  console.log("Data",this.props.data)
        return (
            <>
                <ModalYesNo
                    open={this.state.displayModalBuy}
                    btnCancel={"Non"}
                    btnValid={"Oui"}
                    disabledButtonYes={this.state.buttonModalBuy}
                    disabledButtonNo={this.state.buttonModalBuy}

                    onClose={() => {
                        this.setState({ displayModalBuy: false, processing_buy: null, rowBuy: null, buttonModalBuy: false })
                    }}
                    content={this.state.rowBuy ? <>
                        <div>
                            {"Voulez-vous vraiment acheter ce billet à " + this.state.rowBuy.selected.priceFinal + "€ ?"}
                        </div>
                        {this.state.limitPrice ? <Input labelId="LimitPrice"
                            value={this.state.rowBuy.selected.priceFinal}
                            onChange={(ev) => {

                                let value = Number(ev.target.value.replace(",", ".").trim())

                                this.setState({
                                    rowBuy: {
                                        ... this.state.rowBuy,
                                        selected: {
                                            ...this.state.rowBuy.selected,
                                            priceFinal: value
                                        }
                                    }
                                })
                                console.log("on target value", ev.target.value)
                            }} />
                            : <Button onClick={() => {



                                this.setState({ limitPrice: true })
                            }}>{"Definir le prix maximum manuellement"}</Button>}



                    </> : ""}
                    onCancel={() => {
                        this.setState({ displayModalBuy: false, processing_buy: null, rowBuy: null, limitPrice: false, buttonModalBuy: false })
                    }}
                    onClick={() => {

                        if (!this.state.rowBuy.selected.priceFinal || isNaN(Number(this.state.rowBuy.selected.priceFinal))) {
                            this.setState({ errorMessage: "Le price defini n'est pas correct" })
                            return
                        }
                        let rowBuy = this.state.rowBuy
                        this.setState({ displayModalBuy: false, processing_buy: true, rowBuy: null, limitPrice: false, buttonModalBuy: false })
                        this._orderTicket(rowBuy)
                    }}
                    title={"Confirmation"}
                />





                <div className={"div-option"} style={{ background: "rgba(51, 51, 128, 0.03)", padding: '12px', borderRadius: "6px" }}  >
                    <div style={{ display: "flex" }} >
                        <div className="title-option">{"Option de réservation N°" + (this.props.index)}</div>

                        <div style={{ flexGrow: 1 }}></div>
                        <div>
                            <Button onClick={this._refresh} style={{ color: '#33c', background: "white" }} variant="outlined" >{"Refresh les prix"}</Button>

                        </div>
                    </div>

                    <div>
                        <TableContainer >
                            <Table aria-label="collapsible table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        {this.props.columns.map((o, index) => {
                                            return <TableCell style={{}} key={index} >{o.title}</TableCell>
                                        })}
                                        <TableCell style={{}} key={"passengers_table"} >{"Passengers"}</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody style={{ padding: "10px" }}>
                                    {this.props.data && this.props.data.map((row, index) => {
                                        //   console.log("row data", row)

                                        return <RowNew //<Row 
                                            onSubClick={(name) => {
                                                let subOpen = this.state.subOpen || {}
                                                if (subOpen[name]) delete subOpen[name]
                                                else subOpen[name] = true
                                                this.setState({ subOpen: subOpen })
                                            }}
                                            subOpen={this.state.subOpen}


                                            key={index}
                                            row={row}
                                            columns={this.props.columns}
                                            onClick={() => {
                                                let open = { ...this.state.open, }
                                                let subOpen = { ...this.state.subOpen }
                                                open[row._id] = open[row._id] ? false : true
                                                if (!open[row._id]) {
                                                    for (let key in subOpen) {
                                                        if (key.indexOf(row._id) != -1) {
                                                            subOpen[key] = false
                                                        }
                                                    }
                                                }
                                                this.setState({ open: open, subOpen })

                                                if (open[row._id]) {
                                                    return this.onLoadDetails(row)
                                                }
                                                let details = { ...this.state.details }
                                                details[row._id] = undefined
                                                this.setState({ details: details })
                                            }}

                                            onAddServices={(detailId, listService, value) => {

                                                let services = this.state.services || {}
                                                if (!services[row._id]) services[row._id] = {}
                                                if (!services[row._id][detailId]) services[row._id][detailId] = []
                                                services[row._id][detailId] = listService
                                                return this.setState({ services: services })
                                                if (value) {
                                                    for (let serv of listService) services[row._id][detailId].push(serv)
                                                }
                                                else {
                                                    services[row._id][detailId] = services[row._id][detailId].filter((serv) => {
                                                        return listService.includes(serv.id) ? false : true // === service.id ? false : true
                                                    })
                                                }
                                                this.setState({ services: services })
                                            }}

                                            selectedPassengers={this.state.selectedPassengers[row._id] || undefined}
                                            onAddPassenger={(values) => {
                                                let passengersTemp = { ...this.state.selectedPassengers }
                                                passengersTemp[row._id] = values
                                                if (values.length === 0) passengersTemp[row._id] = []
                                                this.setState({
                                                    selectedPassengers: passengersTemp
                                                })
                                            }}
                                            passengersList={this.props.passengers}

                                            servicesSelected={this.state.services[row._id] || {}}
                                            onBuy={(selectInfofmation) => {
                                                if (this.state.running_buy[row._id]) return
                                                let rowBuy = { ...row, selected: selectInfofmation }
                                                this.setState({ displayModalBuy: true, rowBuy: cloneObject(rowBuy), limitPrice: false, buttonModalBuy: true })
                                                setTimeout(() => {
                                                    this.setState({ buttonModalBuy: false })
                                                }, 1000)
                                            }}
                                            rowId={row._id}
                                            buy_complete={this.state.details[row._id] ? this.state.details[row._id].buy_complete : false}
                                            processing_buy={this.state.running_buy[row._id]}
                                            error={this.state.details[row._id] ? this.state.details[row._id].error : false}
                                            processing={this.state.details[row._id] ? this.state.details[row._id].processing : false}
                                            open={this.state.open[row._id]}
                                            details={this.state.details[row._id] ? this.state.details[row._id].data : undefined}
                                            passengers={this.state.details[row._id] ? this.state.details[row._id].passengers : undefined}

                                        />
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>

                <CombigoSnackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.errorMessage !== null || this.state.snackMessage !== null}
                    onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
                    variant={this.state.errorMessage != null ? 'error' : "success"}
                    autoHideDuration={6000}
                    message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
                />
            </>
        )
    }
}




const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps, {})(Account);




/*
function Row(props) {
    const { row, details, open, columns, processing, processing_buy, error, buy_complete, passengers } = props;

    // console.log("Props  " ,props.selectedPassengers)

    // console.log("row booking", row,details)
    let limitByProvider = { kiwi: 3 }
    let listDetails = []
    if (!processing && !error && details) {
        for (let detail of details) {
            if (detail.book[0] && detail.book[0].options) {
                for (let opt of detail.book[0].options) {
                    listDetails.push({ option: opt, detail: detail })
                }
            }
        }
        listDetails.sort((a, b) => {
            if (!a.option.booking || !b.option.booking) {
                if (!a.price) return 0
                if (!b.price) return 0
                return a.price.amount - b.price.amount
            }
            return a.option.booking.note - b.option.booking.note
        })
    }


    return (
        <React.Fragment>
            <TableRow className={""} style={{ width: "100%" }}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={(processing_buy ? undefined : props.onClick)}>
                        {props.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                {columns.map((o, index) => {
                    return <TableCell key={index}  > {o && o.render ? o.render(row) : row[o.field]}       </TableCell>
                })}

                <TableCell key={"pass"}  >
                    <CheckBoxCombigo
                        style={{ minWidth: "100%" }}
                        chips={true}
                        label={""}
                        multiple={true}

                        getLabel={(opt) => { return opt ? opt.firstName + " " + opt.lastName + " " + opt.birthDate : "" }}
                        //getMatching={(opt) => { return opt ? opt.id : "" }}
                        // value={(props.servicesSelected[option.id + "-" + detail.id] || []).map((o) => {
                        //     return o.id
                        // })}
                        value={props.selectedPassengers}
                        list={props.passengersList}
                        onChange={(value) => {

                            if (props.open) props.onClick()

                            props.onAddPassenger(value)
                            // console.log("On change ",value)
                            // props.onAddServices(option.id + "-" + detail.id, listBaggages.filter((opt) => {
                            //     return value.includes(opt.id)
                            // }).map((o) => {
                            //     return {
                            //         service: o.service,
                            //         id: o.id
                            //     }
                            // }), true)
                            // this.onChangeBody({ users: newValue })
                        }}
                    ></CheckBoxCombigo>
                </TableCell>
            </TableRow>

            <TableRow style={{ width: "100%" }}>

                <TableCell style={{ paddingBottom: 0, paddingTop: 0, width: "100%" }} colSpan={columns.length}>
                    <Collapse in={props.open} timeout="auto" unmountOnExit>
                        <Box margin={1} style={{ background: "rgba(128,128,128,0.09)", margin: "10px 10px 10px 20px" }}>
                            {
                                processing ?
                                    <Progress /> :
                                    error ? "Error to search, retry ..." :
                                        <div>
                                            {props.selectedPassengers ? <span style={{ color: "red" }}>{"Attention, le prix affiché n'est pas le prix pour tous les passagers de la réservation, mais seulement pour les passagers sélectionnés"}</span> : null}
                                            <Table size="small" aria-label="purchases">
                                                <TableHead>
                                                    <TableRow>
                                                        {columnsDetails && columnsDetails.map((detail, index) => {
                                                            return <TableCell key={index}>{detail.name}</TableCell>
                                                        })
                                                        }

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {listDetails.length == 0 ? "No data" : null}
                                                    {listDetails.map((input, index) => {
                                                        let option = input.option
                                                        let detail = input.detail

                                                        if (limitByProvider[option.provider] != undefined) {
                                                            if (!limitByProvider[option.provider]) return null
                                                            limitByProvider[option.provider]--
                                                        }

                                                        let baggages = []
                                                        let listBaggages = []
                                                        if (!option.booking) option.booking = {}
                                                        if (!option.booking.conditions) option.booking.conditions = {}
                                                        let priceFinal = option.booking && option.booking.price ? option.booking.price.amount : option.price.amount
                                                        let priceServicesReal = 0
                                                        for (let serv of (props.servicesSelected[option.id + "-" + detail.id] || [])) {
                                                            priceServicesReal += serv.service.price.amount
                                                        }
                                                        priceServicesReal = Math.round(priceServicesReal * 100) / 100
                                                        priceFinal = Number(Math.round((Number(priceFinal) + Number(priceServicesReal)) * 100) / 100)

                                                        if (option.booking.services) {
                                                            option.booking.services.sort((a, b) => {
                                                                return a.price.amount - b.price.amount
                                                            })

                                                            for (let service of option.booking.services) {
                                                                if (service.incomplete) continue
                                                                if (!service.packages || !service.packages.length) continue
                                                                let labelBaggage = []
                                                                for (let bag of service.packages) {
                                                                    if (service.price.amount && bag.code === "CHECKED_BAG" && bag.weight != undefined && bag.weight < 20) {
                                                                        continue
                                                                    }
                                                                    labelBaggage.push(bag.code + (bag.weight ? " " + bag.weight + "kg " : "") + (bag.dimensions && bag.dimensions.length ? " " + bag.dimensions.join("x") + "cm " : ""))
                                                                }
                                                                if (labelBaggage.length === 0) {
                                                                    continue
                                                                }

                                                                let strPassenger = []
                                                                if (service.passengerIds && passengers) {
                                                                    for (let idPass of service.passengerIds) {
                                                                        let passenger = passengers[idPass]
                                                                        if (passenger) strPassenger.push(passenger.firstName + " " + passenger.lastName + " (" + passenger.age + "ans)")
                                                                    }
                                                                }
                                                                strPassenger = strPassenger.join(",")
                                                                // let flagOK = false
                                                                // if (props.services[option.id]) {
                                                                //     for (let serv of props.services[option.id]) {
                                                                //         if (serv.id && serv.id === service.id) {
                                                                //             flagOK = true; break;
                                                                //         }
                                                                //     }
                                                                // }



                                                                if (service.price.amount) {
                                                                    // console.log(option.booking.services)

                                                                    listBaggages.push({
                                                                        title: <div><b>{labelBaggage.join(" / ")}</b> + {" (" + strPassenger + ")"}<b>
                                                                            {service.price.amount + " " + service.price.currency +
                                                                                (service.fictif || service.fictive ? " (Info Fictif, controler apres achat)" : "")}</b></div>,
                                                                        service: service, id: detail.id + "-" + service.optionId
                                                                    })
                                                                }
                                                                else {

                                                                    baggages.push(<div>{labelBaggage.join(" / ") + " (" + strPassenger + ")" + " "} + <b>{"inclus"}</b></div>)

                                                                }


                                                            }

                                                            // for (let key in category) {
                                                            //     let service = category[key]

                                                            //     baggages.push((Object.keys(service.category)).join(",") + " = " + service.id)
                                                            // }

                                                        }

                                                        // if (option.booking.services) {
                                                        //     let category = {}
                                                        //     for (let service of option.booking.services) {
                                                        //         if (service.incomplete) continue
                                                        //         if (!service.packages || !service.packages.length) continue
                                                        //         let id = service.packages[0].code + (service.packages[0].weight ? " " + service.packages[0].weight + "kg " : "") +
                                                        //             (service.packages[0].dimensions && service.packages[0].dimensions.length ? " " + service.packages[0].dimensions.join("x") + "cm " : "") +
                                                        //             (service.price.amount ?
                                                        //                 " => " +
                                                        //                 service.price.amount +
                                                        //                 " " +
                                                        //                 service.price.currency
                                                        //                 : " inclus")

                                                        //         if (!category[id]) category[id] = { category: {}, id: id }
                                                        //         for (let cat of (service.category || [])) category[id].category[cat] = true
                                                        //     }

                                                        //     for (let key in category) {
                                                        //         let service = category[key]
                                                        //         baggages.push((Object.keys(service.category)).join(",") + " = " + service.id)
                                                        //     }

                                                        // }
                                                        // console.log("listBaggages",listBaggages)
                                                        let productByCombigo = cleanProductByCombigo(option.productByCombigo, option.params)

                                                        return <TableRow key={index}>
                                                            <TableCell style={{ color: option.isBookable === false ? "rgba(0, 0, 0, 0.4)" : undefined }}>{option.provider}</TableCell>
                                                            <TableCell style={{ color: option.isBookable === false ? "rgba(0, 0, 0, 0.4)" : undefined }}>{option.booking.fareName || ""}</TableCell>
                                                            <TableCell style={{ color: option.isBookable === false ? "rgba(0, 0, 0, 0.4)" : undefined }}>{option.booking.owner || ""}</TableCell>

                                                            <TableCell style={{ color: option.isBookable === false ? "rgba(0, 0, 0, 0.4)" : undefined }}>{<b>{option.booking && option.booking.price ? option.booking.price.amount : option.price.amount}</b>}</TableCell>
                                                            <TableCell style={{ color: option.isBookable === false ? "rgba(0, 0, 0, 0.4)" : undefined }}>{<b>{option.booking && option.booking.priceReal ? option.booking.priceReal.amount : ""}</b>}</TableCell>

                                                            <TableCell style={{ color: option.isBookable === false ? "rgba(0, 0, 0, 0.4)" : undefined }}>{
                                                                <div>
                                                                    <span className={""} style={{ "marginRight": "4px" }}>{productByCombigo}</span>
                                                                    {option.params ? <Tooltip
                                                                        // open={true}
                                                                        title={<div className={"tooltip-opti-helpme-text"}> {createTooltipOpti(option)}</div>}

                                                                    ><InfoIcon style={{ width: "20px", height: "20px", fontSize: "20px" }} /></Tooltip> : null}
                                                                </div>
                                                            }</TableCell>

                                                            <TableCell >{<div style={{ display: "flex", flexDirection: "column" }}>
                                                                {baggages.map((baggage) => {
                                                                    return <div>{baggage}</div>
                                                                })}

                                                                {option.isBookable === false ? null : listBaggages.length > 0 ?
                                                                    <CheckBoxCombigo
                                                                        style={{ minWidth: "100%" }}
                                                                        chips={true}
                                                                        label={"Options"}
                                                                        multiple={true}
                                                                        getLabel={(opt) => { return opt ? opt.title : "" }}
                                                                        getMatching={(opt) => { return opt ? opt.id : "" }}
                                                                        value={(props.servicesSelected[option.id + "-" + detail.id] || []).map((o) => {
                                                                            return o.id
                                                                        })}
                                                                        list={listBaggages}
                                                                        onChange={(value) => {
                                                                            // console.log("On change ",value)
                                                                            props.onAddServices(option.id + "-" + detail.id, listBaggages.filter((opt) => {
                                                                                return value.includes(opt.id)
                                                                            }).map((o) => {
                                                                                return {
                                                                                    service: o.service,
                                                                                    id: o.id
                                                                                }
                                                                            }), true)
                                                                            // this.onChangeBody({ users: newValue })
                                                                        }}
                                                                    ></CheckBoxCombigo>
                                                                    : null}
                                                            </div>}</TableCell>

                                                            <TableCell>
                                                                {option.isBookable === false ? null :
                                                                    (buy_complete && !processing_buy ? null : (
                                                                        processing_buy ? <Progress /> : (productByCombigo == "HIDDEN_CITY" ? null :
                                                                            <Button onClick={() => {
                                                                                let amountEUR = option.booking && option.booking.price ? option.booking.price.amount : option.price.amount

                                                                                if (isNaN(priceFinal)) return null
                                                                                return props.onBuy ? props.onBuy({
                                                                                    amountEUR: amountEUR,
                                                                                    priceFinal: priceFinal,
                                                                                    priceServicesReal: priceServicesReal,
                                                                                    ticket: { ...detail, book: [] },
                                                                                    services: props.servicesSelected[option.id + "-" + detail.id] || [],
                                                                                    option: option
                                                                                }) : undefined
                                                                            }}  >{"Acheter " + (priceFinal + " €")}</Button>))
                                                                    )

                                                                }


                                                            </TableCell>


                                                        </TableRow>
                                                    })

                                                    }

                                                </TableBody>
                                            </Table>
                                        </div>
                            }

                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
*/