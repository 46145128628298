


import React from 'react';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Popper from '@material-ui/core/Popper';



import InputLabel from '@material-ui/core/InputLabel';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const nbDisplay = 6.5;
const MenuPropsSelect = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * nbDisplay + ITEM_PADDING_TOP,
            // width: 250,
        },
    },
};


class Body extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }



    render() {

        return (

            <FormControl className={this.props.className} style={this.props.style}>
                <InputLabel id="demo-simple-select-label">{this.props.label}</InputLabel>

                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.props.value}
                    PopperComponent={function (props) {
                        return <Popper {...props} style={{ width: "fit-content" }} placement="bottom-start" />;
                    }}
                    onChange={(ev, value) => {

                        this.props.onChange(ev.target.value)
                    }}        >
                    {this.props.list.map((option) => {
                        let name = this.props.getLabel ? this.props.getLabel(option) : option
                        return <MenuItem key={name} value={option}>{name}</MenuItem>
                    })}

                </Select>
            </FormControl>

        )
    }

}


export default Body

