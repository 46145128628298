import React, { useRef } from 'react';
import Chip from '@material-ui/core/Chip';
import InputLabel from '@material-ui/core/InputLabel';

import FormControl from '@material-ui/core/FormControl';

import { parseQuery, _fetch } from "./../../helpers"
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';
import { Input, TextField } from '@material-ui/core';
// import "./EmailForm.scss"
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import EditorText from "./../EditorText/EditorText"
import DragAndDrop from './../../lib/DrapAndDropFile/DragAndDrop';
import Label from "./../Label/Label"
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';


const filter = createFilterOptions();


class Body extends React.Component {

    constructor(props) {
      super(props)
      this.state = {
        open: undefined
      }
    }
  
    componentDidUpdate(prevProps) {
  
    }
  
  
    render() {
  
      let _list = this.props.list || []
      let _value = this.props.value || []
  
      return   <FormControl className={this.props.className} style={{width:"100%",...this.props.style}}  >

        {/* <Label className={""}  >{this.props.label}</Label> */}
        <Autocomplete
          className={this.props.classNameInput + " input"}
          multiple
          noOptionsText={''}
          // open={true}
          // filterSelectedOptions={true}
          autoHighlight={true}
          disableClearable={true}
          selectOnFocus={true}
          options={_list.filter(function (obj, index) {
            for (let line of _value) if (line.title == obj.title) return false
            return true
          }.bind(this))}
          // open={options.length==0?false:undefined}
          style={{ flexGrow: 1, width: "100%" }}
          onChange={(event, newValue) => {
            if (!this.props.multiple) newValue = [newValue.pop()]
            newValue = newValue.filter(function (obj) {
              return obj ? true : false
            })
            if (this.props.onChange) this.props.onChange(newValue)
            this.setState({ value: newValue })
          }}
          options={_list}
          value={_value}
          renderTags={function (selectValue, getTagProps) {
            return selectValue.map((option, index) => (
              <Chip variant="outlined" size="small" label={option.title} {...getTagProps({ index })} />
            ))
          }}
          // getOptionLabel={(option) => option.title}
  
  
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
  
            // Suggest the creation of a new value
            if (params.inputValue !== '') {
              if (this.props.addIsValid) {
                if (!this.props.addIsValid(params.inputValue)) return filtered;
              }
              for (let line of this.props.list) if (line.title == params.inputValue) return filtered
              // filtered.push({
              //   created: true,
              //   title: params.inputValue,
              //   titleDisplay: (this.props.labelAdd ? this.props.labelAdd + " " : "") + params.inputValue,
              // });
  
            }
            return filtered;
          }}
          getOptionLabel={(option) => {
  
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.created) {
              return option.titleDisplay;
            }
            // Regular option
            return option.title;
          }}
          renderOption={(option, { inputValue }) => {
            const matches = match(option.title, inputValue);
            const parts = parse(option.title, matches);
  
            return (
              <div>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400, fontSize: "0.875rem" }}>
                    {part.text}
                  </span>
                ))}
              </div>
            );
          }}
          renderInput={(params) =>
            <TextField {...params}
              variant="standard"
               label={this.props.label || ""}
               placeholder={this.props.placeholder}
            />
          }
        />
      </FormControl>
    }
  
  }
  

  export default Body
