
import * as React from 'react';
import { connect } from 'react-redux';
import ModelTable from "./../Table/modelTable"
import './TableForwardMail.scss';
import { _fetch } from '../../helpers';

import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';
import SelectHistory from "./../Table/select_history"
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';

const options = {
    filterType: 'checkbox',
    print: false,
    display: false,
    rowsPerPage: 100,
    selectableRows: 'none',
    selectableRowsHideCheckboxes: true,
    download: false,
    filter: false,
    search: false,
    viewColumns: false,
};




const columns = [
  
    {
        name: "mail_from",
        label: "mail_from (;)",
        custom: {
            type: "input"
        },
        options: {
            sort: false
        }
    },
    {
        name: "mail_to",
        label: "mail_to (;)",
        custom: {
            type: "input"
        },
        options: {
            sort: false
        }
    },
    // {
    //     name: "mail_transfer",
    //     label: "mail_transfer",
    //     custom: {
    //         type: "input"
    //     },
    //     options: {
    //         sort: false
    //     }
    // },
    {
        name: "group",
        label: "group",
        custom: {
            type: "list_checkbox",
            props: {
                style: { minWidth: "100px", maxWidth: "180px" },

                disableClearable: true
            },
            list: [
                { id: "all", "title": "all" },
                { id: "password", "title": "password" },
                { id: "notification", "title": "notification" },
                { id: "need_forward", "title": "need_forward" },
                { id: "modification", "title": "modification" },
                { id: "confirmation", "title": "confirmation" },
                { id: "checkin", "title": "checkin" },
                { id: "cancel", "title": "cancel" },
                { id: "refund", "title": "refund" },
                { id: "marketing", "title": "marketing" },

                

            ],
        },
        options: {
            sort: false
        }
    },
    {
        name: "comment",
        label: "Comment.",
        custom: {
            type: "input",
            props: { multiline: true }
        },
        options: {
            sort: false
        }
    },
    {
        name: "status",
        label: "status",
        custom: {
            type: "list",
            props: {
                disableClearable: true,
                style: { maxWidth: "80px" }
            },
            list: [
                { id: "ACTIVE", title: "ACTIVE" },
                { id: "DISABLED", title: "DISABLED" },
              
            ],
        },
        options: {
            sort: false
        }
    },
    {
        name: "dateActivated",
        label: "dateActivated",
        custom: {
            type: "dateTime"
        },
        options: {
            sort: false
        }
    },

    {
        name: "",
        label: "",
        custom: {
            onlyEdit: true,
            type: "action_delete"
        },
        options: {
            sort: false
        }
    }
];



class Account extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            refresh: false,
            errorMessage: null,
            snackMessage: null,
            changeModeView:Date.now()

        }

        this.id = this.props.booking.bookingId
        this.numVersion =this._createNumVersion()
        this._init = this._init.bind(this)
        this._init()

    }

    _createNumVersion = ()=>{
       return  this.props.data.numVersion + "-"+this.props.booking.booking.numVersion + "-"+this.props.booking.contacts.numVersion
    }
    componentDidUpdate() {
        let exVersion = this._createNumVersion()
        if (this.id != this.props.booking.bookingId || this.numVersion != exVersion) {
            this.numVersion =exVersion

            this._init()
            if (this.id != this.props.booking.bookingId ){
                this.setState({changeModeView:Math.random()})
            }
            this.id = this.props.booking.bookingId
            this.setState({refresh:true})


        }

    }


    _init() {
       


        // let list2 = []
        // if (this.props.booking.segments) {
        //     for (let segment of this.props.booking.segments.data) {
        //         list2.push({
        //             title: "", id: segment.id
        //         })
        //     }
        // }
        // columns[2].custom.list = list2










        if (this.props.booking && this.props.booking.booking) {
            // columns[8].custom.list = []
           // console.log("passenger is undefined",this.props.booking.booking)

            // for (let line of this.props.booking.booking.data) {
            //     if (!line){
            //         columns[8].custom.list.push({
            //             title: passenger.lastName + " " + passenger.firstName,
            //             id: passenger.id
            //         })
            //     }

        }


        // columns[1].custom.render=(rowData,obj) => {
        //     let title = obj && obj.title ? obj.title : ""
        //     if (!title) return null
        //     let website = this.dicoCarriers[obj.idP || obj.idSya || obj.id]
        //  //   console.log(rowData)

        //     return <><span>  {title }</span>
        //     {website?<IconButton aria-label="expand row" size="small" onClick={()=>{
        //         let lastName= (this.props.booking.passengers.data[0].lastName || "").toUpperCase().split(" ")[0]
        //         let firstSegment = undefined
        //         if (rowData[2]){
        //             for (let l of rowData[2]){
        //                 if (this.props.booking.segments && this.props.booking.segments.data){
        //                     for (let seg of this.props.booking.segments.data){
        //                         if (seg.id===l.id){
        //                             firstSegment=seg
        //                             break
        //                         }
        //                     }
        //                 }
        //                 if (firstSegment) {
        //                     break
        //                 }
        //             }
        //         }
              
        //         //console.log("firstSegment",firstSegment)

        //         let day =""
        //         let month=""
        //         let year =""
        //         let fromIATA =""
        //         let toIATA = ""
        //         if (firstSegment){
        //             let array = String(firstSegment.dTime || "").split("T")[0].split("-")
        //             day = String(Number(array[2]))
        //             month = String(Number(array[1]))
        //             year = array[0]
        //             fromIATA = firstSegment.from?firstSegment.from.iata:""
        //             toIATA= firstSegment.to?firstSegment.to.iata:""
        //         }

        //         let link = (website.manage_website || website.website || "")
        //         link = link.replace("{bookingMail}",rowData[5] || "")
        //         link = link.replace("{bookingReference}",rowData[3] || "")
        //         link = link.replace("{passengerName}",lastName)
        //         link = link.replace("{day}",day)
        //         link = link.replace("{month}",month)
        //         link = link.replace("{year}",year)
        //         link = link.replace("{fromIATA}",fromIATA)
        //         link = link.replace("{toIATA}",toIATA)

        //         // link = link.replace("{trNum}",fromIATA)






        //         window.open(link , '_blank');

        //     }}>
        //       <OpenInNewIcon />
        //     </IconButton>:null}
        //     </>

        //     // if (!rowData[11]) return null
        //     // return <a href={rowData[11]} target="_blank">{"Link"}  </a>
        // }


    }

    componentDidMount() {

    }

    _checkIsValid = (data) => {
        for (let l of data) {
            if (!l.mail_to) {
                return false
            }
        }
        return true
    }


    render() {




        if (this.state.refresh) {
            this.setState({ refresh: false })
        }


        let data = this.state.dataTemp || this.props.data



        let dataTemp =data.data
        let nbMask = 0
     

        // console.log("data",data)

        return <>

            {this.state.refresh ? null : <ModelTable
                className={"table-mail_forward"}
                data={{ ...data, data: dataTemp }}
                dataOriginal={data}
                name={"mail_forward"}
                changeModeView={this.state.changeModeView}

                disabledModification={this.props.disabledModification || this.state.disabledModification}
                readonly={this.props.readonly}
                // footerHistory={<SelectHistory
                //     style={{ minWidth: "100px" }}
                //     version={this.props.booking.bookingId}
                //     filter={{ relationId: "bookingId:" + this.props.booking.bookingId, event: "booking" }}
                //     historyData={(data, error, flagReset) => {
                //         if (flagReset) {
                //             return this.setState({ dataTemp: null, disabledModification: false })
                //         }
                //         if (!data) {
                //             return this.setState({ disabledModification: true, dataTemp: [] })
                //         }
                //         if (data.rawData && typeof data.rawData === "string") data.rawData = JSON.parse(data.rawData)
                //         if (data.rawData) this.setState({ dataTemp: data.rawData.booking, disabledModification: true })
                //     }}
                // />}
               
                columns={columns}
                options={options}
                modelLineAdd={{ mail_from: "*",mail_to:"*",status:"ACTIVE",dateActivated:new Date().toISOString().substr(0,19),group:[{id:"all",title:"all"}] }}

                // onListen={(data,ev)=>{
                //     if (ev.columnData && ev.columnData.name==="transporter"){
                //         if (ev.rowData[ev.columnIndex].raw && ev.rowData[ev.columnIndex].raw.infos){
                //             let infos = ev.rowData[ev.columnIndex].raw.infos
                //             if (infos.checkin){
                //                 for (let prop of ["booking_mail","booking_password","get_ticket_alone"]){
                //                     if (infos.checkin.indexOf(prop)!=-1){
                //                         if (! data[ev.columnIndex][4])  data[ev.columnIndex][4]=[]
                //                          let flagFind = data[ev.columnIndex][4].filter((o)=>{ return o.id===prop})[0]
                //                          if (!flagFind) data[ev.columnIndex][4].push({title:prop,id:prop})
                //                          console.log("OK add "+prop)
                //                      }
                //                 }
                //             }
                //         }
                //     }
                //     // console.log("data,ev",data,ev)
                //     return data
                // }}

                onChange={(data) => {
                    if (!this._checkIsValid(data.data)) {
                        return this.setState({ errorMessage: "Certaines lignes sont incomplètes" })
                    }
                    //console.log("data",data)
                    // return 
                    this.props.onChange(data)
                }}
                searchDataHistory={this.props.searchDataHistory}
            >

            </ModelTable>
            }
            <CombigoSnackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={this.state.errorMessage !== null || this.state.snackMessage !== null}
                onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
                variant={this.state.errorMessage != null ? 'error' : "success"}
                autoHideDuration={6000}
                message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
            />
        </>


    }
}




const mapStateToProps = (state) => {
    return { booking: state.booking, base: state.data };
};

export default connect(mapStateToProps, {})(Account);