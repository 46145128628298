import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';

import MenuLeft from "./MenuLeft"

import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';

import { _fetch, parseQuery } from '../../helpers';
import Badge from '@material-ui/core/Badge';
// import SummarizeIcon from '@mui/icons-material/Summarize';
import SubjectIcon from '@material-ui/icons/Subject';
import Hubspot from '../../assets/img/hubspot.png';
import Freshdesk from '../../assets/img/freshdesk.png';
import BuildIcon from '@material-ui/icons/Build';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import NoteIcon from '@material-ui/icons/Note';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import RestoreIcon from '@material-ui/icons/Restore';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import MailIcon from '@material-ui/icons/Mail';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
class Body extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            errorMessage: null,
            snackMessage: null

        }

    }


    componentDidMount() {

    }



    componentDidUpdate(prevProps) {

    }


    render() {


        return <>
            <MenuLeft
                title={this.props.booking.informations.referenceBooking}
                Menu={[
                    {
                        list: [
                            {
                                text: "Home", icon: <SubjectIcon />, onClick: () => {
                                    this.props.onClick('general')
                                }
                            },
                            // {
                            //     text: "Booking", icon: <ShoppingBasketIcon />, onClick: () => {
                            //         this.props.onClick('booking')
                            //     }
                            // },
                            {
                                text: "Notes", icon: <Badge badgeContent={this.props.nbNotes} color="primary">
                                    <MenuBookIcon />
                                </Badge>, onClick: () => {
                                    this.props.onClick('notes')
                                }
                            }
                            ,
                            {
                                text: "tasks (Beta)", icon: <Badge badgeContent={this.props.nbTaskOpen} color="primary">
                                    <AssignmentIcon />
                                </Badge>
                                , onClick: () => {
                                    this.props.onClick('tasks')
                                }
                            }
                            ,
                            {
                                text: "mail", icon: <Badge  color="primary">
                                    <MailIcon />
                                </Badge>
                                , onClick: () => {
                                    this.props.onClick('mail')
                                }
                            }
                            ,
                            {
                                text: "realtime", icon: <FlightTakeoffIcon />
                                , onClick: () => {
                                    this.props.onClick('realtime')
                                }
                            },
                            {
                                text: "Logs", icon: <RestoreIcon />, onClick: () => {
                                    this.props.onClick('logs')
                                }
                            }
                            ,
                           




                        ]
                    },
                    {
                        list: [
                        
                            {
                                text: "Freshdesk", icon: < img src={Freshdesk} width="20px" height="20px" />, onClick: () => {
                                    window.open('https://newaccount1603196282781.freshdesk.com/a/search/tickets?sortBy=relevance&term=' + this.props.booking.informations.referenceBooking, '_blank');
                                }
                            }
                        ]
                    },
                    {
                        list: [

                            {
                                text: "accountCustomer", icon: <AccountCircleIcon />
                                , onClick: () => {
                                    this.props.onClick('accountCustomer')
                                }
                            },
                            // {
                            //     text: "accountCustomerLogs", icon: <RestoreIcon />
                            //     , onClick: () => {
                            //         this.props.onClick('accountCustomerLogs')
                            //     }
                            // }
                        ]
                    },
                    // {
                    //     list:[
                    //             {
                    //             text: "Hubspot", icon: < img src={Hubspot} width="20px" height="20px" />, onClick: () => {
                    //                 _fetch(process.env.REACT_APP_URL + '/crm/qd_temp', {
                    //                     "timeout": 10000,
                    //                     "nbRetry": 1,
                    //                     "method": "post",
                    //                     "headers": {},
                    //                     "body": {
                    //                         type: "hubspot",
                    //                         bookingId: this.props.booking.bookingId
                    //                     }
                    //                 })
                    //                     .then(function (response) {
                    //                         if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                    //                         let data = response.json()
                    //                         window.open(data.url, '_blank');
                    //                     }.bind(this))
                    //                     .catch(function (e) {
                    //                         console.log("Error", e)
                    //                     }.bind(this));
                    //             }
                    //         }
                    //     ]
                    // }
                ]}>
                {this.props.children}
            </MenuLeft>

            <CombigoSnackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={this.state.errorMessage !== null || this.state.snackMessage !== null}
                onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
                variant={this.state.errorMessage != null ? 'error' : "success"}
                autoHideDuration={6000}
                message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
            />
        </>





    }
}





export default withRouter(Body);




