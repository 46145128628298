/* eslint-disable no-use-before-define */
import React from 'react';
import { connect } from 'react-redux';
//https://ourcodeworld.com/articles/read/1065/top-15-best-rich-text-editor-components-wysiwyg-for-reactjs
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import CheckBoxCombigo from "./../Utils/Select_CheckBox"

import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';

import { parseQuery, _fetch } from "./../../helpers"
import MyEditor from "./../EditorText/EditorText.js"
import EmailForm from "./../EmailForm/EmailForm.js"
import Label from "./../Label/Label"
import "./ModalMail.scss"
import {Buffer} from "buffer"

function getContactDefault(doc) {
    // if (!doc.contacts) {
    //     console.log()
    // }
    for (let line of doc.contacts.data) {
        if (line.mode == "USER_DEFAULT") return line
    }
    return doc.contact.data[0]
}

function getDataTable(data) {

    if (data && data.table && data.table[0] && data.table[0].data) {
        return data.table[0].data
    }
    return []
}


class Body extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            textModel: this.props.textModel,
            listModel: [],
            locale: {},
            snackMessage: null,
            errorMessage: null,
            listLocale: [{ id: "en", name: "Anglais" }, { id: "fr", name: "Français" }, { id: "es", name: "Espagnol" }],
            mailIntern: "romain." + this.props.data.informations.referenceBooking.toLowerCase() + "@syamail.fr",
            mail: {
                from: [{ "title": "support@combigo.com", name: "Combigo" }],
                to: [],
                cci: [],
                content: "",
                subject: "",
                attachments: [],
                ...this.props.mailData
            }
        }
        this._request = this._request.bind(this)
        this._loadModel = this._loadModel.bind(this)
        this._init = this._init.bind(this)


    }

    _init() {

        let infoContact = getContactDefault(this.props.booking)
        let localeObj = this.state.listLocale.filter(function (obj) {
            return obj.id == (infoContact.locale || this.props.locale)
        })[0]
        this.setState({
            textModel: this.props.textModel,
            locale: localeObj || {},
            infoContact: infoContact,
            mailIntern: "romain." + this.props.data.informations.referenceBooking.toLowerCase() + "@syamail.fr",
            mail: {
                from: [{ "title": "support@combigo.com", name: "Combigo" }],
                to: infoContact ? [{ "title": infoContact.mail, name: infoContact.lastName + " " + infoContact.firstName }] : undefined,
                cci: [],
                content: "",
                subject: "",
                attachments: [],
                ...this.props.mailData
            }
        })
    }

    async componentDidMount() {
        this._init()

        let data = await this._request({ action: "list" }, "/crm/mail/model")
        if (data) this.setState({ listModel: data })
    }

    componentDidUpdate(prevProps) {

        if (prevProps.bookingId != this.props.bookingId || prevProps.version != this.props.version || prevProps.id != this.props.id) {
            this._init()
        }
    }

    async _sendMail() {

        let mail = {
            content: this.state.mail.content,
            to: convertSend(this.state.mail.to),
            cci: convertSend(this.state.mail.cci),
            from: convertSend(this.state.mail.from)[0],
            subject: this.state.mail.subject,
            attachments: convertAttachment(this.state.mail.attachments),
            category: this.state.idModel || "",

        }
        this.setState({ disabledButtonSend: true })

        this._request({
            mail: mail,
            bookingId: this.props.data.bookingId,
            user: this.props.nameUser,
            locale: this.state.locale.id
        }, "/crm/mail/send").then((response) => {
            if (response) {
                this.setState({ snackMessage: "Message envoyé !" })
                setTimeout(() => {
                    this.setState({ disabledButtonSend: false })
                    this.props.onClose()
                }, 2000)

            }
            else {
                this.setState({ errorMessage: "Problème, Message pas envoyé !", disabledButtonSend: false })
            }
        })

        function convertSend(arr = []) {
            return arr.map(function (obj) {
                return { "Name": obj.name || obj.Name, "Email": obj.title || obj.email || obj.mail || obj.Email }
            })
        }

        function convertAttachment(arr = []) {
            return arr.map(function (obj) {
                return {
                    "ContentType": obj.file.type,
                    "Filename": obj.file.name,
                    "Base64Content": (obj.base64 == false ? new Buffer.from(obj.data).toString("base64") : obj.data).split("base64,").pop()
                }
            })
        }
    }


    async _loadModel(textModel, idModel, locale) {
        if (!locale || !textModel) {
            return this.setState({ model: {} })
        }

        // console.log(textModel, this.state)
        let data = await this._request({
            action: "get",
            "name": textModel,
            "idModel": idModel,
            "locale": locale,
            bookingId: this.props.booking ? this.props.booking.bookingId : undefined
        }, "/crm/mail/model")
        if (!data || !data.mail) {
            return alert("Error retry")
        }
        let model = data.mail
        let mail = Object.assign({}, this.state.mail)
        mail["subject"] = model.subject
        mail["content"] = model.contentHTML
        mail["attachments"] = model.attachments
        mail["from"] = (model.sender ? [{ title: model.sender.Email, name: model.sender.Name }] : undefined) || model.from || this.state.mail.from
        mail["to"] = model.to || this.state.mail.to
        mail["cci"] = model.cci || this.state.mail.cci
        this.setState({ mail: mail, model: model })
        if (model.errors && model.errors.length) {
            setTimeout(() => {
                alert("Error with booking and template mail : " + model.errors.join(" ; "))

            }, 2000)
        }
    }


    _request(body, route) {
        return new Promise(function (resolve) {
            _fetch(process.env.REACT_APP_URL + route, {
                "timeout": 8000,
                "nbRetry": 1,
                "method": "post",
                "headers": {},
                "body": body
            })
                .then(function (response) {
                    if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));

                    // this.setState({snackMessage:"Message envoyé"})
                    resolve(response.json())
                }.bind(this))
                .catch(function (e) {
                    console.error("Error modal mail", e);

                    alert("mettre un snackbar pour informer de l'erreur modal mail")
                    resolve(undefined)
                }.bind(this));
        }.bind(this))
    }





    render() {

        // console.log("this.state.locale " , this.state.locale)
        return (
            <div className={"action-mail"} style={{ ...this.props.style, width: "100%" }}>

                <div className={"toolbar"}>
                    <Label >{"Pour charger un modèle :"}</Label>
                    <div className={"space-toolbar model-mail"}>
                        {this.state.listModel && this.state.listModel.length ? <Autocomplete
                            options={this.state.listModel || []}
                            getOptionLabel={(option) => option.name}
                            inputValue={this.state.textModel}
                            onChange={async (event, newInputValue) => {
                                this.setState({ textModel: newInputValue ? newInputValue.name : "", idModel: newInputValue ? newInputValue.id : "" })
                                if (newInputValue && newInputValue.name) {
                                    this._loadModel(newInputValue.name, newInputValue.id, this.state.locale.id)
                                }

                            }}
                            renderInput={(params) =>
                                <TextField {...params}
                                    variant="standard"
                                    onChange={(event) => {
                                        this.setState({ textModel: event.target.value })
                                    }}
                                    aria-label={'description'} />
                            }
                        />:null}
                    </div>
                    <div className={"space-toolbar select-langue"}>
                        <CheckBoxCombigo
                            className={"select-status"}
                            style={{ minWidth: "100%" }}
                            label={"Langue"}
                            list={this.state.listLocale}
                            multiple={false}
                            chips={false}
                            getMatching={(option) => { return option.id }}
                            getLabel={(option) => { return option ? option.name : option }}
                            value={this.state.locale}
                            onChange={(value) => {
                                this.setState({ locale: value })
                                this._loadModel(this.state.textModel, this.state.idModel, value.id)
                            }}
                        ></CheckBoxCombigo>

                    </div>
                    <Button className={""}
                        onClick={() => {
                            this._loadModel(this.state.textModel, this.state.idModel, this.state.locale.id)


                        }} >
                        {"Load/Update"}

                    </Button>
                    <div style={{ flexGrow: 1 }}></div>

                </div>

                <div className={"mail"}>
                    <EmailForm
                        onChange={(prop, value) => {
                            let mail = Object.assign({}, this.state.mail)
                            mail[prop] = value
                            //console.log(mail)
                            this.setState({ mail: mail })
                        }}
                        listFrom={[{ "title": "support@combigo.com", name: "Combigo" }, { "title": "noreply@combigo.com", name: "Combigo no-reply" }, { "title": this.state.mailIntern, "name": "Romain" }]}
                        listTo={this.state.mail.to}
                        listCci={this.state.mail.cci || [{ "title": this.state.mailIntern, "name": "Copy" }]}
                        subject={this.state.mail.subject}
                        attachments={this.state.mail.attachments}
                        content={this.state.mail.content}
                        from={this.state.mail.from}
                        to={this.state.mail.to}
                        cci={this.state.mail.cci}
                        id={this.props.id}
                        version={this.props.version}

                    ></EmailForm>
                </div>


                <div className={"footer"}>
                    <div style={{ flexGrow: 1 }}></div>
                    <Button
                        disabled={this.state.disabledButtonSend}
                        className={""}
                        style={{ marginRight: "50px" }}
                        onClick={() => {
                            this._sendMail()

                        }} >
                        {"Envoyer"}
                    </Button>

                </div>




                <CombigoSnackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.errorMessage !== null || this.state.snackMessage !== null}
                    onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
                    variant={this.state.errorMessage != null ? 'error' : "success"}
                    autoHideDuration={6000}
                    message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
                />

            </div>

        );
    }
}




const mapStateToProps = (state) => {
    return { booking: state.booking, nameUser: state.user.name };
};

export default connect(mapStateToProps, {})(Body);