import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  breakpoints: {
    values: {
      sm: 640,
    }
  },
  shape: {
    borderRadius: 10,
  },
  spacing: 1,
  palette: {
    primary: {
      main: '#3333CC',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
  overrides: {
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: '0.625rem',
      }
    },
    MuiCheckbox: {
          root: {
            color: '#3333cc',
          },
          // colorPrimary: {
          //   '&:before': {
          //     content: '""',
          //     left: 8,
          //     top: 8,
          //     height: 18,
          //     width: 18,
          //     position: "absolute",
          //     border: '1px solid rgba(51,51,204,0.2)',
          //     borderRadius: 4,
          //     zIndex: 1
          //   },
          //   '&$checked': {
          //     color: 'white',
          //     '&:before': {
          //       borderColor: 'rgba(51,51,204,0.6)',
          //     }
          //   },
          //   '&:hover': {
          //     background: 'none!important',
          //   }
          // }
    },
    MuiExpansionPanelDetails: {
      root: {
        flexDirection: 'column',
        padding: 0,
      }
    },
    MuiPaper: {
      elevation8: {
        boxShadow: '0 0 16px 0 rgba(26,26,102,0.1)'
      },
      root: {
        outline: 'none!important'
      }
    },
    MuiExpansionPanelSummary: {
      content: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }
    },
    MuiDivider: {
      root: {
         backgroundColor: 'rgba(51,51,204,0.04)'
      }
    }
  }
});

export default theme;