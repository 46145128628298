import React, { Component, version } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from "material-table";
import WrapperMaterialTable from "./../Table/WrapperMaterialTable"

import Button from '@material-ui/core/Button';
import Delete from '@material-ui/icons/Delete';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import IconButton from '@material-ui/core/IconButton';

import Drawer from '@material-ui/core/Drawer';
import Edit from '@material-ui/icons/Edit';
import Chip from '@material-ui/core/Chip';
import "./TableBankingCard"
import CheckBoxCombigo from "./../Utils/Select_CheckBox"
import Grid from '@material-ui/core/Grid';
import TooltipCombigo from "./../Utils/TooltipCombigo"
import Toolbar from "./ToolbarTransactions"
import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';


class Table extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      errorMessage:null,
      snackMessage:null
    }
  }


  render() {
    if (!this.state.data) return null
    let size = Math.min(this.state.data.length, 100)

    const columns = [
      {
        title: "", field: "", render: function (rowData) {
          return <a href={"/oneTransaction/" + rowData.bookingId} target="_blank"><IconButton onClick={() => {
            // this.props.history.push("/oneTransaction?bookingId="+rowData.bookingId)
          }}>
            <FolderOpenIcon />
          </IconButton>
          </a>
        }.bind(this)
      },
      {
        title: "date", field: "date", render: (rowData) => {
          return new Date(rowData.date).toISOString().replace("T", " ").substr(0,16)
        }
      },
      { title: "cardReference", field: "cardReference" },
      { title: "typeTransaction", field: "type" },
      { title: "notification", field: "notification" },
      { title: "merchant", field: "merchant" },


      { title: "description", field: "description" },
      { title: "amount", field: "amount" },
      // { title: "balance", field: "balance" },
      // {
      //   title: "", field: "", render: function (rowData) {
      //     return <a href={"/oneTransaction/" + rowData.bookingId} target="_blank">{rowData.bookingId} </a>
      //   }.bind(this)
      // },
      { title: "bookingId", field: "bookingId" },
     
      { title: "status", field: "status" },
      { title: "transactionReference", field: "transactionReference" },

    ]



    return (


      <>

        <Toolbar
          url={'/crm/bankcard'}
          timeout={this.props.timeout || 2 * 60000} //12000
          filter={{
            ...this.props.filter,

          }}
          version={this.state.filterStr}
          onUpdate={function (data, error) {
            if (data) {
              if (this.state.version != data.version) {

                this.setState({ data: data.data, version: data.version, dateUpdate: Date.now() })
              }
              else {
                this.setState({ dateUpdate: Date.now() })
              }
            }
            if (error){
              this.setState({errorMessage:"Erreur to update the data, retry",data:[],version:Math.random()})
            }

          }.bind(this)}

        />

        <div style={{ maxWidth: "100%" }}>
          <WrapperMaterialTable
            columns={columns}

            data={this.state.data}
            title={this.props.title || ""}
            options={{
             pageSize: size,
              pageSizeOptions: [50, 100, 200, 400],
              grouping: true,
              exportButton: true,
              padding: "dense",
              //filtering: true
            }}
          />
        </div>
        <CombigoSnackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.errorMessage !== null || this.state.snackMessage !== null}
                    onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
                    variant={this.state.errorMessage != null ? 'error' : "success"}
                    autoHideDuration={6000}
                    message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
                />
      </>

    );
  }
}



const mapStateToProps = (state) => {
  return { base: state.data };
};

export default connect(mapStateToProps, {})(Table);