import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";


import Onglet from './onglet'
import "./OneTransaction.scss"
import { parseQuery, _fetch } from "./../helpers"
import { setBooking, resetBooking } from './../Store/actions/booking';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FloatMenu from "./../Components/FloatMenu/FloatMenu"
import { CombigoSnackbar } from './../Components/Snackbar/Snackbar';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';


class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      snackMessage: null,
      dateLastModification: null
    }

    this.lastRequestVersion = 0
    this._updateVersion = this._updateVersion.bind(this)
    this.updateBooking = this.updateBooking.bind(this)
    this._downloadBookingInfo = this._downloadBookingInfo.bind(this)
    this._getBookingIdUrl = this._getBookingIdUrl.bind(this)
    this.nbTryVersion = 0
  }

  componentDidMount() {

    this.updateBooking(true)
    this._updateVersion()

  }

  _getBookingIdUrl() {
    return {
      bookingId: window.location.pathname.split("/").pop()
    }
  }
  componentDidUpdate(prevProps) {
    let query = this._getBookingIdUrl()

    // console.log("Component did update prev=" + prevProps.bookingId + " propsBookingId=" + this.props.bookingId + " query=" + query.bookingId + " booking" + (this.props.booking ? this.props.booking.bookingId : "?"))

    if (prevProps.bookingId != this.props.bookingId || ((this.props.booking ? this.props.booking.bookingId : "?") != this.props.bookingId)) {
      return this._downloadBookingInfo(this.props.bookingId, 0, true)
    }
    //console.log("update",prevProps,this.props)
    // if (prevProps.booking && this.props.booking && this.props.bookingId )
    this.updateBooking()

  }

  _updateVersion() {

    let query = this._getBookingIdUrl()

    // console.log("update version ",query,"booking",this.props.booking)
    if (this.props.booking && this.props.booking.bookingId && query.bookingId === this.props.booking.bookingId) {
      let bookingId = this.props.booking.bookingId
      if ((Date.now() - this.lastRequestVersion) > 20000) {
        this.lastRequestVersion = Date.now()
        
        _fetch(process.env.REACT_APP_URL + '/crm/transactions/version_updated', {
          "timeout": 3000,
          "nbRetry": 1,
          "method": "post",
          "headers": {},
          "body": {
            bookingId: this.props.booking.bookingId,
            _hashVersion: this.props.booking._hashVersion
          }
        })
          .then(function (response) {
            this.lastRequestVersion = 0
            if (response.status !== 200 && response.status != 201) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
            //let data = response.json()

            if (response.status == 200 && bookingId === this.props.bookingId) {
              //  console.log("Mise à jour"
              this.setState({ snackMessage: "Réservation vient d'être mis à jour car des modifications ont eu lieu", dateLastModification: Date.now() })
              this._downloadBookingInfo(bookingId)
            }
            this.nbTryVersion = 0

          }.bind(this))
          .catch(function (e) {
            if (this.nbTryVersion > 5) {
              this.setState({ errorMessage: "Error one transactions _updateVersion" })
              this.nbTryVersion = 0
            }
            this.nbTryVersion++
            console.error("Error one transactions _updateVersion", e);
            this.lastRequestVersion = 0
          }.bind(this));
      }
    }
    else {
      this.lastRequestVersion = 0
    }
    
    setTimeout(() => {
      this._updateVersion()
    }, 10000)

  }


  updateBooking(force = false) {

    if (force || window.location.search != this.location_url) {
      window.scrollTo(0, 0)
      this.location_url = window.location.search
      let query = this._getBookingIdUrl()

      if (!this.props.bookingId) {
        // fix el temps de mettre à jour tout les query
        // console.log("On route vers le oneTransaction " + query.bookingId)
        return this.props.history.push("/oneTransaction/" + query.bookingId + window.location.search)
      }
      const bookingId = query.bookingId || this.props.bookingId;
      // console.log("this.props.bookingId  "+this.props.bookingId + " bookingId="+bookingId)
      if (bookingId) {

        this._downloadBookingInfo(bookingId, undefined, undefined, true)
      }
      else this.props.resetBooking()
    }
  }


  _downloadBookingInfo(bookingId, nbTry = 0, force = false, activeLoading = false) {
    //console.log(this.props.booking)
    //   console.log("this.props.booking.bookingId ="+this.props.booking.bookingId  + " bookingId="+bookingId)
    if (!force && this.props.booking.bookingId && bookingId != this.props.booking.bookingId) {

      return
    }

    if (activeLoading) {
      this.setState({ loaded: true })

    }

    let query = this._getBookingIdUrl()

    if (query.bookingId && query.bookingId != bookingId) {
      return
    }


    _fetch(process.env.REACT_APP_URL + '/crm/transactions/search', {
      "timeout": 3000,
      "nbRetry": 1,
      "method": "post",
      "headers": {},
      "body": {
        bookingId: bookingId,
        addCustomerData: true
      }
    })
      .then(function (response) {
        if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
        let data = response.json()

        if (!force && this.props.booking.bookingId && bookingId != this.props.booking.bookingId) {
          return
        }

        let query = this._getBookingIdUrl()


        if (query.bookingId && query.bookingId != bookingId) {
          return
        }

        if (!data[0]) {
          console.error("Booking id unknow ", bookingId, data)
          this.setState({ errorMessage: "Impossible de charger cette réservation!!!!" })
          return
        }
        // this.setState({dateLastModification:Date.now()})
        //  console.log("On update booking " , data[0])
        this.props.setBooking(data[0])

        // if (this.isCombigoTeam(data[0])){
        //   return this.setState({errorMessage:"Oups tu ne peux pas gérer ta propre réservation! Ne t'inquiête pas le meilleur service client va s'occuper de toi :-)."})
        // }

        setTimeout(() => {
          this.setState({ loaded: false })
        }, 200)

      }.bind(this))
      .catch(function (e) {
        if (nbTry > 5) {
          console.error("Error one transactions _downloadBookingInfo", e);

          this.setState({ errorMessage: "Impossible de charger cette réservation!!!!" })
          return
        }
        setTimeout(() => {
          this._downloadBookingInfo(bookingId, ++nbTry, force, activeLoading)
        }, 2000)
      }.bind(this));

  }

  isCombigoTeam(booking) {

    return false
  }


  render() {


    return <>
      {this.state.loaded ? <Backdrop open={true} className={"backdrop-loading"} >
        <CircularProgress color="inherit" />
      </Backdrop> : null}

      <div className={"view-transaction"}>



        <Onglet
          version={this.props.booking ? this.props.booking._hashVersion : ""}
          bookingId={this.props.bookingId}
          dateLastModification={this.state.dateLastModification}
        />

        <CombigoSnackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.errorMessage !== null || this.state.snackMessage !== null}
          onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
          variant={this.state.errorMessage != null ? 'error' : "info"}
          autoHideDuration={8000}
          message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
        />
      </div>
    </>
  }
}

const mapStateToProps = (state) => {
  return { booking: state.booking, user: state.user };
};

export default connect(mapStateToProps, {
  setBooking, resetBooking
})(withRouter(Body));
