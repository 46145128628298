
import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setBooking } from '../../Store/actions/booking';
import { _fetch, isMobile } from '../../helpers';
import Aucocomplete_ from "../../lib/Autocomplete/Autocomplete"


class Body extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectValue: "*",
            value: "",
        }

        this.changeSelect = this.changeSelect.bind(this)
        this.request = this.request.bind(this)

    }


    request(body) {
        return new Promise(function (resolve) {

            _fetch(process.env.REACT_APP_URL + '/crm/transactions/search', {
                "timeout": 2000,
                "nbRetry": 1,
                "method": "post",
                "headers": {},
                "body": body
            })
                .then(function (response) {
                    if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                    resolve(response.json())
                }.bind(this))
                .catch(function (e) {
                    console.error("Error search autocomplete", e);
                }.bind(this));


        }.bind(this))

    }


    changeSelect(ev) {
        this.setState({ selectValue: ev.target.value, loading: true })
        this.request({
            typeSearch: ev.target.value,
            searchTxt: this.state.value,
            mode: "autocomplete",
            size: 10
        })

    }


    render() {

        return <div className={"searchBooking-bar"}>
            <div className={"subcontainer"}>
                {this.props.title && <div className={"title"}>
                    {this.props.title}
                </div>}

                {this.props.maskSelect === true ? null : <Select
                    className={"select-mode-search"}
                    // labelId=""
                    // MenuProps={{PopoverClasses:{zIndex:10000}}}
                    value={this.state.selectValue}
                    style={{ background: "white" }}
                    onChange={this.changeSelect}
                >
                    <MenuItem value={""}>{"*"}</MenuItem>
                    <MenuItem value={"*"}>{"*"}</MenuItem>
                    <MenuItem value={"bookingId"}>Id</MenuItem>
                    <MenuItem value={"bookingReference"}>Reference</MenuItem>
                    <MenuItem value={"mail"}>Mail</MenuItem>
                    <MenuItem value={"trNum"}>Numéro Vol</MenuItem>
                    <MenuItem value={"bookingTR"}>Booking PNR</MenuItem>
                    <MenuItem value={"notes"}>Note</MenuItem>
                    <MenuItem value={"bookingProv"}>Booking Provider</MenuItem>
                    <MenuItem value={"phone"}>PhoneNumber</MenuItem>
                    <MenuItem value={"name"}>Name</MenuItem>
                </Select>}

                <Aucocomplete_
                    className={"autocomplete-search"}
                    placeholder={"Search a booking ...."}
                    options={async (value) => {
                        if (!value) return []
                        return this.request({
                            typeSearch: this.state.selectValue,
                            searchTxt: value,
                            mode: "autocomplete",
                            sorting: "CREATED_DATE_DEC",
                            // data: {
                            //     autocomplete: true
                            // },
                            size: 30
                        })
                    }}
                    onChange={(value) => {
                        this.setState({ value: value })
                    }}
                    onClick={this.props.onClick}
                />


            </div>
        </div>

    }
}


const mapStateToProps = (state) => {
    return { booking: state.booking };
};

export default connect(mapStateToProps, {
    setBooking
})(withRouter(Body));
