

import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import Progress from './../Progress/progress';
import { AttachFile } from '@material-ui/icons';
import "./DragAndDrop.scss"
import Chip from '@material-ui/core/Chip';
import { _fetch } from '../../helpers';



class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      files: this._cleanFiles() //props.files || []
    }

    this.deleteFiles = this.deleteFiles.bind(this)
    this._convertFormatFiles = this._convertFormatFiles.bind(this)
    this.setFiles = this.setFiles.bind(this)
  }

  _cleanFiles = ()=>{
    return (this.props.files || []).filter((obj) => {
      return obj ? true : false
    })
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.files) !== JSON.stringify(prevProps.files)) {
     
      this.setState({ files: this._cleanFiles() })
    }
  }

  setFiles(files) {
    let newFiles = this.state.files.concat(files)
    for (let k = 0; k < newFiles.length; k++) {
      if (newFiles[k].file) newFiles[k].name = newFiles[k].file.name
    }
    this.setState({ files: newFiles })
    return newFiles
  }

  deleteFiles(indexDelete) {


    let files = this.state.files.filter(function (obj, index) {
      if (index == indexDelete) return false
      return true
    })
    for (let k = 0; k < files.length; k++) {
      if (files[k].file) files[k].name = files[k].file.name
    }
    this.setState({ files: files })
    return files
  }


  _convertFormatFiles(files) {
    let results = []
    for (let file of files) {
      results.push(file.data && file.file ? file : { file: file })
    }

    return results
  }

  render() {

    return (<div className={this.props.className + " dragAndDrop " + (this.state.files.length ? "dragAndDrop-hidden-text" : "")}>

      {/* <div className={"icon"}>
        {this.state.loading?<Progress  /> :<AttachFile></AttachFile>}
      </div> */}
      {this.props.mode != "view" && <DropzoneAreaBase
        previewText={this.props.previewText || "Selected files"}
        showPreviews={false}
        showPreviewsInDropzone={this.props.showPreviewsInDropzone != undefined ? this.props.showPreviewsInDropzone : true}
        useChipsForPreview
        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
        previewChipProps={{ classes: { root: { minWidth: "120px", maxWidth: "250px" } } }}
        filesLimit={1000}
        dropzoneText={this.props.dropzoneText || "your files ..."}
        fileObjects={this._convertFormatFiles(this.state.files)}
        maxFileSize={this.props.maxFileSize || 20000000}
        onDelete={async function (file, index) {
          let files = this.deleteFiles(index)
          if (this.props.onDelete) await this.props.onDelete(files, file, index)
        }.bind(this)}

        onAdd={async function (files) {
          let newFiles = this.setFiles(files)
          // this.setState({loading:true})
          if (this.props.onAdd) await this.props.onAdd(newFiles, files)
          //this.setState({loading:false})
        }.bind(this)}
      >
      </DropzoneAreaBase>}

      {this.props.mode == "view" && <div style={{ display: "flex", flexWrap: "wrap" }}>
        {this.state.files.map(function (obj) {
          if (!obj) return null
          return <Chip
            clickable
            size="small"
            onClick={() => {
            }}
            style={{ margin: "2px" }}
            label={<a style={{ color: "black" }} href={process.env.REACT_APP_URL + "/crm/files?" + "name=" + encodeURIComponent(obj.name) + "&file=" + encodeURIComponent(obj.pathname)} target="_blank" download>{(obj.file ? obj.file.name : obj.name)}</a>}
            // obj.file? obj.file.name :obj.name }
            color="primary"
            variant="outlined"
          />
        }.bind(this))}
      </div>}

    </div>
    )
  }
}


export default Body;


/*

                        _fetch('https://cors-anywhere.herokuapp.com/' + fileURL, {
                          method: 'post',
                          headers: { },
                          body:{
                            name:obj.name,

                          }
                        })
                        .then((response) => response.blob())
                        .then((blob) => {
                          // Create blob link to download
                          const url = window.URL.createObjectURL(
                            new Blob([blob]),
                          );
                          const link = document.createElement('a');
                          link.href = url;
                          link.setAttribute(
                            'download',
                            `FileName.pdf`,
                          );

                          // Append to html link element page
                          document.body.appendChild(link);

                          // Start download
                          link.click();

                          // Clean up and remove the link
                          link.parentNode.removeChild(link);
                        });

*/