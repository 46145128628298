import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Popper from '@material-ui/core/Popper';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import moment from "moment";
import { parseQuery, _fetch } from "./../../helpers"

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Edit from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

import TooltipCombigo from "./../Utils/TooltipCombigo"

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import CheckBoxCombigo from "./../Utils/Select_CheckBox"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import { connect } from 'react-redux';
import './VisionTransaction.scss';




class Body extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            bookingIdModal: null,
            modalManagerOpen: false,
            openModalDescription: false,
        }

        this.onChange = this.onChange.bind(this)
        // console.log(this.props)
    }


    componentDidMount() {
    }
    componentDidUpdate() {

    }



    async onChange(prop, value) {
        let data = Object.assign({}, this.props.data.data)
        data[prop] = value
        if (this.props.onChange) {
            if (await this.props.onChange({ data: data, typeUpdate: "table_data", lastVersion: this.props.data.version }) == true) {
                return true
            }
        }
    }


    render() {




        return (<div className={"recapitulatif-transaction"}>


            <Grid xs={12} sm={12} container item className={""}>


                {["general"].includes(this.props.mode) ? <Grid xs={1} sm={2} container item className={"block"}>
                    <CheckBoxCombigo
                        className={"select-status"}
                        styleSelect={{ width: "100%" }}
                        label={"Transfer Disrupted"}
                        list={this.props.base.transferDisrupted}
                        multiple={false}
                        chips={false}
                        value={this.props.data.data.transferDisrupted}
                        onChange={(value) => {
                            this.onChange("transferDisrupted", value)
                        }}
                    ></CheckBoxCombigo>

                </Grid> : null}

                {["general"].includes(this.props.mode) ? <Grid xs={1} sm={2} container item className={"block"}>
                    <CheckBoxCombigo
                        className={"select-status"}
                        styleSelect={{ width: "100%" }}
                        label={"Démarches"}
                        list={this.props.base.taskCombigoIntern}
                        multiple={false}
                        chips={false}
                        value={this.props.data.data.taskCombigoIntern}
                        onChange={(value) => {

                            this.onChange("taskCombigoIntern", value)
                        }}
                    ></CheckBoxCombigo>


                </Grid> : null}
                {["general"].includes(this.props.mode) ? <Grid xs={1} sm={2} container item className={"block"}>
                    <CheckBoxCombigo
                        className={"select-status"}
                        styleSelect={{ width: "100%" }}
                        label={"Status Voyage"}
                        list={this.props.base.statusCombigoTravel}
                        multiple={false}
                        chips={false}
                        value={this.props.data.data.statusCombigoTravel}
                        onChange={(value) => {

                            this.onChange("statusCombigoTravel", value)
                        }}
                    ></CheckBoxCombigo>


                </Grid> : null}



                {["general"].includes(this.props.mode) ? <Grid xs={1} sm={2} container item className={"block"}>
                    <CheckBoxCombigo
                        className={"select-status"}
                        styleSelect={{ width: "100%" }}
                        label={"Satisfaction Client"}
                        list={this.props.base.satisfactionCustomer}
                        multiple={false}
                        chips={false}
                        value={this.props.data.data.satisfactionCustomer}
                        onChange={(value) => {

                            this.onChange("satisfactionCustomer", value)
                        }}
                    ></CheckBoxCombigo>
                </Grid> : null}

                {["general"].includes(this.props.mode) ? <Grid xs={1} sm={2} container item className={"block"}>
                    <CheckBoxCombigo
                        className={"select-status"}
                        styleSelect={{ width: "100%" }}
                        label={"Satisfaction Note"}
                        list={[-1, 0, 1, 2, 3, 4, 5]}
                        multiple={false}
                        chips={false}
                        value={this.props.data.data.satisfactionNote && this.props.data.data.satisfactionNote != -1 ? Number(this.props.data.data.satisfactionNote) : ""}
                        onChange={(value) => {
                            this.onChange("satisfactionNote", value != null && value != undefined && value != "" ? Number(value) : -1)
                        }}
                    ></CheckBoxCombigo>
                </Grid> : null}


                {["general"].includes(this.props.mode) ? <Grid xs={1} sm={2} container item className={"block"}>
                    <CheckBoxCombigo
                        className={"select-status"}
                        styleSelect={{ width: "100%" }}
                        label={"transferDisruptedCustomer"}
                        list={this.props.base.transferDisruptedCustomer || []}
                        multiple={true}
                        chips={false}
                        value={this.props.data.data.transferDisruptedCustomer}
                        onChange={(value) => {
                            this.onChange("transferDisruptedCustomer", value)
                        }}
                    ></CheckBoxCombigo>


                </Grid> : null}


            </Grid>


        </div>
        )
    }
}






const mapStateToProps = (state) => {
    return { booking: state.booking, base: state.data, nameUser: state.user.name, user: state.user };
};

export default connect(mapStateToProps, {})(Body);