import React from 'react';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import "./MenuLeft.scss"


export default function MiniDrawer(props) {

    const [open, setOpen] = React.useState(false);

    const handleDrawerClose = () => {
        setOpen(!open);
    };

    return (
        <div className={"menu-left"}>

            <div className={"panel " + (open ? "open" : "close")}  >
                <div className={"header"}>

                    <IconButton size={"medium"} onClick={handleDrawerClose}>
                        {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                    {open?<div style={{display:"flex",marginLeft:"10px"}}><span style={{margin:"auto","fontSize":"14px","fontWeight":500}}>{props.title}</span></div>:null}

                </div>
                {props.Menu &&props.Menu.map((o, index) => {
                    return <>
                        <Divider />
                        <List>
                            {o.list.map((o2, index) => {
                                return <ListItem button key={index} onClick={(ev)=>{
                                    setOpen(false);
                                    if (o2.onClick) o2.onClick(ev)
                                }}>
                                    <ListItemIcon > {o2.icon} </ListItemIcon>
                                    {open ? <ListItemText primary={o2.text} /> : null}
                                </ListItem>
                            })}
                        </List>
                    </>

                })}
                {/*
                                <Divider />

                <List>
                {['All mail', 'Trash', 'Spam'].map((text, index)=> {
                        return <ListItem button key={text}>
                        <ListItemIcon> <InboxIcon /> </ListItemIcon>
                        {open ? <ListItemText primary={text} /> : null}
                    </ListItem> 
                    })}
                </List>
                <Divider />
                <List>
                {['All mail', 'Trash', 'Spam'].map((text, index)=> {
                        return <ListItem button key={text} >
                        <ListItemIcon> <InboxIcon /> </ListItemIcon>
                        {open ? <ListItemText primary={text} /> : null}
                    </ListItem> 
                    })}
                </List> */}
            </div>

            {props.children}
            {/* <div className={classes.content}>
        //  <div className={classes.toolbar} /> 
       
        
      </div> */}
        </div>
    );
}