
import * as React from 'react';
import { connect } from 'react-redux';
import { cloneObject } from '../../helpers';
import './TablePaymentInformation.scss';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';
import ModalCapture from "./ModalCapture"
import ModalRefund from "./ModalRefund"
import { parseQuery, _fetch } from "./../../helpers"


const columns = [{ "name": "Provider" }, { "name": "Status" }, { "name": "Amount Init" }, { "name": "Amount Updated" }, { "name": "dCreated" }, { "name": "cardholderName" },
{ "name": "expiredate" }, { "name": "last4Digits" }, { "name": "Fraude" },{"name":"Litige"}, { "name": "typeId" }, { "name": "" }, { "name": "" }, { "name": "id" }, { "name": "lien" }]
const columnsDetails = [{ "name": "date" }, { "name": "status" }, { "name": "amount" }, { "name": "comment" }]



function Row(props) {
    const { row, details, open } = props;
    if (!row) return null
    // console.log("row",row)

    return (
        <React.Fragment>
            <TableRow className={""}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={props.onClick}>
                        {props.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell  >{row.provider}</TableCell>
                <TableCell  >{row.status}</TableCell>
                <TableCell >{row.amountInit + " " + row.currency}</TableCell>

                <TableCell >{row.amountUpdated + " " + row.currency}</TableCell>

                <TableCell >{row.dateCreated ? new Date(row.dateCreated * 1000).toISOString().substr(0, 16).replace("T", " ") : ""}</TableCell>
                <TableCell  >{row.cardholderName}</TableCell>
                <TableCell  >{row.expiredate}</TableCell>
                <TableCell  >{row.last4Digits}</TableCell>
                <TableCell  >{row.fraud && row.fraud.risk_level?row.fraud.risk_score + " "+row.fraud.risk_level:""}</TableCell>
                <TableCell  >{row.dispute || ""}</TableCell>

                <TableCell  >{row.typeId}</TableCell>

                <TableCell >    {
                    (row.amountUpdated ? <Button onClick={() => {
                        props.onRefund(row)
                    }}>{"Refund"}</Button> : null)



                }</TableCell>
                <TableCell >    {

                    (row.status == "requires_capture" ? <Button onClick={() => {
                        props.onCapture(row)
                    }}>{"Capture"}</Button> : null)

                }</TableCell>
                <TableCell  >{row.id}</TableCell>
                <TableCell  >{row.link ? <a href={row.link} target="_blank">{"Link"}</a> : null}</TableCell>



            </TableRow>

            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={props.open} timeout="auto" unmountOnExit>
                        <Box margin={1} style={{ background: "rgba(128,128,128,0.09)", margin: "10px 10px 10px 80px" }}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        {columnsDetails && columnsDetails.map((detail, index) => {
                                            return <TableCell key={index}>{detail.name}</TableCell>
                                        })
                                        }

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {details && details.map((detail) => (
                                        <TableRow key={detail.id}>
                                            <TableCell >{detail.date ? new Date(detail.date * 1000).toISOString().substr(0, 16).replace("T", " ") : null} </TableCell>
                                            <TableCell>{detail.dispute?(detail.status=="refunded"?"litige":"litige gagné"):detail.status }</TableCell>
                                            <TableCell >{detail.amount + " " + detail.currency}</TableCell>
                                            <TableCell>{detail.comment || ""}</TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}




class Account extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            data: [],
            open: {},
            errorMessage: null,
            snackMessage: null,
            modalRefund: {},
            displayModalCapture: false,
            modalCapture: null,
            // modalBill: null,
            displayModalBill: true

        }

        this._refresh = this._refresh.bind(this)
        this._request = this._request.bind(this)
        this._update = this._update.bind(this)

    }


    _request(body, route = "payment/list") {
        return new Promise(function (resolve) {
            _fetch(process.env.REACT_APP_URL + '/crm/' + route, {
                "timeout": 20000,
                "nbRetry": route.indexOf("list") != -1 ? 2 : 1,
                "method": "post",
                "headers": {},
                "body": body
            })
                .then(function (response) {
                    if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                    let data = response.json()
                    if (data.success) return resolve(data)
                    throw data
                }.bind(this))
                .catch(function (e) {
                    this.setState({ errorMessage: 'Erreur inconnu,sur le payment get ' + route + ' ' })
                    resolve(undefined)
                }.bind(this));
        }.bind(this))
    }


    _refresh() {
        this._request({
            data: {

                bookingId: this.props.bookingId,
                sorting: this.props.sort,
                size: this.props.size,
                ...this.props.filter
            },
        }, "payment/list").then((response) => {
            if (response && response.success) {
                if (this.props.updateParent) {
                    this.props.updateParent(response.data.length)
                }

                this.setState({
                    data: response.data || [],
                })
            }
        })
    }


    _update() {

        if (this.props.bookingId != this.bookingId || this.props.version != this.version) {

            this.bookingId = this.props.bookingId
            this.version = this.props.version

            this.setState({
                data: [],
                open: {},
                displayModalCapture: false,
                displayModalCapture: false,
                modalCapture: {},
                modalRefund: {}
            })

            this._refresh()
        }

    }


    componentDidUpdate() {
        this._update()
    }

    componentDidMount() {
        this._update()

    }


    render() {

        if (!this.state.data) return null
        // component={Paper}

        return (
            <>
                {/* {this.state.displayModalBill ? <ModalBill
                    open={this.state.displayModalBill}
                    data={this.state.modalBill}
                    onClose={() => {
                        this.setState({ displayModalBill: false })
                    }}
                    onCancel={() => {
                        this.setState({ displayModalBill: false })
                    }}
                    onError={(errorMessage) => {
                        this.setState({ errorMessage: errorMessage })
                    }}
                /> : null} */}

                {this.state.displayModalRefund ? <ModalRefund
                    open={this.state.displayModalRefund}
                    amount={0} //this.state.modalRefund.amountUpdated}
                    currency={this.state.modalRefund.currency}
                    data={this.state.modalRefund}
                    onClose={() => {
                        this.setState({ displayModalRefund: false })
                    }}
                    onCancel={() => {
                        this.setState({ displayModalRefund: false })
                    }}
                    onError={(errorMessage) => {
                        this.setState({ errorMessage: errorMessage })
                    }}
                    listParentId={this.props.account2 && this.props.account2.data ? this.props.account2.data.filter((line) => {
                        return line.parentId ? false : true
                    }).map((line, index) => {
                        return {
                            id: line._id,
                            name: ((index + 1) + " " + line.category + " " + (line.comment || "")).trim().substr(0, 30)
                        }
                    }) : null
                    }
                    onSave={(data,callback) => {

                        this._request({
                            ...data,
                            bookingId: this.bookingId,

                        }, "payment/refund")
                            .then((response) => {
                                this._refresh()
                                setTimeout(() => {
                                    this._refresh()
                                }, 5000)
                                if (!response || response.error) {
                                    this.setState({ errorMessage: (response ? response.error : "") || "erreur " })
                                }
                                else {
                                    this.setState({ snackMessage: "Votre action a bien ete effectuée" })
                                    this.setState({ displayModalRefund: false })
                                }
                               if (callback) callback()

                            })  .catch((e)=>{
                                console.error("erreur tablepayment refund",e)
                                this.setState({ snackMessage: "Erreur : Attendre 2 minutes, vérifier la comptabilité et le fournisseur de paiement" })
                                if (callback) callback()
                            })
                    }}
                /> : null}

                {this.state.displayModalCapture ? <ModalCapture
                    open={this.state.displayModalCapture}
                    amount={this.state.modalCapture.amountUpdated} //}
                    currency={this.state.modalCapture.currency}
                    data={this.state.modalCapture}
                    onClose={() => {
                        this.setState({ displayModalCapture: false })
                    }}
                    onCancel={() => {
                        this.setState({ displayModalCapture: false })
                    }}
                    onError={(errorMessage) => {
                        this.setState({ errorMessage: errorMessage })
                    }}
                    onSave={(data,callback) => {
                        this._request({
                            ...data,
                            bookingId: this.bookingId,
                        }, "payment/capture")
                            .then((response) => {

                                this._refresh()
                                if (!response || response.error) {
                                    this.setState({ errorMessage: (response ? response.error : "") || "erreur " })
                                }
                                else {
                                    this.setState({ snackMessage: "Votre action a bien ete effectuée" })
                                    this.setState({ displayModalCapture: false })
                                }
                                if (callback) callback()

                            })
                            .catch((e)=>{
                                console.error("erreur tablepayment capture",e)
                                this.setState({ snackMessage: "Erreur : Attendre 2 minutes, vérifier la comptabilité et le fournisseur de paiement" })
                                if (callback) callback()
                            })
                    }}
                /> : null}

                <Grid style={{overflow:"auto"}}>
                    <Grid >
                        <Button onClick={this._refresh} style={{ color: '#33c' }} variant="outlined">{"Refresh"}</Button>
                    </Grid>
                    <div style={{width:"100%"}}>
                        <TableContainer >
                            <Table aria-label="collapsible table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        {columns.map((o, index) => {
                                            return <TableCell key={index}>{o.name}</TableCell>
                                        })}

                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {this.state.data && this.state.data.map((row) => {

                                        return <Row key={row.id}
                                            row={row}
                                            onCapture={(data) => {
                                                // on ouvre la modale de demande de refund/cancel
                                                //this.setState({ displayModalRefund: true })
                                                this.setState({ displayModalCapture: true, modalCapture: data })

                                            }}
                                            onRefund={(data) => {
                                                this.setState({ displayModalRefund: true, modalRefund: data })

                                            }}
                                            onClick={() => {
                                                let open = { ...this.state.open, }

                                                open[row.id] = open[row.id] ? false : true
                                                this.setState({ open: open })
                                            }}
                                            open={this.state.open[row.id]}
                                            details={row.details}
                                        />
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Grid>

                <CombigoSnackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.errorMessage !== null || this.state.snackMessage !== null}
                    onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
                    variant={this.state.errorMessage != null ? 'error' : "success"}
                    autoHideDuration={6000}
                    message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
                />
            </>
        )
    }
}




const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps, {})(Account);