
import * as React from 'react';
import { connect } from 'react-redux';
import { cloneObject } from '../../helpers';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';
import Edit from '@material-ui/icons/Edit';
import AddBox from '@material-ui/icons/AddBox';
import { parseQuery, _fetch } from "./../../helpers"





















class Account extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            refresh: false,
            errorMessage: null,
            snackMessage: null
        }




    }


    componentDidUpdate() {

    }


   

    render() {


        //mode

        return (
            <>
                <React.Fragment>
                    <TableRow className={""}>
                        <TableCell>
                            <IconButton aria-label="expand row" size="small" onClick={this.props.onClickOpen}>
                                {this.props.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>

                        {this.props.columns && this.props.columns.map((column, index) => {
                            if (this.props.mode === "view" && column.custom && column.custom.onlyEdit) return null
                            if (this.props.mode === "edit" && column.custom && column.custom.onlyView) return null

                            let valueTable = column.getReadTable && this.props.mode === "view" ? column.getReadTable(this.props.row) : column.getValueTable(this.props.row)

                            if (column.options && column.options.customBodyRenderLite) {
                                return <TableCell  >{column.options.customBodyRenderLite(valueTable, index, this.props.row)}</TableCell>
                            }
                            else if (column.options && column.options.customBodyRender) {


                                return <TableCell  >{column.options.customBodyRender(valueTable, {}, (value) => {
                                    this.props.onChange(value, this.props.row, column, index)

                                })}</TableCell>
                            }
                            else {
                                return <TableCell  >{valueTable}</TableCell>
                            }

                        })
                        }


                    </TableRow>

                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={this.props.open} timeout="auto" unmountOnExit>
                                <Box margin={1} style={{ borderRadius: "10px", background: "rgba(204,204,204,0.2)", margin: "0px 0px 5px 20px" }}>
                                    <Table size="small" aria-label="purchases">
                                        <TableHead>
                                            <TableRow>
                                                {this.props.subColumns && this.props.subColumns.map((column, index) => {

                                                    if (this.props.mode === "view" && column.custom && column.custom.onlyEdit) return null
                                                    if (this.props.mode === "edit" && column.custom && column.custom.onlyView) return null

                                                    return <TableCell key={index}>{column.label}</TableCell>
                                                })
                                                }

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.props.details && this.props.details.map((line) => {


                                                return <TableRow key={line.id}>

                                                    {this.props.subColumns && this.props.subColumns.map((column, index) => {
                                                        if (this.props.mode === "view" && column.custom && column.custom.onlyEdit) return null
                                                        if (this.props.mode === "edit" && column.custom && column.custom.onlyView) return null


                                                        let valueTable = column.getReadTable && this.props.mode === "view" ? column.getReadTable(line) : column.getValueTable(line)
                                                        if (column.options && column.options.customBodyRenderLite) {
                                                            return <TableCell  >{column.options.customBodyRenderLite(valueTable, index, line)}</TableCell>
                                                        }
                                                        else if (column.options && column.options.customBodyRender) {

                                                            return <TableCell  >{column.options.customBodyRender(valueTable, {}, (value) => {
                                                                // console.log("on change ",line)
                                                                this.props.onChange(value, line, column, index)
                                                            })}</TableCell>
                                                        }
                                                        else {
                                                            return <TableCell  >{valueTable}</TableCell>
                                                        }


                                                    })
                                                    }

                                                </TableRow>
                                            })}
                                        </TableBody>
                                    </Table>

                                    <div className={"footer-table-collapse"} >
                                        {this.props.disabledModification?null:<IconButton onClick={() => {
                                            this.props.onChangeMode("edit")
                                        }}
                                            size="small"
                                            disabled={this.props.mode != "edit" ? false : true}>
                                            <Edit />
                                        </IconButton>}
                                        {this.props.disabledModification?null:<IconButton size="small"
                                            onClick={() => {
                                                if (this.props.mode == "edit") {
                                                    this.props.addLineSub()
                                                }
                                                else {
                                                    this.props.onChangeMode("edit", () => {
                                                        this.props.addLineSub()
                                                    })

                                                }
                                            }}>

                                            <AddBox />
                                        </IconButton>}

                                        {/* {this.props.modelLineAddEmpty ? <IconButton size="small"
                                            text="Empty"
                                            onClick={() => {
                                                if (this.state.mode == "edit") {
                                                    this.addLine("empty")
                                                }
                                                else {
                                                    this.edit()
                                                    this.setState({ mode: "edit" }, function () {
                                                        this.addLine("empty")
                                                    })
                                                }
                                            }}>
                                            <AddBox />
                                            {"empty"}
                                        </IconButton> : null} */}
                                        {this.props.disabledModification?null:this.props.mode == "edit" && <Button size="small" onClick={() => {
                                            this.props.onSave()
                                        }}>
                                            {"Sauvegarde"}
                                        </Button>}
                                        {this.props.disabledModification?null:this.props.mode == "edit" && <Button size="small" onClick={() => {
                                            if (this.props.onChangeMode) this.props.onChangeMode("view")

                                        }}>
                                            {"Annuler"}
                                        </Button>}
                                    </div>
                                </Box>


                            </Collapse>
                        </TableCell>
                    </TableRow>
                </React.Fragment>


                <CombigoSnackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.errorMessage !== null || this.state.snackMessage !== null}
                    onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
                    variant={this.state.errorMessage != null ? 'error' : "success"}
                    autoHideDuration={6000}
                    message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
                />
            </>
        )
    }
}




const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps, {})(Account);