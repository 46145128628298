import React, { Component, version } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from "material-table";
import WrapperMaterialTable from "./../Table/WrapperMaterialTable"

import Button from '@material-ui/core/Button';
import Delete from '@material-ui/icons/Delete';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import Drawer from '@material-ui/core/Drawer';
import Edit from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import "./../TableAllTransaction/Table"
import CheckBoxCombigo from "./../Utils/Select_CheckBox"
import Grid from '@material-ui/core/Grid';
import TooltipCombigo from "./../Utils/TooltipCombigo"

function getColumnLocal() {
  try {
    return JSON.parse(localStorage.getItem("selectColumnTableVoucherMain"))
  } catch (e) {

  }
}


class Table extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectColumn: getColumnLocal() || ["voucherCode", "status", "typeVoucher", "description", "amount", "cartMin", "dBegin", "dExpired"]
    }
    this.columnAvailable = ["voucherId", "voucherCode", "status", "amount", "typeVoucher", 
          "description", "bookingReference", "dBegin", "dExpired", "amount", "cartMin"]
  }

  render() {

    let size = Math.min(this.props.data.length, 100)

    const columns = [
      {
        title: "", field: "", render: function (rowData) {
          return <IconButton onClick={() => {
            this.props.onClick(rowData)
          }}>
            <FolderOpenIcon />
          </IconButton>
        }.bind(this)
      },
      { title: "voucherCode", field: "voucherCode" },
      { title: "status", field: "status" },
      { title: "typeVoucher", field: "typeVoucher" },

      { title: "Montant Avoir", field: "amount" },
      { title: "Montant Min panier", field: "cartMin" },

      {
        title: "description", field: "description", render: (rowData) => {
          return (rowData.description || "").substr(0, 25) + "..."

        }
      },



    ]



    return (
      <div style={{ maxWidth: "100%" }}>
        <WrapperMaterialTable
          columns={columns.filter(function (obj) {
            if (!obj.title) return true
            return this.state.selectColumn.includes(obj.title) || this.state.selectColumn.includes(obj.field)
          }.bind(this))}
          components={{
            Toolbar: function (props) {
              return <div style={{ backgroundColor: '#e8eaf5' }}>
                <div className={"select-column"}>
                  <Grid container item direction={"row"} xs={2} spacing={3} className={""}>
                    <CheckBoxCombigo
                      className={""}
                      style={{ width: "100%" }}
                      label={"Affichage Colonne"}
                      getLabel={(option) => { return option }}
                      // getMatching={(option) => { return option.code }}
                      list={this.columnAvailable}
                      multiple={true}
                      chips={false}
                      value={this.state.selectColumn}
                      onChange={(value) => {
                        this.setState({ selectColumn: value })
                        localStorage.setItem("selectColumnTableVoucherMain", JSON.stringify(value))
                      }}
                    ></CheckBoxCombigo>
                  </Grid>
                </div>
                <MTableToolbar {...props} />
              </div>
            }.bind(this)


          }}
          data={this.props.data}
          title={this.props.title || ""}
          options={{
             pageSize: size,
            pageSizeOptions: [50, 100, 200, 400],
            grouping: true,
            exportButton: true,
            padding: "dense"
            // filtering: true
          }}
        />
      </div>
    );
  }
}



const mapStateToProps = (state) => {
  return { base: state.data };
};

export default connect(mapStateToProps, {})(Table);