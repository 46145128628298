
import * as React from 'react';
import { connect } from 'react-redux';
import { cloneObject } from '../../helpers';

import './TableMail.scss';
// import { DataGrid } from '@material-ui/data-grid';
import Toolbar from "./Toolbar"
import TableMaterial from "./../Table/TableMaterial"
import moment from 'moment';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import IconButton from '@material-ui/core/IconButton';


class Account extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            data: [],
            version: "",
            dateUpdate: 0
        }

    }

    componentDidUpdate(prevProps) {
        let filterString = JSON.stringify(this.props.filter || {}) +"-"+this.props.version
        if (this.state.filterStr != filterString || ( this.props.version && prevProps.version!=this.props.version)) {
            this.setState({ filterStr: filterString,data:[],version:"" })
        }

    }

    componentDidMount() {

    }



    render() {

        const columns = [
            { collapse: true },
            // {field:"_id",name:"_id"},
            {
                field: "dateCreated", name: "Date", render: (obj) => {
                    return moment(obj.dateCreated).format("DD/MM/YYYY HH:mm:ss")
                }
            },
            {
                field: "user", name: "User", render: (obj) => {
                    return obj.user || (obj.infos ? obj.infos.user : "")
                }
            },
            {
                field: "from", name: "from", render: (obj) => {
                    return obj.data ? obj.data.from : obj.mail.from.Email
                }
            },
            {
                field: "to", name: "to", render: (obj) => {
                    return obj.data ? obj.data.to : obj.mail.to.map((line) => {
                        return line.Email
                    }).join(",")
                }
            },
            {
                field: "subject", name: "subject", render: (obj) => {
                    return obj.data ? obj.data.subject : obj.mail.subject
                }
            },
            {
                field: "status", name: "status", render: (obj) => {
                    return obj.events ? obj.events[obj.events.length - 1].status : obj.status
                }
            },
            {
                field: "category", name: "category", render: (obj) => {
                    return obj.infos ? obj.infos.category : obj.category
                }
            },
        

            {
                field: "link", name: "link", render: (obj) => {

                    let link = ""
                    let provider
                    if (obj.linkId && obj.linkId.indexOf("freshdesk") != -1) {
                        let ticketId = obj.linkId.replace("freshdesk:", "")
                        provider = "freshdesk"
                        link = "https://newaccount1603196282781.freshdesk.com/a/tickets/" + ticketId
                    }
                    if (!link) return null


                    return <a style={{ color: "black" }} href={link} target="_blank">{provider}</a>
                }
            }
        ]
        // if (this.props.openLink) {
        //     columns.push({
        //         field: "", noSort: true, name: "Link", render:  (rowData)=> {
        //             let url  = ""
        //             if (rowData.relationId && rowData.relationId.indexOf("bookingId")!=-1) url = "/oneTransaction/" + rowData.relationId.split(":")[1]
        //             return <a href={url} target="_blank"><IconButton onClick={() => {
        //             }}>
        //                 <FolderOpenIcon />
        //             </IconButton>
        //             </a>
        //         }
        //     })
        // }

        return <div className={"container-table-mail"}>
            {this.props.activeToolbarFilter ? <Toolbar
                url={'/crm/mail/search'}
                timeout={0} //12000
                filter={this.props.filter}
                version={this.state.filterStr}
                onUpdate={function (data, error) {
                    if (data) {
                        if (this.state.version != data.version) {
                            this.setState({ data: data.data, version: data.version, dateUpdate: Date.now() })
                        }
                        else {
                            this.setState({ dateUpdate: Date.now() })
                        }
                    }

                }.bind(this)}

            /> : null}
            <TableMaterial
                className={"table-mail"}

                columns={columns}
                functionCollapse={(row) => {
                    return row.events
                }}
                headerCollapse={(row) => {
                    return <div className='headerCollapse'> {row.data ? row.data.bodyHtml : (row.mail ? row.mail.content : "")}</div>
                }}
                subColumn={[
                    {
                        field: "date", name: "Date", render: (obj) => {
                            return moment(obj.date).format("DD/MM/YYYY HH:mm:ss")
                        }
                    },
                    { field: "status", name: "status" },
                    { field: "user_agent", name: "user_agent" },

                    { field: "device", name: "device" },
                    {
                        field: "fournisseur", name: "fournisseur", render: (obj) => {
                            return obj.position ? obj.position.fai : ""
                        }
                    },
                    {
                        field: "country", name: "country", render: (obj) => {
                            return obj.position ? obj.position.country : ""
                        }
                    },
                    {
                        field: "city", name: "city", render: (obj) => {
                            return obj.position ? obj.position.city : ""
                        }
                    },
                    {
                        field: "IP", name: "IP", render: (obj) => {
                            return obj.position ? obj.position.ip : ""
                        }
                    },

                ]}
                data={this.state.data}
            />

        </div>



    }
}




const mapStateToProps = (state) => {
    return { base: state.data };
};

export default connect(mapStateToProps, {})(Account);