import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./CalendarView.scss"
// timeline : https://github.com/prabhuignoto/react-chrono
import Grid from '@material-ui/core/Grid';
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
// import Button from 'antd/lib/button'
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import IconButton from '@material-ui/core/IconButton';




import moment from 'moment'
import Scheduler, { SchedulerData, ViewTypes, DATE_FORMAT, CellUnits } from 'react-big-scheduler'
import withDragDropContext from './withDnDContext'
import 'react-big-scheduler/lib/css/style.css'

const colors = ["#f7c079", "#ff99b9", "#a7a5f7", "#b0ffad", "#74e1ed", "#9b74f7", "#c6d6ff", "#f4a99a", "#f7a87e", "#fcebc7", "#f7eb7e", "#d36eef", "#a2e4f9", "#ed7bc7", "#b9acf9", "#edc66a", "#dba3ff", "#d994fc", "#ffaaf6", "#e4fc9c", "#a6b7f4", "#71f2e5", "#7ef7bc", "#f4abcc", "#bb95f4", "#75b9ea", "#77a3f4"]
const colorFunction={
  "PROCESSING":"#f1f1f1",
  "PROCESSING_AUTO":"#f1f1f1",
  "PROCESSING_MANAGE":"#f1f1f1",
  "PROCESSING_ERROR":"#f1f1f1",

  "CONFIRMED":"#77a3f4",
  "DONE":"#77a3f4",
  "WAIT_CONFIRMED":"#ed7bc7",
  "MODIFICATION":"#f7c079",
  "CANCEL_OTHER":"#f7eb7e",
  "CANCEL_COMBIGO":"#f7eb7e",
  "CANCEL_VOLUNTARY":"#f7eb7e",
  "DETAIL":"#c6d6ff"
}




class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: null,
      // configDate: {
      //   duration: 24,// hours,
      //   date: this._getTime(props.dateSelection)
      // }
    }

    // if (this.props.onDate) this.props.onDate(this.state.configDate)
    this.dataFormat = { events: [], resources: [] }

  }

  _getTime = (date) => {
    return moment(date.format("YYYY-MM-DD") + "T00:00:00+00:00")
    //return moment(Math.floor(date.valueOf() / (10 * 60 * 1000)) * (10 * 60 * 1000))
  }

  componentDidMount() {

    this.schedulerData = new SchedulerData(this.props.dateSelection.format(DATE_FORMAT), ViewTypes.Week, false, false, {
      startResizable: false,
      endResizable: false,
      movable: false,
      creatable: false,
      defaultExpanded: false,
      customCellWidth: 30,
      customResourceTableWidth: 180,
      nonAgendaDayCellHeaderFormat: 'D/M|H:mm',
      nonWorkingTimeBodyBgColor: "#f4f4ff",
      nonWorkingTimeHeadBgColor: "#f4f4ff",
      nonWorkingTimeHeadColor: "#3333cc",
       minuteStep: 30,
      resourceName: "",
      views: [
        { viewName: 'Day', viewType: ViewTypes.Custom, showAgenda: false, isEventPerspective: false },
        // { viewName: '2 Days', viewType: ViewTypes.Custom1, showAgenda: false, isEventPerspective: false, duration: 48 },
        // { viewName: 'Week', viewType: ViewTypes.Custom2, showAgenda: false, isEventPerspective: false },
        // { viewName: 'Month', viewType: ViewTypes.Custom3, showAgenda: false, isEventPerspective: false },
      ],
    }, {
       getCustomDateFunc: this.getCustomDate,
      isNonWorkingTimeFunc: this.isNonWorkingTime
    });
    //set locale moment to the schedulerData, if your locale isn't English. By default, Scheduler comes with English(en, United States).
    // moment.locale('en-GB');
    this.schedulerData.setLocaleMoment(moment);

    this.schedulerData.setViewType(ViewTypes.Custom);
    this.onSelectDate(this.schedulerData,this.props.dateSelection)


    this.setState({ viewModel: this.schedulerData })
  }




  _duration = (viewTypes) => {
    return 48
    if (viewTypes == ViewTypes.Custom) return 24
    if (viewTypes == ViewTypes.Custom2) return 48
  }


  componentDidUpdate(prevProps) {
    //console.log(this.props.version,prevProps.version)
    if (this.props.version != prevProps.version) {
      if (this.props.data) this._cleanData(this.props.data)
      // this.schedulerData.setEvents(this.dataFormat.events);
      // this.schedulerData.setResources(this.dataFormat.resources);
      this.setState({ viewModel: this.schedulerData })
    }
    if (this.props.dateSelection != prevProps.dateSelection){
      this.onSelectDate(this.schedulerData,this.props.dateSelection)
    }

  }


  _getColor = () => {
    let selectColor = colors[this.idxColor++]
    if (this.idxColor >= colors.length) this.idxColor = 0
    return selectColor
  }

  _cleanData = (data) => {

    let newData = { events: [], resources: [] }


    this.idxColor = 0
    let nb = 0
    if (data.length == 0) {
      newData.resources.push({
        id: 'r',
        name: ""  //+ " " + line.informations.lastName //+ " (" + line.informations.title[0] + ")"
      })

    }

    for (let line of data) {

      // console.log(line)
      nb++
      newData.resources.push({
        id: 'r-' + nb,
        name: line.informations.referenceBooking  //+ " " + line.informations.lastName //+ " (" + line.informations.title[0] + ")"
      })


      newData.events.push({
        id: 'e-' + nb,
        start: moment(line.ticket.legs[0].dTimeTZ * 1000).format("YYYY-MM-DD HH:MM:SS"),
        end: moment(line.ticket.legs[line.ticket.legs.length - 1].aTimeTZ * 1000).format("YYYY-MM-DD HH:MM:SS"),
        resourceId: 'r-' + nb,
        title: line.informations.title[0] + " (" + (line.ticket.legs.length - 1) + " esc.)",
        showPopover: true,
        bgColor: colorFunction[line.summary.data.statusBooking], //this._getColor(),
        bookingId: line._id,
        booking:line
      })



      if (line.segments && line.segments.data) {
        let subId = 0
        const statusSegmentsOK = { "MODIFIED": 1, ACTIVE: 1, DELAYED: 1 }

        for (let segment of line.segments.data) {
          if (statusSegmentsOK[segment.status]) {
            // console.log("segment",segment)

            let resourceId = 'r-' + nb + "-" + (++subId)

            newData.events.push({
              id: 'e-' + nb + "-" + (++subId),
              start: moment(segment.dTimeTZ * 1000).format("YYYY-MM-DD HH:MM:SS"),
              end: moment(segment.aTimeTZ * 1000).format("YYYY-MM-DD HH:MM:SS"),
              resourceId: resourceId,
              title: segment.transporter.name + " " + segment.type,
              showPopover: true,
              bgColor: colorFunction["DETAIL"], //this._getColor(),
              bookingId: line._id,
            })

            newData.resources.push({
              id: resourceId,
              parentId: 'r-' + nb,
              name: segment.from.city + " -> " + segment.to.city //segment.transporter.name 
            })

          }
        }
      }


      // break;
    }





    this.dataFormat = newData
    this.schedulerData.setResources(newData.resources);
    this.schedulerData.setEvents(newData.events);





  }





  isNonWorkingTime = (schedulerData, time) => {
    const { localeMoment } = schedulerData;
    if (schedulerData.cellUnit === CellUnits.Hour) {
      let hour = localeMoment(time).hour();
      if (hour < 9 || hour > 18)
        return true;
    }
    else {
      let dayOfWeek = localeMoment(time).weekday();
      if (dayOfWeek === 0 || dayOfWeek === 6)
        return true;
    }

    return false;
  }



  prevClick = (schedulerData) => {
    schedulerData.prev();
    schedulerData.setEvents(this.dataFormat.events);
    this.setState({
      viewModel: schedulerData
    })
  }

  nextClick = (schedulerData) => {
    schedulerData.next();
    schedulerData.setEvents(this.dataFormat.events);
    this.setState({
      viewModel: schedulerData
    })
  }

  onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
  //  schedulerData.config.customCellWidth = 60 //view.viewType === ViewTypes.Custom ? 30 : 80; dans le cas 

    // if (this.props.onDate) this.props.onDate({ date: this.state.configDate.date, duration: this._duration(view.viewType) })

    schedulerData.setEvents(this.dataFormat.events);
    this.setState({
      // configDate: {
      //   date: this.state.configDate.date,
      //   duration: 2
      // },
      viewModel: schedulerData
    })
  }


  onSelectDate = (schedulerData, date) => {

    let newDate = this._getTime(date)
    // if (this.props.onDate) this.props.onDate({ date: newDate, duration: this.state.configDate.duration })
    schedulerData.setDate(newDate);
    schedulerData.setEvents(this.dataFormat.events);

    this.setState({
      // configDate: {
      //   date: newDate,
      //   duration: this.state.configDate.duration
      // },
      viewModel: schedulerData
    })
  }

  // eventClicked = (schedulerData, event) => {
  //   console.log("event", event)
  //   alert(`You just clicked an event: {id: ${event.id}, title: ${event.title}}`);
  // };




  onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.next();
      schedulerData.setEvents(this.dataFormat.events);
      this.setState({
        viewModel: schedulerData
      });
      schedulerContent.scrollLeft = maxScrollLeft - 10;
    }
  }

  onScrollLeft = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.prev();
      schedulerData.setEvents(this.dataFormat.events);
      this.setState({
        viewModel: schedulerData
      });

      schedulerContent.scrollLeft = 10;
    }
  }

  // onScrollTop = (schedulerData, schedulerContent, maxScrollTop) => {
  //   console.log('onScrollTop');
  // }

  // onScrollBottom = (schedulerData, schedulerContent, maxScrollTop) => {
  //   console.log('onScrollBottom');
  // }

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({
      viewModel: schedulerData
    });
  }





  nonAgendaCellHeaderTemplateResolver = (schedulerData, item, formattedDateItems, style) => {
    let datetime = schedulerData.localeMoment(item.time);
    let isCurrentDate = false;

    if (schedulerData.viewType === ViewTypes.Custom) {
      isCurrentDate = datetime.isSame(new Date(), 'hour');
    }
    else {
      isCurrentDate = datetime.isSame(new Date(), 'day');
    }

    if (isCurrentDate) {
      style.backgroundColor = '#118dea';
      style.color = 'white';
    }

    return (
      <th key={item.time} className={`header3-text`} style={style}>
        {
          formattedDateItems.map((formattedItem, index) => (
            <div key={index}
              dangerouslySetInnerHTML={{ __html: formattedItem.replace(/[0-9]/g, '<b>$&</b>') }} />
          ))
        }
      </th>
    );
  }


  
    getCustomDate = (schedulerData, num, date = undefined) => {
      const { viewType } = schedulerData;
      let selectDate = schedulerData.startDate;
      if (date != undefined) selectDate = date;
  
      let startDate, endDate, cellUnit
      console.log("viewType",viewType)
      // console.log("DATE_FORMAT", DATE_FORMAT)
      if (viewType === ViewTypes.Custom) {
        startDate = num === 0 ? selectDate : schedulerData.localeMoment(selectDate).add( num, 'days').format(DATE_FORMAT)
        endDate = schedulerData.localeMoment(startDate).add(1, 'days').format(DATE_FORMAT)
        cellUnit = CellUnits.Hour;
      }
      
  
      return {
        startDate,
        endDate,
        cellUnit
      };
    }
  
  

  eventItemPopoverTemplateResolver = (schedulerData, eventItem, title, start, end, statusColor) => {
    console.log("eventItem", eventItem)

    let tz
    try {
      tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    } catch (e) { }

    let statusBooking =""
    try{
      statusBooking = eventItem.booking.summary.data.statusBooking
    }catch(e){}
    
    return (

      // <React.Fragment>
      //     <h3>{title}</h3>
      //     <h5>{start.format("HH:mm")} - {end.format("HH:mm")}</h5>
      //     <img src="./icons8-ticket-96.png" />
      // </React.Fragment>
      <Grid style={{ width: '400px' }} container item direction={"column"}>
        {/* <Grid direction={"column"}>
          <Grid direction={"row"} sm={2} xs={2}>
            <div className="status-dot" style={{ backgroundColor: statusColor }} />
          </Grid>
          <Grid direction={"row"} sm={2} xs={2}>
          <span className="header2-text" title={title}>{title}</span>
          </Grid>
        </Grid> */}

        <Row type="flex" align="middle">
          <Col span={2}>
            <div className="status-dot" style={{ backgroundColor: statusColor }} />
          </Col>
          <Col span={20} className="overflow-text">
            <span className="header2-text" title={title}>{title}</span>
          </Col>
        </Row>
        {statusBooking?<Row type="flex" align="middle">
          <Col span={5}>
            {statusBooking}
          </Col>
        </Row>:null}
        <Row type="flex" align="middle">
          <Col span={2}>
            <div />
          </Col>
          <Col span={22}>
            <span className="header2-text">{start.format("D MMM HH:mm")} - {end.format("D MMM HH:mm") + " (" + tz + ")"}</span>
          </Col>
        </Row>

        <Row type="flex" align="middle">
          <Col span={2}>
            <div />
          </Col>
          <Col span={22}>
            <a href={"/oneTransaction/" + eventItem.bookingId} target="_blank"><IconButton onClick={() => {
              // this.props.history.push("/oneTransaction?bookingId="+rowData.bookingId)
            }}>
              <FolderOpenIcon />
            </IconButton>
            </a>
          </Col>
        </Row>
      </Grid>
    );
  }


  render() {

    if (!this.state.viewModel) return null //|| !this.props.data

    return <div className={"view-calendar-travel"}>

      <Scheduler
        leftCustomHeader={this.props.toolbar}
        schedulerData={this.state.viewModel}
        prevClick={this.prevClick}
        nextClick={this.nextClick}
        onSelectDate={this.onSelectDate}
        onViewChange={this.onViewChange}
        eventItemClick={this.eventClicked}
        eventItemPopoverTemplateResolver={this.eventItemPopoverTemplateResolver}
        onScrollLeft={this.onScrollLeft}
        onScrollRight={this.onScrollRight}
        onScrollTop={this.onScrollTop}
        onScrollBottom={this.onScrollBottom}
        toggleExpandFunc={this.toggleExpandFunc}
        nonAgendaCellHeaderTemplateResolver={this.nonAgendaCellHeaderTemplateResolver}
      />


    </div>
  }
}


export default withRouter(withDragDropContext(Body));


