import React, { Component, version } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { parseQuery, _fetch } from "./../helpers"
import "./Home.scss"
import MaterialTable, { MTableToolbar } from "material-table";
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import IconButton from '@material-ui/core/IconButton';
import Normal from './ConfigGeneral/Normal.js';


class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      _id: null
    }
  }

  componentDidMount() {

    let query = (parseQuery(window.location.search) || {})
    if (query && query._id) {
      this.setState({ _id: query._id })
    }
    else {
      this._request({ action: "list" }, (success, data) => {
        if (success) this.setState({ data: data.data })
      })
    }
  }

  componentDidUpdate(prevProps) {

  }

  _request(body, cb) {
    _fetch(process.env.REACT_APP_URL + "/crm/technical_config/consul_data", {
      "timeout": 2000,
      "nbRetry": 2,
      "method": "post",
      "headers": {},
      "body": body
    })
      .then(function (response) {
        if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
        let data = response.json()
        cb(true, data, response)

      }.bind(this))
      .catch(function (e) {
        console.log("Error request", e);
        cb(false, undefined, e)

        alert("reload the page")

      }.bind(this));
  }




  render() {

    if (this.state._id) {

      return <Normal
        _id={this.state._id}
        _request={this._request}
      />

    }

    if (!this.state.data) return null

    const columns = [
      {
        title: "", field: "", render: function (rowData) {
          return <a href={"/private/configuration_technical?_id=" + rowData._id} target="_blank"><IconButton onClick={() => {
          }}>
            <FolderOpenIcon />
          </IconButton>
          </a>
        }.bind(this)
      },
      { title: "Title", field: "name" },
      {
        title: "description", field: "description"
      },
      { title: "lastModified", field: "lastModified", customSort: (a, b) => new Date(a.lastModified + "T00:00:00+00:00").getTime() - new Date(b.lastModified + "T00:00:00+00:00").getTime() },
    ]

    return <div className={"view-transaction"}>
      {/* <div className={"title-page"}> {"Liste des Configurations " + new Date().toISOString()}</div> */}

      <div style={{ maxWidth: "100%" }}>
        <MaterialTable
          columns={columns}
          data={this.state.data}
          title={"Liste des Configurations " + new Date().toISOString()}
          options={{
            // pageSize: Math.max(this.state.data.length, 10),
            pageSizeOptions: [50, 100],
            grouping: false,
            exportButton: true,
            padding: "dense"
          }}
        />
      </div>


    </div>
  }
}


export default withRouter(Body);

