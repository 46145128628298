

class AbortFetch
{
    constructor()
    {
        if (!isSafari())
        {
            // nous avons un navigateur safari
            this.signal = undefined
        }   
        else   
        {
            try{
                this.controler = new AbortController()
                this.signal = this.controler.signal
            }
            catch(e)
            {
                console.log("Constructor de Abort",e)
            }
        }

        function isSafari()
        {
            const user_agent = navigator.userAgent.toLowerCase(); 
            return user_agent.indexOf('safari') !== -1?true:false
        }
    }

    abort()
    {
        try{
            if (this.controler) this.controler.abort()
        }catch(e)
        {
            console.log("Abort the signal",e)
        }
    } 
}


export function fileDownload(data,filename="file"){
    const url = window.URL.createObjectURL(
        new Blob([data]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        filename,
      );
      link.setAttribute(
        'target',
        "_blank",
      );


      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
     if ( link.parentNode) link.parentNode.removeChild(link);
}


export function emailValidator(value)
{
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(value).toLowerCase());
}




export function _fetch(url, opt={}) {

    const timeout = opt.timeout
    opt.timeout = undefined
 
    if (!opt.statusRetry) opt.statusRetry = {}
    if (!opt.statusNoRetry) opt.statusRetry = {}
    
    if (opt.body && !opt.method) opt.method = "post"

    if (opt.body && typeof opt.body =="object")
    {
        if (! exist(opt.headers,"Content-Type")) opt.headers = addHeaders(opt.headers,"Content-Type",'application/json')
        opt.body = JSON.stringify(opt.body)
    }
    if ( !exist(opt.headers,"Accept")) opt.headers = addHeaders(opt.headers,"Accept",'application/json, text/plain, */*')

    if ( !exist(opt.headers,"x-version-front") && process.env.REACT_APP_GITHASH) opt.headers = addHeaders(opt.headers,"x-version-front",process.env.REACT_APP_GITHASH)


    if ( !exist(opt.headers,"x-api-key")){
        try{
            let valueDebug = localStorage.getItem("x-api-key") || null
            let valueUser = localStorage.getItem("user3") || null
            if (valueDebug && valueUser) opt.headers = addHeaders(opt.headers,"x-api-key",valueUser+":"+valueDebug)
        }catch(e){}
    }

    if ( !exist(opt.headers,"x-source")) opt.headers = addHeaders(opt.headers,"x-source",'site1')

    
    //macflyWithCombigo

  
    return new Promise(function(resolve,reject){
        request(url,opt,timeout,{"nbRetry":0,resolve,reject})
    })


    function exist(headers,key)
    {
        if (!headers) return false
        if (headers.append && headers.get)    return headers.get[key]!=undefined?true:false
        return headers[key]!=undefined?true:false
    }

    function addHeaders(headers,key,value)
    {
        if (!headers)  headers =  new Headers({});
        if ( headers.append) headers.append(key,value);
        else if (typeof opt.headers === "object")        headers[key] = value
        return headers
    }

    function _fetch_timeout(url,opt={}, timeout= 1000)
    {
        return new Promise(async function(resolve, reject) {
            
                let flagOK=false;

                if (timeout!=undefined)
                {
                    let controller;
                    if (opt.signal===undefined) {
                        controller = new AbortFetch(); // new AbortController
                        opt.signal = controller.signal;
                    }
    
                    setTimeout(function(){ 
                        if(flagOK) return;
                        reject(new Error('Request timed out'));
                        if(controller) controller.abort();
                    }, timeout);
                }
               

                fetch(url,opt)
                .then(function(response){
                    if (response.status===823){
                        window.alert("Need to refresh CRM, clean cache")
                    }
                    if (opt.statusRetry && opt.statusRetry[response.status] )  throw response
                    if (opt.statusNoRetry && !opt.statusNoRetry[response.status]) throw response
                    flagOK = true
                    resolve(response);
                })
                .catch(function(err){
                    flagOK = true
                    reject(err); 
                })
        })
    }

    function request(url,opt,timeout,objRetry)
    {

        return _fetch_timeout(url,opt,timeout)
        .then(function(response) {
            response.text()
            .then(function(data){
                objRetry.resolve({
                    "status":response.status,
                    "statusText":response.statusText,
                    "redirected":response.redirected,
                    "type":response.type,
                    "url":response.url,
                    "ok":response.ok,
                    "headers":response.headers,
                    "body":data,
                    "json":function(){
                        return  JSON.parse(this.body)
                    }
                })
            }.bind(this))
            .catch(function(e){
                throw e
            })
        }.bind(this))
        .catch(function(e){
            if (opt.nbRetry==undefined)  objRetry.reject(e)
            else if (++objRetry.nbRetry>= opt.nbRetry) objRetry.reject(e)
            else request(url,opt,timeout,objRetry)
        })
    }
}


export function _isEmpty(obj) {
    if (typeof obj !== "object" ) return true;
    for(let key in obj) {return false;}
    return true;
}


export function stringifyQuery(query)
{
    let str =""
    for (let key in query)
    {
        if (query[key]==undefined) continue
        if (str!="") str+="&"
        str+= key + "=" + query[key]
    }
    return str
}
// to parse an url www.combigo.com/results?a=1&b=2
export function parseQuery(url)
{
    try {
        let url_parse = parseURL(url)
        let query = _parseQuery(url_parse.query);
        return query
    }
    catch(e)
    {
        console.log("parseQuery",e)
        return url
    }
  
    function parseURL(url) { 
        const l = url.indexOf("?");
        let path = (l ===-1)?url:url.substr(0,l);
        if (path[path.length-1]==="/") path = path.substr(0,path.length-1);  // dans le cas ou nous avons un "/" à la fin
        return {"pathname": path, "query": (l===-1)? undefined: url.substr(l+1,url.length)}
    }

    function _parseQuery(url) {
        if (!url) return {};
        let l, str, query = {};
        for (str of url.split("&")) {
            l = str.indexOf("=");
            if (l !==-1) query[str.substr(0,l)] = str.substr(l+1,str.length);
        }
        return query;
    }
}

export function isMobile(){
    return (window.innerWidth < 641);
}

export function isSmallScreen() {
    return (window.innerWidth < 1060);
}

export function cloneObject(o) {
    if (typeof o =="object") {
        let value, key;
        let output = Array.isArray(o) ? [] : {};
        for (key in o) {
            if (o[key]===undefined) continue;
            value = o[key];
            if (typeof value == "object" && value!=null)
            {
                if (!value._isAMomentObject)   output[key] = cloneObject(value);
                else output[key] = value.clone() // pour les moments
            }
            else output[key] = value;
        }
        return output;
    }
    else return o;
}

export function getClientId(){
    try {
        return document.cookie.match(/_ga=(.+?);/)[1].split('.').slice(-2).join(".")
    } catch (e) {
        return 0
    }
}

export function getSessionId(){
    const sessionId = localStorage.getItem("x-session-id");
    return sessionId;
}





export function decodeFiltersToURL(url,filters)
{
   let query = parseQuery(url)
//    for (let key in query)
//    {
//         if( key=="priceMax") 
//    }



}




export function encodeFiltersToURL(url)
{
    
}


/*

searchPositionReal()
    {
        return new Promise(function(resolve,reject){

            let options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
              };
            navigator.geolocation.getCurrentPosition(function (pos)
            {
                let crd = pos.coords;
                this.localisationReal ={"lat":crd.latitude,"lng":crd.longitude,"precisionMeters":crd.accuracy}
                console.log('Votre position actuelle est :');
                console.log(`Latitude : ${crd.latitude}`);
                console.log(`Longitude : ${crd.longitude}`);
                console.log(`La précision est de ${crd.accuracy} mètres.`);
                resolve(this.localisationReal)
            }.bind(this), function(err) {
                console.warn(`ERREUR (${err.code}): ${err.message}`);
                resolve(false)
            }.bind(this),
            options);
        }.bind(this))
       
    }



*/