
// USER
export const SET_LOCALE = "SET_LOCALE";
export const SET_USER = "SET_USER";

export const SET_PAGE = "SET_PAGE";



export const SET_BOOKING = "SET_BOOKING";
export const RESET_BOOKING = "RESET_BOOKING";

export const SET_CARRIER = "SET_CARRIER";
export const SET_USERS = "SET_USERS";
