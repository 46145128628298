import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Select_CheckBox from "./../Utils/Select_CheckBox"
import TableSegments from "./../TableSegments/TableSegments"
import MinimizeIcon from '@mui/icons-material/Minimize';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@material-ui/core/IconButton';

import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import moment from "moment";
import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';

import TooltipCombigo from "./../Utils/TooltipCombigo"

import TextField from '@material-ui/core/TextField';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker, DatePicker, DateTimePicker } from "@material-ui/pickers";
import DateTimeContainer from "./../Utils/DateTimeContainer"
import CheckBoxCombigo from "./../Utils/Select_CheckBox"
import "./ModalDisruptedConnection.scss"






const steps = ['Search', 'Trip', 'Booking', 'Results'];





class ModalVoucher extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            activeStep: 0,
            step: 0,
            model:{}
        }
    
       // this._reset()
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.version != prevProps.version){
            this._reset()
        }
    }

    _createSegments = ()=>{
        return this.props.segments.map((o)=>{
            return {
                ...o,
                statusUpdated:""
            }
        })
    }

    _reset=()=>{
        return null
        this.setState({
            model: {
                bookingId:this.props.bookingId,
                name: "",
                search: {
                    checked_bag: this.props.checked_bag != undefined ? this.props.checked_bag : false,
                    typeTrip: this.props.typeTrip != undefined ? this.props.typeTrip : "oneway",
                    from: { name: "Paris", lat: 0, lng: 0 },
                    to: { name: "Madrid", lat: 0, lng: 0 },
                    passengers: this.props.passengers
                },
                segments: this._createSegments(),
                booking: [
                    {
                        referenceBooking: "",

                        priceModification: 0, // si on le modifie avec la meme compagnie combien on paye
                        priceCancel: 0, // si on annule le vol ou si deja annulé on sait qu'on recupere cette somme,
                        datePrevenuModification: ""
                    }
                ]
            }
        })
    }


   


    render() {









         return null
        return (
            <div>
                <Dialog
                    open={this.props.open || true}
                    onClose={this.props.onClose}
                    maxWidth={"md"}
                >

                    <div style={{ display: "flex", "flewDirection": "row", borderBottom: "1px solid grey" }}>
                        <DialogTitle>{"Disrupted Connection"}</DialogTitle>

                        <div style={{ flexGrow: 1 }}></div>

                        <IconButton onClick={() => {
                            if (this.props.onClose) this.props.onClose()

                        }}>
                            <MinimizeIcon />
                        </IconButton>
                        <IconButton onClick={() => {
                            this._reset()
                            if (this.props.onClose) this.props.onClose()

                        }}>
                            <CloseIcon />
                        </IconButton>
                    </div>

                    <DialogContent>

                        <div style={{ marginBottom: "20px" }}>
                            <Stepper nonLinear activeStep={this.state.activeStep}>
                                {steps.map((label, index) => (
                                    <Step key={label} completed={false}>
                                        <StepButton color="inherit" onClick={() => {
                                            this.setState({ activeStep: index })
                                        }}>
                                            {label}
                                        </StepButton>
                                    </Step>
                                ))}
                            </Stepper>
                        </div>
                        {/* 
                        <DialogContentText className={"modal-voucher"}
                            id="alert-dialog-description" style={{ display: "flex", flexDirection: "column" }}>
                        </DialogContentText> */}
                        {this.state.activeStep === 0 ? <>

                            <div>
                                {"Nom de la simulation : Test"}
                            </div>

                            <div >
                                <div>{"Paris"}</div>
                                <div>{"Madrid"}</div>
                                <div>{"Aller Retour"}</div>
                            </div>
                            <div>
                                {"A t'il un checked_bag ? "}
                            </div>
                            {/* <div>
                                {"list passenger avec possibilité de supprimer"}
                            </div> */}
                        </> : null}
                        {this.state.activeStep === 1 ? <>
                            <div>
                                {"list segment id From-> To dtime atime status=delayed,...."}
                            </div>

                        </> : null}
                        {this.state.activeStep === 2 ? <>
                            <div>
                                {"Montant = Prix Initial "}
                            </div>
                        </> : null}






                        {
                            /*

                            ici on affiche tous les legs et on affiche si on souhaite l'inclure dans la recherche
                            Status
                             departure 
                             arrival, 
                             terminal dep
                             terminal arr

                             utilisé ou non

                             fare remboursable

                             state modified/delayed/cancelled

                             modified 
                                departure
                                arrival                                

                            */
                        }




                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            if (this.state.step == 2) {
                                this.setState({ step: 1, message: null })
                                return
                            }
                            this._reset()
                            if (this.props.onClose) this.props.onClose()
                        }} color="primary">
                            {(this.state.step == 1 ? "Fermer" : "Précédent")}
                        </Button>
                        <Button onClick={() => {
                            if (!this.state.typeMail) {
                                return this.setState({ errorMessage: "Le type de mail n'est pas selectionné" })
                            }
                            if (this.state.secondVersion && this.state.firstVersion && this.state.secondVersion._id != this.state.firstVersion._id) {
                                this.onClick()
                            }
                            else {
                                this.setState({ errorMessage: "Incomplet, vous devez selectionner 2 versions différentes" })
                            }
                        }}
                            disabled={this.state.disabledButtonSend}

                            color="primary" >
                            {this.state.step == 1 ? "Visualisation Message" : "Envoyer"}
                        </Button>
                    </DialogActions>
                </Dialog>

                <CombigoSnackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.errorMessage !== null}
                    onClose={() => this.setState({ errorMessage: null })}
                    variant={this.state.errorMessage != null ? 'error' : "success"}
                    autoHideDuration={6000}
                    message={<span id="message-id"> {this.state.errorMessage}</span>}
                />
            </div >
        );
    }

}




const mapStateToProps = (state) => {
    return { locale: state.user.locale, nameUser: state.user.name, base: state.data };
};


export default connect(mapStateToProps, {

})(withRouter(ModalVoucher));