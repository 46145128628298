import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

import Slider from '@material-ui/core/Slider';
import "./Toolbar.scss"

import TextField from "@material-ui/core/TextField";

import { parseQuery, _fetch } from "./../../helpers"
import CheckBoxCombigo from "./../Utils/Select_CheckBox"
import IntervalleDate from "./../Utils/IntervalleDate"
import SelectCombigo from "./../Utils/Select"

// sessionStorage.setItem("toolbar-filter", "")
function getHistory() {
    try {
        return JSON.parse(sessionStorage.getItem("toolbar-filter-voucher"))
    } catch (e) { }
}
const initialStateBody = {
    mail: "",
    bookingReference: "",
    status: ["ACTIVE"],
    description: "",
    typeVoucher: []
}



class Body extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            body: getHistory() || initialStateBody
        }
        this.lastRequest = Date.now()
        this.search = this.search.bind(this)
        this.onChangeBody = this.onChangeBody.bind(this)
        this.lastUpdateVersion = 0
        this.search(true, this.state.body)
        if (this.props.timeout) setInterval(function () {
            this.search(false, this.state.body)
        }.bind(this), this.props.timeout)

    }

    componentDidMount() {
        if (this.props.onRef) this.props.onRef(this)
    };


    componentWillUnmount() {
        if (this.props.onRef) this.props.onRef(undefined)
    }

    updateData() {
        this.search(true, this.state.body)
    }

    async search(force, body = {}) {

        if (force || Date.now() - this.lastRequest > 0.8 * this.props.timeout) {
            this.lastRequest = Date.now()
            this.lastUpdateVersion++
            setTimeout(function (lastUpdateVersion) {
                if (this.lastUpdateVersion != lastUpdateVersion) return
                request.call(this, body, this.lastUpdateVersion)

            }.bind(this, this.lastUpdateVersion), 1000)

        }


        function request(body, lastUpdateVersion) {
            _fetch(process.env.REACT_APP_URL + this.props.url, {
                "timeout": 10000,
                "nbRetry": 1,
                "method": "post",
                "headers": {},
                "body": {
                    filter: body
                }
            })
                .then(function (response) {
                    if (this.lastUpdateVersion != lastUpdateVersion) return
                    if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                    let data = response.json()
                    if (this.props.onUpdate) this.props.onUpdate(data)
                }.bind(this))
                .catch(function (e) {
                    if (this.lastUpdateVersion != lastUpdateVersion) return

                    console.error("Error toolbar voucher", e);
                    if (this.props.onUpdate) this.props.onUpdate(undefined, { error: true })

                }.bind(this));
        }


    }

    onChangeBody(obj) {
        let body = Object.assign({}, this.state.body)
        for (let prop in obj) body[prop] = obj[prop]

        this.setState({ body: body })
        if (this.props.onChange) this.props.onChange(body)
        this.search(true, body)
        sessionStorage.setItem("toolbar-filter-voucher", JSON.stringify(body))
    }

    render() {


        return (
            <Grid container item xs={12} direction="row" className={"toolbar-container-search " + this.props.className} spacing={3}>
                <Grid container item xs={12} direction="row" className={" " + this.props.className} spacing={3}>

                    <Grid container item direction={"row"} xs={2} spacing={3} className={"block"} >
                        <TextField label="N° booking" variant="standard" value={this.state.body.bookingReference} onChange={(ev) => {
                            this.onChangeBody({ bookingReference: ev.target.value })
                        }} />
                    </Grid>
                    <Grid container item direction={"row"} xs={2} spacing={3} className={"block"} >
                        <TextField label="Description" variant="standard" value={this.state.body.description} onChange={(ev) => {
                            this.onChangeBody({ description: ev.target.value })
                        }} />
                    </Grid>
                    <Grid container item direction={"row"} xs={2} spacing={3} className={"block"}>
                        <TextField label="Mail" variant="standard" value={this.state.body.mail} onChange={(ev) => {
                            this.onChangeBody({ mail: ev.target.value })
                        }} />
                    </Grid>


                    <Grid container item direction={"row"} xs={2} spacing={3} className={"block"}>
                        <CheckBoxCombigo
                            style={{ minWidth: "100%" }}
                            label={"Status"}
                            list={["ACTIVE", "EXPIRED", "INACTIVE", "SUSPENDED", "SOLD_OUT", "DELETED"]}
                            value={this.state.body.status}
                            multiple={true}
                            chips={false}

                            onChange={(value) => {
                                this.onChangeBody({ status: value })
                            }}
                        ></CheckBoxCombigo>
                    </Grid>
                    <Grid container item direction={"row"} xs={2} spacing={3} className={"block"}>
                        <CheckBoxCombigo
                            style={{ minWidth: "100%" }}
                            label={"typeVoucher"}
                            list={["CREDIT", "PROMO"]}
                            value={this.state.body.typeVoucher}
                            multiple={true}
                            chips={false}

                            onChange={(value) => {
                                this.onChangeBody({ typeVoucher: value })
                            }}
                        ></CheckBoxCombigo>
                    </Grid>

                    <Grid container item direction={"row"} xs={1} spacing={3} className={"block"}>
                        <Button onClick={function () {
                            sessionStorage.setItem("toolbar-filter-toolbar", "")
                            this.setState({
                                body: {
                                    ...initialStateBody,
                                    ...this.props.filter,
                                }
                            }, () => {

                                this.search(true,this.state.body)
                            })
                        }.bind(this)}>{"Reset"}</Button>
                    </Grid>

                </Grid>







            </Grid>
        );
    }

}


const mapStateToProps = (state) => {
    return { base: state.data };
};

export default connect(mapStateToProps, {})(Body);