import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import moment from "moment";
import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';


import TooltipCombigo from "./../Utils/TooltipCombigo"

import TextField from '@material-ui/core/TextField';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker, DatePicker, DateTimePicker } from "@material-ui/pickers";
import DateTimeContainer from "./../Utils/DateTimeContainer"
import CheckBoxCombigo from "./../Utils/Select_CheckBox"
import "./ModalVoucher.scss"

class ModalVoucher extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            voucher: props.voucher || null,
            expand_0: true,
            expand_1: true,
            expand_2: true,
            errorMessage: null
        }
        this.currency = this.props.base.currency.concat(["%"])
    }

    componentDidUpdate(prevProps, prevState) {
        if ((!prevProps.voucher && this.props.voucher) || (prevProps.voucher && !this.props.voucher) ||
            (this.props.voucher && prevProps.voucher && this.props.voucher.voucherId != prevProps.voucher.voucherId)

        ) {

            this.setState({ voucher: this.props.voucher || null })
        }
    }

    onChange = (obj) => {
        let voucher = this.state.voucher || {}
        for (let key in obj) voucher[key] = obj[key]
        this.setState({ voucher: voucher })
    }

    generate = () => {
        return Math.round(Math.random() * 100000000)
    }

    isValid(voucher) {

        for (let prop of ["typeVoucher", "voucherCode", "status", "voucherId"]) {
            if (!voucher[prop]) {
                this.setState({ errorMessage: "Error " + prop + " is missing" })
                return false
            }
        }
        if (voucher.voucherCode && voucher.status==="ACTIVE") {
            let o = voucher.voucherCode.toUpperCase().replace("COMBIGO", "").replace("_", "").replace("-", "").replace(".", "")
            if (!isNaN(Number(o.trim()))) {
                this.setState({ errorMessage: "Ne pas mettre COMBIGO + des chiffres c'est trop facile à craquer!!!! " })
                return false
            }
        }
        for (let prop of ["amount", "currency"]) {
            if (!voucher.voucher[prop]) {
                this.setState({ errorMessage: "Error the information " + prop + " is missing" })
                return false
            }
        }
        if (voucher.conditions.cartMin.amount && !voucher.conditions.cartMin.currency) {
            if (!voucher.conditions.cartMin.currency || !voucher.conditions.cartMin.amount) {
                this.setState({ errorMessage: "Error with the information about basket minimum, currency or amount is missing" })
                return false
            }
        }

        if (voucher.conditions.cartMin.amount) {
            voucher.conditions.cartMin.amount = Number(voucher.conditions.cartMin.amount)

            if (isNaN(voucher.conditions.cartMin.amount)) {
                this.setState({ errorMessage: "Error the cart minimum amount is not a number" })
                return false
            }
        }
        if (voucher.voucher.amount) {
            voucher.voucher.amount = Number(voucher.voucher.amount)
            if (isNaN(voucher.voucher.amount)) {
                this.setState({ errorMessage: "Error the voucher amount is not a number" })
                return false
            }
        }

        return true
    }





    render() {

        if (!this.state.voucher) return null

        const listRecap = [
            {
                title: "Général",
                render: <>
                    <Grid spacing={5} direction={"column"} >

                        <FormControl style={{ width: "100%" }}  >
                            <CheckBoxCombigo
                                className={""}
                                styleSelect={{ width: "100%" }}
                                label={"Type Voucher"}
                                list={["CREDIT", "PROMO"]}
                                multiple={false}
                                chips={false}
                                value={this.state.voucher.typeVoucher}
                                onChange={(value) => {
                                    this.onChange({ typeVoucher: value })
                                }}
                            />
                        </FormControl>
                        <FormControl style={{ width: "100%" }}  >
                            <CheckBoxCombigo
                                className={""}
                                styleSelect={{ width: "100%" }}
                                label={"Status"}
                                list={["ACTIVE", "EXPIRED", "INACTIVE", "SUSPENDED", "SOLD_OUT", "DELETED"]}
                                multiple={false}
                                chips={false}
                                value={this.state.voucher.status}
                                onChange={(value) => {
                                    this.onChange({ status: value })
                                }}
                            />
                        </FormControl>
                        <FormControl style={{ width: "100%" }}  >
                            <InputLabel id="demo-simple-select-label">{"voucherCode"}</InputLabel>
                            <Input id="demo-simple-select-label"
                                labelId="demo-simple-select-label"
                                value={this.state.voucher.voucherCode || ""}
                                onChange={(ev) => {
                                    this.onChange({ voucherCode: (ev.target.value || "").toUpperCase() })
                                }} />

                            <FormHelperText id="my-helper-text">A vous de le définir ou vous pouvez cliquer sur <a style={{ cursor: "pointer" }} onClick={() => {
                                if (this.state.voucher.voucherCode) return
                                this.onChange({
                                    voucherCode: this.state.voucher.typeVoucher == "CREDIT" ?
                                        "VOUCHER_" + Date.now() + "-" + this.generate() :
                                        "COMBIGO_" + new Date().getUTCFullYear() + "-" + this.generate()
                                })

                            }}>générer automatiquement un code</a></FormHelperText>
                        </FormControl>

                        <FormControl style={{ width: "100%" }}>
                            <div style={{ display: "flex", "flexDirection": "row" }}>
                                <TextField label="Montant du voucher"
                                    value={this.state.voucher.voucher.amount}
                                    style={{ flexGrow: 1 }}
                                    id="my-input" onChange={(ev) => {
                                        this.onChange({ voucher: { amount: ev.target.value ? ev.target.value : "", currency: this.state.voucher.voucher.currency } })
                                    }} />
                                <CheckBoxCombigo
                                    className={""}
                                    styleSelect={{ width: "70px" }}
                                    label={"%,€,$,..."}
                                    list={this.currency}
                                    multiple={false}
                                    chips={false}
                                    value={this.state.voucher.voucher.currency}
                                    onChange={(value) => {
                                        this.onChange({ voucher: { currency: value, amount: this.state.voucher.voucher.amount } })
                                    }}
                                />
                            </div>
                            <FormHelperText id="my-helper-text">Montant du voucher en % ou dans une monnaie</FormHelperText>
                        </FormControl>
                    </Grid>
                </>
            },
            {
                title: "Conditions"
                , render: <>

                    <Grid spacing={5} direction={"column"} >


                        {this.state.voucher.typeVoucher == "CREDIT" && <>
                            <FormControl style={{ width: "100%" }}>
                                <TextField label="Booking Reference"
                                    value={this.state.voucher.bookingReference}

                                    id="my-input" onChange={(ev) => {
                                        this.onChange({ bookingReference: ev.target.value })
                                    }} />
                                <FormHelperText id="my-helper-text">Si ce voucher est lié a une réservation</FormHelperText>
                            </FormControl>

                        </>}
                        {this.state.voucher.typeVoucher == "PROMO" && <>




                        </>}

                        <FormControl style={{ width: "100%" }}>
                            <TextField label="Mail"
                                value={this.state.voucher.conditions.mail}

                                id="my-input" onChange={(ev) => {
                                    this.onChange({ conditions: { ...this.state.voucher.conditions, mail: ev.target.value } })
                                }} />
                            <FormHelperText id="my-helper-text">Si vous souhaitez imposer une restriction sur une adresse email</FormHelperText>
                        </FormControl>

                        <FormControl style={{ width: "100%" }}>
                            <div style={{ display: "flex", "flexDirection": "row" }}>
                                <TextField label="Montant Minimal du Panier"
                                    value={this.state.voucher.conditions.cartMin.amount}
                                    style={{ flexGrow: 1 }}
                                    id="my-input" onChange={(ev) => {
                                        this.onChange({ conditions: { ...this.state.voucher.conditions, cartMin: { amount: ev.target.value ? ev.target.value : "", currency: this.state.voucher.conditions.cartMin.currency } } })
                                    }} />
                                <CheckBoxCombigo
                                    className={""}
                                    styleSelect={{ width: "70px" }}
                                    label={"€,$,..."}
                                    list={this.props.base.currency}
                                    multiple={false}
                                    chips={false}
                                    value={this.state.voucher.conditions.cartMin.currency}
                                    onChange={(value) => {
                                        this.onChange({ conditions: { ...this.state.voucher.conditions, cartMin: { currency: value, amount: this.state.voucher.conditions.cartMin.amount } } })
                                    }}
                                />
                            </div>
                            <FormHelperText id="my-helper-text">Montant du Panier Minimum dans une monnaie</FormHelperText>
                        </FormControl>

                        <FormControl style={{ width: "100%", display: "flex", "flexDirection": "column",gap:"12px" }}>
                            <div style={{ display: "flex", "flexDirection": "column" }}>

                                <CheckBoxCombigo
                                    className={""}
                                    styleSelect={{ width: "100%" }}
                                    label={"Type d'articles"}
                                    list={this.props.base.listItems}
                                    multiple={true}
                                    chips={false}
                                    value={this.state.voucher.conditions.listItems}
                                    onChange={(value) => {
                                        this.onChange({ conditions: { ...this.state.voucher.conditions, listItems: value } })
                                    }}
                                />
                                <FormHelperText id="my-helper-text">Restrictions sur une liste d'articles</FormHelperText>
                            </div>
                            <div style={{ display: "flex", "flexDirection": "column" }}>

                                <CheckBoxCombigo
                                    className={""}
                                    styleSelect={{ width: "100%" }}
                                    label={"Type de Produit"}
                                    list={["TICKET","BUNDLE","OPTION","BAG"]}
                                    multiple={true}
                                    chips={false}
                                    value={this.state.voucher.conditions.listProducts}
                                    onChange={(value) => {
                                        this.onChange({ conditions: { ...this.state.voucher.conditions, listProducts: value } })
                                    }}
                                />
                                <FormHelperText id="my-helper-text">Restrictions sur une liste de produits</FormHelperText>
                            </div>

                        </FormControl>





                        <FormControl style={{ width: "100%" }}>
                            <DateTimeContainer
                                DateObj={DatePicker}
                                label={"date Début"}
                                autoOk={true}
                                value={this.state.voucher.conditions.dBegin || ""}
                                format={"DD/MM/yyyy"}
                                onChange={(newValue, h) => {
                                    this.onChange({ conditions: { ...this.state.voucher.conditions, dBegin: moment(newValue) } })
                                }}
                                invalidLabel=""
                                invalidDateMessage={null}
                                variant={"inline"}
                                ampm={false}
                                TextFieldComponent={(props) => {
                                    // console.log(props)
                                    return <TextField {...props}
                                        value={this.state.voucher.conditions.dBegin ? moment(this.state.voucher.conditions.dBegin).format("DD/MM/yyyy") : ""} />
                                }}
                            />

                        </FormControl>
                        <FormControl style={{ width: "100%" }}>
                            <DateTimeContainer
                                DateObj={DatePicker}
                                label={"date Expired"}
                                autoOk={true}
                                value={this.state.voucher.conditions.dExpired || ""}
                                format={"DD/MM/yyyy"}
                                onChange={(newValue, h) => {
                                    this.onChange({ conditions: { ...this.state.voucher.conditions, dExpired: moment(newValue) } })
                                }}
                                invalidLabel=""
                                invalidDateMessage={null}
                                variant={"inline"}
                                ampm={false}
                                TextFieldComponent={(props) => {
                                    // console.log(props)
                                    return <TextField {...props}
                                        value={this.state.voucher.conditions.dExpired ? moment(this.state.voucher.conditions.dExpired).format("DD/MM/yyyy") : ""} />
                                }}
                            />

                        </FormControl>
                        {/* <FormControl style={{ width: "100%" }}>

                            <CheckBoxCombigo
                                className={""}
                                styleSelect={{ width: "100%" }}
                                label={"Type d'articles"}
                                list={this.props.base.listItems}
                                multiple={true}
                                chips={false}
                                value={this.state.voucher.conditions.listItems}
                                onChange={(value) => {
                                    this.onChange({ conditions: { ...this.state.voucher.conditions, listItems: value }})
                                }}
                            />
                            <FormHelperText id="my-helper-text">Restrictions sur une liste de transporteurs</FormHelperText>
                        </FormControl> */}
                    </Grid>


                </>
            },
            {
                title: "Description"
                , render: <FormControl style={{ width: "100%" }} required={true}>
                    <TextField label="Description" rows={4} multiline id="my-input"
                        value={this.state.voucher.description}
                        onChange={(ev) => {
                            this.onChange({ description: ev.target.value })
                        }} />
                    <FormHelperText id="my-helper-text">Raisons de la création du voucher</FormHelperText>
                </FormControl>
            },
            {
                title: "Réservations"
                , render: "Toutes les réservations qui ont utilisés ce voucher"
            },
        ]



        return (
            <div>
                <Dialog
                    open={this.props.open || false}
                    onClose={this.props.onClose}
                    maxWidth={"md"}
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Voucher ID " + "(" + this.state.voucher.voucherId + ")"}
                    </DialogTitle>
                    <DialogContent>

                        {listRecap.map(function (obj, index) {
                            return <Accordion expanded={this.state["expand_" + index]} key={index}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    onClick={() => {
                                        let obj = {}
                                        obj["expand_" + index] = !this.state["expand_" + index]
                                        this.setState(obj)
                                    }}
                                >
                                    <Typography className={""}>{obj.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {obj.render}
                                </AccordionDetails>
                            </Accordion>
                        }.bind(this))}

                        <DialogContentText className={"modal-voucher"}
                            id="alert-dialog-description" style={{ display: "flex", flexDirection: "column" }}>
                            <Grid>
                                <span>{"Created by " + this.state.voucher.createdBy.id + " le " + moment(this.state.voucher.createdBy.date).format()}</span>
                            </Grid>
                            <Grid>
                                <span>{"Modified by " + this.state.voucher.modifiedBy.id + " le " + moment(this.state.voucher.modifiedBy.date).format()}</span>
                            </Grid>
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.onClose} color="primary">
                            {"Annuler"}
                        </Button>
                        <Button onClick={() => {


                            if (!this.isValid(this.state.voucher)) return

                            this.props.onClick(this.state.voucher)
                        }} color="primary" autoFocus>
                            {"Sauvegarder"}
                        </Button>
                    </DialogActions>
                </Dialog>

                <CombigoSnackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.errorMessage !== null}
                    onClose={() => this.setState({ errorMessage: null })}
                    variant={this.state.errorMessage != null ? 'error' : "success"}
                    autoHideDuration={6000}
                    message={<span id="message-id"> {this.state.errorMessage}</span>}
                />
            </div>
        );
    }

}




const mapStateToProps = (state) => {
    return { locale: state.user.locale, nameUser: state.user.name, base: state.data };
};


export default connect(mapStateToProps, {

})(withRouter(ModalVoucher));