import React, { Component, version } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { parseQuery, _fetch } from "./../helpers"
import "./AllTransaction.scss"
import Table from "./../Components/TableBankingCard/TableBankingCard.js"
import TableTransactionCard from "./../Components/TableBankingCard/TableTransactionCard.js"

import { Helmet } from "react-helmet";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';


class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      version: "init",
      expand_: false
    }
  }

  componentDidUpdate(prevProps) {


  }



  render() {

    return <div className={"view-transaction"}>
      

      <div className={"title-page"}> {"" + (this.state.dateUpdate ? new Date(this.state.dateUpdate).toISOString() : "")}</div>

      {/* <Accordion expanded={this.state["expand_"]} key={"1"} className={"filtre-bandeau"}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          onClick={() => {
            let obj = {}
            obj["expand_"] = !this.state["expand_"]
            this.setState(obj)
          }}
        >
          <Typography className={""}>{"Filtre"}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          { <Toolbar
              url={'/crm/transactions/search'}
              timeout={0} //12000
             
              mode={this.props.planning?"calendar":undefined}

              onUpdate={function(data,error){
                  if (data){
                    if (this.state.version != data.version){
                      this.setState({data:data.data,version:data.version,dateUpdate:Date.now()})
                    }
                    else{
                      this.setState({dateUpdate:Date.now()})
                    }
                  }
                 
                }.bind(this)}
              
              
              ></Toolbar>}
        </AccordionDetails>
      </Accordion> */}

      {this.props.cards ? <Table
        activeToolbarFilter={true}
        allCards={true}
        //   data={this.state.data}
        version={"1"}
      ></Table> : null}



      {this.props.transactions ? <TableTransactionCard
        //   data={this.state.data}
        activeToolbarFilter={true}

        version={"1"}
      ></TableTransactionCard> : null}

    </div>
  }
}


export default withRouter(Body);

