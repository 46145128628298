
import React, { useRef } from 'react';
import { InputLabel, TextField } from '@material-ui/core';

import "./Label.scss"


class Body extends React.Component {
    constructor(props){
        super(props)

    }

    render(){
        return <div className={this.props.classNameLabel } style={{"display":"flex"}}>
            <InputLabel
                {...this.props}
                className={ "label-textfield"}
                disabled
                >
                    {this.props.children}
                </InputLabel>
            </div>
    }
}
export default Body;
