
import React from 'react';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import SelectCheckBoxCombigo from "./../Utils/Select_CheckBox"
import Grid from '@material-ui/core/Grid';

import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';

import { parseQuery, _fetch } from "./../../helpers"
import Label from "./../Label/Label"
import "./ModalCardBank.scss"


class Body extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            view: "init",
            card: undefined,
            errorMessage: null,
            snackMessage: null,
            cardSelect: null,
            disabledButton: false,
            createCard: {
                cardHolderName: props.cardHolderName,
                currency: "EUR",
                usage: []
            },
            listCard: [],
        }

        this.onClose = this.onClose.bind(this)
        this.createCard = this.createCard.bind(this)

    }



    async componentDidMount() {
        let data = await this._request({ action: "list" })

        if (data) {
            this.setState({
                listCard: data.data,
                createCard: {
                    id: data.data[0].id,
                    cardId: data.data[0].id,
                    provider: data.data[0].provider,
                    currency: "EUR",
                    cardHolderName: this.props.cardHolderName,
                    amount: this.props.amountMinimum
                }
            })
        }
    }

    componentDidUpdate(prevProps) {

        if (this.props.version != prevProps.version) {

            this.setState({
                createCard: {
                    ...this.state.createCard,
                    cardHolderName: this.props.cardHolderName,
                },
                amount: this.props.amountMinimum
            })
        }

    }





    _request(body, route = "bankcard") {
        return new Promise(function (resolve) {
            _fetch(process.env.REACT_APP_URL + '/crm/' + route, {
                "timeout": 20000,
                "nbRetry": 1,
                "method": "post",
                "headers": {},
                "body": body
            })
                .then(function (response) {
                    if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                    let data = response.json()
                    if (data.success) return resolve(data)
                    throw data
                }.bind(this))
                .catch(function (e) {
                    console.log("Error modal card bank", e);
                    alert("mettre un snackbar pour informer de l'erreur modal card bank")
                    resolve(undefined)
                }.bind(this));
        }.bind(this))
    }




    onClose() {
        // this.setState({
        //     view: "init",
        //     cardSelect: null,
        // })
        this.props.onClose()
    }



    async createCard() {
        // console.log(this.state)
        if (!this.state.createCard.cardId) return this.setState({ errorMessage: "Aucune Carte selectionnée" })
        if (!this.state.createCard.currency) return this.setState({ errorMessage: "Aucune Currency selectionnée" })
        if (!this.state.createCard.amount) return this.setState({ errorMessage: "Aucun Montant renseigné" })
        let amount = _convertNumber(this.state.createCard.amount)
        if (isNaN(amount)) return this.setState({ errorMessage: "Montant renseigné incorrect" })
        let bodyRequest = {
            informations: [
                { description: this.state.createCard.description || "" },
                { usage: (this.state.createCard.usage || []).join(";") },
                { bookingId: this.props.bookingId },
                { bookingReference: this.props.bookingReference },
                { user: this.props.nameUser },
                { source: "crm" }
            ],
            bookingId: this.props.bookingId,
            user: this.props.nameUser,
            cardHolderName: this.state.createCard.cardHolderName,
            currency: this.state.createCard.currency,
            amount: amount,
            id: this.state.createCard.id,
            cardId: this.state.createCard.cardId,
            provider: this.state.createCard.provider
        }
        this.setState({ disabledButton: true })
        let response = await this._request({
            action: "create",
            data: bodyRequest
        })

        if (response && response.success && response.data) {
            if (response.data.error) return this.setState({ errorMessage: "Impossible to create Card", disabledButton: false, cardSelect: null })
            let doc = response.data
            if (!doc || !doc.data || !doc.data.cardReference) {
                return this.setState({ errorMessage: "Impossible to create Card, (peut être pas assez d'argent chez notre partenaire)", disabledButton: false, cardSelect: null })
            }
            this.setState({ cardSelect: doc, view: "create_success", snackMessage: "Carte Crée" })
        }
        this.setState({ disabledButton: false })


        function _convertNumber(num) {
            return Number(String(num).replace(",", ".").trim())

        }
    }

    render() {


        /*
            typeCard:
            amount:
            currency:
            description:
            

            source:
        */
        let listProperties = ["number", "cvv", "expMonth", "expYear", "number_format", "name", "brand"]
        
        return (
            <>
                <Dialog
                    className={(this.props.className || "") + " modalDialog"}
                    open={this.props.open || false}
                    onClose={() => {
                        this.onClose()
                    }}
                    keepMounted
                >
                    <DialogTitle id="alert-dialog-title">
                        {this.state.view == "init" ? "Création d'une carte" : ""}
                        {this.state.view == "create_success" ? "Votre carte" : ""}

                    </DialogTitle>

                    <DialogContent>
                        {this.props.open &&
                            <>
                                {this.state.view == "init" ? <div className={"action-cardbank"} style={{ background: "white", width: "90%", height: "90%" }}>
                                    <Grid spacing={5} direction={"column"} >

                                        <FormControl style={{ width: "100%" }} required={true} >
                                            <SelectCheckBoxCombigo
                                                className={""}
                                                style={{ minWidth: "100%" }}
                                                label={"Card"}
                                                list={this.state.listCard}
                                                multiple={false}
                                                chips={false}
                                                getLabel={(option) => {
                                                    return option ? option.name : option
                                                }}
                                                getMatching={(option) => {

                                                    return option.id
                                                }}
                                                value={this.state.createCard.id}
                                                onChange={(value) => {
                                                    this.setState({ createCard: { ...this.state.createCard, cardId: value.cardId, id: value.id, provider: value.provider }, currencies: value.currencies || ["EUR"] })
                                                }}
                                            />
                                        </FormControl>


                                        <FormControl style={{ width: "100%" }} required={true}>
                                            <div style={{ display: "flex", "flexDirection": "row" }}>
                                                <TextField label="Montant de la carte"
                                                    value={this.state.createCard.amount}
                                                    style={{ flexGrow: 1 }}
                                                    id="my-input"
                                                    onChange={(ev) => {
                                                        this.setState({ createCard: { ...this.state.createCard, amount: ev.target.value ? ev.target.value : "" } })
                                                    }}
                                                />
                                                <SelectCheckBoxCombigo
                                                    className={""}
                                                    styleSelect={{ width: "70px" }}
                                                    label={"€,$,..."}
                                                    list={this.state.currencies}
                                                    multiple={false}
                                                    chips={false}
                                                    value={this.state.createCard.currency}
                                                    onChange={(value) => {
                                                        this.setState({ createCard: { ...this.state.createCard, currency: value } })
                                                    }}
                                                />
                                            </div>
                                            <FormHelperText id="my-helper-text">Voir Notion pour savoir dans quel cas changer la devise</FormHelperText>
                                        </FormControl>

                                        {/* <FormControl style={{ width: "100%" }} required={false}>
                                            <SelectCheckBoxCombigo
                                                className={""}
                                                styleSelect={{ width: "100%" }}
                                                label={"Type d'achat"}
                                                list={["TICKET","TICKET_GUARANTEE","OPTIONS"]}
                                                multiple={true}
                                                chips={false}
                                                value={this.state.createCard.usage}
                                                onChange={(value) => {
                                                    this.setState({ createCard: { ...this.state.createCard, usage: value } })
                                                }}
                                            />
                                        </FormControl> */}


                                        <FormControl style={{ width: "100%" }} >
                                            <TextField label="cardHolderName" id="my-input"
                                                value={this.state.createCard.cardHolderName}

                                                onChange={(ev) => {
                                                    this.setState({ createCard: { ...this.state.createCard, cardHolderName: ev.target.value } })
                                                }} />
                                            <FormHelperText id="my-helper-text">Nom titulaire carte en plus que Combigo</FormHelperText>
                                        </FormControl>

                                        <FormControl style={{ width: "100%" }} >
                                            <TextField label="Description" rows={4} multiline id="my-input" onChange={(ev) => {
                                                this.setState({ createCard: { ...this.state.createCard, description: ev.target.value } })
                                            }} />
                                            <FormHelperText id="my-helper-text">Raisons de la création</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                </div> : null}


                                {this.state.view == "create_success" && this.state.cardSelect && this.state.cardSelect.data ? <div className={"action-cardbank"} style={{ background: "white", width: "90%", height: "90%" }}>
                                    <Grid spacing={5} direction={"column"} >
                                        <FormControl className={"select-status"} style={{ width: "100%" }}>
                                            <InputLabel id="demo-simple-select-label">{"cardReference"}</InputLabel>
                                            <Input id="demo-simple-select-label" labelId="demo-simple-select-label" style={{ "color": "black" }}
                                                value={this.state.cardSelect.data.cardReference} disabled={true} />
                                        </FormControl>
                                        {listProperties.map((key) => {
                                            return <FormControl className={"select-status"} style={{ width: "100%" }}>
                                                <InputLabel id="demo-simple-select-label">{"Card " + key}</InputLabel>
                                                <Input id="demo-simple-select-label" labelId="demo-simple-select-label" style={{ "color": "black" }}
                                                    value={this.state.cardSelect.data.properties[key]} disabled={true} />
                                            </FormControl>

                                        })}

                                        {this.props.listPropertiesAdd && Object.keys(this.props.listPropertiesAdd).map((key) => {
                                            return <FormControl className={"select-status"} style={{ width: "100%" }}>
                                                <InputLabel id="demo-simple-select-label">{key}</InputLabel>
                                                <Input id="demo-simple-select-label" labelId="demo-simple-select-label" style={{ "color": "black" }}
                                                    value={this.props.listPropertiesAdd[key]} disabled={true} />
                                            </FormControl>

                                        })}
                                    </Grid>

                                </div> : null}





                            </>
                        }
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={this.onClose} color="primary">
                            {"Cancel"}
                        </Button>
                        {this.state.view == "init" ? <Button disabled={this.state.disabledButton}
                            onClick={() => {
                                this.createCard()
                            }} color="primary">
                            {"Création"}
                        </Button> : null}
                        {this.state.view == "create_success" ? <Button
                            onClick={() => {
                                this.setState({ view: "init", cardSelect: null, })
                            }} color="primary">
                            {"Nouvelle Carte"}
                        </Button> : null}
                    </DialogActions>
                </Dialog>
                <CombigoSnackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.errorMessage !== null || this.state.snackMessage !== null}
                    onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
                    variant={this.state.errorMessage != null ? 'error' : "success"}
                    autoHideDuration={6000}
                    message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
                />
            </>
        );
    }
}




const mapStateToProps = (state) => {
    return { booking: state.booking, nameUser: state.user.name };
};

export default connect(mapStateToProps, {})(Body);