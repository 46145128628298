
import React from 'react';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';

import ModalSms from "./ModalSms"


class Body extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }

    }



    async componentDidMount() {

    }

    componentDidUpdate(prevProps) {

    }




    render() {
        return (

            <Dialog
                className={(this.props.className || "") + " modalMailDialog"}
                open={this.props.open || false}
                onClose={() => {
                    this.props.onClose()
                }}
                
            >
                {this.props.open?<ModalSms
                    data={this.props.data}
                    onClose={() => {
                        this.props.onClose()
                    }}
                    id={this.props.id}
                    version={this.props.version}
                    style={{ background: "white", width: "90%", height: "90%" }}>
                </ModalSms>:null}

            </Dialog>
        );
    }
}




const mapStateToProps = (state) => {
    return { booking: state.booking };
};

export default connect(mapStateToProps, {})(Body);