import { combineReducers } from "redux";

import user from './user';
import booking from './booking';
import data from './data';


// https://mosaika.fr/redux-appli-mobile-react-native/ => pour store persistent
export default combineReducers({ 
    user,
    booking,
    data
});