
import React from 'react';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { parseQuery, _fetch } from "./../../helpers"


// import ModalMail from "./ModalMail"
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Select_CheckBox from "./../Utils/Select_CheckBox"
import "./ModalModification.scss"
import TableSegments from "./../TableSegments/TableSegments"
import MinimizeIcon from '@mui/icons-material/Minimize';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@material-ui/core/IconButton';
import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';


class Body extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            firstVersion: null,
            secondVersion: null,
            snackMessage: null,
            disabledButtonSend: false,
            errorMessage: null,
            list: null,
            typeMail: null,
            step:1
        }

    }



    componentDidMount() {
        this.updateList()
    }

    _reset = () => {
        this.setState({ requestRunning: false, list: null, secondVersion: null, firstVersion: null, bodyMail: null, secondData: null, firstData: null, disabledButtonSend: false, typeMail: null, step: 1,message:null })

    }
    updateList = () => {

        this.setState({requestRunning:true})
        this._request('/crm/logs/search', {
            rawData: true,
            filter: {
                relationId: "bookingId:" + this.props.bookingId,
                event: "segments" //
                //searchDifference:"segments"
            }

        }, (data, error) => {
            //return 
            // console.log("data = ",data.data)
            let list = [{ id: "latest", "title": "latest", rawData: this.props.booking }]
            if (data && data.data) {
                list = list.concat(data.data.map((line) => {

                    let title = [createDate(line.date), line.user]

                    // if (!this.props.filter || !this.props.filter.event) {
                    //     title.push(line.event)
                    // }
                    let label = []
                    if (line.updateData) {
                        if (line.updateData.changed) {
                            for (let line2 of line.updateData.changed) {
                                label.push("avant modif " + line2.key + " av=" + String(line2.before || "") + " ap=" + String(line2.after || ""))
                            }
                        }
                        if (line.updateData.added) {
                            let listTrip = []
                            for (let line2 of line.updateData.added) {
                                if (line2.after && line2.after.transporter) listTrip.push(line2.after.transporter.name + " " + line2.after.trNum)
                                else listTrip.push(typeof line2==="object"?JSON.stringify(line2):String(line2 || ""))
                            }
                            if (listTrip.length) label.push("avant ajout trajet " + listTrip.join(" & "))
                        }
                        if (line.updateData.deleted) {
                            let listTrip = []
                            for (let line2 of line.updateData.deleted) {
                                if (line2.after && line2.after.transporter) listTrip.push(line2.after.transporter.name + " " + line2.after.trNum)
                                else listTrip.push(typeof line2==="object"?JSON.stringify(line2):String(line2 || ""))


                            }
                            if (listTrip.length) label.push("avant suppression trajet " + listTrip.join(" & "))

                        }
                    }

                    label = label.join(" & ")
                    if (label.length > 50) {
                        label = label.substr(0, 50) + "..."
                    }

                    title.push(label)
                    if (line.title) {
                        title = [line.title]
                    }

                    return {
                        _id: line._id,
                        date: line.date,
                        user: line.user,
                        updateData: line.updateData,
                        rawData: line.rawData,
                        title: title.join(", ")
                    }
                }))
                this.setState({ requestRunning: false, list: list, error: false, secondVersion: list[0] }, () => {
                    this._getDataByVersion(this.state.secondVersion, (newData) => {
                        this.setState({ secondData: newData })
                    })
                })

            }
            else {
                this.setState({ requestRunning: false, errorMessage: "Problème, veuillez réessayer dans quelques minutes" })
                console.error("erreur init modification", data)
            }

        })

        function createDate(date) {
            return new Date(date).toISOString().substr(0, 16).replace("T", " ")

        }
    }


    componentDidUpdate(prevProps) {

        if (this.props.version != prevProps.version) {
            // console.log("Difference version " + this.props.version + " vs " + prevProps.version)
            this.updateList()
        }
        else if (!this.state.requestRunning && this.props.open && !this.state.list) {
         //   console.log("Diff list " + this.state.requestRunning + " " + this.props.open + " ")
            this.updateList()
        }
    }


    _getDataByVersion = (obj, cb) => {
        let newData
        if (obj.rawData) {
            if (typeof obj.rawData === "string") obj.rawData = JSON.parse(obj.rawData)
            newData = obj.rawData.segments
        }

        cb(newData)
    }

    _request = (url = '/crm/logs/search', body, cb) => {

        _fetch(process.env.REACT_APP_URL + url, {
            "timeout": 20000,
            "nbRetry": 2,
            "method": "post",
            "headers": {},
            "body": {
                ...body
            }
        })
            .then(function (response) {
                if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                let data = response.json()
                cb(data)
            }.bind(this))
            .catch(function (e) {
                cb(undefined, e)
                console.error("Error modal modification" + url, e);
            }.bind(this));
    }


    onClick = () => {
        this.setState({ disabledButtonSend: true })
        this._request('/crm/transactions/action', {
            segments1: this.state.firstData,
            segments2: this.state.secondData,
            bookingId: this.props.bookingId,
            typeMail: this.state.typeMail.id,
            action: "mail_modification",
            onlyView:this.state.step===1?true:undefined

        }, (data, error) => {
            this.setState({ disabledButtonSend: false })
            if (data && data.success) {
                if (this.state.step === 1) {
                    this.setState({ message: data.message, step: 2 })
                }
                else {
                    this.setState({ snackMessage: "Notification envoyée au client!" })
                    this._reset()
                    if (this.props.onClose) this.props.onClose()
                }


            }
            else {
                if (this.state.step === 1) {
                    this.setState({ errorMessage: "Erreur, problème ! " + (data ? (data.message || "") : "") })
                }
                else {
                    this.setState({ errorMessage: "Erreur, message non envoyé! " + (data ? (data.message || "") : "") })

                }
                console.error("error to send modification", data)

            }

        })

    }


    render() {
        return (
            <>
                <Dialog
                    className={(this.props.className || "") + " modal-modification"}
                    open={this.props.open || false}
                    onClose={() => {
                        this.props.onClose()
                    }}

                >
                    <div style={{ display: "flex", "flewDirection": "row", borderBottom: "1px solid grey" }}>
                        <DialogTitle>{"Notifier le client d'une modification d'horaire (sans modification PNR)"}</DialogTitle>

                        <div style={{ flexGrow: 1 }}></div>

                        <IconButton onClick={() => {
                            if (this.props.onClose) this.props.onClose()

                        }}>
                            <MinimizeIcon />
                        </IconButton>
                        <IconButton onClick={() => {
                            this._reset()
                            if (this.props.onClose) this.props.onClose()

                        }}>
                            <CloseIcon />
                        </IconButton>
                    </div>

                    {this.state.list ? <>
                        <DialogContent>

                            {this.state.step === 1 ? <div  >
                                <Grid container item style={{ marginTop: "10px", minWidth: "100px", backgroundColor: "#eff6fb" }} className={""} >
                                    <Select_CheckBox
                                        className={"select-typeMail"}
                                        styleSelect={{ width: "100%" }}
                                        label={"Type Modification"}
                                        list={[{ title: "Le transporteur a modifié le trajet", id: "TRANSPORTER" }, { title: "Combigo ou le Client a modifié le trajet", id: "OTHER" }]}
                                        multiple={false}
                                        getLabel={(option) => { return option.title }}
                                        value={this.state.typeMail}
                                        onChange={(value) => {

                                            this.setState({ typeMail: value })

                                        }}
                                    ></Select_CheckBox>
                                </Grid>



                                <Grid container item style={{ marginTop: "10px", justifyContent: "center" }} className={"line-item"} >
                                    <span style={{ textAlign: "center", fontSize: "18px", fontWeight: "500" }}>    {"Avant"}</span>
                                </Grid>

                                <Grid container item className={"block_select"}>
                                    <Select_CheckBox
                                        className={"select-version"}
                                        styleSelect={{ width: "100%" }}
                                        label={"Trajet Précedent"}
                                        list={this.state.list}
                                        multiple={false}
                                        getLabel={(option) => { return option.title }}
                                        value={this.state.firstVersion}
                                        onChange={(value) => {

                                            this.setState({ firstVersion: value, firstData: null })
                                            this._getDataByVersion(value, (newData) => {
                                                this.setState({ firstData: newData })
                                            })
                                        }}
                                    ></Select_CheckBox>

                                </Grid>
                                <Grid container item className={"block_select"} >
                                    {this.state.firstData ? <TableSegments
                                        disabledModification={true}
                                        searchDataHistory={false}
                                        data={this.state.firstData}
                                        readonly={true}
                                    /> : ""}
                                </Grid>

                                <div style={{ marginTop: "10px", marginBottom: "10px", height: "4px", backgroundColor: "" }}></div>
                                <div  >
                                    <Grid container item style={{ justifyContent: "center" }} className={"line-item"}>
                                        <span style={{ textAlign: "center", fontSize: "18px", fontWeight: "500" }}>    {"Après"}</span>
                                    </Grid>
                                    <Grid container item className={"block_select"} style={{ marginTop: "10px" }}>
                                        <Select_CheckBox
                                            className={"select-version"}
                                            styleSelect={{ width: "100%" }}
                                            label={"Nouveau Trajet"}
                                            list={this.state.list}
                                            multiple={false}
                                            getLabel={(option) => { return option.title }}
                                            value={this.state.secondVersion}
                                            onChange={(value) => {
                                                this.setState({ secondVersion: value, secondData: null })
                                                this._getDataByVersion(value, (newData) => {
                                                    this.setState({ secondData: newData })
                                                })
                                            }}
                                        ></Select_CheckBox>

                                    </Grid>
                                    <Grid container item className={"block_select"}>
                                        {this.state.secondData ? <TableSegments
                                            disabledModification={true}
                                            searchDataHistory={false}
                                            data={this.state.secondData}
                                            readonly={true}

                                        /> : ""}
                                    </Grid>
                                </div>
                            </div> : null}
                            {
                                this.state.step === 2 ? <div   dangerouslySetInnerHTML = {{__html: this.state.message.content}} /> : null
                            }


                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                if (this.state.step==2){
                                    this.setState({step:1,message:null})
                                    return 
                                }
                                this._reset()
                                if (this.props.onClose) this.props.onClose()
                            }} color="primary">
                                {(this.state.step==1?"Fermer":"Précédent")}
                            </Button>
                            <Button onClick={() => {
                                if (!this.state.typeMail) {
                                    return this.setState({ errorMessage: "Le type de mail n'est pas selectionné" })
                                }
                                if (this.state.secondVersion && this.state.firstVersion && this.state.secondVersion._id != this.state.firstVersion._id) {
                                    this.onClick()
                                }
                                else {
                                    this.setState({ errorMessage: "Incomplet, vous devez selectionner 2 versions différentes" })
                                }
                            }}
                                disabled={this.state.disabledButtonSend}

                                color="primary" >
                                {this.state.step==1?"Visualisation Message":"Envoyer"}
                            </Button>
                        </DialogActions>
                    </> : null}
                </Dialog>
                <CombigoSnackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.errorMessage !== null || this.state.snackMessage !== null}
                    onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
                    variant={this.state.errorMessage != null ? 'error' : "success"}
                    autoHideDuration={10000}
                    message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
                />

            </>
        );
    }
}




const mapStateToProps = (state) => {
    return { booking: state.booking };
};

export default connect(mapStateToProps, {})(Body);