import React, { Component, version } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { parseQuery, _fetch } from "./../helpers"
import "./AllTransaction.scss"

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { CombigoSnackbar } from './../Components/Snackbar/Snackbar';
import { Chart } from "react-google-charts";
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import { Helmet } from "react-helmet";






class Body extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            version: "init",
            modeVisu:"day",
            countVersion: 0,
            expand_0: true,
            expand_1: true,
            expand_2: true,
            expand_3: true,
            expand_4: true,
            expand_5: true,
            expand_6: true,
            expand_7: true,
            expand_8: true,
            expand_9: true,
            expand_10: true,
            expand_11: true,
            expand_12: true,
            expand_13: true,
        }
    }


    initData = () => {
        this.request(undefined, (data) => {
            if (data) {

                let map = {}
                for (let l of data.data) map[l.key] = l

                this.setState({ data: map, dateUpdate: Date.now() })
            }
        })
    }

    componentDidMount() {
        this.initData()
    }

    componentDidUpdate(prevProps) {




    }


    request = (body, cb) => {
        _fetch(process.env.REACT_APP_URL + "/crm/metrics/main", {
            "timeout": 10000,
            "nbRetry": 1,
            "method": "post",
            "headers": {},
            "body": {

            }
        })
            .then(function (response) {
                if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                let data = response.json()
                cb(data)
            }.bind(this))
            .catch(function (e) {
                cb(undefined, e)

                console.error("Error metrics", e);

            }.bind(this));
    }



    render() {
        if (!this.state.data) return null


        const list = {
            "gmv_by_month": (line) => {

                let data = [
                    ["Month", "", { role: 'annotation' }],
                ];

                for (let l of line.data.buckets) {
                    data.push([l.key_as_string.substr(0, 7), l.gmv.value, l.gmv.value])
                }

                const options = {
                    chart: {},
                    bar: { groupWidth: "90%" },
                    // legend: { position: "top", maxLines: 2 },
                };

                return {
                    render: <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />,
                    name: "GMV par MOIS"
                }
            },
            "gmv_by_day": (line) => {

                let data = [
                    ["Day", "", { role: 'annotation' }],
                ];

                for (let l of line.data.buckets) {
                    data.push([l.key_as_string.substr(0, 10), l.gmv.value,l.gmv.value])
                }

                const options = {
                    chart: {},
                    bar: { groupWidth: "90%" },
                    // legend: { position: "top", maxLines: 2 },
                };

                return {
                    render: <Chart
                    
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />,
                    name: "GMV par JOUR"
                }
            },

            "auto_by_day": (line) => {

                let data = [
                ];
                let keys = {}
                for (let l of line.data.buckets) {
                    for (let l2 of l.manager.buckets) {
                        let key = l2.key === "AUTO" ? "AUTO" : "SUPPORT"
                        keys[key] = 1
                    }
                }

                let listKey = Object.keys(keys)
                listKey.unshift("Day")
                data.push(listKey)
                for (let l of line.data.buckets) {
                    let lineObj = new Array(listKey.length)
                    for (let k = 0; k < lineObj.length; k++) lineObj[k] = null
                    lineObj[0] = l.key_as_string.substr(0, 10)
                    for (let l2 of l.manager.buckets) {
                        let key = l2.key === "AUTO" ? "AUTO" : "SUPPORT"

                        let index = listKey.indexOf(key)
                        if (!lineObj[index]) lineObj[index] = 0
                        lineObj[index] += l2.doc_count
                    }
                    data.push(lineObj)
                }

                const options = {
                    chart: {},
                    bar: { groupWidth: "90%" },
                    // legend: { position: "top", maxLines: 2 },
                };
                return {
                    render: <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />,
                    name: "Auto par JOUR"
                }
            },
            "auto_by_month": (line) => {

                let data = [
                ];
                let keys = {}
                for (let l of line.data.buckets) {
                    for (let l2 of l.manager.buckets) {
                        let key = l2.key === "AUTO" ? "AUTO" : "SUPPORT"
                        keys[key] = 1
                    }
                }

                let listKey = Object.keys(keys)
                listKey.unshift("Day")
                data.push(listKey)
                for (let l of line.data.buckets) {
                    let lineObj = new Array(listKey.length)
                    for (let k = 0; k < lineObj.length; k++) lineObj[k] = null
                    lineObj[0] = l.key_as_string.substr(0, 7)
                    for (let l2 of l.manager.buckets) {
                        let key = l2.key === "AUTO" ? "AUTO" : "SUPPORT"

                        let index = listKey.indexOf(key)
                        if (!lineObj[index]) lineObj[index] = 0
                        lineObj[index] += l2.doc_count
                    }
                    data.push(lineObj)
                }

                const options = {
                    chart: {},
                    bar: { groupWidth: "90%" },
                    // legend: { position: "top", maxLines: 2 },
                };
                return {
                    render: <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />,
                    name: "Auto par MOIS"
                }
            },
            "sells_by_month": (line) => {

                let data = [
                    ["Month", "", { role: 'annotation' }],
                ];

                for (let l of line.data.buckets) {
                    data.push([l.key_as_string.substr(0, 7), l.doc_count, l.doc_count])
                }

                const options = {
                    chart: {},
                    bar: { groupWidth: "90%" },
                    // legend: { position: "top", maxLines: 2 },
                };

                return {
                    render: <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />,
                    name: "Nombre de ventes par MOIS"
                }
            },
            "margin_by_month": (line) => {

                let data = [
                    ["Month", "", { role: 'annotation' }],
                ];

                for (let l of line.data.buckets) {
                    data.push([l.key_as_string.substr(0, 7), l.margin.value, l.margin.value])
                }

                const options = {
                    chart: {},
                    bar: { groupWidth: "90%" },
                    // legend: { position: "top", maxLines: 2 },
                };

                return {
                    render: <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />,
                    name: "Marge Brute par MOIS"
                }
            },
            "margin_by_day": (line) => {

                let data = [
                    ["Day", "", { role: 'annotation' }],
                ];

                for (let l of line.data.buckets) {
                    data.push([l.key_as_string.substr(0, 10), l.margin.value, l.margin.value])
                }

                const options = {
                    chart: {},
                    bar: { groupWidth: "90%" },
                    // legend: { position: "top", maxLines: 2 },
                };

                return {
                    render: <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />,
                    name: "Marge Brute par JOUR"
                }
            },
            "sells_by_day": (line) => {

                let data = [
                ];

                let keys = {}
                for (let l of line.data.buckets) {
                    for (let l2 of l.status.buckets) {
                        keys[l2.key] = 1
                    }
                }
                let listKey = Object.keys(keys)
                listKey.unshift("Day")
                data.push(listKey)
                for (let l of line.data.buckets) {
                    let lineObj = new Array(listKey.length)
                    for (let k = 0; k < lineObj.length; k++) lineObj[k] = null
                    lineObj[0] = l.key_as_string.substr(0, 10)
                    for (let l2 of l.status.buckets) {
                        let index = listKey.indexOf(l2.key)
                        lineObj[index] = l2.doc_count
                    }
                    data.push(lineObj)
                }


                //                        data.push([l.key_as_string.substr(0,10),l.margin.value])



                const options = {
                    chart: {},
                    bar: { groupWidth: "90%" },

                    // legend: { position: "top", maxLines: 2 },
                };

                return {
                    render: <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />,
                    name: "Nombre de ventes par JOUR"
                }
            },
            "margin_net_by_month": (line) => {

                let data = [
                    ["Month", "", { role: 'annotation' }],
                ];

                for (let l of line.data.buckets) {
                    data.push([l.key_as_string.substr(0, 7), l.margin.value, l.margin.value])
                }

                const options = {
                    chart: {},
                    bar: { groupWidth: "90%" },
                    // legend: { position: "top", maxLines: 2 },
                };

                return {
                    render: <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />,
                    name: "Marge nette par MOIS (Marge Brute - Frais Comparateur, Bancaire, Fournisseur)"
                }
            },
            "margin_net_by_day": (line) => {

                let data = [
                    ["Day", "", { role: 'annotation' }],
                ];

                for (let l of line.data.buckets) {
                    data.push([l.key_as_string.substr(0, 10), l.margin.value, l.margin.value])
                }

                const options = {
                    chart: {},
                    bar: { groupWidth: "90%" },
                    // legend: { position: "top", maxLines: 2 },
                };

                return {
                    render: <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />,
                    name: "Marge Nette par JOUR (Marge Brute - Frais Comparateur, Bancaire, Fournisseur)"
                }
            },
            "percentage_margin_by_month": (line,dataInput) => {
                if (!dataInput["margin_by_month"]) return null

                let margin = {}
                let gmv = {}
                for (let l of dataInput["margin_by_month"].data.buckets) margin[l.key_as_string.substr(0,7)]=l.margin.value
                for (let l of dataInput["gmv_by_month"].data.buckets) gmv[l.key_as_string.substr(0,7)]=l.gmv.value

                let data = [
                    ["Month", "", { role: 'annotation' }],
                ];

               
                for (let l of  dataInput["margin_by_month"].data.buckets) {
                    let key = l.key_as_string.substr(0, 7)
                    let percentage = Math.round((margin[key]/gmv[key])*10000)/100
                    data.push([key, percentage,percentage])
                }

                const options = {
                    chart: {},
                    bar: { groupWidth: "90%" },
                    // legend: { position: "top", maxLines: 2 },
                };

                return {
                    render: <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />,
                    name: "% Margin par MOIS"
                }
            },
            "percentage_margin_by_day": (line,dataInput) => {
                if (!dataInput["margin_by_day"]) return null

                
                let margin = {}
                let gmv = {}
                for (let l of dataInput["margin_by_day"].data.buckets) margin[l.key_as_string.substr(0,10)]=l.margin.value
                for (let l of dataInput["gmv_by_day"].data.buckets) gmv[l.key_as_string.substr(0,10)]=l.gmv.value


                let data = [
                    ["Day", "", { role: 'annotation' }],
                ];

                for (let l of dataInput["gmv_by_day"].data.buckets) {
                    let key = l.key_as_string.substr(0, 10)
                    let percentage = Math.round((margin[key]/gmv[key])*10000)/100
                    data.push([key, percentage,percentage])
                }

                const options = {
                    chart: {},
                    bar: { groupWidth: "90%" },
                    // legend: { position: "top", maxLines: 2 },
                };

                return {
                    render: <Chart
                    
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />,
                    name: "% margin par JOUR"
                }
            },
        }






        return <div className={"view-transaction"}>
            <div className={"title-page"}> {"WOUAAHHHHH les metrics.... " + (this.state.dateUpdate ? new Date(this.state.dateUpdate).toISOString() : "")}</div>
            
            {
                <div>
                    <FormControl className={""}>
                        <InputLabel htmlFor="age-native-simple">{"Visualisation Day/Month"}</InputLabel>
                        <Select
                             native
                            value={this.state.modeVisu}
                            onChange={(ev)=>{
                                this.setState({modeVisu:ev.target.value})
                            }}
                            
                        >
                            <option value={"day"}>{"Day"}</option>
                            <option value={"month"}>{"Month"}</option>
                        </Select>
                    </FormControl>

                </div>
            }
            {Object.keys(list).map((key, index) => {
                let obj = this.state.data[key]
                const value = list[key](obj,this.state.data)
                if (key.indexOf(this.state.modeVisu)===-1) return null
                if (!value) return null

                return <Accordion expanded={this.state["expand_" + index]} key={index} className={"filtre-bandeau"}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        onClick={() => {
                            let obj_ = {}
                            obj_["expand_" + index] = !this.state["expand_" + index]
                            this.setState(obj_)
                        }}
                    >
                        <Typography className={""}>{value.name || key}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>

                        {value.render}
                    </AccordionDetails>
                </Accordion>
            })
            }




        </div>
    }
}


export default withRouter(Body);

