import { 
    SET_BOOKING,
    RESET_BOOKING
} from '../actions/types';

export const initialSearch = {
    bookingId:"",
    empty:true,
    contacts:[],

};

export default function(state = initialSearch, action) {
    switch (action.type) {
        case SET_BOOKING:        
        return {...state, ...action.payload };
        
        case RESET_BOOKING:
            return initialSearch;
        default:
        return state;
    }
}
