

import EditIcon from '@material-ui/icons/Edit';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PaymentIcon from '@material-ui/icons/Payment';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import "./FloatMenu.scss"
import SmsIcon from '@mui/icons-material/Sms';
import ChatIcon from '@mui/icons-material/Chat';
import AirplanemodeInactiveIcon from '@mui/icons-material/AirplanemodeInactive';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';


class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }

  }

  componentDidUpdate(prevProps) {
  }






  render() {
    return <div className={"menu-float"} >


      <div className={"subLine"} >
        {this.props.mailOnClick && <Fab color="primary" size="medium" onClick={this.props.mailOnClick}>
          <MailOutlineIcon />
        </Fab>}

        {this.props.smsOnClick && <Fab color="primary" size="medium" onClick={this.props.smsOnClick}>
          <SmsIcon />
        </Fab>}

        {this.props.taskOnClick && <Fab color="primary" size="medium" onClick={this.props.taskOnClick}>
          <NoteAddIcon />
        </Fab>}
        {this.props.cardBankOnClick && <Fab color="primary" size="medium" onClick={this.props.cardBankOnClick}>
          <PaymentIcon />
        </Fab>}
        {this.props.bookingCombigoClick && <Fab color="primary" size="medium" onClick={this.props.bookingCombigoClick}>
          <AirplaneTicketIcon />
        </Fab>}
      </div>

      <div className={"subLine"} >


      </div>




    </div>
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {
})(withRouter(Body));
