
import * as React from 'react';
import { connect } from 'react-redux';
import ModelTable from "./../Table/modelTable"
import './TableOptions.scss';
import moment from "moment";
import SelectHistory from "./../Table/select_history"
import checked_bag from "./../../assets/img/CHECKED_BAG.svg"
import hand_bag from "./../../assets/img/HAND_BAG.svg"
import small_bag from "./../../assets/img/SMALL_BAG.svg"


const columns = [
    {
        name: "",
        label: "",
        custom: {
        },
        options: {
            customBodyRender :  (value_, tableMeta, updateValue) =>{
               let value = tableMeta.rowData[1]
                if (value==="CHECKED_BAG")    return <img src={checked_bag} width="20px" height={"20px"}></img>
                if (value==="HAND_BAG")    return <img src={hand_bag} width="20px" height={"20px"}></img>
                if (value==="SMALL_BAG")    return <img src={small_bag} width="20px" height={"20px"}></img>

            },
        }
    },
    {
        name: "type",
        label: "Type",
        custom: {
            type: "list",
            props: {
                disableClearable: true,
                style: { maxWidth: "80px" }
            },
            list: [
                { id: "HAND_BAD", title: "HAND_BAD" },
                { id: "CHECKED_BAG", title: "CHECKED_BAG" },
                { id: "CABIN_BAG", title: "CABIN_BAG" },
                { id: "BAGGAGE", title: "BAGGAGE" },
                { id: "INSURANCE", title: "INSURANCE" },
                { id: "BAGGAGE_NO_RESTRICTION", title: "BAGGAGE_NO_RESTRICTION" },

            ],
            // format: function (obj) {
            //     return obj ? obj.title : ""
            // },
            // getOptionLabel: function (obj) {
            //     return (typeof obj == "object") ? obj.title || obj.id : ""
            // },
        },
        options: {
            sort: true
        }
    },
    {
        name: "status",
        label: "Status",
        custom: {
            type: "list",
            props: {
                disableClearable: true,
                style: { maxWidth: "80px" }
            },
            list: [
                { id: "PAID_EXTRA", title: "PAID_EXTRA" },
                { id: "INCLUDE", title: "INCLUDE" },
                { id: "PAID_IN_CART", title: "PAID_IN_CART" },
                { id: "PENDING", title: "PENDING" }
            ],
            // format: function (obj) {
            //     return obj ? obj.title : ""
            // },
            // getOptionLabel: function (obj) {
            //     return (typeof obj == "object") ? obj.title || obj.id : ""
            // },
        },
        options: {
            sort: true
        }
    },
    {
        name: "passengers",
        label: "Passengers",
        custom: {
            type: "list_obj",
            props: {
                disableClearable: true,
                style: { maxWidth: "80px" }
            },
            format: function (obj) {
                return obj ? obj.title : ""
            },
            getOptionLabel: function (obj) {
                return (typeof obj == "object") ? obj.title : ""
            },
            list: [],
        },
        options: {
            sort: true
        }
    },
    {
        name: "amountEUR",
        label: "Price EUR",
        custom: {
            type: "input",
            props: { style: { maxWidth: "80px" } }
        },
        options: {
            sort: false
        }
    },
    {
        name: "packageId",
        label: "packageId",
        custom: {
            type: "input",
            props: { style: { maxWidth: "80px" } }
        },
        options: {
            sort: false
        }
    },
    {
        name: "dimensions",
        label: "dimensions (long,larg,prof)",
        custom: {
            type: "input",
            props: { style: { maxWidth: "80px" } }
        },
        options: {
            sort: false
        }
    },
    {
        name: "weight",
        label: "weight kg",
        custom: {
            type: "input",
            props: { style: { maxWidth: "80px" } }
        },
        options: {
            sort: false
        }
    },
    {
        name: "sum",
        label: "Sommes des 3 côtes",
        custom: {
            type: "input",
            props: { style: { maxWidth: "80px" } }
        },
        options: {
            sort: false
        }
    },
    {
        name: "comment",
        label: "Commentaire",
        custom: {
            type: "input",
            props: { multiline: true }
        },
        options: {
            sort: false
        }
    },
    {
        name: "date",
        label: "Date",
        custom: {
            type: "date",
            props: { style: { maxWidth: "100px" } }
        },
        options: {
            sort: false
        }
    }
    ,
    {
        name: "files",
        label: "files",
        custom: {
            type: "files",
            props: {
                style: { maxWidth: "80px" }
            }
        },
        options: {
            sort: false
        }
    }
    , {
        name: "",
        label: "",
        custom: {
            // type:"action",
            onlyEdit: true,
            type: "action_delete",
        },
        options: {
            sort: false
        }
    }
];

const options = {
    filterType: 'checkbox',
    print: false,
    display: false,
    rowsPerPage: 20,
    expandableRows: false,
    selectableRows: 'none',
    selectableRowsHideCheckboxes: false,
    download: false,
    filter: false,
    search: false,
    viewColumns: false,
};



class Account extends React.Component {

    constructor(props) {

        super(props);
        this.state={
            refresh:false,
            changeModeView:Date.now()
        }
        this.numVersion = this.props.data.numVersion
        this.id = this.props.booking.bookingId
    }

    componentDidUpdate(){
        if (this.id!=this.props.booking.bookingId || this.numVersion != this.props.data.numVersion){
           
            if (this.id != this.props.booking.bookingId ){
                this.setState({changeModeView:Math.random()})
            }
            this.numVersion = this.props.data.numVersion
            this.id = this.props.booking.bookingId
            this.setState({refresh:true})

        }
    } 

    componentDidMount() {

    }



    render() {

        if (this.state.refresh){
            this.setState({refresh:false})
        }
        // columns[3].custom.list=[]
        if (this.props.booking.passengers) {
            columns[3].custom.list = []
            for (let passenger of this.props.booking.passengers.data) {
                columns[3].custom.list.push({
                    title: passenger.lastName + " " + passenger.firstName,
                    lastName: passenger.lastName,
                    id: passenger.id
                })
            }
            //console.log(columns,this.props.data)
            // for (let passenger of this.props.booking.passengers.data){
            //     columns[3].custom.list.push({title: passenger.lastName + " " + passenger.firstName ,id:passenger.id})
            // }
        }

        // if (this.props.data) {
        //     for (let l of this.props.data.data) {
        //         if (typeof l.status == "string") l.status = { id: l.status, title: l.status }
        //         if (typeof l.type == "string") l.type = { id: l.type, title: l.type }
        //     }
        // }


        
        let data = this.state.dataTemp || this.props.data


        return (this.state.refresh?null:<ModelTable
            className={"table-options"}
            name={"options"}
            readonly={this.props.readonly}
            disabledModification={this.props.disabledModification || this.state.disabledModification}
            changeModeView= {this.state.changeModeView}
            footerHistory={<SelectHistory
                style={{ minWidth: "100px" }}
                version={this.props.booking.bookingId}
                filter={{ relationId: "bookingId:" + this.props.booking.bookingId, event: "options" }}
                historyData={(data,error,flagReset) => {
                    if (flagReset){
                        return this.setState({dataTemp:null,disabledModification:false})
                    }
                    if (!data){
                        return this.setState({ disabledModification: true,dataTemp: [] })
                    }
                    if (data.rawData && typeof data.rawData==="string") data.rawData = JSON.parse(data.rawData)
                    if (data.rawData) this.setState({dataTemp:data.rawData.options,disabledModification:true}) 
                }}
            />}
            data={data}
            columns={columns}
            options={options}
            modelLineAdd={{ date: moment(), comment: "", type: "", amountEUR: "", status: "", dimensions: "long,larg,prof" }}
            onChange={this.props.onChange}
            searchDataHistory={this.props.searchDataHistory}
        >

        </ModelTable>
        )
    }
}




const mapStateToProps = (state) => {
    return { booking: state.booking, base: state.data };
};

export default connect(mapStateToProps, {})(Account);