
import * as React from 'react';
import { connect } from 'react-redux';
import { cloneObject, _fetch } from '../../helpers';

import './TableBill.scss';
// import { DataGrid } from '@material-ui/data-grid';
import Toolbar from "./Toolbar"
import TableMaterial from "./../Table/TableMaterial"
import moment from 'moment';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import IconButton from '@material-ui/core/IconButton';
import NewBill from "./newBill"
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';


class Account extends React.Component {

    constructor(props) {


        super(props);
        this.state = {
            data: [],
            selectedBill: { billReference: "" },
            version: "",
            listParentId:null,
            versionRefresh: Date.now(),
            openModal: false,//true,
            dateUpdate: 0
        }

    }

    componentDidUpdate(prevProps) {

     //   console.log("this.props.booking.account2",this.props)
        let filterString = JSON.stringify(this.props.filter || {}) + "-" + this.props.version
        let listParentId = this.props.account2 && this.props.account2.data ? this.props.account2.data.filter((line) => {
            return line.parentId ? false : true
        }).map((line, index) => {
            return {
                id: line._id,
                name: ((index + 1) + " " + line.category + " " + (line.comment || "")).trim().substr(0, 30)
            }
        }) : null

        if (JSON.stringify(listParentId)!=JSON.stringify(this.state.listParentId)){
            this.setState({ listParentId:listParentId})
        }

        if (this.state.filterStr != filterString || (this.props.version && prevProps.version != this.props.version)) {
         
            
            this.setState({ filterStr: filterString ,data:[],version:""})

        }

    }

    componentDidMount() {

    }

    _formatDate = (num) => {
        return new Date(num).toISOString().substr(0, 19) + "+00:00"
    }


    request = (body, endpoint, cb) => {
        _fetch(process.env.REACT_APP_URL + endpoint, {
            "timeout": 10000,
            "nbRetry": 1,
            "method": "post",
            "headers": {
            },
            "body": body
        })
            .then(function (response) {
                if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));

                cb(response, response.json())
            }.bind(this))
            .catch(function (e) {
                this.setState({ versionRefresh: Math.random() })
                cb({ status: -1 }, {})
                //  alert("request bill")
                // console.log("Error bill", e);
            }.bind(this));
    }

    _sendMail = (obj, cb = () => { }) => {

        this.request({
            ...obj,
        }, "/crm/bill/mail", cb)

    }


    _updateBill = (obj, cb = () => { }) => {


        this.request({
            document: obj,
            type: "document",
            user:this.props.nameUser,
            version: obj.version
        }, "/crm/bill/update", cb)



    }


    render() {

        // window.moment = moment
        /*
            Date Created
            Created by USER
            Status_COMBIGO : ACTIVE/INACTIVE/CANCEL
            Status PAYMENT (PAID/REFUND/REFUND_PARTIAL)
            SUBJECT : MODIFICATION_TICKET/NEW_TICKET/AJOUT OPTION/MODIFICATION
            Date LIMIT PAIEMENT
            STATUS_CUSTOMER:OPEN/NOT_OPEN
            DATE PAIEMENT : 
            MONTANT : 
            COMMENTAIRE : 
        */

        const columns = [
            {
                field: "", name: "", render: (obj) => {
                    //console.log(obj)
                    return <IconButton onClick={() => {
                        this.setState({ openModal: true, selectedBill: obj, newBill: false })
                    }}>
                        <FolderOpenIcon />
                    </IconButton>


                }
            },
            {
                field: "created", name: "created", render: (obj) => {
                    return moment(obj.createdBy.date).format("DD/MM/YY HH:mm") + " " + obj.createdBy.id
                }
            },

            {
                field: "status", name: "status", render: (obj) => {
                    return obj.status || ""
                }
            },
            {
                field: "statusPayment", name: "statusPayment", render: (obj) => {
                    return obj.statusPayment || ""
                }
            },
           
            {
                field: "type", name: "type", render: (obj) => {
                    return obj.typeBill || ""
                }
            },
            {
                field: "amount", name: "amount", render: (obj) => {
                    return obj.price.amount + " " + obj.price.currency
                }
            },
            {
                field: "numero", name: "billReference", render: (obj) => {
                    return obj.billReference
                }
            },

            {
                field: "dateLimit", name: "dateLimit", render: (obj) => {

                    return obj.dateLimitPayment && obj.dateLimitPayment.value ? moment(obj.dateLimitPayment.value).format("DD/MM/YY HH:mm") + " " + obj.dateLimitPayment.tz : ""
                    // return moment(this._formatDate((obj.dTimeReal || obj.dTime) * 1000)).utc().format("DD/MM/YY HH:mm")
                }
            },
            {
                field: "comment", name: "comment", render: (obj) => {
                    return obj.comment || ""
                }
            },
            {
                field: "bookingReference", name: "bookingReference", render: (obj) => {
                    return obj.bookingReference || ""
                }
            },
            
        ]



        return <div className={"container-table-bill"}>
            {this.props.activeToolbarFilter ? <Toolbar
                url={'/crm/bill/search'}
                hidden={this.props.hiddenToolbar}
                timeout={this.props.timeout != undefined ? this.props.timeout : 2 * 60000} //12000
                filter={this.props.filter}
                versionSearch={this.state.versionRefresh}
                version={this.state.filterStr}
                onUpdate={function (data, error) {
                    if (data) {
                        if (this.state.version != data.version) {
                            if (this.props.onData) {
                                this.props.onData(data.data)
                            }
                            this.setState({ data: data.data, version: data.version, dateUpdate: Date.now() })
                        }
                        else {
                            this.setState({ dateUpdate: Date.now() })
                        }
                    }

                }.bind(this)}

            /> : null}


            {this.props.mode === "booking" ? <Grid >
                <Grid >
                    <Button onClick={() => {

                        this.setState({
                            // openModal: true,
                            newBill: true,
                            selectedBill: {
                                bookingId: this.props.bookingId,
                                bookingReference: this.props.referenceBooking,
                                mail: this.props.mail,
                                locale:this.props.locale,
                                billReference: createNumBill(),
                                relationId: this.props.relationId
                            }
                        }, () => {
                            this.setState({ openModal: true })
                        })
                    }}
                        style={{ color: '#33c' }}
                        variant="outlined"
                    >{"Création d'une Facture"}</Button>
                </Grid>
            </Grid> : null}

            <NewBill
                open={this.state.openModal}
                data={this.state.selectedBill}
                newBill={this.state.newBill}
                onSave={(obj, cb) => {
                    this._updateBill(obj, cb)
                }}
                listParentId={this.state.listParentId}
                onClose={() => {
                    this.setState({ openModal: false, versionRefresh: Math.random(), selectedBill: {} })
                }}
                sendMail={(obj,cb)=>{
                    this._sendMail(obj, cb)

                }}
            />

            <TableMaterial
                className={"table-bill"}

                columns={columns}

                data={this.state.data}
            />

        </div>


    }
}


function createNumBill() {

    let date = new Date().toISOString()
    let array = date.split("T")
    let first = array[0].split("-")
    let second = array[1].split(":")
    return first[0].substr(2, 4) + "" + first[1] + "" + first[2] + "" + second[0] + "-" + String(Math.round(Math.random() * 10000000))
}



const mapStateToProps = (state) => {
    return { booking: state.booking, base: state.data, locale: state.user.locale, nameUser: state.user.name, };
};

export default connect(mapStateToProps, {})(Account);