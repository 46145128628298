import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import { withRouter } from 'react-router-dom';
import { MuiPickersUtilsProvider, DatePicker, DateTimePicker, KeyboardDatePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import DateTimeContainer from "./../Utils/DateTimeContainer"
import Slider from '@material-ui/core/Slider';
import "./Toolbar.scss"

import TextField from "@material-ui/core/TextField";

import { parseQuery, _fetch } from "./../../helpers"
import CheckBoxCombigo from "./../Utils/Select_CheckBox"
import IntervalleDate from "./../Utils/IntervalleDate"
import SelectCombigo from "./../Utils/Select"



const initialStateBody = {
    // startDate: "",
    // endDate: "",

    referenceBooking: "",
    statusBooking: ["CONFIRMED","PROCESSING","PROCESSING_AUTO","PROCESSING_ERROR",
    "PROCESSING_MANAGE","MODIFICATION","DONE","WAIT_CONFIRMED"],
    countries: [],
    trName: "",

    typeFilterDate: "CALENDAR",
    statusCombigo: [],
}



class Body extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            body: initialStateBody //getHistory() ||
        }

        this.lastRequest = Date.now()
        this.search = this.search.bind(this)
        this.onChangeBody = this.onChangeBody.bind(this)
        this.lastUpdateVersion = 0
        this.search(true, this.state.body)
        if (this.props.timeout) setInterval(function () {
            this.search(false, this.state.body)
        }.bind(this), this.props.timeout)
    }

    componentDidUpdate() {

        //this.urlSearch
        let filterString = JSON.stringify(this.props.filter)
        if (this.urlSearch != window.location.search || filterString != this.filter) {
            this.filter = filterString

            let o = parseQuery(window.location.search)
            if (o.bodyRequest) {
                // console.log(" o.bodyRequest", decodeURI(o.bodyRequest))
                // o.bodyRequest = decodeURI(o.bodyRequest)
                this.onChangeBody(JSON.parse(decodeURI(o.bodyRequest)))
            }
            else {
                this.onChangeBody({})

            }

        }
        // console.log(window.location)

    }

    async search(force, body = {}) {

        if (force || Date.now() - this.lastRequest > 0.8 * this.props.timeout) {
            this.lastRequest = Date.now()
            this.lastUpdateVersion++
            setTimeout(function (lastUpdateVersion) {
                if (this.lastUpdateVersion != lastUpdateVersion) return
                request.call(this, body, this.lastUpdateVersion)

            }.bind(this, this.lastUpdateVersion), 1000)

        }


        function request(body, lastUpdateVersion) {
            if (!this.props.filter) return
            let tz
            try {
                tz = Intl.DateTimeFormat().resolvedOptions().timeZone
            } catch (e) { }

            _fetch(process.env.REACT_APP_URL + this.props.url, {
                "timeout": 10000,
                "nbRetry": 1,
                "method": "post",
                "headers": {},
                "body": {
                    filter: {
                        ...this.props.filter,
                        ...body
                    },
                    aggregate:this.props.aggregate?true:undefined,
                    timezone: tz || "Europe/Paris",
                    mode: this.props.mode || "alltransactions"
                }
            })
                .then(function (response) {
                    if (this.lastUpdateVersion != lastUpdateVersion) return
                    if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                    let data = response.json()
                    if (this.props.onUpdate) this.props.onUpdate(data)
                }.bind(this))
                .catch(function (e) {
                    if (this.lastUpdateVersion != lastUpdateVersion) return

                    console.error("Error toolbar", e);
                    if (this.props.onUpdate) this.props.onUpdate(undefined, { error: true })

                }.bind(this));
        }


    }

    onChangeBody(obj) {
        let body = Object.assign({}, this.state.body)
        for (let prop in obj) body[prop] = obj[prop]

        this.setState({ body: body })
        if (this.props.onChange) this.props.onChange(body)
        this.search(true, body)
        // console.log("body", body, "bodyStr", this.bodyStr)
        this.bodyStr = JSON.stringify(body)
        // sessionStorage.setItem("toolbar-filter3", this.bodyStr)
        this.urlSearch = "?bodyRequest=" + encodeURI(this.bodyStr)

        this.props.history.push({
            pathname: window.location.pathname,
            //URLSearchParams
            search: this.urlSearch
        })
    }

    render() {


        return (
            <Grid container item xs={12} direction="row" className={"toolbar-container-calendar " + this.props.className} spacing={3}>

                {/* <Grid container item direction={"row"} xs={1} spacing={3} className={"block"} >
                        <TextField label="N° booking" variant="standard" value={this.state.body.referenceBooking} onChange={(ev) => {
                            this.onChangeBody({ referenceBooking: ev.target.value })
                        }} />
                    </Grid> */}

                <Grid container item direction={"row"} xs={2} sm={2} spacing={3} className={"block"}>
                    <TextField className={"input"} label="transporter" variant="standard" value={this.state.body.trName} onChange={(ev) => {
                        this.onChangeBody({ trName: ev.target.value })
                    }} />
                </Grid>

                <Grid container item direction={"row"} xs={2} sm={2} spacing={3} className={"block"}>
                    <CheckBoxCombigo
                        style={{ minWidth: "100%" }}
                        label={"Status Combigo"}
                        list={this.props.base.statusCombigo}
                        value={this.state.body.statusCombigo}
                        multiple={true}
                        chips={false}

                        onChange={(value) => {
                            this.onChangeBody({ statusCombigo: value })
                        }}
                    ></CheckBoxCombigo>
                </Grid>


                <Grid container item direction={"row"} xs={2} sm={2} spacing={3} className={"block"}>
                    <CheckBoxCombigo
                        className={"select-status"}
                        style={{ width: "100%" }}
                        label={"statusBooking"}
                        list={this.props.base.statusBooking}
                        multiple={true}
                        chips={false}
                        value={this.state.body.statusBooking}
                        onChange={(value) => {
                            this.onChangeBody({ statusBooking: value })
                        }}
                    ></CheckBoxCombigo>
                </Grid>
                <Grid container item direction={"row"} xs={2} sm={2} spacing={3} className={"block"}>
                    <CheckBoxCombigo
                        className={"select-status"}
                        style={{ width: "100%" }}
                        label={"Country"}
                        getLabel={(option) => { return option.label + "(" + option.code + ")" }}
                        getMatching={(option) => { return option.code }}
                        list={this.props.base.countries}
                        multiple={true}
                        chips={false}
                        value={this.state.body.countries}
                        onChange={(value) => {
                            this.onChangeBody({ countries: value })
                        }}
                    ></CheckBoxCombigo>
                </Grid>
                <div style={{ flexGrow: 1 }}> </div>


                {/* {this.props.aggregate?


                {this.props.aggregate?null: */}
                <Grid container item direction={"row"} xs={1} sm={1} spacing={3} className={"block"}>
                    <DateTimeContainer
                        DateObj={DatePicker}
                        label={"Date"}
                        autoOk={true}
                        value={this.props.dateSelection || ""}
                        format={"DD MMM YY"}
                        onChange={(newValue, h) => {
                            this.props.selectDate(newValue)
                        }}
                        invalidLabel=""
                        invalidDateMessage={null}
                        variant={"inline"}
                        ampm={false}
                        style={{}}
                        TextFieldComponent={(props) => {
                            // console.log(props)
                            return <TextField {...props} />
                        }}
                    />
                   
                </Grid>













            </Grid>
        );
    }

}


const mapStateToProps = (state) => {
    return { base: state.data };
};

export default connect(mapStateToProps, {})(withRouter(Body));