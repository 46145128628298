


import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        // backgroundColor: theme.palette.common.white,
        // color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: "0.75rem",
    },
}))(Tooltip);


class Body extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }



    render() {

        let value 
        if (this.props.title && typeof this.props.title == "string") {
            value = this.props.title
        }
        else if (this.props.title && this.props.title.length) {
            value = (<div style={{ display: "flex", flexDirection: "column" }}>
                { this.props.title.map(function (line,index) {
                    let str = this.props.getLabel ? this.props.getLabel(line) : line
                    return <span key={index}>{str}</span>
                }.bind(this))}
            </div>)
            // for (let line of){
            //     value+= n 
            // }
        }

        return (
            value ?
                <LightTooltip title={
                    <React.Fragment>
                         {value}
                                  </React.Fragment>
                }>
                    {this.props.children}
                </LightTooltip > : this.props.children
        )
    }

}


export default Body

