import React, { Component, version } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Delete from '@material-ui/icons/Delete';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import Drawer from '@material-ui/core/Drawer';
import Edit from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import CheckBoxCombigo from "./../Utils/Select_CheckBox"
import Grid from '@material-ui/core/Grid';
import TooltipCombigo from "./../Utils/TooltipCombigo"
import moment from "moment"
import { parseQuery, _fetch } from "./../../helpers"
import TableBooking from './TableBooking.js'
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import './TableBooking.scss';


class Account extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            bookingRaw: null,
            data: null,
            website: null,
            ticketId: ""
        }

        this.add = this.add.bind(this)
        this.update = this.update.bind(this)
        this.priceOptions = this.priceOptions.bind(this)
        this.getPassengerInformation = this.getPassengerInformation.bind(this)
    }

    componentDidUpdate(prevProps) {

        if ((prevProps.booking && prevProps.booking.bookingId != this.props.booking.bookingId) || (prevProps.version && prevProps.version != this.props.version)) {
            this.setState({ticketId:null})
            this.update()
        }
    }

    add() {
        // console.log(this.props.base.carrier)
        if (this.props.base.carrier && this.props.base.carrier.length != 0 && !this.state.website) {
            let website = {}
            for (let line of this.props.base.carrier) {
                let obj = { name: line.name, _infos: line._infos }
                if (line.infos && line.infos.website) {
                    if (line.infos.website.indexOf("http") == -1) {
                        line.infos.website = "https://" + line.infos.website
                    }
                    obj.website = line.infos.website
                }
                website[line.idSya] = obj
            }

            this.setState({ website: website })
            return
        }

    }

    update() {
        this.setState({ bookingRaw: null, data: null,isRunning:true ,errorMessage:null})

        if (!this.state.website) {
            return setTimeout(function () {
                this.update()
            }.bind(this), 500)
        }
        let query = ""
        if (this.state.ticketId) {
            query = "ticketId=" + this.state.ticketId
        }
        else {
            query = "bookingId=" + this.props.booking.bookingId
        }

        _fetch(process.env.REACT_APP_URL + '/crm/booking_decomposition?' + query, {
            "timeout": 10000,
            "nbRetry": 2,
            "method": "get",
            "headers": {}
        })
            .then(function (response) {
                // if (response.status === 204) {
                //     return this.setState({ bookingRaw: null, data: null,isRunning:false })
                // }
                if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                let data = response.json()
                if (!data) {
                    return this.setState({ bookingRaw: null, data: null ,isRunning:false})
                }
                this.setState({ bookingRaw: data, data: convertBooking.call(this, data, this.state.website,this.props.booking.passengers.data) ,isRunning:false})
            }.bind(this))
            .catch(function (e) {
                this.setState({ bookingRaw: null, data: null,isRunning:false ,errorMessage:"Reload the application, if the problem persist contact the admin"})
                console.log("Error bookinghelp", e)
            }.bind(this));
    }
    
    componentDidMount() {

        setTimeout(function () {
            this.add()
        }.bind(this), 2000)
        this.update()




    }


    getPassengerInformation(dTime) {
        if (!dTime) dTime = new Date().toISOString().substr(0, 19)
        let labels = {}
        for (let line of this.props.booking.passengers.data) {
            let type = "ADULT"

            let age = Math.abs(moment(dTime + "+00:00").diff(line.birthDate + "T00:00:00+00:00", 'years'))

            if (age < 2) type = "BABY"
            else if (age < 12) type = "CHILDREN"
            if (!labels[type]) labels[type] = 0
            labels[type]++
        }
        let label = []
        // if (price){
        for (let key in labels) {
            label.push(labels[key] + " x " + key)
        }
        // }

        return { label: label.join(", ") }
    }

    priceOptions() {
        let price = 0
        let packageId = {}
        let labels = {}
        let list = {}
        for (let line of this.props.booking.options.data) {
            if (!labels[line.type]) labels[line.type] = 0
            labels[line.type]++

            if (packageId[line.packageId]) {
                continue;
            }
            packageId[line.packageId] = true
            price += line.amountEUR
            if (!list[line.passengers.id]) list[line.passengers.id] = { name: line.passengers.title, amountEUR: 0, labels: {} }
            list[line.passengers.id].amountEUR += line.amountEUR
            if (!list[line.passengers.id].labels[line.type]) list[line.passengers.id].labels[line.type] = 0
            list[line.passengers.id].labels[line.type]++
        }
        let label = []
        // if (price){
        for (let key in labels) {
            label.push(labels[key] + " x " + key)
        }
        // }
        let detailOptions = []
        for (let key in list) {
            let labelOption = []
            for (let key2 in list[key].labels) {
                labelOption.push(list[key].labels[key2] + " x " + key2)
            }
            list[key].options = labelOption
            detailOptions.push(list[key])
        }


        return { price: price, label: label.join(", "), details: detailOptions }
    }


    advice(trId, distance, price) {


    }




    render() {
        if (this.props.statusBooking==="PROCESSING_MANAGE"){
            return "DISABLED => Status = Processing Manage"
        }
        if (!this.state.website) return null
        let columns
        if (this.state.bookingRaw) {
            columns = this.state.bookingRaw.ticket.legs_rT && this.state.bookingRaw.ticket.legs_rT.length ? [
                { title: "Trajet Aller", field: "departureTrip" },

                {
                    title: "Date Aller", field: "departureDate", render: (rowData) => {
                        return <div dangerouslySetInnerHTML={{ __html: rowData.departureDate }} />
                    }
                },

                // { title: "Details Aller", field: "departureDetail" },

                {
                    title: "Date Retour", field: "returnDate", render: (rowData) => {
                        if (!rowData.returnDate) return null
                        return <div dangerouslySetInnerHTML={{ __html: rowData.returnDate }} />
                    }
                },
                { title: "Trajet Retour", field: "returnTrip" },
                { title: "Prix €", field: "price", type: "numeric" },

                // { title: "Details Retour", field: "returnDetail" },
                {
                    title: "redirection", field: "link", render: (rowData) => {
                        return <div>
                            {rowData.link.map((o) => {
                                if (!o || !o.link) return null
                                return <a href={o.link} target="_blank">{" " + o.provider}</a>
                            })}
                        </div>
                    }
                },
                {
                    title: "Transporter", field: "carriers", render: (rowData) => {


                        if (!rowData.carriers) return null
                        if (!this.state.website) return null
                        return rowData.carriers.map((line) => {

                            let o = this.state.website[line] || { name: line }
                            return o && o.website ? <a style={{ marginRight: "5px" }} href={o.website} target="_blank">{o.name}</a> : o.name
                        })

                    }
                },
                // { title: "Provider", field: "provider" },
                // { title: "Dispo", field: "available" },
                // { title: "alliance", field: "alliance" },
                // {
                //     title: "Astuce", field: "productByCombigo", render: (rowData) => {
                //         if (!rowData.returnFictive || rowData.returnFictive.length == 0) return rowData.productByCombigo || null
                //         return <div>
                //             {rowData.returnFictive.map((o) => {
                //                 return <div style={{ marginLeft: "4px" }}>
                //                     {o.label + " "}
                //                     {o.links.map((l) => {
                //                         return <a href={l.link} target="_blank">{l.provider + " "}</a>
                //                     })}
                //                 </div>

                //             })}
                //         </div>
                //     }
                // },


            ] :
                [
                    { title: "Trajet Aller", field: "departureTrip" },

                    {
                        title: "Date Aller", field: "departureDate", render: (rowData) => {
                            return <div dangerouslySetInnerHTML={{ __html: rowData.departureDate }} />
                        }
                    },
                    { title: "Prix €", field: "price", type: "numeric" },

                    {
                        title: "redirection", field: "link", render: (rowData) => {

                            return <div>
                                {rowData.link.map((o) => {
                                    if (!o.link) return null

                                    return <a href={o.link} target="_blank">{" " + o.provider}</a>
                                })}
                            </div>
                        }
                    },

                    // { title: "Details Aller", field: "departureDetail" },
                    // { title: "Provider", field: "provider" },
                    {
                        title: "Transporter", field: "carriers", render: (rowData) => {
                            if (!rowData.carriers) return null
                            if (!this.state.website) return null
                            return rowData.carriers.map((line) => {

                                let o = this.state.website[line] || { name: line }
                                return o && o.website ? <a style={{ marginRight: "5px" }} href={o.website} target="_blank">{o.name}</a> : o.name
                            })

                        }
                    },

                    // // { title: "Dispo", field: "available" },
                    // {
                    //     title: "Astuce", field: "productByCombigo", render: (rowData) => {
                    //         if (!rowData.returnFictive || rowData.returnFictive.length == 0) return rowData.productByCombigo || null
                    //         return <div>
                    //             {rowData.returnFictive.map((o) => {
                    //                 return <div style={{ marginLeft: "4px" }}>
                    //                     {o.label + " "}
                    //                     {o.links.map((l) => {
                    //                         return <a href={l.link} target="_blank">{l.provider + " "}</a>
                    //                     })}
                    //                 </div>

                    //             })}
                    //         </div>
                    //     }
                    // },

                    // { title: "alliance", field: "alliance" },


                ]
        }


        let amountBundle = 0
        for (let row of this.props.booking.account2.data) {
            if (row.type === "BUNDLE") {
                amountBundle += row.amountEUR
            }
        }

        let options = this.priceOptions()

        let passengers = this.getPassengerInformation(this.state.bookingRaw ? this.state.bookingRaw.ticket.legs[0].dTime : undefined)
        // console.log(this.state.bookingRaw,this.state.data)
        return (
            <>
                <div style={{ display: "flex", flexDirection: "column", overflow: "auto" }} className={"au-secours-booking-container"}>

                    <div className='warning-airline'>
                        {"ATTENTION : Copa Airlines, Avianca, Iberia, Air Canada, VivaAir, VivaAerobus, ethiopian airlines, LATAM, SunExpress, JetBlue, LionAir, Virgin Atlantic, Scoot Airlines, AirFrance"}
                    </div>

                    <div className='table-au-secours'>
                        <div className='line blue'>
                            <div className='col col-header bold'>
                                {"Ticket Price"}
                                <div className='subline-passenger'>
                                    {passengers.label}
                                </div>
                            </div>
                            <div className='col bold big-size'>
                                {(this.props.booking.summary.data.amountEUR - amountBundle) + " €"}
                                <div className='subline-bag'>
                                    <ul>
                                        {options.details.map((l) => {
                                            return <li><b>{l.name} {" : "}</b> {l.options.join(", ")} {"  "} {l.amountEUR} {" €"} </li>


                                        })}
                                    </ul>
                                    {/* { "avec " + options.label + (options.price ? " => payé " + options.price + " " + " €" : " => inclus") } */}
                                </div>
                            </div>
                        </div>

                        <div className='line'>
                             <div className='col col-header'>
                                {"Price Tarif " + (this.props.booking.bundle?this.props.booking.bundle.data.id:"BASIC")}
                            </div> 
                            <div className='col '>
                                {amountBundle + " €"}
                            </div>
                        </div>
                        <div className='information'>
                            {"Total Price Cart = " + this.props.booking.summary.data.amountEUR + " €"}
                        </div>
                    </div>
                    <div className='search'>
                        <TextField
                            style={{width:"100%",margin:"8px"}}
                            id="ticket"
                            label="Book another Ticket for the same passengers with TicketId"
                            variant="standard"
                            placeholder={"Booking Original"}
                            value={this.state.ticketId}
                            onChange={(ev) => {

                                let ticketId
                                if (ev.target.value && ev.target.value.indexOf("https://") != -1) {
                                    const url = new URL(ev.target.value );
                                    ticketId = url.searchParams.get('ticket_id')
                                }
                                ticketId = ticketId || ev.target.value


                                this.setState({ ticketId: ticketId })
                                setTimeout(() => {
                                    if (ev.target.value === ticketId) {
                                        this.update()
                                    }
                                }, 500)
                            }}
                        />

                    </div>

                    {
                        this.state.isRunning ? <CircularProgress />  : (
                            this.state.errorMessage?
                            <Alert severity="error">{this.state.errorMessage}</Alert>
                          :
                            this.state.data && this.state.data.length ? this.state.data.map((obj, index) => {
                                if (index > 3) return null
                                return <div key={index} style={{ display: "flex", "flexDirection": "column", "marginBottom": "50px" }}>
                                    <>

                                        <div style={{ width: "100%" }}>


                                            <TableBooking
                                                key={index}
                                                index={index + 1}
                                                bookingId={this.props.booking.bookingId}
                                                passengers={this.props.booking.passengers.data}
                                                columns={columns}
                                                data={obj.table}
                                            />




                                        </div>
                                    </>

                                </div>
                            }) : <Alert severity="info">{"NO DATA!!!"}</Alert>)

                    }
                </div>

            </>
        )
    }
}




const mapStateToProps = (state) => {
    return { booking: state.booking, base: state.data };
};

export default connect(mapStateToProps, {})(Account);


function convertBooking(bookingRaw, website, passengers) {
    if (!bookingRaw.ticket) return []
    if (!bookingRaw.redirectUpdated) {
        if (!bookingRaw._private) bookingRaw._private = {}
        if (!bookingRaw.ticket) bookingRaw.ticket = {}

        bookingRaw.redirectUpdated = bookingRaw.ticket._proposition || bookingRaw._private._proposition || []
    }
    if (!bookingRaw.redirectUpdated) return []
    let legs = {}
    for (let leg of bookingRaw.ticket.legs) {
        legs[leg.id] = leg
    }
    if (bookingRaw.ticket.legs_rT) {
        for (let leg of bookingRaw.ticket.legs_rT) {
            legs[leg.id] = leg
        }
    }

    addRedirectUpdated(bookingRaw.redirectUpdated, bookingRaw.ticket)


    //  console.log("bookingRaw.redirectUpdated=",bookingRaw.redirectUpdated)
    let data = []
    let nb = 0
    for (let line of bookingRaw.redirectUpdated) {
        //console.log("line",line)
        let info = {
            _id: line.id,
            carriers: {},
            nbTickets: line.nbTickets,
            checked_bag: line.checked_bag,
            productByCombigo: line.productByCombigo,
            price: line._secret ? Math.round(line._secret.price) : "",
            table: []
        }

        for (let book of line.book) {
            // console.log("book",book)
            // console.log("book", book)
            let infos = decodeBookId(book.id)
            let carriers = {}
            let ticket = { legs: [], legs_rT: [], book: [book] }
            if (infos.legs) {
                for (let id of infos.legs) {
                    if (legs[id].opId) carriers[legs[id].opId] = true

                    if (legs[id].trId) carriers[legs[id].trId] = true
                    else if (legs[id].trName) carriers[legs[id].trName] = true

                    ticket.legs.push(legs[id])
                }
            }
            if (infos.legs_rT) {
                for (let id of infos.legs_rT) {
                    if (legs[id].trId) carriers[legs[id].trId] = true
                    else if (legs[id].trName) carriers[legs[id].trName] = true
                    if (legs[id].opId) carriers[legs[id].opId] = true
                    ticket.legs_rT.push(legs[id])
                }
            }

            if (ticket.legs.length == 0 && ticket.legs_rT && ticket.legs_rT.length) {
                ticket.legs = ticket.legs_rT
                ticket.legs_rT = undefined //[]
            }
            if (ticket.legs_rT && ticket.legs_rT.length == 0) ticket.legs_rT = undefined

            let end = ticket.legs.length - 1
            let end_rT = ticket.legs_rT ? ticket.legs_rT.length - 1 : undefined
            let one = {
                _id: bookingRaw._id + "," + line.id + "," + book.id,
                available: book.available || book.av ? "OK" : "?",
                options: [],
                link: [],
                provider: {},
                price: "",
                nbTicket: "",
                alliance: book.alliance ? (typeof book.alliance == "string" ? [book.alliance] : book.alliance).join(",") : "",
                departureDate: (ticket.legs && ticket.legs[0] ? cleanDate(ticket.legs[0].dTime) : "") + " - " + (ticket.legs && ticket.legs[0] ? cleanDateArrival(ticket.legs[ticket.legs.length - 1].aTime, ticket.legs[0].dTime) : "") + " (" + (ticket.legs.length - 1) + ")",

                departureTrip: ticket.legs[0] ?
                    (ticket.legs[0].fromCityHub || ticket.legs[0].fromCity)
                    + (ticket.legs[0].fromIATA ? " (" + ticket.legs[0].fromIATA + ")" : "")
                    + "->"
                    + (ticket.legs[end].toCityHub || ticket.legs[end].toCity)
                    + (ticket.legs[end].toIATA ? " (" + ticket.legs[end].toIATA + ")" : "")
                    : "",
                returnTrip: ticket.legs_rT && ticket.legs_rT[0] ?
                    (ticket.legs_rT[0].fromCityHub || ticket.legs_rT[0].fromCity)
                    + (ticket.legs_rT[0].fromIATA ? " (" + ticket.legs_rT[0].fromIATA + ")" : "")
                    + "->"
                    + (ticket.legs_rT[end_rT].toCityHub || ticket.legs_rT[end_rT].toCity)
                    + (ticket.legs_rT[end_rT].toIATA ? " (" + ticket.legs_rT[end_rT].toIATA + ")" : "")
                    : "",
                returnDate: ticket.legs_rT && ticket.legs_rT[0] ?
                    (ticket.legs_rT && ticket.legs_rT[0] ? cleanDate(ticket.legs_rT[0].dTime) : "") + " - " + (ticket.legs_rT && ticket.legs_rT[0] ? cleanDateArrival(ticket.legs_rT[ticket.legs_rT.length - 1].aTime, ticket.legs_rT[0].dTime) : "") + " (" + (ticket.legs_rT.length - 1) + ")" : "",

                carriers: Object.keys(carriers),
                productByCombigo: "",
                returnFictive: []

            }


            let optionsComment = []
            let alreadyDone = {}
            if (book.options) {
                book.options.sort((a, b) => {
                    if (!a.price || !b.price) return 1
                    return a.price.amount - b.price.amount
                })
                for (let option of book.options) {
                    if (!one.price) {
                        one.price = option.price ? Math.round(option.price.amount) : Math.round(option.pi.am)
                    }
                    if (!one.provider) {
                        one.provider[option.provider] = true
                    }
                    if (option.link && option.provider) {
                        if (option.provider.indexOf("blablabus") === -1) {
                            one.link.push({ link: option.link, provider: option.provider })
                        }
                        //
                        // one.provider = option.provider
                    }
                    else if (option.booking && option.booking.link && option.provider) {
                        if (option.provider.indexOf("blablabus") === -1) {
                            one.link.push({ link: option.booking.link, provider: option.provider })
                        }
                        // 
                        // one.provider = option.provider
                    }
                    else if (passengers) {
                        one.link.push({
                            link: createLinkSkyscanner("skyscanner", ticket,
                                passengers, bookingRaw.search),
                            provider: "skyscanner"
                        })
                        one.link.push({
                            link: createLinkSkyscanner("google", ticket,
                                passengers, bookingRaw.search), provider: "gflight"
                        })


                    }




                    if (option.productByCombigo && option.productByCombigo.indexOf("rTf") != -1) {
                        one.productByCombigo = "RETURN_FICT"
                    }
                    if (option.productByCombigo && option.productByCombigo.indexOf("mDf") != -1) {
                        one.productByCombigo = "MULTIDEST_FICT"
                    }
                    let flagRetour = false

                    if (!flagRetour && option.comment) {
                        if (alreadyDone[option.comment]) continue;
                        alreadyDone[option.comment] = true
                        optionsComment.push(option.comment + " " + option.price.amount + " €")
                    }
                }
            }

            let uniqueLink = new Set()
            one.link = one.link.filter((o) => {
                if (!o.link) return false
                if (uniqueLink.has(o.provider)) return false
                uniqueLink.add(o.provider)
                return true
            })
            // if (!one.link) one.link = createLinkSkyscanner(ticket, bookingRaw.cart.passengers, bookingRaw.search)
            one.provider = Object.keys(one.provider).join(",")


            one.price = one.price + " €"

            one.batch = [
                {
                    id: line.id + "," + book.id,
                    bookingId: bookingRaw._id,
                    ticket: ticket,
                    // price:{
                    //     amount:one.price ,
                    //     currency:"EUR"
                    // }
                }
            ]


            info.table.push(one)
        }



        data.push(info)
    }

    return data


    function addRedirectUpdated(redirectUpdated, ticket) {
        // console.log("ticket",ticket,"redirectUpdated",redirectUpdated)
        let nb = (ticket.legs && ticket.legs.length ? ticket.legs.length : 0) + (ticket.legs_rT && ticket.legs_rT.length ? ticket.legs_rT.length : 0)
        for (let line of redirectUpdated) {
            if (line.book.length === nb) return true
        }

        let book = []
        let ids = []
        for (let prop of ["legs", "legs_rT"]) {
            if (!ticket[prop]) continue
            for (let leg of ticket[prop]) {
                ids.push(leg.id)
                book.push({
                    id: leg.id,
                    available: true,
                    options: []
                })
            }
        }
        redirectUpdated.push({ book: book, id: ids.join(",") })


    }

    function cleanDateArrival(time, timeDeparture) {
        return cleanDate(time)
        let dateStr = time.substr(0, 16).split("T")
        let dateStrArrival = timeDeparture.substr(0, 16).split("T")
        if (dateStrArrival[0] == dateStr[0]) {
            return " <b>" + dateStr[1] + "</b>"
        }
        let arr = dateStrArrival[0].split("-")
        let beautyDate = arr[2] + "/" + arr[1] //moment(time).format('ll') 
        return " <b>" + dateStr[1] + "</b>" + "<sup style='font-size:12px'>" + beautyDate + "</sup>"
        return time.substr(0, 16).replace("T", " ")
    }


    function cleanBookId(id = "") {
        while (id.indexOf("[") !== -1) id = id.replace(/ *\[[^\]]*]/, '');
        //if (flagDeleteConditions) while (id.indexOf("(") !== -1) id = id.replace(/ \([^()]*\)/, '');
        return id
    }



    function decodeBookId(id) {

        let array = cleanBookId(id).split("|")
        let legs = array[0].split("+").filter(function (obj) { return obj ? true : false })
        let legs_rT = array[1] ? array[1].split("+").filter(function (obj) { return obj ? true : false }) : undefined


        return { legs: legs, legs_rT: legs_rT }

    }

    function cleanDate(time) {
        let dateStr = time.substr(0, 16).split("T")
        return moment(time).format('ll') + " <b>" + dateStr[1] + "</b>"
        return time.substr(0, 16).replace("T", " ")
    }
}




function createLinkSkyscanner(site, ticket, passengers = [], search, dTimeRT_fictive) {


    let dTime = ticket.legs && ticket.legs[0] ? ticket.legs[0].dTime : undefined
    let dTimeRT = ticket.legs_rT && ticket.legs_rT[0] ? ticket.legs_rT[0].dTime : undefined

    let from = cIata(dTime ? ticket.legs[0].fromIATA : (dTimeRT ? ticket.legs_rT[0].fromIATA : ""))
    let to = cIata(dTime ? ticket.legs[ticket.legs.length - 1].toIATA : (dTimeRT ? ticket.legs_rT[ticket.legs_rT.length - 1].toIATA : ""))


    // nombre adulte
    //children=0&childrenv2=9
    // departure-times=150-810&duration=1890
    let infoPassager = createPassenger(passengers, dTimeRT || dTimeRT_fictive || dTime)
    if (site === "skyscanner") {
        if (!from || !to) return ""

        const classConversion = {
            "ECO": "economy",
            "ECO_PREMIUM": "premiumeconomy",
            "BUSINESS": "business",
            "FIRST_CLASS": "first"

        }

        return "https://www.skyscanner.fr/transport/vols/" + from + "/" + to +
            "/" + cDate(dTime || dTimeRT) + "/" + ((dTimeRT || dTimeRT_fictive) && dTime ? cDate(dTimeRT || dTimeRT_fictive) + "/" : "") +
            "?" + infoPassager + "&cabinclass=" + classConversion[getClass(search)] + "&inboundaltsenabled=false" +
            "&departure-times=" + getTimes(dTime, dTimeRT) + "&infants=0&outboundaltsenabled=false&preferdirects=false&preferflexible=false&ref=home&rtn=0"
    }
    else if (site === "google") {

        const classConversion = {
            "ECO": "economy",
            "ECO_PREMIUM": "economy",
            "BUSINESS": "business",
            "FIRST_CLASS": "first"

        }

        from = (ticket.legs && ticket.legs[0] ? ticket.legs[0].fromCityHub || ticket.legs[0].fromCity || ticket.legs[0].fromIATA : "") || (ticket.legs_rT ? ticket.legs_rT[0].fromCityHub || ticket.legs_rT[0].fromCity || ticket.legs_rT[0].fromIATA : "")

        to = (ticket.legs && ticket.legs[ticket.legs.length - 1] ? ticket.legs[ticket.legs.length - 1].toCityHub || ticket.legs[ticket.legs.length - 1].toCity || ticket.legs[ticket.legs.length - 1].toIATA : "")
            || (ticket.legs_rT ? ticket.legs_rT[ticket.legs_rT.length - 1].toCityHub || ticket.legs_rT[ticket.legs_rT.length - 1].toCity || ticket.legs_rT[ticket.legs_rT.length - 1].toIATA : "")

        let passenger_string = getPassengerG(passengers, dTimeRT || dTimeRT_fictive || dTime)


        return "https://www.google.com/travel/flights?q=Flights to=" + to + " from=" + from + " on=" + cDateG(dTime || dTimeRT) + ((dTimeRT || dTimeRT_fictive) && dTime ? " return=" + cDateG(dTimeRT || dTimeRT_fictive) : " oneway") + " " + passenger_string + " " + classConversion[getClass(search)]
        //         " return=14/08/2022"
    }




    function getTimes(dTime, dTimeRT, dTimeRT_fictive) {



        return gethour(dTime) + (dTimeRT ? "," + gethour(dTimeRT) : "") + (dTimeRT_fictive ? "," + "0-1440" : "")


        function gethour(dTimeString) {
            let dTime = typeof dTimeString == "string" ? new Date(dTimeString + "+00:00").getTime() / 1000 : dTimeString
            let t = new Date(dTime * 1000).getUTCHours() * 60 + new Date(dTime * 1000).getUTCMinutes()
            return Math.max(0, t - 10) + "-" + Math.min(1440, t + 10)
        }
    }
    function getClass(search) {

        try {
            return search.options.class.PLANE || "ECO"
        } catch (e) {

        }
    }

    function getPassengerG(passengers, dTime) {
        if (passengers.length == 0) return ""
        let category = { "26_59": "adult", "12_17": "adult", "18_25": "adult", "3_11": "children", "0_2": "infant", "+60": "adult" }
        let count = { adult: 0, infant: 0, children: 0 }
        for (let passenger of passengers) {
            passenger.age = Math.abs(moment(dTime + "+00:00").diff(passenger.birthDate + "T00:00:00+00:00", 'years'))

            if (passenger.age && passenger.age >= 12) {
                count["adult"]++
            }
            else if (passenger.age && passenger.age >= 2) {
                count["children"]++
            }
            else if (passenger.age) {
                count["infant"]++
            }
            else if (passenger.category && category[passenger.category]) {
                count[category[passenger.category]]++
            }
        }
        return (count["adult"] ? count["adult"] + " adult" : "") + " " + (count["infant"] ? count["infant"] + " infant" : "") + " " + (count["children"] ? count["children"] + " children" : "")
    }

    function createPassenger(passengers, dTime) {
        if (passengers.length == 0) {
            return "adults=1&adultsv2=1&children=0"
        }
        let children = []
        let nbAdult = 0
        for (let passenger of passengers) {
            let age = Math.abs(moment(dTime + "+00:00").diff(passenger.birthDate + "T00:00:00+00:00", 'years'))
            // console.log(age, passenger,"dTime="+dTime,'moment(dTime + "+00:00") ',moment(dTime + "+00:00"))
            if (age <= 15) {
                children.push(age)
            }
            else {
                nbAdult++
            }
        }


        return "adults=" + nbAdult + "&adultsv2=" + nbAdult + "&children=" + children.length + "" + (children.length ? "&childrenv2=" + children.join("|") : "")
    }

    function cIata(str = "") {
        if (str.length > 3) return ""
        return str.toLowerCase()
    }

    function cDateG(str) {
        let arr = str.substr(0, 10).split("-")
        return arr[2] + '/' + arr[1] + "/" + arr[0]
    }
    function cDate(str) {
        return str.substr(2, 8).split("-").join("")
    }
}