import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import './BookingTool.scss';
import {isMobile} from "../helpers"


class Body extends Component {
    constructor(props) {
        super(props);
       
    }

    render() {

        return (
            <div className={this.props.className}>
              
                <div id="body" className={"body"}>
                    
                </div>
            </div>)
    }
}



const mapStateToProps = (state) => {
    return { booking: state.booking, locale: state.user.locale, nameUser: state.user.name };
  };
  
  
  export default connect(mapStateToProps, {
    
  })(withRouter(Body));
  
  

