import React, { Component, version } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { parseQuery, _fetch } from "./../helpers"
import "./AllTransaction.scss"

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { CombigoSnackbar } from './../Components/Snackbar/Snackbar';
import { Chart } from "react-google-charts";
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import { Helmet } from "react-helmet";






class Body extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            version: "init",
            modeVisu: "day",
            countVersion: 0,
            expand_0: true,
            expand_1: true,
            expand_2: true,
            expand_3: true,
            expand_4: true,
            expand_5: true,
            expand_6: true,
            expand_7: true,
            expand_8: true,
            expand_9: true,
        }
    }


    initData = () => {
        this.request(undefined, (data) => {
            if (data) {
                this.setState({ data: data.data, dateUpdate: Date.now() })
            }
        })
    }

    componentDidMount() {
        this.initData()
    }

    componentDidUpdate(prevProps) {




    }


    request = (body, cb) => {
        _fetch(process.env.REACT_APP_URL + "/crm/metrics/main_trustpilot", {
            "timeout": 10000,
            "nbRetry": 1,
            "method": "post",
            "headers": {},
            "body": {

            }
        })
            .then(function (response) {
                if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                let data = response.json()
                cb(data)
            }.bind(this))
            .catch(function (e) {
                cb(undefined, e)

                console.error("Error metrics", e);

            }.bind(this));
    }



    render() {
        if (!this.state.data) return null

      

        const list = {
            "note_by_month": (line) => {

                let data = [
                    ["Month", "", { role: 'annotation' }],
                ];

                for (let l of line.data.buckets) {
                    data.push([l.key_as_string.substr(0, 7), l.note.value, l.note.value])
                }

                const options = {
                    chart: {},
                    bar: { groupWidth: "70%" },
                    // legend: { position: "top", maxLines: 2 },
                };

                return {
                    render: <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />,
                    name: "Note par MOIS"
                }
            },
            "note_by_day": (line) => {

                let data = [
                    ["Day", "", { role: 'annotation' }],
                ];

                for (let l of line.data.buckets) {
                    data.push([l.key_as_string.substr(0, 10), l.note.value, l.note.value])
                }

                const options = {
                    chart: {},
                    bar: { groupWidth: "70%" },
                    // legend: { position: "top", maxLines: 2 },
                };

                return {
                    render: <Chart

                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />,
                    name: "Note par JOUR"
                }
            },
            "quantity_by_month": (line) => {

                let data = [
                    ["Month", "", { role: 'annotation' }],
                ];

                for (let l of line.data.buckets) {
                    data.push([l.key_as_string.substr(0, 7), l.note.value, l.note.value])
                }

                const options = {
                    chart: {},
                    bar: { groupWidth: "70%" },
                    // legend: { position: "top", maxLines: 2 },
                };

                return {
                    render: <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />,
                    name: "Nombre Avis par MOIS"
                }
            },
            "quantity_by_day": (line) => {

                let data = [
                    ["Day", "", { role: 'annotation' }],
                ];

                for (let l of line.data.buckets) {
                    data.push([l.key_as_string.substr(0, 10), l.note.value, l.note.value])
                }

                const options = {
                    chart: {},
                    bar: { groupWidth: "70%" },
                    // legend: { position: "top", maxLines: 2 },
                };

                return {
                    render: <Chart

                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />,
                    name: "Nombre Avis par JOUR"
                }
            },
            "notegood_by_month": (line) => {

                let data = [
                    ["Month", "", { role: 'annotation' }],
                ];

                for (let l of line.data.buckets) {
                    data.push([l.key_as_string.substr(0, 7), l.note.value, l.note.value])
                }

                const options = {
                    chart: {},
                    bar: { groupWidth: "70%" },
                    // legend: { position: "top", maxLines: 2 },
                };

                return {
                    render: <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />,
                    name: "Nombre Avis >4 par MOIS"
                }
            },
            "notegood_by_day": (line) => {

                let data = [
                    ["Day", "", { role: 'annotation' }],
                ];

                for (let l of line.data.buckets) {
                    data.push([l.key_as_string.substr(0, 10), l.note.value, l.note.value])
                }

                const options = {
                    chart: {},
                    bar: { groupWidth: "70%" },
                    // legend: { position: "top", maxLines: 2 },
                };

                return {
                    render: <Chart

                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />,
                    name: "Nombre Avis >4 par JOUR"
                }
            },
           
        }






        return <div className={"view-transaction"}>
         
            <div className={"title-page"}>
                {/* {"TRUSTPILOT.... " + (this.state.dateUpdate ? new Date(this.state.dateUpdate).toISOString() : "")} */}
                <a href={"https://fr.trustpilot.com/review/combigo.com?languages=all"} target={"_blank"}>{"Trustpilot"}</a>{" - "} { (this.state.dateUpdate ? new Date(this.state.dateUpdate).toISOString() : "")}
            </div>
            <div>
            </div>
            {
                <div>
                    <FormControl className={""}>
                        <InputLabel htmlFor="age-native-simple">{"Visualisation Day/Month"}</InputLabel>
                        <Select
                            native
                            value={this.state.modeVisu}
                            onChange={(ev) => {
                                this.setState({ modeVisu: ev.target.value })
                            }}

                        >
                            <option value={"day"}>{"Day"}</option>
                            <option value={"month"}>{"Month"}</option>
                        </Select>
                    </FormControl>

                </div>
            }
            {this.state.data.map((obj, index) => {
                const value = list[obj.key](obj)
                if (obj.key.indexOf(this.state.modeVisu) === -1) return null
                if (!value) return null

                return <Accordion expanded={this.state["expand_" + index]} key={index} className={"filtre-bandeau"}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        onClick={() => {
                            let obj = {}
                            obj["expand_" + index] = !this.state["expand_" + index]
                            this.setState(obj)
                        }}
                    >
                        <Typography className={""}>{value.name || obj.key}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>

                        {value.render}
                    </AccordionDetails>
                </Accordion>
            })
            }




        </div>
    }
}


export default withRouter(Body);

