import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import "./history.scss"
import moment from "moment";
import Button from '@material-ui/core/Button';


import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TableMaterial from "./../Table/TableMaterial"




const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },

}));


function getDataDifference(row) {
  let array = []
  for (let key in row.updateData) {
    for (let l of row.updateData[key]) {

      for (let prop of ["before", "after"]) {
        if (typeof l[prop] === "object") l[prop] = JSON.stringify(l[prop])
        if (typeof l[prop] === "boolean") l[prop] = String(l[prop])
      }
      array.push({ ...l, difference: key })

    }
  }
  return array

}

class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }


  render() {
    if (!this.props.list) return
    return (
      <Timeline align="" className={"history-timeline " + this.props.className}>

        {this.props.list.map(function (obj, index) {

          let title = (obj.title ? obj.title + " " : "") + moment(obj.date).format('D/MM/YY h:mm')



          return <TimelineItem key={index} onClick={() => {
            // if (this.props.onClick) this.props.onClick(obj, index)
          }}>

            <TimelineOppositeContent className={"title"}>
              {/* <Typography variant="body2" color="textSecondary">
                {title}
              </Typography> */}

              <Button size="small"
                onClick={() => {
                  if (this.props.onClick) this.props.onClick(obj, index)
                }}          >
                {title}
              </Button>
            </TimelineOppositeContent>

            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>


            <TimelineContent>
              {/* 
              <Paper elevation={1} className={"content"}>
                <Typography className={"text"}>{obj.content}</Typography>
              </Paper> */}

              {obj.id === "latest" ? null : <><Paper className={"content"}>

                {<>

                  <CardActions disableSpacing>
                    <CardContent>
                      <Typography className={"text"}>{(obj.title || "").substr(0, 100)}</Typography>
                    </CardContent >
                    <IconButton
                      className={""}
                      onClick={() => {
                        let obj = {}
                        obj["expanded_" + index] = !this.state["expanded_" + index]
                        this.setState(obj)
                      }}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </CardActions>

                  <Collapse in={this.state["expanded_" + index] ? true : false} timeout="auto" unmountOnExit>
                    <CardContent>


                      {obj.updateData ? <TableMaterial
                        className={"table-logs"}

                        columns={[
                          { field: "difference", name: "difference" },
                          { field: "key", name: "key" },
                          { field: "before", name: "before" },
                          { field: "after", name: "after" },

                        ]}
                        data={getDataDifference(obj)}
                      /> : null}


                    </CardContent>
                  </Collapse
                  ></>}
              </Paper>

                {obj.user && <Paper elevation={0} className={"author"}>
                  {obj.user}
                </Paper>}
                {obj.date && <Paper elevation={0} className={"author"}>
                  {moment(obj.date).format('D/MM/YY h:mm')}
                </Paper>}
              </>}

            </TimelineContent>


          </TimelineItem>
        }.bind(this))}



      </Timeline>
    );
  }

}

export default Body;
