import React, { Component, version } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { parseQuery, _fetch } from "./../helpers"
import "./Voucher.scss"
import Table from "./../Components/Voucher/Table"
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import moment from 'moment';
import TableBill from "./../Components/TableBill/TableBill"

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Helmet } from "react-helmet";





class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      voucherSelected: null,
      openModal: false
    }


  }


  
  componentDidUpdate(prevProps) {


  }



  render() {

    return <div className={"view-transaction"}>


      <TableBill
        mode={"table"}
        filter={{  status: ["ACTIVE", "PAID", "EXPIRED"]}}
        hiddenToolbar={false}
        activeToolbarFilter={true}
        timeout={5000}
        
      />



    </div>
  }
}



const mapStateToProps = (state) => {
  return { locale: state.user.locale, nameUser: state.user.name, base: state.data };
};


export default connect(mapStateToProps, {

})(withRouter(Body));

