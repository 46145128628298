


import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import TooltipCombigo from "./TooltipCombigo"
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Popper from '@material-ui/core/Popper';

import InputLabel from '@material-ui/core/InputLabel';

const icon = <CheckBoxOutlineBlankIcon fontSize="medium" />;
const checkedIcon = <CheckBoxIcon fontSize="medium" />;


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const nbDisplay = 6.5;
const MenuPropsSelect = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * nbDisplay + ITEM_PADDING_TOP,
            // width: 250,
        },
    },
};


class Body extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }

    }

    matching(options, value) {
        if (!this.props.matching) return value
        for (let option of options) {
            if (this.props.matching(option, value)) return option
        }
    }


    render() {

        let values
        const listValue = []
        const mapDico = {}
        if (this.props.multiple) {
            values = []
            const valuesInput = this.props.value || []
            if (this.props.getMatching) {
                for (let l of this.props.list) mapDico[this.props.getMatching(l)] = l
                for (let l of valuesInput) {
                    if (l != null) values.push(mapDico[l])
                }
            }
            else {
                // console.log(this.props)
                values = valuesInput.filter(function (obj) {
                    return obj != null ? true : false
                })
            }

            for (let option of values) listValue.push(this.props.getLabel ? this.props.getLabel(option) : option)

        }
        else {
            values = this.props.value
            if (this.props.getMatching) {
                for (let l of this.props.list) {
                    if (this.props.getMatching(l)==this.props.value) values=l
                }
            }
        }
        // console.log("value",this.props.value,listValue)


        const getLabel = this.props.getLabel?this.props.getLabel:(option)=>{ return typeof option==="string"?option:option.title}
        const list = (this.props.multiple ? this.props.list  || [] : ["RESET_INTERN"].concat(this.props.list || []))

        // console.log(list,values,)
        return (

            <TooltipCombigo title={this.state.open ? "" : values} getLabel={getLabel}>
                <FormControl className={this.props.className} style={this.props.style}>
                    <InputLabel id="demo-mutiple-checkbox-label">{this.props.label}</InputLabel>
                    <Select
                        autoWidth={true}
                        style={this.props.styleSelect}
                        labelId="demo-mutiple-checkbox-label"
                        id="demo-mutiple-checkbox"
                        multiple={this.props.multiple}
                        value={values}
                        open={this.state.open}
                        PopperComponent={function (props) {
                            return <Popper {...props} style={{ width: "fit-content" }} placement="bottom-start" />;
                        }}
                        onOpen={() => {
                            this.setState({ open: true })
                        }}
                        onClose={() => {
                            this.setState({ open: false })
                        }}
                        onChange={(ev, value) => {

                            if (this.props.multiple) {
                                const results = []
                                for (let val of ev.target.value) {
                                    if (typeof val == "string" && val == "RESET_INTERN") continue
                                    results.push(this.props.getMatching ? this.props.getMatching(val) : val)
                                }
                                this.props.onChange(results)
                            }
                            else {
                                if (ev.target.value == "RESET_INTERN") this.props.onChange("")
                                else this.props.onChange(ev.target.value)
                            }
                        }}
                        input={< Input />}
                        renderValue={this.props.multiple ? (this.props.chips ? (selected) => {
                            return <div style={{ display: "flex", flexWrap: "wrap" }}>
                                {selected.map((option,index) => {
                                    const value = getLabel ? getLabel(option) : option
                                    return <Chip key={index}  label={value} size='small' style={{ margin: "5px" }}

                                    // onClick={(ev)=>{
                                    //     ev.preventDefault()
                                    //     ev.stopPropagation()
                                    //     console.log("click")
                                    //     return 
                                    // }}
                                    // onDelete={(ev) => {
                                    //     console.log("click")

                                    //     ev.preventDefault()
                                    //     ev.stopPropagation()
                                    //     this.props.onChange(this.props.value.filter(function(obj){
                                    //         let valueIntern = this.props.getLabel ? this.props.getLabel(obj) : obj
                                    //         return valueIntern==value?false:true
                                    //     }))
                                    // }} 
                                    />
                                })}
                            </div>
                        } : (selected) => listValue.join(', ')) : (selected) => (getLabel ? getLabel(selected) : selected)}
                        MenuProps={MenuPropsSelect}
                    >
                        {list.map((option,index) => {
                            if (typeof option=="string" && option == "RESET_INTERN") {
                                return <MenuItem key={index} value="RESET_INTERN">
                                    {""}
                                </MenuItem>
                            }
                            let name = getLabel ?getLabel(option) : option
                            return <MenuItem key={index} value={option}>
                                {this.props.multiple && <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 ,color: "#33c" }}
                                    checked={listValue.indexOf(name) > -1}
                                />}

                                <ListItemText primary={name} />
                            </MenuItem>
                        })}
                    </Select>
                </FormControl>
            </TooltipCombigo>
        );
    }

}


export default Body
