import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import { withRouter } from 'react-router-dom';

import Slider from '@material-ui/core/Slider';
import "./Toolbar.scss"

import TextField from "@material-ui/core/TextField";

import { parseQuery, _fetch } from "./../../helpers"
import CheckBoxCombigo from "./../Utils/Select_CheckBox"
import IntervalleDate from "./../Utils/IntervalleDate"
import SelectCombigo from "./../Utils/Select"

// sessionStorage.setItem("toolbar-filter", "")
function getHistory() {
  try {
    return JSON.parse(sessionStorage.getItem("toolbar-filter3"))
  } catch (e) { }
}
const initialStateBody = {
  startDate: "",
  endDate: "",
  mail: "",
  referenceBooking: "",
  statusBooking: [],
  countries: [],
  satisfactionNote: [],// undefined, //[-1, 10]
  managerBooking: [],
  taskCombigoIntern: [],
  transferDisrupted: [],
  statusCombigoTravel: [],
  satisfactionCustomer: [],
  description: "",
  lastName: "",
  bookingTR: "",
  passengers: "",
  notes: "",
  trName: "",
  amountWaitingMin: "",
  trNum: "",
  typeFilterDate: "TRAVEL",
  statusCombigo: [],
  sorting: "CREATED_DATE_DEC",
}



class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      body: initialStateBody //getHistory() ||
    }
    this.lastRequest = Date.now()
    this.search = this.search.bind(this)
    this.onChangeBody = this.onChangeBody.bind(this)
    this.lastUpdateVersion = 0


  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval)
  }

  componentDidMount() {
    this.search(true, this.state.body)
    if (this.props.timeout) this.interval = setInterval(function () {
      this.search(false, this.state.body, true)
    }.bind(this), this.props.timeout)
  }


  componentDidUpdate() {

    //this.urlSearch
    if (this.urlSearch != window.location.search) {
      let o = parseQuery(window.location.search)
      if (o && o.bodyRequest) {
        // o.bodyRequest = decodeURI(o.bodyRequest)
        this.onChangeBody(JSON.parse(decodeURI(o.bodyRequest)))
      }

    }
    // console.log(window.location)

  }

  async search(force, body = {}, isCycle = false) {

    if (isCycle === false) {
      if (this.props.resetInit) this.props.resetInit()
    }
    if (force || Date.now() - this.lastRequest > 0.8 * this.props.timeout) {
      this.lastRequest = Date.now()
      this.lastUpdateVersion++
      setTimeout(function (lastUpdateVersion) {
        if (this.lastUpdateVersion != lastUpdateVersion) return
        request.call(this, body, this.lastUpdateVersion)

      }.bind(this, this.lastUpdateVersion), 1000)

    }


    function request(body, lastUpdateVersion) {
      _fetch(process.env.REACT_APP_URL + this.props.url, {
        "timeout": 10000,
        "nbRetry": 1,
        "method": "post",
        "headers": {},
        "body": {
          filter: {
            ...this.props.filter,
            ...body
          },
          mode: this.props.mode || "alltransactions"
        }
      })
        .then(function (response) {
          if (this.lastUpdateVersion != lastUpdateVersion) return
          if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
          let data = response.json()
          if (this.props.onUpdate) this.props.onUpdate(data)
        }.bind(this))
        .catch(function (e) {
          if (this.lastUpdateVersion != lastUpdateVersion) return

          console.log("Error toolbar", e);
          if (this.props.onUpdate) this.props.onUpdate(undefined, { error: true })

        }.bind(this));
    }


  }

  onChangeBody(obj) {
    let body = Object.assign({}, this.state.body)
    for (let prop in obj) body[prop] = obj[prop]

    this.setState({ body: body })
    if (this.props.onChange) this.props.onChange(body)
    this.search(true, body)

    // if (this.noRoutingUrl) return 
    this.bodyStr = JSON.stringify(body)
    sessionStorage.setItem("toolbar-filter3", this.bodyStr)
    this.urlSearch = "?bodyRequest=" + encodeURI(this.bodyStr)

    this.props.history.push({
      pathname: window.location.pathname,
      //URLSearchParams
      search: this.urlSearch
    })
  }

  render() {


    return (
      <Grid container item xs={12} direction="row" className={"toolbar-container-search " + this.props.className} spacing={3}>
        <Grid container item xs={12} direction="row" className={" " + this.props.className} spacing={3}>
          <Grid container item direction={"row"} xs={2} sm={2} spacing={3} className={"block"}>
            <SelectCombigo
              list={["TRAVEL", "CREATED", "MODIFIED", "RAPPEL"]}
              label={"Date Filtre"}
              value={this.state.body.typeFilterDate}
              onChange={(value) => {
                this.onChangeBody({ typeFilterDate: value })
              }}
            />
            <IntervalleDate
              label_start={"Start"}
              label_end={"End"}
              format={"DD/MM/yyyy"}
              type={"date"}
              style={{ width: "100px" }}
              startDate={this.state.body.startDate}
              endDate={this.state.body.endDate}

              onChange={(value) => {
                // console.log(value)
                this.onChangeBody({ startDate: value[0], endDate: value[1] })
              }}
            />
          </Grid>


          <Grid container item direction={"row"} xs={1} spacing={3} className={"block"} >
            <TextField label="N° booking" variant="standard" value={this.state.body.referenceBooking} onChange={(ev) => {
              this.onChangeBody({ referenceBooking: ev.target.value })
            }} />
          </Grid>
          <Grid container item direction={"row"} xs={1} spacing={3} className={"block"} >

            <TextField label="lastName" variant="standard" value={this.state.body.lastName} onChange={(ev) => {
              this.onChangeBody({ lastName: ev.target.value })
            }} />
          </Grid>
          <Grid container item direction={"row"} xs={1} spacing={3} className={"block"}>
            <TextField label="Mail" variant="standard" value={this.state.body.mail} onChange={(ev) => {
              this.onChangeBody({ mail: ev.target.value })
            }} />
          </Grid>
          <Grid container item direction={"row"} xs={1} spacing={3} className={"block"}>

            <TextField label="booking (PNR)" variant="standard" value={this.state.body.bookingTR} onChange={(ev) => {
              this.onChangeBody({ bookingTR: ev.target.value })
            }} />
          </Grid>
          <Grid container item direction={"row"} xs={1} spacing={3} className={"block"}>
            <TextField label="Number Flight" variant="standard" value={this.state.body.trNum} onChange={(ev) => {
              this.onChangeBody({ trNum: ev.target.value })
            }} />
          </Grid>

          <Grid container item direction={"row"} xs={1} spacing={3} className={"block"}>

            <TextField label="transporter" variant="standard" value={this.state.body.trName} onChange={(ev) => {
              this.onChangeBody({ trName: ev.target.value })
            }} />
          </Grid>



          {/* <Grid container item direction={"row"} xs={3} spacing={3}>
            <Typography id="range-slider" gutterBottom>
              Note User
            </Typography>
            <Slider
              value={this.state.body.satisfactionNote}
              onChange={(ev, value) => {
                this.onChangeBody({ satisfactionNote: value })
              }}
              min={-1}
              step={0.5}
              max={10}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
            // getAriaValueText={valuetext}
            />

          </Grid> */}

          <Grid container item direction={"row"} xs={1} spacing={3} className={"block"}>
            <CheckBoxCombigo
              style={{ minWidth: "100%" }}
              label={"Status Combigo"}
              list={this.props.base.statusCombigo}
              value={this.state.body.statusCombigo}
              multiple={true}
              chips={false}

              onChange={(value) => {
                this.onChangeBody({ statusCombigo: value })
              }}
            ></CheckBoxCombigo>
          </Grid>

          <Grid container item direction={"row"} xs={1} spacing={3} className={"block"}>
            <CheckBoxCombigo
              style={{ minWidth: "100%" }}
              label={"Manager"}
              multiple={true}
              chips={false}
              getMatching={(option) => { return option.id }}
              list={this.props.base.users}
              getLabel={(option) => { return option ? option.name : option }}
              value={this.state.body.managerBooking}
              onChange={(value) => {
                this.onChangeBody({ managerBooking: value })
              }}
            ></CheckBoxCombigo>
          </Grid>

          <Grid container item direction={"row"} xs={2} spacing={3} className={"block"}>
            <CheckBoxCombigo
              style={{ minWidth: "100%" }}
              label={"taskCombigoIntern"}
              multiple={true}
              chips={false}
              list={this.props.base.taskCombigoIntern}
              value={this.state.body.taskCombigoIntern}
              onChange={(value) => {
                this.onChangeBody({ taskCombigoIntern: value })
              }}
            ></CheckBoxCombigo>
          </Grid>

          <Grid container item direction={"row"} xs={2} spacing={3} className={"block"}>
            <CheckBoxCombigo
              className={"select-status"}
              style={{ minWidth: "100%" }}
              label={"Transfer Disrupted"}
              list={this.props.base.transferDisrupted}
              multiple={false}
              chips={false}
              value={this.state.body.transferDisrupted}
              onChange={(value) => {
                this.onChangeBody({ transferDisrupted: value })

              }}
            ></CheckBoxCombigo>
          </Grid>

          <Grid container item direction={"row"} xs={2} spacing={3} className={"block"}>
            <CheckBoxCombigo
              className={"select-status"}
              style={{ minWidth: "100%" }}
              label={"Etat Voyage"}
              list={this.props.base.statusCombigoTravel}
              multiple={false}
              chips={false}
              value={this.state.body.statusCombigoTravel}
              onChange={(value) => {
                this.onChangeBody({ statusCombigoTravel: value })

              }}
            ></CheckBoxCombigo>
          </Grid>
          <Grid container item direction={"row"} xs={2} spacing={3} className={"block"}>
            <CheckBoxCombigo
              className={"select-status"}
              style={{ width: "100%" }}
              label={"Satisfaction Client"}
              list={this.props.base.satisfactionCustomer}
              multiple={false}
              chips={false}
              value={this.state.body.satisfactionCustomer}
              onChange={(value) => {
                this.onChangeBody({ satisfactionCustomer: value })
              }}
            ></CheckBoxCombigo>
          </Grid>


          <Grid container item direction={"row"} xs={2} spacing={3} className={"block"}>
            <CheckBoxCombigo
              className={"select-status"}
              style={{ width: "100%" }}
              label={"Satisfaction Note"}
              list={[-1, 0, 1, 2, 3, 4, 5]}
              multiple={true}
              chips={false}
              value={this.state.body.satisfactionNote}
              onChange={(value) => {
                this.onChangeBody({ satisfactionNote: value })
              }}
            ></CheckBoxCombigo>
          </Grid>



        </Grid>





        <Grid container item xs={12} direction="row" className={" " + this.props.className} spacing={3} style={{ marginTop: "10px" }}>

          <Grid container item direction={"row"} xs={2} spacing={3} className={"block"}>
            <CheckBoxCombigo
              className={"select-status"}
              style={{ width: "100%" }}
              label={"statusBooking"}
              list={this.props.base.statusBooking}
              multiple={true}
              chips={false}
              value={this.state.body.statusBooking}
              onChange={(value) => {
                this.onChangeBody({ statusBooking: value })
              }}
            ></CheckBoxCombigo>
          </Grid>
          <Grid container item direction={"row"} xs={2} spacing={3} className={"block"}>
            <CheckBoxCombigo
              className={"select-status"}
              style={{ width: "100%" }}
              label={"Country"}
              getLabel={(option) => { return option.label + "(" + option.code + ")" }}
              getMatching={(option) => { return option.code }}
              list={this.props.base.countries}
              multiple={true}
              chips={false}
              value={this.state.body.countries}
              onChange={(value) => {
                this.onChangeBody({ countries: value })
              }}
            ></CheckBoxCombigo>
          </Grid>
          <Grid container item direction={"row"} xs={1} spacing={3} className={"block"}>
            <TextField label="Notes" variant="standard" value={this.state.body.notes} onChange={(ev) => {
              this.onChangeBody({ notes: ev.target.value })
            }} />
          </Grid>
          <Grid container item direction={"row"} xs={1} spacing={3} className={"block"}>
            <TextField label="Description" variant="standard" value={this.state.body.description} onChange={(ev) => {
              this.onChangeBody({ description: ev.target.value })
            }} />
          </Grid>
          <Grid container item direction={"row"} xs={1} spacing={3} className={"block"}>
            <TextField label="Name Passengers" variant="standard" value={this.state.body.passengers} onChange={(ev) => {
              this.onChangeBody({ passengers: ev.target.value })
            }} />
          </Grid>
          <Grid container item direction={"row"} xs={1} spacing={3} className={"block"}>

            <TextField label="amountWaitingMin" variant="standard" value={this.state.body.amountWaitingMin} onChange={(ev) => {
              this.onChangeBody({ amountWaitingMin: ev.target.value || "" })
            }} />
          </Grid>
          <Grid container item direction={"row"} xs={1} spacing={3} className={"block"}>

            <CheckBoxCombigo
              className={"select-status"}
              style={{ width: "100%" }}
              label={"Sorting"}
              list={["CREATED_DATE_DEC", "CREATED_DATE_CRO", "LASTMODIFIED_DEC", "LASTMODIFIED_CRO", "MARGIN_DEC", "MARGIN_CRO", "PRICE_DEC", "PRICE_CRO", "SCORE", "DEPART_CRO", "DEPART_DEC"]}
              multiple={false}
              chips={false}
              value={this.state.body.sorting}
              onChange={(value) => {
                this.onChangeBody({ sorting: value })
              }}
            ></CheckBoxCombigo>
          </Grid>

          <Grid container item direction={"row"} xs={1} spacing={3} className={"block"}>
            <Button onClick={function () {
              sessionStorage.setItem("toolbar-filter3", "")

              this.setState({
                body: {
                  ...initialStateBody,
                  ...this.props.filter,
                }
              }, () => {

                this.search(true, this.state.body)
              })
            }.bind(this)}>{"Reset"}</Button>
          </Grid>

        </Grid>



      </Grid>
    );
  }

}


const mapStateToProps = (state) => {
  return { base: state.data };
};

export default connect(mapStateToProps, {})(withRouter(Body));