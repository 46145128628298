import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import SelectCheckBoxCombigo from "./../Utils/Select_CheckBox"



const listCategory =[
    { id: "CANCEL_VOLUNTARY", title: "CANCEL_VOLUNTARY" },
    { id: "DENIED_BOARDED", title: "DENIED_BOARDED" },
    { id: "DISRUPTED_CONNECTION", title: "DISRUPTED_CONNECTION" },
    { id: "MODIFICATION", title: "MODIFICATION" },
    { id: "OPTIONS", title: "OPTIONS" },
    { id: "ERROR", title: "ERROR" },
    { id: "OTHER", title: "OTHER" },
    { id: "BANK", title: "BANK" },
]

class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            description: "",
            amount: props.amount,
            disabled:false,
            listParentId:[],
        }
    }

    componentDidMount(){

        this.loadList()


    }


    componentDidUpdate(prevProps){
        if (prevProps.open===false && this.props.open){
            this.setState({disabled:true})
            setTimeout(()=>{
                this.setState({disabled:false})
            },8000)
        }
        if ( this.props.data.id!= prevProps.data.id){
            this.loadList()
        }
    }

    
    loadList = ()=>{
        this.setState({listParentId:this.props.listParentId.concat([{id:"",name:"---------"}]).concat(listCategory.map((o)=>{
            return {
                id:"new:"+o.id,
                name:"New "+ o.id
            }
        }))})
    }


    render() {
        if (!this.state.listParentId) return 

      
        // console.error("this.props.listParent=",this.props.listParent)

    return (<div>
            <Dialog open={this.props.open} onClose={() => {
                this.props.onClose()
                this.setState({ description: "", amount: "" })
            }} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{this.props.data.provider + " " + this.props.data.id + ", Attention etes-vous vraiment sur de vouloir rembourser, si vous avez un doute demander à quelqu'un de l'équipe car après ca sera trop tard!!!!"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {"Montant maximal remboursable : " + this.props.data.amountUpdated + " " + this.props.currency + ""}
                        
                        {this.state.listParentId && <div style={{ display: "flex", "flexDirection": "row" }}>
                            <FormControl style={{ width: "100%" }}  >
                                <SelectCheckBoxCombigo
                                    className={""}
                                    getLabel={(obj)=>{
                                        return obj.name
                                    }}
                                    styleSelect={{ width: "100%" }}
                                    label={"Category"}
                                    list={this.state.listParentId}
                                    multiple={false}
                                    chips={false}
                                    value={this.state.parentId}
                                    onChange={(value) => {
                                        this.setState({disabled:true})
                                        setTimeout(()=>{
                                            this.setState({disabled:false})
                                        },2000)
                                        if (value.id===""){
                                            this.setState({ parentId: null })

                                        }else{
                                            this.setState({ parentId: value })

                                        }
                                    }}
                                />
                            </FormControl>
                        </div>}
                        <div style={{ display: "flex", "flexDirection": "row" }}>

                            <TextField
                                label={"Montant du Remboursement"}
                                value={this.state.amount}
                                onChange={(ev) => {
                                    this.setState({disabled:true})
                                    setTimeout(()=>{
                                        this.setState({disabled:false})
                                    },2000)
                                    this.setState({ amount: ev.target.value })
                                }}
                                autoFocus
                                margin="dense"
                                id="comment"
                                fullWidth
                            />

                            <FormControl className={"select-status"}>
                                <InputLabel id="demo-simple-select-label">{"Currency"}</InputLabel>
                                <Input id="demo-simple-select-label"
                                    labelId="demo-simple-select-label"
                                    disabled={true}
                                    style={{color:"red"}}
                                    value={this.props.currency} />
                                {this.props.currency != "EUR" ? <FormHelperText id="my-helper-text">{"Attention currency"}</FormHelperText> : null}

                            </FormControl>

                        </div>


                    </DialogContentText>

                    <DialogContentText>
                        <TextField
                            label="Raison du remboursement"
                            value={this.state.description}
                            multiline={true}
                            onChange={(ev) => {
                                this.setState({disabled:true})
                                setTimeout(()=>{
                                    this.setState({disabled:false})
                                },2000)
                                this.setState({ description: ev.target.value })
                            }}
                            autoFocus
                            margin="dense"
                            id="comment"
                            fullWidth
                        />

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        this.props.onClose()
                        this.setState({ description: "", amount: "" })
                    }} color="primary">
                        {this.props.btnCancel || "Cancel"}
                    </Button>
                    <Button 
                    disabled={this.state.disabled}
                    onClick={() => {

                        let valueAmount = Number(String(this.state.amount).trim().replace(",", "."))
                        if (isNaN(valueAmount)) {
                            return this.props.onError("Valeur du montant n est pas un nombre")
                        }
                    
                        if (!valueAmount) {
                            return this.props.onError("Valeur du montant est 0")
                        }
                        if (!this.state.description) {
                            return this.props.onError("Vous devez mettre une raison")
                        }
                        if (!this.state.parentId) {
                            return this.props.onError("Vous devez choisir une category")
                        }
                        this.setState({disabled:true},()=>{
                            this.props.onSave({ description: this.state.description, amount: valueAmount, id: this.props.data.id, provider: this.props.data.provider,parentId:this.state.parentId?this.state.parentId.id:undefined },()=>{
                               setTimeout(()=>{
                                this.setState({disabled:false})
                               },10000)
                            })
                        })
                      

                        
                    }} color="primary">
                        {this.props.btnValid || "Rembourser"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
        );
    }
}

export default Modal;