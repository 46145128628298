import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Popper from '@material-ui/core/Popper';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import moment from "moment";
import { parseQuery, _fetch } from "./../../helpers"

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Edit from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

import TooltipCombigo from "./../Utils/TooltipCombigo"

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker, DatePicker, DateTimePicker } from "@material-ui/pickers";
import DateTimeContainer from "./../Utils/DateTimeContainer"
import CheckBoxCombigo from "./../Utils/Select_CheckBox"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SelectHistory from "./../Table/select_history"


import { connect } from 'react-redux';
import './VisionTransaction.scss';




class Body extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bookingIdModal: null,
      modalManagerOpen: false,
      openModalDescription: false,
      openModalDescriptionView: false
    }

    this.onChange = this.onChange.bind(this)
    this.checkManager = this.checkManager.bind(this)
    // console.log(this.props)
  }




  checkManager() {
    if (this.state.bookingIdModal != this.props.booking.bookingId) {
      this.setState({
        bookingIdModal: this.props.booking.bookingId,
        modalManagerOpen: ((this.props.data.data.managerBooking.length == 0 || !this.props.data.data.managerBooking) &&
          (this.props.data.data.statusBooking == "PROCESSING" ||
            this.props.data.data.statusBooking == "PROCESSING_AUTO")
        )
      })
    }
  }

  componentDidMount() {
    this.listDefault = []

    for (let l of this.props.base.users) {
      if (l.id == this.props.nameUser) {
        this.listDefault.push(l)
        break
      }
    }
    this.listDefaultIds = []
    for (let l of this.props.base.users) {
      // if (l.role.includes("AUTO")) continue
      if (l.id == this.props.nameUser) continue
      if (l.role.includes("DISABLED")) continue
      if (l.role.includes("INACTIVE")) continue
      this.listDefault.push({ ...l })
      if (l.id) this.listDefaultIds.push(l.id)
    }
    this._addManager()
    this.checkManager()


  }

  _addManager = () => {
    // return
    //this.setState({listUsers:null})
    let manager = this.props.data.data.managerBooking || []
    let list = this.listDefault.slice()

    for (let l of manager) {
      //console.log("Manager ",l)
      let id = l.id || l
      if (!this.listDefaultIds.includes(id)) {
        let val = this.props.base.users.filter((o) => {
          return o.id === l
        })[0]
        if (val) {
          // console.log(val)
          list.push(val)

        }
      }
    }
    if (JSON.stringify(list) != JSON.stringify(this.state.listUsers)) {
      this.setState({ listUsers: list })
    }

  }

  componentDidUpdate(prevProps) {
    this.checkManager()
    if (prevProps && prevProps.booking && this.props.booking && this.props.booking.bookingId != prevProps.booking.bookingId) {

      this._addManager()
    }
  }



  async onChange(prop, value, obj) {
    let data = { ... this.props.data.data }
    if (prop) data[prop] = value
    if (obj) for (let key in obj) data[key] = obj[key]

    if (this.props.onChange) {
      if (await this.props.onChange({ data: data, typeUpdate: "table_data", lastVersion: this.props.data.version }) == true) {
        return true
      }
    }
  }



  openManagerModalDescription() {
    
    if (this.state.openModalDescriptionView){
      if (!this.props.booking.summary.data.description && this.state.openModalDescriptionView){
        this.setState({ openModalDescriptionView: false })
      }
    }
    if (!this.props.booking.summary.data.description){
      return null
    }

    if (this.state.openModalDescriptionView === 1) {
      setTimeout(() => {
        if (this.state.openModalDescriptionView===1) this.setState({ openModalDescriptionView: 2 })
      }, 2000)
    }

    // console.log(this.props.data.data.managerBooking,this.props.data.data.statusBooking)
    return <Dialog
      open={this.state.openModalDescriptionView}
      keepMounted
      onClose={() => {
        //this.setState({ openModalDescriptionView: false })
      }}
    >
      <DialogTitle >{"IMPORTANT, message dans la description : "}</DialogTitle>
      <DialogContentText style={{padding:"12px"}}>{this.props.booking.summary.data.description}</DialogContentText>
      <DialogActions>


        <Button
          disabled={this.state.openModalDescriptionView === 1}
          onClick={() => {
            this.setState({  openModalDescriptionView: false })
          }} >
          {"Fermer"}
        </Button>
      </DialogActions>
    </Dialog>


  }

  openManagerModal() {



    // console.log(this.props.data.data.managerBooking,this.props.data.data.statusBooking)
    return <Dialog
      open={this.state.modalManagerOpen}
      keepMounted
      onClose={() => {
      //  this.setState({ modalManagerOpen: false, openModalDescriptionView: 1 })
      }}
    >
      <DialogTitle >{"Veux tu être en charge de faire la réservation ? "}</DialogTitle>

      <DialogActions>

        <Button onClick={() => {
          this.setState({ modalManagerOpen: false, openModalDescriptionView: 1 })
        }} >
          {"Non"}
        </Button>
        <Button onClick={() => {

          const userSelected = this.props.base.users.filter((o) => {
            if (o.id == this.props.nameUser) return true
            return false
          })
          if (!userSelected || !userSelected.length) {
            console.log("ERROR THE USER IS UNKNOW", this.props.nameUser, this.props.base.users)
            return
          }
          if (this.props.openTabMail) {
            this.props.openTabMail()
            window.open('https://newaccount1603196282781.freshdesk.com/a/search/tickets?sortBy=relevance&term=' + this.props.booking.informations.referenceBooking, '_blank');

          }
          else {
            window.open('https://newaccount1603196282781.freshdesk.com/a/search/tickets?sortBy=relevance&term=' + this.props.booking.informations.referenceBooking, '_blank');
          }

          this.onChange(undefined, undefined, {
            managerBooking: [userSelected[0].id],
            statusBooking: "PROCESSING"
          })
            .then((response) => {
              if (response) {
                this.setState({ modalManagerOpen: false, openModalDescriptionView: 1 })
                if (this.props.onUpdateMode) this.props.onUpdateMode("booking")
              }
              else alert("Attention quelqu'un fait peut etre deja la reservation, veuillez réactualiser la page")

            })
            .catch((e) => {
              console.error("error to affect manager at the booking ", e)
              alert("Attention quelqu'un fait peut etre deja la reservation, veuillez réactualiser la page")
            })
        }} color="primary">
          {"Yes !!"}
        </Button>
      </DialogActions>
    </Dialog>


  }

  render() {

    if (!this.state.listUsers) return null


    return (<div className={"recapitulatif-transaction"}>


      {this.openManagerModal()}
      {this.openManagerModalDescription()}

      <Grid xs={12} sm={12} container item className={"line "}>

        <Grid xs={1} sm={1} container item className={"block"}  >
          <FormControl className={"select-status"}>
            <InputLabel id="demo-simple-select-label">{"referenceBooking"}</InputLabel>
            <Input id="demo-simple-select-label" labelId="demo-simple-select-label" style={{ "color": "black" }} value={this.props.booking.informations.referenceBooking} disabled={true} />
          </FormControl>
        </Grid>

        <Grid xs={2} sm={2} container item className={"block"}>
          <FormControl className={"select-status"}>
            <InputLabel id="demo-simple-select-label">{"lastName"}</InputLabel>
            <Input id="demo-simple-select-label" labelId="demo-simple-select-label" style={{ "color": "black" }} value={this.props.booking.informations.lastName} disabled={true} />
          </FormControl>
        </Grid>

        <Grid xs={1} sm={2} container item className={"block"}>
          <FormControl className={"select-status"}>
            <InputLabel id="demo-simple-select-label">{"dateCreated"}</InputLabel>
            <Input id="demo-simple-select-label" labelId="demo-simple-select-label"
              value={moment(this.props.booking.summary.data.dateCreated * 1000).format("YYYY-MM-DD HH:mm")} disabled={true} />
          </FormControl>
        </Grid>
        <Grid xs={1} sm={2} container item className={"block"}>
          <FormControl className={"select-status"}>
            <InputLabel id="demo-simple-select-label">{"lastModified"}</InputLabel>
            <Input id="demo-simple-select-label" labelId="demo-simple-select-label"
              value={moment(this.props.booking.summary.data.lastModified * 1000).format("YYYY-MM-DD HH:mm")} disabled={true} />
          </FormControl>
        </Grid>

        <Grid xs={1} sm={2} container item className={"block"}>
          <FormControl className={"select-status"}>
            <InputLabel id="demo-simple-select-label">{"Price Total"}</InputLabel>

            {
              this.props.booking.summary.data.statusBooking.indexOf("WAIT_CONFIRMED") != -1 || this.props.booking.summary.data.statusBooking.indexOf("PROCESSING") != -1 ? <><Input id="demo-simple-select-label" labelId="demo-simple-select-label" style={{ "color": "black" }}
                value={"?"}
                disabled={true} />
                <div style={{ color: "grey", fontSize: "12px" }}>
                  {"Prix indisponible car processing"}
                </div>
              </> :
                <>
                  <Input id="demo-simple-select-label" labelId="demo-simple-select-label" style={{ "color": "black" }}
                    value={this.props.booking.summary.data.amountEUR + " €"}
                    disabled={true} />
                  <div style={{ color: "grey", fontSize: "12px" }}>
                    {(this.props.booking.summary.data.amountMarginEUR != undefined ?
                      "Marge de " + this.props.booking.summary.data.amountMarginEUR +
                      "€ (" + Math.round(this.props.booking.summary.data.amountMarginEUR / this.props.booking.summary.data.amountEUR * 100)
                      + "%)" : "")}

                  </div> </>

            }


          </FormControl>
        </Grid>

        <Grid xs={1} sm={1} container item className={"block"}>
          <FormControl className={"select-status"}>
            <InputLabel id="demo-simple-select-label">{"Classe"}</InputLabel>
            <Input id="demo-simple-select-label" labelId="demo-simple-select-label" style={{ "color": "black" }}
              value={this.props.booking.summary.data.class || ""}
              disabled={true} />

          </FormControl>
        </Grid>
        <Grid xs={1} sm={1} container item className={"block"}>
          <FormControl className={"select-status"}>
            <InputLabel id="demo-simple-select-label">{"Tarif"}</InputLabel>
            <Input id="demo-simple-select-label" labelId="demo-simple-select-label" style={{ "color": "black" }}
              value={this.props.booking.bundle && this.props.booking.bundle.data && this.props.booking.bundle.data.id ? this.props.booking.bundle.data.id : "BASIC"}
              disabled={true} />

          </FormControl>
        </Grid>
      </Grid>



      <Grid xs={12} sm={12} container item className={"line "}>

        <Grid xs={2} sm={2} container item className={"block"}  >
          <FormControl className={"select-status"}>
            <InputLabel id="demo-simple-select-label">{"bookingId"}</InputLabel>
            <Input id="demo-simple-select-label" labelId="demo-simple-select-label" style={{ "color": "black" }} value={this.props.booking.bookingId} disabled={true} />
          </FormControl>
        </Grid>

        <Grid xs={2} sm={2} container item className={"block"}>
          <FormControl className={"select-status"}>
            <InputLabel id="demo-simple-select-label">{"Mail Combigo"}</InputLabel>
            <Input id="demo-simple-select-label" labelId="demo-simple-select-label"
              value={this.props.mailCombigo}
              disabled={true}
            />
          </FormControl>
        </Grid>



        <Grid xs={2} sm={2} container item className={"block"}  >
          <FormControl className={"select-status"}>
            <InputLabel id="demo-simple-select-label">{"RedirectBy"}</InputLabel>
            <Input id="demo-simple-select-label" labelId="demo-simple-select-label" 
            style={{ "color": "black" }} value={"*"}
             disabled={true} />
          </FormControl>
        </Grid>

      </Grid>

      <Grid xs={12} sm={12} container item className={"line border"}>

        {<Grid xs={1} sm={2} container item className={"block"}>
          <CheckBoxCombigo
            className={"select-status"}
            styleSelect={{ width: "100%" }}
            label={"Manager"}
            list={this.state.listUsers} //(this.props.base.users || [])}
            multiple={true}
            chips={false}
            getLabel={(option) => { return option ? option.name : "" }}
            getMatching={(option) => { return option ? option.id : "" }}
            value={this.props.data.data.managerBooking}
            onChange={(value) => {
              this.onChange("managerBooking", value)
            }}
          ></CheckBoxCombigo>

        </Grid>}

        <Grid xs={1} sm={2} container item className={"block"}>
          <CheckBoxCombigo
            className={"select-status"}
            styleSelect={{ width: "100%" }}
            label={"Status User"}
            list={this.props.base.statusBooking.filter((o) => {
              if (o === "PROCESSING_ERROR") return false
              if (o === "PROCESSING_AUTO") return false

              return true

            })}
            multiple={false}
            // getLabel={(option)=>{return option.name}}
            value={this.props.data.data.statusBooking}
            onChange={(value) => {
              this.onChange("statusBooking", value)
            }}
          ></CheckBoxCombigo>

        </Grid>

        <Grid xs={1} sm={2} container item className={"block"}>
          <CheckBoxCombigo
            className={"select-status"}
            styleSelect={{ width: "100%" }}
            label={"Status Combigo"}
            list={this.props.base.statusCombigo || []}
            multiple={true}
            chips={false}
            // getLabel={(option)=>{return option.name}}
            value={this.props.data.data.statusCombigo}
            onChange={(value) => {
              this.onChange("statusCombigo", value)
            }}
          ></CheckBoxCombigo>

        </Grid>


        {/* </Grid>

<Grid xs={12} sm={12} container item className={"line line-color"} spacing={5}> */}


        <Grid xs={1} sm={2} container item className={"block"}>
          <FormControl className={"description-input"}>
            <TooltipCombigo title={this.props.booking.summary.data.description} >
              <TextField
                className={"input-color-" + (this.props.booking.summary.data.description ? "orange" : "black")}
                id="standard-basic"
                label="Description"
                value={this.props.booking.summary.data.description}
                multiline={true}
                disabled={true}
                style={{ cursor: "pointer" }} />
            </TooltipCombigo>
            <IconButton size="small" onClick={() => {
              this.setState({ openModalDescription: true, descriptionValue: this.props.booking.summary.data.description || "" })
            }}>
              <Edit />
            </IconButton>
            <Dialog open={this.state.openModalDescription} maxWidth={"sm"} fullWidth={true} onClose={() => {
              this.setState({ openModalDescription: false })
            }} aria-labelledby="form-dialog-title">
              <DialogContent>
                <TextField
                  value={this.state.descriptionValue}
                  multiline={true}
                  onChange={(ev) => {
                    this.setState({ descriptionValue: ev.target.value })
                  }}
                  autoFocus
                  margin="dense"
                  id="comment"
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => { this.setState({ openModalDescription: false }) }} >{"Cancel"}</Button>
                <Button onClick={() => {
                  this.onChange("description", this.state.descriptionValue)
                  this.setState({ openModalDescription: false })
                }} color="primary"> {"Sauvegarde"} </Button>
              </DialogActions>
            </Dialog>
          </FormControl>
        </Grid>

        <Grid xs={1} sm={2} container item className={"block"}>
          <FormControlLabel
            control={<Checkbox
              style={{ color: "#33c" }}
              label={"Active Rappel"}
              checked={this.props.data.data.dateRappel ? true : false}
              onChange={(ev, value_) => {
                if (value_ == false) this.onChange("dateRappel", "")
                else this.onChange("dateRappel", new Date(moment().format("YYYY-MM-DD") + "T00:00:00+00:00") / 1000)
              }
              }
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />}
            label={(this.props.data.data.dateRappel ?
              <DateTimeContainer
                DateObj={DatePicker}
                autoOk
                variant="dialog"
                invalidDateMessage={""}
                inputVariant="standard"
                label="Date Rappel"
                format="DD/MM/yyyy"
                value={moment(this.props.data.data.dateRappel * 1000)}
                TextFieldComponent={
                  (props) =>
                    <TextField {...props}
                      className={"text-input "}
                      variant={"standard"} />
                }
                InputAdornmentProps={{ position: "start" }}
                onChange={(date, value) => {
                  let time = new Date(moment(date).format("YYYY-MM-DD") + "T00:00:00+00:00") / 1000
                  this.onChange("dateRappel", time)
                }}
              />
              : "Date Rappel")}
          />
        </Grid>

      </Grid>


      <Grid xs={12} sm={12} container item className={"line border"}>


        {["general"].includes(this.props.mode) ? <Grid xs={1} sm={2} container item className={"block"}>
          <CheckBoxCombigo
            className={"select-status"}
            styleSelect={{ width: "100%" }}
            label={"Transfer Disrupted"}
            list={this.props.base.transferDisrupted}
            multiple={false}
            chips={false}
            value={this.props.data.data.transferDisrupted}
            onChange={(value) => {
              this.onChange("transferDisrupted", value)
            }}
          ></CheckBoxCombigo>

        </Grid> : null}

        {["general"].includes(this.props.mode) ? <Grid xs={1} sm={2} container item className={"block"}>
          <CheckBoxCombigo
            className={"select-status"}
            styleSelect={{ width: "100%" }}
            label={"Démarches"}
            list={this.props.base.taskCombigoIntern}
            multiple={false}
            chips={false}
            value={this.props.data.data.taskCombigoIntern}
            onChange={(value) => {

              this.onChange("taskCombigoIntern", value)
            }}
          ></CheckBoxCombigo>


        </Grid> : null}
        {["general"].includes(this.props.mode) ? <Grid xs={1} sm={2} container item className={"block"}>
          <CheckBoxCombigo
            className={"select-status"}
            styleSelect={{ width: "100%" }}
            label={"Status Voyage"}
            list={this.props.base.statusCombigoTravel}
            multiple={false}
            chips={false}
            value={this.props.data.data.statusCombigoTravel}
            onChange={(value) => {

              this.onChange("statusCombigoTravel", value)
            }}
          ></CheckBoxCombigo>


        </Grid> : null}



        {["general"].includes(this.props.mode) ? <Grid xs={1} sm={2} container item className={"block"}>
          <CheckBoxCombigo
            className={"select-status"}
            styleSelect={{ width: "100%" }}
            label={"Satisfaction Client"}
            list={this.props.base.satisfactionCustomer}
            multiple={false}
            chips={false}
            value={this.props.data.data.satisfactionCustomer}
            onChange={(value) => {

              this.onChange("satisfactionCustomer", value)
            }}
          ></CheckBoxCombigo>
        </Grid> : null}

        {/* {["general"].includes(this.props.mode) ? <Grid xs={1} sm={2} container item className={"block"}>
          <CheckBoxCombigo
            className={"select-status"}
            styleSelect={{ width: "100%" }}
            label={"Satisfaction Note"}
            list={[-1, 0, 1, 2, 3, 4, 5]}
            multiple={false}
            chips={false}
            value={this.props.data.data.satisfactionNote && this.props.data.data.satisfactionNote != -1 ? Number(this.props.data.data.satisfactionNote) : ""}
            onChange={(value) => {
              this.onChange("satisfactionNote", value != null && value != undefined && value != "" ? Number(value) : -1)
            }}
          ></CheckBoxCombigo>
        </Grid> : null} */}

        {/* 
        {["general"].includes(this.props.mode) ? <Grid xs={1} sm={2} container item className={"block"}>
          <CheckBoxCombigo
            className={"select-status"}
            styleSelect={{ width: "100%" }}
            label={"transferDisruptedCustomer"}
            list={this.props.base.transferDisruptedCustomer || []}
            multiple={true}
            chips={false}
            value={this.props.data.data.transferDisruptedCustomer}
            onChange={(value) => {
              this.onChange("transferDisruptedCustomer", value)
            }}
          ></CheckBoxCombigo>


        </Grid> : null} */}




        {["general"].includes(this.props.mode) ? <Grid xs={1} sm={2} container item className={"block"}>

          <SelectHistory
            style={{ minWidth: "100px" }}
            version={this.props.booking.bookingId}
            filter={{ relationId: "bookingId:" + this.props.booking.bookingId, }}
            historyData={(data, error, flagReset) => {
              if (data && data.rawData && typeof data.rawData === "string") data.rawData = JSON.parse(data.rawData)

              this.props.historyData(data && data.rawData ? data.rawData : undefined, error, flagReset)

            }}
          />
        </Grid> : null}



      </Grid>


    </div>
    )
  }
}






const mapStateToProps = (state) => {
  return { booking: state.booking, base: state.data, nameUser: state.user.name, user: state.user };
};

export default connect(mapStateToProps, {})(Body);