
import * as React from 'react';
import { connect } from 'react-redux';
import { cloneObject } from '../../helpers';
import './TableErrorUpdate.scss';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { CombigoSnackbar ,CombigoUpdate} from './../../Components/Snackbar/Snackbar';
import Toolbar from "./Toolbar"
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import Progress from './../Components/Progress';
import TableMaterial from "./../Table/TableMaterial"

import { parseQuery, _fetch } from "./../../helpers"


const columnsDetails = [{ "name": "date" }, { "name": "type" }, { "name": "status" }, { "name": "notification" }, { "name": "transactionReference" },
{ "name": "description" }, { "name": "amount in €" }, { "name": "currency original transaction" },
{ "name": "balance" }, { "name": "operationCode" }]


function getUser(one) {
    if (one.informations) {
        for (let line of one.informations) {
            if (line.user) {
                return line.user
            }
        }
    }
}

function Row(props) {
    const { row, details, open } = props;
    if (!row) return null


    // console.log("row=",row,"details",details)
    const columns = [
        { "name": "bookingId" },
        { "name": "dateCreated" },

        { "name": "Status" },
        { "name": "Amount" },
        { "name": "Margin" },


        { "name": "Ecart" },
        { "name": "balanceCRM" },
        { "name": "balanceReal" },
        { "name": "nbWaiting" },

    ]
    return (
        <React.Fragment>
            <TableRow className={""}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={props.onClick}>
                        {props.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell  >        {row.bookingId}       </TableCell>
                <TableCell >{row.dateCreated ? new Date(row.dateCreated).toISOString().substr(0, 16).replace("T", " ") : ""}</TableCell>

                <TableCell >{row.status}</TableCell>
                <TableCell >{row.business_gmv + " €"}</TableCell>
                <TableCell >{row.business_margin_raw + " €"}</TableCell>

                <TableCell >{row.deltaBalance + " €"}</TableCell>
                <TableCell >{row.balanceCRM}</TableCell>
                <TableCell >{row.balanceReal}</TableCell>
                <TableCell >{row.nbWaiting || 0}</TableCell>


                {row.bookingId ? <TableCell ><a href={"/oneTransaction/" + row.bookingId} target="_blank"><IconButton onClick={() => {
                }}>
                    <FolderOpenIcon />
                </IconButton>
                </a></TableCell> : null}

            </TableRow>

            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={props.open} timeout="auto" unmountOnExit>
                        <Box margin={1} style={{ background: "rgba(128,128,128,0.09)", margin: "10px 10px 10px 80px" }}>
                            {props.loading ? <Progress /> : <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        {columnsDetails && columnsDetails.map((detail, index) => {
                                            return <TableCell key={index}>{detail.name}</TableCell>
                                        })
                                        }

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {details && details.map((detail) => (
                                        <TableRow key={detail.date}>
                                            <TableCell >{new Date(detail.date).toISOString().substr(0, 16).replace("T", " ")} </TableCell>
                                            <TableCell>{detail.type}</TableCell>
                                            <TableCell >{detail.status}</TableCell>
                                            <TableCell >{detail.notification}</TableCell>

                                            <TableCell >{detail.transactionReference}</TableCell>
                                            <TableCell >{detail.description}</TableCell>
                                            <TableCell >{detail.amount}</TableCell>
                                            <TableCell >{detail.currencyTransaction || ""}</TableCell>

                                            <TableCell >{detail.balance}</TableCell>
                                            <TableCell >{detail.operationCode || ""}</TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>}


                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}





class Account extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            data: [],
            details: {},
            open: {},
            loading: {},
            errorMessage: null,
            snackMessage: null,
            countVersion: 0,
            version:"init",
            openUpdatedButton: false

        }

        this._refresh = this._refresh.bind(this)
        this._request = this._request.bind(this)
        this._update = this._update.bind(this)
        this.onLoadDetails = this.onLoadDetails.bind(this)
        // columns[2].custom.list= []
        // for (let line of this.props.base.carrier) {
        //     columns[2].custom.list.push({title:line.name + (line.code && line.code.iata?" ("+line.code.iata+")":""),name:line.name,idSya:line.idSya,idP:line.idP})
        // }
        // columns[2].custom.list.sort(function(a,b){
        //     return a.title.localeCompare(b.title)
        // })
    }


    _request(body, route = "bankcard") {
        return new Promise(function (resolve) {
            _fetch(process.env.REACT_APP_URL + '/crm/' + route, {
                "timeout": 60000,
                "nbRetry": 2,
                "method": "post",
                "headers": {},
                "body": body
            })
                .then(function (response) {
                    if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                    let data = response.json()
                    if (data.success) return resolve(data)
                    throw data
                }.bind(this))
                .catch(function (e) {
                    this.setState({ errorMessage: "Probleme pour actualiser les données des cartes bancaires virtuelles, veuillez réessayer" })
                    resolve(undefined)
                }.bind(this));
        }.bind(this))
    }


    _refresh() {
        this._request({
            data: {

                bookingId: this.props.bookingId,
                sorting: this.props.sort,
                size: this.props.size,
                ...this.props.filter
            },
            action: "get"
        }, "report/error_update_crm").then((response) => {
            if (response && response.success) {
                this.setState({
                    data: response.data || null,
                    details: {},
                    open: {}
                })
            }
        })
    }
    _update() {

        if (this.props.bookingId != this.bookingId || this.props.version != this.version) {
            this.setState({
                data: [],
                details: {},
                open: {},
                loading: {}
            })
            this.bookingId = this.props.bookingId
            this.version = this.props.version
            this._refresh()
        }

    }


    componentDidUpdate() {
        this._update()
    }

    componentDidMount() {
        this._update()

    }

    onLoadDetails(row) {
        if (!row || !row.data) return null

        // if (this.state.details && this.state.details[row.data.cardReference]) {
        //     return this.state.details[row.data.cardReference]
        // }

        this.valueToken = Math.random()
        let loading = { ...this.state.loading }
        loading[row.data.cardReference] = this.valueToken
        this.setState({ loading: loading })


        this._request({
            data: {
                cardReference: row.data.cardReference,
                provider: row.provider
            },
            action: "details"
        }, "report/error_update_crm").then((response) => {
            let loading = { ...this.state.loading }
            if (this.valueToken !== loading[row.data.cardReference]) return

            loading[row.data.cardReference] = false
            this.setState({ loading: loading })

            if (response && response.success) {
                let details = { ...this.state.details }
                details[row.data.cardReference] = response.data
                this.setState({
                    details: details
                })
            }
        })
    }

    render() {

        // component={Paper}


        const columns = [
            { collapse: true },

            {
                "name": "", "field": "", render: (row) => {
                    return <a href={"/oneTransaction/" + row.bookingId} target="_blank"><IconButton onClick={() => {
                    }}>
                        <FolderOpenIcon />
                    </IconButton>
                    </a>
                }
            },
            {
                "name": "BookingId", "field": "bookingId", render: (row) => {
                    return row.bookingId
                }
            },
            {
                "name": "dateCreated", "field": "dateCreated", render: (row) => {
                    return new Date(row.dateCreated * 1000).toISOString().substr(0, 10).replace("T", " ")
                }
            },
            {
                "name": "lastModified", "field": "dateCreated", render: (row) => {
                    return row.lastModified ? new Date(row.lastModified * 1000).toISOString().substr(0, 10).replace("T", " ") : ""
                }
            },

            {
                "name": "Status", "field": "status", render: (row) => {
                    return row.status
                }
            },
            {
                "name": "Amount", "field": "Amount", getValue: (row) => {
                    return row.business_gmv
                }, render: (row) => {
                    return row.business_gmv
                }
            },
            {
                "name": "statusBooking", "field": "crm_statusBooking", getValue: (row) => {
                    return row.crm_statusBooking
                }, render: (row) => {
                    return row.crm_statusBooking
                }
            },
            // {
            //     "name": "statusCombigo", "field": "crm_statusCombigo", getValue:(row)=>{
            //         return row.crm_statusCombigo.join(",")
            //     },render: (row) => {
            //         return row.crm_statusCombigo.join(",")
            //     }
            // },
            // {
            //     "name": "Margin", "field": "Margin",getValue:(row)=>{
            //         return row.business_margin_raw
            //     }, render: (row) => {
            //         return row.business_margin_raw
            //     }
            // },

            {
                "name": "deltaBalance", "field": "Ecart", getValue: (row) => {
                    return row.note
                }, render: (row) => {
                    return row.deltaBalance
                }
            },
            {
                "name": "balanceCRM", "field": "balanceCRM", getValue: (row) => {
                    return row.balanceCRM
                }, render: (row) => {
                    return row.balanceCRM
                }
            },
            {
                "name": "balanceReal", "field": "balanceReal", getValue: (row) => {
                    return row.balanceReal
                }, render: (row) => {
                    return row.balanceReal
                }
            },
            {
                "name": "nbWaiting", "field": "nbWaiting", getValue: (row) => {
                    return row.nbWaiting
                }, render: (row) => {
                    return row.nbWaiting
                }
            },
            {
                "name": "amountWaiting", "field": "amountWaiting", getValue: (row) => {
                    return row.amountWaiting
                }, render: (row) => {
                    return row.amountWaiting ? row.amountWaiting : ""
                }
            },
            {
                "name": "description", "field": "description", render: (row) => {
                    return (row.description || "").substr(0, 50)
                }
            },
            // {
            //     "name": "note", "field": "note",getValue:(row)=>{
            //         return row.note
            //     }, render: (row) => {
            //         return row.note
            //     }
            // },

        ]



        return (
            <>
                <div className={this.props.activeTitle ? "view-transaction" : ""}>


                    {this.props.activeTitle ? <div className={"title-page"}> {" ==== Youpi Escape Game en Folie!!! ==== " + (this.state.dateUpdate ? new Date(this.state.dateUpdate).toISOString() : "")}</div> : null}

                    <Grid >
                        {this.props.activeToolbarFilter ? <Toolbar
                            url={'/crm/report/error_update_crm'}
                            timeout={this.props.timeout || 2 * 60000} //12000
                            filter={{
                                ...this.props.filter,

                            }}
                            version={{
                                ...this.state.filterStr
                            }}
                            onUpdate={function (data, error) {

                                if (data) {
                                    if (this.state.version != data.version) {


                                        if (this.state.version === "init") {
                                            this.setState({ data: data.data, version: data.version, dateUpdate: Date.now(), countVersion: 0, newDataVersion: null, openUpdatedButton: false })
                                        }
                                        else {
                                            let newVersion = (data.version != this.state.lastVersionUpdated ? 1 : 0)
                                            let countVersion = this.state.countVersion + newVersion
                                            this.setState({
                                                openUpdatedButton: true, countVersion: newVersion ? countVersion : this.state.countVersion,
                                                lastVersionUpdated: newVersion ? data.version : this.state.lastVersionUpdated,
                                                newDataVersion: newVersion ? { data: data.data, version: data.version, dateUpdate: Date.now() } : this.state.newDataVersion
                                            })
                                            // this.setState({ newVersion:{data: data.data, version: data.version, dateUpdate: Date.now()} })
                                        }
                                    }
                                    // if (this.state.version != data.version) {
                                    //     this.setState({ data: data.data, version: data.version, dateUpdate: Date.now() })
                                    // }
                                    // else {
                                    //     this.setState({ dateUpdate: Date.now() })
                                    // }
                                }

                            }.bind(this)}

                        /> : <Grid >
                            <Button onClick={this._refresh} style={{ color: '#33c' }} variant="outlined">{"Refresh"}</Button>
                        </Grid>}

                        <TableMaterial
                            className={"table-error-update"}


                            columns={columns}
                            functionCollapse={(row) => {

                                return row.delta && row.delta.list ? row.delta.list : []
                            }}
                            subColumn={[
                                {
                                    field: "date", name: "date", render: (obj) => {
                                        return obj.date ? new Date(obj.date).toISOString().substr(0, 16).replace("T", " ") : ""
                                    }
                                },
                                {
                                    field: "sourceData", name: "sourceData", render: (obj) => {
                                        return obj.sourceData
                                    }
                                },

                                {
                                    field: "sens", name: "sens", render: (obj) => {
                                        return obj.sens
                                    }
                                },
                                {
                                    field: "amount", name: "amount EUR", render: (obj) => {
                                        return obj.amount
                                    }
                                },
                                {
                                    field: "name", name: "name", render: (obj) => {
                                        return obj.source ? obj.source.title : obj.provider
                                    }
                                },
                                {
                                    field: "id", name: "id", render: (obj) => {
                                        return obj.providerId || obj.booking_reference || obj.source_transaction_id || obj.id
                                    }
                                },

                                {
                                    field: "data", name: "data", render: (obj) => {
                                        return JSON.stringify(obj)
                                    }
                                }


                            ]}
                            data={this.state.data}
                        />


                    </Grid>

                    <CombigoSnackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={this.state.errorMessage !== null || this.state.snackMessage !== null}
                        onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
                        variant={this.state.errorMessage != null ? 'error' : "success"}
                        autoHideDuration={6000}
                        message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
                    />
                    <CombigoUpdate
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        nb={this.state.countVersion}
                        autoHideDuration={null}
                        open={this.state.openUpdatedButton}
                        // onClose={() => this.setState({ openUpdatedButton: false })}
                        onClick={() => {
                            let newDataBackup = this.state.newDataVersion
                            this.setState({ openUpdatedButton: false, countVersion: 0 })
                            this.setState({ ...newDataBackup, newDataVersion: null, lastVersionUpdated: null })
                        }}
                    />
                </div>

            </>

        )
    }
}




const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps, {})(Account);