
import * as React from 'react';
import { connect } from 'react-redux';
import './ActionTransaction.scss';
import moment from "moment";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { TextField } from '@material-ui/core';
import { _fetch, fileDownload } from '../../helpers';
import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';
import BillModal from "./BillModal"
import ModalYesNo from "./../dialogConfirm/ModalYesNo"
import ModalModification from '../ModalModification/ModalModification';
import ModalDisruptedConnection from '../DisruptedConnection/ModalDisruptedConnection';
import ModalCancelBooking from '../ModalCancel/ModalCancel';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Buffer } from "buffer"


class Account extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            modeModal:"",
            disabled: false,
            errorMessage: null,
            snackMessage: null,
            openModalBill: null,
            openCancelModal: false,
            openModalModification: false,
            openDisruptedConnection: null,
            disabledButtonYesNo:true

        }

        this.sendMail = this.sendMail.bind(this)
        this._sendRequestMail = this._sendRequestMail.bind(this)
    }


    componentDidUpdate() {

    }

    componentDidMount() {

    }

    sendMail(action) {

        this._sendRequestMail(action, async (success) => {
            if (success) {
                this.setState({ snackMessage: "OK to send" })
                setTimeout(function () {
                    this.setState({ disabled: false })
                }.bind(this), 5000)
            }
            else {
                this.setState({ errorMessage: "Problem to send" })
                setTimeout(function () {
                    this.setState({ disabled: false })
                }.bind(this), 5000)
            }
        })
    }

    _sendRequestMail(action, cb) {
        return new Promise(function (resolve) {
            _fetch(process.env.REACT_APP_URL + '/crm/transactions/action', {
                "timeout": 8000,
                "nbRetry": 1,
                "method": "post",
                "headers": {},
                "body": {
                    // booking: this.props.booking,
                    bookingId: this.props.booking.bookingId,
                    action: action,
                }
            })
                .then(async function (response) {

                    if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));

                    cb(true, response.json(), response).then(() => {
                        resolve(true)
                    })

                }.bind(this))
                .catch(function (e) {

                    cb(false, undefined, e).then(() => {
                        resolve(false)
                    })
                    console.error("Error sendMail", e);
                    this.setState({ errorMessage: "Erreur to action " })

                }.bind(this));

        }.bind(this))

    }

    render() {

        return (<div className={"container-action"}>
            <div className='container-action-line'>
                <div className='container-action-line-title'>
                    {"Processing Booking"}
                </div>
                <div className='container-action-line-data'>
                    <Grid container item xs={2} className="">
                        <Button size='small' color="secondary"
                            disabled={this.props.booking.summary.data.statusBooking.indexOf("PROCESSING") == -1}
                            onClick={(ev) => {
                                if (this.props.booking.summary.data.statusBooking.indexOf("PROCESSING") == -1) {
                                    return this.setState({ errorMessage: "Impossible d'envoyer un message en cours car le status de la réservation n'est pas en processing." })
                                }
                                this.setState({ disabled: true }, function () {
                                    this.sendMail("mail_processing")
                                }.bind(this))

                            }}>{"Envoi Mail Processing"}</Button>
                    </Grid>
                    <Grid container item xs={2} className="">
                        <Button size='small' color="secondary"
                            disabled={this.props.booking.summary.data.statusBooking.indexOf("CONFIRMED") == -1}
                            onClick={(ev) => {
                                if (this.props.booking.summary.data.statusBooking.indexOf("CONFIRMED") == -1) {
                                    return this.setState({ errorMessage: "Impossible d'envoyer un message en cours car le status de la réservation n'est pas en confirmed." })
                                }
                                this.setState({ disabled: true }, function () {
                                    this.sendMail("mail_confirmation")
                                }.bind(this))

                            }}>{"Envoi Mail Trajet/Confirmation"}</Button>
                    </Grid>
                    <Grid container item xs={2} className="">
                        <Button size='small' color="primary"
                            disabled={this.props.booking.summary.data.statusBooking.indexOf("CONFIRMED") != -1
                                || this.props.booking.summary.data.statusBooking.indexOf("PROCESSING") != -1 ? false : true}
                            onClick={(ev) => {
                                if (this.props.booking.summary.data.statusBooking.indexOf("CONFIRMED") != -1
                                    || this.props.booking.summary.data.statusBooking.indexOf("PROCESSING") != -1) {
                                    this.setState({ displayModalYesNo: true, modeModal: "confirmed_combigo", disabledButtonYesNo:false})
                                }
                            }}>{
                                "Confirmed Combigo!"
                            }</Button>
                    </Grid>
                    <Grid container item xs={2} className="">
                        <Button size='small' color="primary"

                            disabled={
                                this.props.booking.summary.data.statusBooking.indexOf("PROCESSING") != -1
                                    || this.props.booking.summary.data.statusBooking.indexOf("CANCEL") != -1 ? false : true}
                            onClick={(ev) => {
                                if (this.props.booking.summary.data.statusBooking.indexOf("PROCESSING") != -1 || this.props.booking.summary.data.statusBooking.indexOf("CANCEL") != -1) {
                                    this.setState({ displayModalYesNo: true, modeModal: "cancel_combigo", disabledButtonYesNo: true })
                                    // setTimeout(() => {
                                    //     if (this.state.modeModal === "cancel_combigo" && this.state.displayModalYesNo) {
                                    //         this.setState({ disabledButtonYesNo: false })
                                    //     }
                                    // }, 4000)
                                }
                            }}>{
                                <span style={{ color: "#f66" }}>{"Cancel Combigo!"}</span>
                            }</Button>
                    </Grid>
                </div>

            </div>
            <div className='container-action-line'>
                <div className='container-action-line-title'>
                    {"Manage Booking"}
                </div>
                <div className='container-action-line-data'>
                    <Grid container item xs={2} className="">
                        <Button size='small' color="secondary"
                            disabled={this.state.disabled}
                            onClick={(ev) => {
                                //  ev.preventDefault()
                                ev.stopPropagation()
                                // this.setState({ disabled: true }, function () {
                                //     this.sendMail("eticket")
                                // }.bind(this))

                            }}>{
                                <a style={{ color: "black" }} href={process.env.REACT_APP_URL + "/crm/transactions/eticket?bookingId=" + this.props.booking.bookingId + "&files=1"} target="_blank" download>{"Etickets"}</a>
                            }</Button>
                    </Grid>
                    <Grid container item xs={2} className="">
                        <Button size='small' color="secondary"
                            disabled={this.state.disabled}
                            onClick={(ev) => {
                                this.setState({ openModalBill: true })
                                //  ev.preventDefault()
                                //  ev.stopPropagation()
                                // this.setState({ disabled: true }, function () {
                                //     this.sendMail("eticket")
                                // }.bind(this))

                            }}>{
                                // <a style={{ color: "black" }} href={process.env.REACT_APP_URL + "/crm/transactions/bill?bookingId="+this.props.booking.bookingId +"&files=1"} target="_blank" download>{"Facture"}</a>
                                "Facture"
                            }</Button>
                    </Grid>

                    <Grid container item xs={2} className="">
                        <Button size='small' color="primary"
                            onClick={(ev) => {
                                this.setState({ openModalModification: true })
                            }}>{
                                "Mail Modification"
                            }</Button>
                    </Grid>
                </div>
            </div>



            {/* <Grid container item xs={2} className="">
                    <Button size='small' color="primary"
                        onClick={(ev) => {
                            this.setState({ openCancelModal: true })
                        }}>{
                            <span style={{ color: "#f66" }}>{"Annulation"}</span>
                        }</Button>
                </Grid>
                <Grid container item xs={2} className="">
                    <Button size='small' color="primary"

                        onClick={(ev) => {
                            this.setState({ openDisruptedConnection: true })
                        }}>{
                            <span style={{ color: "#3333cc" }}>{"Disrupted Connection"}</span>
                        }</Button>
                </Grid> */}

            {/* <Grid container item xs={2} className="">
                    <Button size='small' color="secondary"
                        disabled={this.state.disabled}
                        onClick={(ev) => {
                            if (this.props.booking.summary.data.statusBooking != "CONFIRMED") {
                                return this.setState({errorMessage:"Impossible d'envoyer un message en cours car le status de la réservation n'est pas en confirmed."})
                            }
                            this.setState({ disabled: true }, function () {
                                this.sendMail("mail_confirmation")
                            }.bind(this))

                        }}>{"Envoi Mail Trajet/Confirmation"}</Button>
                </Grid> */}


            {
                <ModalDisruptedConnection
                    booking={this.props.booking}
                    open={this.state.openDisruptedConnection}
                    bookingId={this.props.booking.bookingId}
                    version={this.props.booking.bookingId + "-" + this.props.booking._hashVersion}
                    onClose={() => { this.setState({ openDisruptedConnection: false }) }}
                />
            }
            {
                <ModalCancelBooking
                    booking={this.props.booking}
                    open={this.state.openCancelModal}
                    bookingId={this.props.booking.bookingId}
                    version={this.props.booking.bookingId + "-" + this.props.booking._hashVersion}
                    onClose={() => { this.setState({ openCancelModal: false }) }}
                />
            }
            {<ModalModification
                booking={this.props.booking}
                open={this.state.openModalModification}
                bookingId={this.props.booking.bookingId}
                version={this.props.booking.bookingId + "-" + this.props.booking._hashVersion}
                onClose={() => { this.setState({ openModalModification: false }) }}
            />}
            {<BillModal
                open={this.state.openModalBill}
                version={this.props.booking.bookingId}

                onClick={(bodyInput) => {
                    this.setState({ snackMessage: "Veuillez attendre quelques secondes...." })

                    _fetch(process.env.REACT_APP_URL + '/crm/transactions/action', {
                        "timeout": 10000,
                        "nbRetry": 2,
                        "method": "post",
                        "headers": {},
                        "body": {
                            ...bodyInput,
                            bookingId: this.props.booking.bookingId,
                            action: "bill",
                        }
                    })
                        .then(async function (response) {

                            if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                            let data = response.json()
                            fileDownload(new Buffer(data.data, "base64"), "bill_" + this.props.booking.bookingId + ".pdf")
                            this.setState({ openModalBill: false, snackMessage: "FINI !" })
                        }.bind(this))
                        .catch(function (e) {
                            this.setState({ errorMessage: "Action erreur", snackMessage: null })
                            console.error("Error action modal bill", e);

                        }.bind(this));
                }}
                onClose={() => { this.setState({ openModalBill: false }) }}
            />}


            <ModalYesNo
                open={this.state.displayModalYesNo}
                btnCancel={"Non"}
                styleTitle={{
                    "backgroundColor": this.state.modeModal === "cancel_combigo" ? "#faa" : "#afa",
                    color:"black"
                }}
                styleContent={{
                    "backgroundColor": this.state.modeModal === "cancel_combigo" ? "#faa" : "#afa",
                  
                }}
                btnValid={"Oui"}

                disabledButtonYes={this.state.disabledButtonYesNo}

                onClose={() => {
                    this.setState({ displayModalYesNo: false, modeModal: null, disabledButtonYesNo: false })
                }}

                content={this.state.modeModal === "cancel_combigo" ? <div>

                    <span style={{fontWeight:"500" ,  color:"black"}}>{"Souhaitez vous annuler la réservation?"}</span>
                   <div>
                   <Checkbox checked={!this.state.disabledButtonYesNo} onChange={()=>{
                            this.setState({disabledButtonYesNo:!this.state.disabledButtonYesNo})
                        }}  /><span style={{  color:"black"}}>{"J'ai vérifié qu'il n'existe pas d'alternative à ce trajet (Rappel process : Proposé une alternative si Panier > 800€ et que l'on a un aller retour fictif)."}</span>
                   </div>
                </div>
                    :
                    (this.state.modeModal === "confirmed_combigo" ? "Confirmez vous la confirmation de la réservation ?" : "ATTENTION STOP!!!")}
                onCancel={() => {
                    this.setState({ displayModalYesNo: false, modeModal: null, disabledButtonYesNo: false })
                }}
                onClick={() => {
                     
                    this.setState({ disabledButtonYesNo: true })
                    _fetch(process.env.REACT_APP_URL + '/crm/transactions/flow', {
                        "timeout": 20000,
                        "nbRetry": 1,
                        "method": "post",
                        "headers": {},
                        "body": {
                            bookingId: this.props.booking.bookingId,
                            flow: this.state.modeModal,
                        }
                    })
                        .then(async function (response) {

                            if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                            let data = response.json()

                            this.setState({ disabledButtonYesNo: false, displayModalYesNo: false, modeModal: null, snackMessage: "PARFAIT : " + data.join(", ") })

                        }.bind(this))
                        .catch(function (e) {
                            this.setState({ disabledButtonYesNo: false, errorMessage: "Action erreur workflow", modeModal: null, displayModalYesNo: false })
                            console.error("Error action transaction flow", e);

                        }.bind(this));
                }}
                title={this.state.modeModal === "cancel_combigo" ?"Annulation Cancel Combigo":"Confirmation Réservation"}
            />

            <CombigoSnackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={this.state.errorMessage !== null || this.state.snackMessage !== null}
                onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
                variant={this.state.errorMessage != null ? 'error' : "success"}
                autoHideDuration={10000}
                message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
            />

        </div>
        )
    }
}




const mapStateToProps = (state) => {
    return { booking: state.booking };
};

export default connect(mapStateToProps, {})(Account);