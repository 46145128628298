


import React from 'react';

import moment from "moment";
import { MuiPickersUtilsProvider, DatePicker, DateTimePicker, KeyboardDatePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import DateTimeContainer from "./DateTimeContainer"
import InputLabel from '@material-ui/core/InputLabel';



class Body extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.TypeDate = this.props.type == "date" ? DatePicker : DateTimePicker
        this.format = this.props.format || (this.props.type == "date" ? "DD/MM/yyyy" : "DD/MM/yyyy HH:mm")
    }

    render() {

        // if (this.props.startDate == null || this.props.endDate == null) {
        //     this.props.onChange(["", ""])
        // }

        return (<>

            <DateTimeContainer
                DateObj={this.TypeDate}
                label={this.props.label_start}
                autoOk={true}
                value={this.props.startDate || ""}
                format={this.format}
                onChange={(newValue, h) => {
                    this.props.onChange([newValue, this.props.endDate])
                }}
                invalidLabel=""
                invalidDateMessage={null}
                variant={"inline"}
                ampm={false}

                style={this.props.style}
                TextFieldComponent={(props) => {
                    // console.log(props)
                    return <TextField {...props} value={this.props.startDate ? props.value : ""} />
                }}
            />

            <DateTimeContainer
                DateObj={this.TypeDate}
                label={this.props.label_end}
                invalidLabel=""
                autoOk={true}
                ampm={false}
                invalidDateMessage={null}
                value={this.props.endDate || ""}
                format={this.format}
                style={this.props.style}
                onChange={(newValue) => {
                    this.props.onChange([this.props.startDate, newValue])
                }}
                variant={"inline"}
                TextFieldComponent={(props) => {
                    return <TextField {...props} value={this.props.endDate ? props.value : ""} />
                }}
            />
        </>
        );
    }

}


export default Body
