
import * as React from 'react';
import { connect } from 'react-redux';
import { cloneObject } from '../../helpers';

import './TableRealTime.scss';
// import { DataGrid } from '@material-ui/data-grid';
import Toolbar from "./Toolbar"
import TableMaterial from "./../Table/TableMaterial"
import moment from 'moment';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import IconButton from '@material-ui/core/IconButton';


class Account extends React.Component {

    constructor(props) {


        super(props);
        this.state = {
            data: [],
            version: "",
            dateUpdate: 0
        }

    }

    componentDidUpdate(prevProps) {
        let filterString = JSON.stringify(this.props.filter || {}) +"-"+this.props.version
        if (this.state.filterStr != filterString || ( this.props.version && prevProps.version!=this.props.version)) {
            this.setState({ filterStr: filterString,data:[],version:""})
        }

    }

    componentDidMount() {

    }

    _formatDate = (num) => {
        return new Date(num).toISOString().substr(0, 19) + "+00:00"
    }


    render() {

        // window.moment = moment

        const columns = [
            { collapse: true },
            // {field:"_id",name:"_id"},
            {
                field: "trip", name: "trip", render: (obj) => {
                    return (obj.from.city || obj.from.iata || obj.from.name) + " -> " + (obj.to.city || obj.to.iata || obj.to.name)
                }
            },
            {
                field: "status", name: "status", render: (obj) => {
                    if (obj.informations.statusTime === "cancelled" || obj.informations.status === "cancelled") {
                        return <span style={{ color: "#ff2a00" }}>
                            {obj.informations.status + (obj.informations.statusTime ? " (" + obj.informations.statusTime + ")" : "")}
                        </span>
                    }
                    
                    return obj.informations.status + (obj.informations.statusTime ? " (" + obj.informations.statusTime + ")" : "")
                }
            },
            {
                field: "dTime", name: "dTime", render: (obj) => {
                    if (obj.dTimeReal && obj.dTime && obj.dTimeReal != obj.dTime) {
                        let difference = (obj.dTimeReal - obj.dTime)/60

                        return <div style={{ display: "flex", "flexDirection": "column" }}>
                            <span className={"strike-style"}>
                                {moment(this._formatDate((obj.dTime) * 1000)).utc().format("DD/MM/YY HH:mm")}
                            </span>
                            <span style={{ color: difference > 5 ? "#ff6600" : undefined }}>
                                {moment(this._formatDate((obj.dTimeReal) * 1000)).utc().format("DD/MM/YY HH:mm")}
                            </span>

                        </div>
                    }


                    return moment(this._formatDate((obj.dTimeReal || obj.dTime) * 1000)).utc().format("DD/MM/YY HH:mm")
                }
            },
            {
                field: "aTime", name: "aTime", render: (obj) => {
                    if (obj.aTimeReal && obj.aTime && obj.aTimeReal != obj.aTime) {
                        let difference = (obj.aTimeReal - obj.aTime)/60

                        return <div style={{ display: "flex", "flexDirection": "column" }}>
                            <span className={"strike-style"}>
                                {moment(this._formatDate((obj.aTime) * 1000)).utc().format("DD/MM/YY HH:mm")}
                            </span>
                            <span style={{ color: difference > 5 ? "#ff6600" : undefined }}>
                                {moment(this._formatDate((obj.aTimeReal) * 1000)).utc().format("DD/MM/YY HH:mm")}
                            </span>

                        </div>
                    }


                    return moment(this._formatDate((obj.aTimeReal || obj.aTime) * 1000)).utc().format("DD/MM/YY HH:mm")
                }
            },

            {
                field: "trName", name: "trName", render: (obj) => {
                    return obj.trName
                }
            },
            {
                field: "number", name: "number", render: (obj) => {
                    return ((obj.trIATA || obj.trName || "") + "" + obj.trNum).trim()
                }
            },

            {
                field: "dSearch", name: "dSearch", render: (obj) => {
                    return moment(this._formatDate(obj.dSearch)).utc().format("DD/MM/YY HH:mm")
                }
            },
            {
                field: "link", name: "link", render: (obj) => {
                    if (!obj.dashboardLink) return null
                    return <a href={obj.dashboardLink} target="_blank">{"link"}  </a>

                }
            },
        ]
        if (this.props.openLink) {
            columns.push({
                field: "", noSort: true, name: "Link", render: (rowData) => {
                    let url = ""
                    if (rowData.bookingId) url = "/oneTransaction/" + rowData.bookingId + "?mode=realtime"
                    return <a href={url} target="_blank"><IconButton onClick={() => {
                    }}>
                        <FolderOpenIcon />
                    </IconButton>
                    </a>
                }
            })
        }
        
        // if (this.state.change){
        //     this.setState({change:false})
        //     return null
        // }

        return <div className={"container-table-realtime"}>
            {this.props.activeToolbarFilter ? <Toolbar
                url={'/crm/realtime/search'}
                timeout={this.props.timeout!=undefined? this.props.timeout: 2 * 60000} //12000
                filter={this.props.filter}
                version={this.state.filterStr}
                onUpdate={function (data, error) {
                    if (data) {
                        if (this.state.version != data.version) {
                            this.setState({ data: data.data, version: data.version, dateUpdate: Date.now() })
                        }
                        else {
                            this.setState({ dateUpdate: Date.now() })
                        }
                    }

                }.bind(this)}

            /> : null}
            <TableMaterial
                className={"table-realtime"}

                columns={columns}
                functionCollapse={(row) => {
                    let array = [{ ...row.logistic, ...row.informations }]
                 
                    return array
                }}
                subColumn={[
                    {
                        field: "from_delay_time", name: "from_delay", render: (obj) => {
                            return obj.from_delay_time ? obj.from_delay_time + " min" : ""
                        }
                    },

                    { field: "from_gate", name: "from_gate" },
                    { field: "from_platform", name: "from_platform" },
                    { field: "from_terminal", name: "from_terminal" },

                    {
                        field: "to_delay_time", name: "to_delay", render: (obj) => {
                            return obj.to_delay_time ? obj.to_delay_time + " min" : ""
                        }
                    },
                    {
                        field: "to_diverted", name: "to_diverted", render: (obj) => {
                            return obj.to_diverted ? obj.to_diverted  : ""
                        }
                    },

                    { field: "to_gate", name: "to_gate" },
                    { field: "to_platform", name: "to_platform" },
                    { field: "to_terminal", name: "to_terminal" },
                    { field: "to_baggageClaim", name: "to_baggageClaim" }


                ]}
                data={this.state.data}
            />

        </div>



    }
}




const mapStateToProps = (state) => {
    return { booking: state.booking, base: state.data };
};

export default connect(mapStateToProps, {})(Account);