import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';



class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value:""
        }
    }

    render() {

        return (<div>
            <Dialog open={this.props.open} onClose={()=>{
                this.props.onClose()
                this.setState({value:""})
            }} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {this.props.text}
                    </DialogContentText>

                    <TextField
                        value = {this.state.value}
                        multiline={true}
                        onChange={(ev)=>{
                            this.setState({value:ev.target.value})
                        }}
                        autoFocus
                        margin="dense"
                        id="comment"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        this.props.onClose()
                        this.setState({value:""})
                    }} color="primary">
                        {this.props.btnCancel || "Cancel"}
                    </Button>
                    <Button onClick={() => {
                        this.props.onSave(this.state.value)
                        this.setState({value:""})
                    }} color="primary">
                        {this.props.btnValid || "Sauvegarde"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
        );
    }
}

export default Modal;