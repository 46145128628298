import * as Sentry from '@sentry/browser';
import createSentryMiddleware from "redux-sentry-middleware";
import { ReportingObserver as ReportingObserverIntegration, CaptureConsole } from '@sentry/integrations';

import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers";
import { composeWithDevTools } from 'redux-devtools-extension';

if(false && process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: process.env.SENTRY_URL ||  "https://79f17dc0512249ce9dfeb9cdfc1c9bef@logs.combigo.com/16",
        integrations: [
	        new ReportingObserverIntegration({
	            levels: ['warn', 'error'] // , 'debug', 'assert'
	        }),
	        new CaptureConsole({
	        	levels: ['warn', 'error'] // , 'debug', 'assert'
	        })
	    ],
	    environment: process.env.NODE_ENV,
	    release: process.env.CI_COMMIT_SHA
    });
}


const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(createSentryMiddleware(Sentry)),
    ));

export default store;