import { 
  SET_LOCALE,SET_USER,SET_PAGE
} from '../actions/types';




const initialState = {
  "locale":"",
  "page":{pageUsers:[],state_processing:[],lastUpdated:0,state_truspilot:{}},
  "pageUsers":{list:[],id:""},
  "name":localStorage.getItem("user3") || ""
};


define(initialState)


function define(initialState)
{
  let locale = localStorage.getItem("combigo_locale")
  if(!locale) locale = getLangByDomain()
  if(!locale) locale = parseQuery(window.location.search,"locale")
  if(!locale) locale  = getLangByNavigator()[0]
  if (!locale) locale = "fr-FR"

  initialState.locale = locale

  return 
  function parseQuery(url_search,properties)
  {
      let index = url_search.indexOf(properties + "=")
      if (index!=-1)
      {
        let flagSave = false
        let str = ""
        for (let k=index;k<url_search.length;k++)
        {
            if (flagSave)
            {   
                if (url_search[k]=="&") break;
                str+= url_search[k]
            }
            else if (url_search[k]=="=")  flagSave = true
        }
        return  str
      }
  }

  function getLangByDomain()
  {
      let tln = window.location.origin.split(".").pop()
      if (tln=="com")
      {
          let array = (window.location.href || "").split("/")
          if (array[0] && array[0].length==2) return array[0]
      }
      else if (tln=="fr") return "fr-FR"
      else if (tln=="es") return "es-ES"
      else if (tln=="it") return "it-IT"
  }

  function getLangByNavigator(unique = {},list = [])
  {
      if(window.navigator.language)
      {
          unique[window.navigator.language]=true
          list.push(window.navigator.language)
      }
      for (let lang of  (window.navigator.languages || []))
      {
          if ( unique[lang]) continue
          list.push(lang)
          unique[lang] = true
      }
      return list
  }

}






export default function(state = initialState, action) {
  switch (action.type) {
    case SET_LOCALE:
        return {...state, locale: action.payload.locale};


    case SET_USER:
      return {...state, name: action.payload.name};
      case SET_PAGE:
        return {...state, page: action.payload.data};
  
    default:
      return state;
  }
}










