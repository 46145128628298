import { 
    SET_BOOKING,RESET_BOOKING
} from './types';

export const setBooking = (booking) => ({
    type: SET_BOOKING,
    payload: booking
})

export const resetBooking = () => ({
    type: RESET_BOOKING,
    payload: {
    }
})
