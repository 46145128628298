import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';


class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            description: "",
            amount: props.amount,
            disabled:false
        }
    }

    render() {

        return (<div>
            <Dialog open={this.props.open} onClose={() => {
                this.props.onClose()
                this.setState({ description: "", amount: "" })
            }} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{"CAPTURE " + this.props.data.provider + " " + this.props.data.id}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {"Montant maximal capturable : " + this.props.data.amountUpdated + " " + this.props.currency + ""}
                        <div style={{ display: "flex", "flexDirection": "row" }}>

                            <TextField
                                label={"Montant de la capture"}

                                value={this.state.amount}
                                onChange={(ev) => {
                                    this.setState({ amount: ev.target.value })
                                }}
                                autoFocus
                                margin="dense"
                                id="comment"
                                fullWidth
                            />
                            <FormControl className={"select-status"}>
                                <InputLabel id="demo-simple-select-label">{"Currency"}</InputLabel>
                                <Input id="demo-simple-select-label"
                                    labelId="demo-simple-select-label"
                                    disabled={true}
                                    value={this.props.currency} />
                                {this.props.currency!="EUR"?<FormHelperText id="my-helper-text">{"Attention currency"}</FormHelperText>:null}
                            </FormControl>

                        </div>


                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        this.props.onClose()
                        this.setState({ description: "", amount: "" })
                    }} color="primary">
                        {this.props.btnCancel || "Cancel"}
                    </Button>
                    <Button 
                    disabled={this.state.disabled}
                    onClick={() => {
                        
                        let valueAmount = Number(String(this.state.amount).trim().replace(",", "."))
                        if (isNaN(valueAmount)) {
                            return this.props.onError("Valeur du montant n est pas un nombre")
                        }
                        if (!valueAmount) {
                            return this.props.onError("Valeur du montant est 0")
                        }

                        this.setState({disabled:true},()=>{
                            this.props.onSave({ amount: valueAmount, id: this.props.data.id, provider: this.props.data.provider },()=>{
                                this.setState({disabled:false})
                            })
                        })
                       
                    }} color="primary">
                        {this.props.btnValid || "Capturer"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
        );
    }
}

export default Modal;