import React from 'react';
import ReactDOM from 'react-dom';
import './assets/scss/main.scss';

import App from './App';
//https://jakearchibald.com/2016/caching-best-practices/
// import registerServiceWorker from './registerServiceWorker';

import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux'
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './Theme/index';

import momentDurationFormatSetup from 'moment-duration-format';
import moment from 'moment';

import store from './Store/store.js';
import cookies from './cookies';

window.store = store;

momentDurationFormatSetup(moment);

// pour mettre un cookie dans le cas d'une redirection
cookies()

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <CssBaseline />
                
                <App />
                
            </BrowserRouter>
        </ThemeProvider>
    </Provider>,
    document.getElementById('root')
);
// registerServiceWorker();

