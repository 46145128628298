import React, { Component, version } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { parseQuery, _fetch } from "./../helpers"
import "./EditConfig.scss"
import MaterialTable, { MTableToolbar } from "material-table";
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import IconButton from '@material-ui/core/IconButton';
import Normal from './ConfigGeneral/Normal.js';
import ModelTable from "./../Components/Table/modelTable"


const options = {
    filterType: 'checkbox',
    print: false,
    display: false,
    rowsPerPage: 200,
    selectableRows: 'none',
    selectableRowsHideCheckboxes: true,
    download: true,
    filter: true,
    search: true,
    viewColumns: true,
};



const columns = [

    {
        name: "provider",
        label: "provider",
        custom: {
            type: "input"
        },
        options: {
            sort: true
        }
    },
    {
        name: "active",
        label: "active",
        custom: {
            props:{
                style: { minWidth: "80px", maxWidth: "80px" },
            },
            type: "checkbox"
        },
        options: {
            sort: true
        }
    },
    {
        name: "freq_minute",
        label: "freq_minute",
        custom: {
            props:{
                style: { minWidth: "80px", maxWidth: "80px" },
            },
            type: "input"
        },
        options: {
            sort: true
        }
    },
    {
        name: "max",
        label: "max",
        custom: {
            props:{
                style: { minWidth: "80px", maxWidth: "80px" },
            },
            type: "input"
        },
        options: {
            sort: true
        }
    },
    {
        name: "expiredMinute",
        label: "expiredMinute",
        custom: {
            props:{
                style: { minWidth: "80px", maxWidth: "80px" },
            },
            type: "input"
        },
        options: {
            sort: true
        }
    },
    {
        name: "timeout",
        label: "timeout",
        custom: {
            props:{
                style: { minWidth: "80px", maxWidth: "80px" },
            },
            type: "input"
        },
        options: {
            sort: true
        }
    },

    {
        name: "trigUsedBySec",
        label: "trigUsedBySec",
        custom: {
            props:{
                style: { minWidth: "80px", maxWidth: "80px" },
            },
            type: "input"
        },
        options: {
            sort: true
        }
    }, {
        name: "modeCreate",
        label: "modeCreate",
        custom: {
            props:{
                // style: { minWidth: "80px", maxWidth: "80px" },
            },
            type: "input"
        },
        options: {
            sort: true
        }
    },
    
    {
        name: "nav",
        label: "nav",
        custom: {
            props:{
                multiline:true,

                 style: { minWidth: "120px", maxWidth: "150px" },
            },
            type: "input"
        },
        options: {
            sort: true
        }
    },
    {
        name: "proxy.type",
        label: "proxy.type",
        custom: {
            props:{
                multiline:true,
                style: { minWidth: "300px", maxWidth: "300px" },
                // style: { minWidth: "80px", maxWidth: "80px" },
            },
            type: "input"
        },
        options: {
            sort: true
        }
    },
      {
        name: "optionsPage",
        label: "optionsPage",
        custom: {
            props:{
                multiline:true,
                style: { minWidth: "300px", maxWidth: "300px" },
                // style: { minWidth: "80px", maxWidth: "80px" },
            },
            type: "input"
        },
        options: {
            sort: true
        }
    },
    
    
    {
        name: "comment",
        label: "comment",
        custom: {
            type: "input"
        },
        options: {
            sort: true
        }
    },
    {
        name: "",
        label: "",
        custom: {
            onlyEdit: true,
            type: "action_delete"
        },
        options: {
            sort: false
        }
    }
];




class Body extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            _id: null
        }

        columns[8].custom.list= this.props.base.countries.map((l)=>{
            return {id:l.code || l.id,title:l.label  || l.name}
        })
    }

    componentDidMount() {

        let query = (parseQuery(window.location.search) || {})
        if (query && query._id) {
            this.setState({ _id: query._id })
        }
        else {
            this._request({ action: "get" }, (success, data) => {
                if (success) this.setState({ dataTemp: data.data,hashVersion:data.hashVersion })
            })
        }
    }

    componentDidUpdate(prevProps) {

    }

    _request(body, cb) {
        _fetch(process.env.REACT_APP_URL + "/crm/technical_config/general", {
            "timeout": 2000,
            "nbRetry": 2,
            "method": "post",
            "headers": {},
            "body": {
                ...body,
                nameConf:this.props.nameConf || "configuration/helpBots/default/config2.json"
            }
        })
            .then(function (response) {
                if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                let data = response.json()
                cb(true, data, response)

            }.bind(this))
            .catch(function (e) {
                console.log("Error request", e);
                cb(false, undefined, e)

                alert("reload the page")

            }.bind(this));
    }




    render() {

     


        let data = this.state.dataTemp || this.props.data
        if (!data){
            return null
        }
        if (this.state.refresh) {
            this.setState({ refresh: false })
        }
        // data = {
        //     data: [
        //         {
        //             "provider": "esky",
        //             "active": false,
        //             "activeSearch": false,
        //             "activeBotsAPI": true,
        //             "activeCacheWorker": false,
        //             "activeBotsBooking": true,
        //             "searchConfiguration": {
        //                 "searchOnlyOneWay": false,
        //                 "searchRadius": 50,
        //                 "listToSearchId": [
        //                     "esky"
        //                 ],
        //                 "typeData": [
        //                     "PLANE",
        //                     "SEAPLANE",
        //                     "HELICOPTER"
        //                 ]
        //             }
        //         }
        //     ]
        // }
        let dataTemp = data.data



        return <div className={"view-transaction "}>

            <div className={"title-page"}>
                {"Listes des Providers " + new Date().toISOString()}
            </div>

            <div style={{ maxWidth: "100%" }} className='container-table'>
                {this.state.refresh ? null : <ModelTable
                    className={"table-edit_config"}
                    data={{ ...data, data: dataTemp }}
                    dataOriginal={data}
                    name={"edit_config"}
                    changeModeView={this.state.changeModeView}

                    // disabledModification={this.props.disabledModification || this.state.disabledModification}
                    readonly={this.props.readonly}
                    columns={columns}
                    options={options}
                    modelLineAdd={{
                       
                    }}
                    onChange={(dataInput) => {
                        // console.log("dataInput",dataInput)
                        return new Promise((resolve,reject)=>{
                            this._request({ action: "save" ,hashVersion:this.state.hashVersion,data:dataInput}, (success, data) => {
                                if (success){
                                    this.setState({ dataTemp: data.data,hashVersion:data.hashVersion })
                                    resolve(true)
                                }
                                else{
                                    resolve(false)
                                }
                            })
                        })

                       
                    }}
                >

                </ModelTable>
                }
            </div>


        </div>
    }
}








const mapStateToProps = (state) => {
    return {  base: state.data };
};

export default connect(mapStateToProps, {})( withRouter(Body));