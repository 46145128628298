/* eslint-disable no-use-before-define */
import React, { createRef, useState, useRef } from 'react';
// import JoditEditor from "jodit-react";
//  import 'jodit';
import { Jodit } from 'jodit'
import 'jodit/build/jodit.min.css';
// import ReactQuill from 'react-quill-2';
// import 'react-quill-2/dist/index.css'
//import 'react-quill/dist/quill.snow.css';
// import Quill from "quill";
//   import "quill/dist/quill.snow.css";
//   import { useQuill } from 'react-quilljs';
//   import 'quill/dist/quill.snow.css'; // Add css for snow theme
// import "quill-mention";
// import "quill-mention/dist/dist/quill.mention.css"

// import 'react-summernote/lang/summernote-en-GB'; // you can import any other locale
//https://xdsoft.net/jodit/
// Import bootstrap(v3 or v4) dependencies
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/tooltip';
// import 'bootstrap/dist/css/bootstrap.css';
// import ReactSummernote from 'react-summernote';
// import 'react-summernote/dist/react-summernote.css'; // import styles

// import { convertToHTML, convertFMatromHTML } from 'draft-convert';

//https://newbedev.com/how-to-add-custom-font-sizes-to-quilljs-editor
import "./EditorText.scss"


class Body extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            textValue: props.value || "",
            idDiv: "" //"edit-" +String(Math.round(Math.random()*100000000)),
        }

        // this.editor = createRef(null)
        // this.onChange = this.onChange.bind(this)

    }

    _addJodit = () => {
        // let exist = document.querySelector('#' + this.state.idDiv)
        // if (!exist){
        //     return setTimeout(()=>{
        //     },50)
        // }
        const buttons = [
            'bold',
            'strikethrough',
            'underline',
            'italic', '|',
            'font',
            'fontsize',
            'brush',
            'paragraph', '|',
            'ul',
            'ol', '|',
            'align', 'undo', 'redo', '|',
            'outdent', 'indent', '|',
            'image',
            'video',
            'table',
            'link', '|',
            'hr',
            'eraser', '|',
            'symbol',
            // 'fullsize',
            // 'print',
            // 'about'
        ]
        // console.log("jodit",this.state.idDiv)
        this.editor = new Jodit('#' + this.state.idDiv, {
            fullsize: false, // fullsize editor
            toolbarButtonSize: "small",
            readonly: false,
            allowResizeY: true,
            statusbar: false,
            // disablePlugins: ['mobile'],
            autofocus: true,
            buttons: buttons,
            buttonsMD: buttons,
            buttonsSM: buttons,
            buttonsXS: buttons,
            ...this.props.configEditor

        });
        this.editor.value = this.state.textValue;
        // console.log(this.editor)
        this.editor.events.on('change', (value) => {

            // console.log("onchag",value )

            if (this.props.instantUpdated) {
                if (this.props.onChange) {
                    this.props.onChange(value)
                }
            }
            else {
                if (this.props.onChange) {
                    if (this.lastTimeout) {
                        try { clearTimeout(this.lastTimeout) } catch (e) { }
                    }
                    this.lastValue = Math.random() + "-" + Date.now()
                    let lastValueFixed = this.lastValue
                    this.lastTimeout = setTimeout(() => {
                        this.lastTimeout = undefined
                        this._updateOnChangeTrigger(lastValueFixed, value)
                    }, 500)
                    //     this.props.onChange(value)
                }
            }

            // this.setState({ textValue: value })


        })

        this.setState({ ready: Math.random() })
    }



    _updateOnChangeTrigger(lastValue, value) {
        if (this.lastValue == lastValue) {
            this.props.onChange(value)
        }
    }


    _generateZone = () => {

        if (this.state.idDiv) {
            let elem = document.querySelector("#cont-" + this.state.idDiv);
            if (elem && elem.parentNode) {
                elem.parentNode.removeChild(elem);
            }
        }

        let idDiv = "ed-" + String(Math.round(Math.random() * 1000000))
        this.setState({
            idDiv: ""
        }, () => {
            this.setState({
                idDiv: idDiv
            }, () => {
                // console.log("id",this.props,this.props.version)
                this._addJodit()
            })
        })


    }

    componentDidMount() {
        this._generateZone()
    }

    _updateValue = () => {

        let textHTML = this.props.value || ""
        // this.setState({
        //     textValue: textHTML
        // });
        if (this.editor && this.editor.value != textHTML) {
            console.log("updatevalue ", textHTML)
            this.editor.value = textHTML;
        }

    }

    componentWillUnmount() {

    }

    componentDidUpdate(prevProps, prevState) {

        if (this.id != this.props.id || this.props.version != this.version) {
            this.id = this.props.id
            this.version = this.props.version
            this._updateValue()
        }

    }





    render() {

        if (!this.state.idDiv) return null;

        return (
            <>
                <div id={"cont-" + this.state.idDiv}>
                    <div id={this.state.idDiv} className={this.props.className}></div>
                </div>
            </>


        );
    }
}
export default Body;
/*

 // <ReactQuill
            //     theme="snow"
            //     value={this.state.textValue}
            //     onChange={this.onChange}
            //     modules={{
            //         mention: {
            //              allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            //             mentionDenotationChars: ["@", "#"],
            //             source: function (searchTerm, renderList, mentionChar) {
            //                 return
            //                 let values;

            //                 if (mentionChar === "@") {
            //                     values = atValues;
            //                 } else {
            //                     values = hashValues;
            //                 }
            //                 console.log(searchTerm, mentionChar, renderList)

            //                 if (searchTerm.length === 0) {
            //                     renderList(values, searchTerm);
            //                 } else {
            //                     const matches = [];
            //                     for (let i = 0; i < values.length; i++)
            //                         if (
            //                             ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
            //                         )
            //                             matches.push(values[i]);
            //                     renderList(matches, searchTerm);
            //                 }
            //             }
            //         }
            //     }

            //     }
            // />


            // <ReactSummernote
            //     value={this.state.textValue}
            //     options={{
            //         lang: 'en-GB',
            //         height: this.props.height || 200,
            //         //   dialogsInBody: true,
            //         toolbar: [
            //             ['font', ['bold', 'underline', "color", 'clear']],
            //             ['fontname', ['fontname', "fontsize"]],
            //             ['para', ['ul', 'ol', 'paragraph', "style"]],
            //             ['insert', ['link', 'picture', 'video', "table", "hr"]],
            //             ['view', ['undo', 'redo']]
            //         ],
            //         // dialogsInBody: false,
            //         focus: true
            //     }}

            //     // onImageUpload={(files)=>{
            //     //     console.log("Image ",files)
            //     // }}
            //     onChange={this.onChange}


            // />

    _mount = () => {
        let o = document.querySelector('#editor' ) //this.state.idDiv
        console.log("o", o)
        if (!o) {
            setTimeout(() => {
                this._mount()
            }, 200)
            return
        }

        const fontSizeArr = ['12px', '6px', '8px', '9px', '10px', '14px', '16px', '18px', '20px', '24px', '32px'];

        // var Size = Quill.import('attributors/style/size');
        // Size.whitelist = fontSizeArr;
        // Quill.register(Size, true);
        let color = ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", '#33c']

        var toolbarOptions = [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            [{ 'size': fontSizeArr }],  // custom dropdown ['small', false, 'large', 'huge']
            [{ 'color': color }, { 'background': color }],          // dropdown with defaults from theme
            [{ 'font': ["serif", "monospace"] }], //"roboto"
            [{ 'align': [] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            ['link', 'image', 'video'],
            ['blockquote', 'code-block'], //, "hr", "span-block"
            ['clean']                                         // remove formatting button

            // [
            //    {
            //     handlers: {
            //         // handlers object will be merged with default handlers object
            //         'link': function (value) {
            //             console.log("handlers link ", value)
            //             if (value) {
            //                 var href = prompt('Enter the URL');
            //                 this.quill.format('link', href);
            //             } else {
            //                 this.quill.format('link', false);
            //             }
            //         }
            //     }
            // }
            //]
        ];
        //https://www.npmjs.com/package/quill-image-resizer
        //https://www.npmjs.com/package/@winterlood/quill-image-resize-module-react
        if (this.state.idDiv) {
            //+ this.state.idDiv
            this.quill = new Quill('#editor' , {
                // modules: { toolbar: '#toolbar' },
                theme: 'snow',
                readOnly : false,
                modules: {
                    // imageResize: {
                    //     parchment: Quill.import('parchment')
                    //     // See optional "config" below
                    //   }
                    // 'link-tooltip': true
                    // toolbar: toolbarOptions,
                    // mention: {
                    //     allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                    //     mentionDenotationChars: ["@", "#"],
                    //     source: (searchTerm, renderList, mentionChar) => {
                    //         let values;

                    //         if (mentionChar === "@") {
                    //             values = this.props.mentions;
                    //         } else {
                    //             values = this.props.hashtag;
                    //         }
                    //         if (!values) return

                    //         if (searchTerm.length === 0) {
                    //             renderList(values, searchTerm);
                    //         } else {
                    //             const matches = [];
                    //             for (let i = 0; i < values.length; i++)
                    //                 if (
                    //                     ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
                    //                 )
                    //                     matches.push(values[i]);
                    //             renderList(matches, searchTerm);
                    //         }
                    //     }
                    // }
                }
            });

            this.quill.enable();

            //this.quill.root.innerHTML = "coucou"
            console.log(this.quill)
            this.quill.on('text-change', (delta, oldDelta, source) => {

                // let textHTML = this.quill.root.innerHTML;
                // this.setState({
                //     textValue: textHTML
                // })

                // if (this.props.onChange) {
                //     this.props.onChange(textHTML)
                // }

                // if (source == 'api') {
                //     console.log("An API call triggered this change.");
                // } else if (source == 'user') {
                //     console.log("A user action triggered this change.");
                // }
                // console.log('Text change!', delta, oldDelta, source);
            });

            this._updateValue()
            this.setState({ quillReady: true })
        }
    }






*/