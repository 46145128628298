import React, { Component, version } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { parseQuery, _fetch } from "./../helpers"
import "./AllTransaction.scss"
import Table from "./../Components/TableAllTransaction/Table.js"
import Toolbar from "./../Components/TableAllTransaction/Toolbar"

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Planning from "./../Components/CalendarView/CalendarView.js"
import { CombigoSnackbar, CombigoUpdate } from './../Components/Snackbar/Snackbar';
import MaterialTable, { MTableToolbar } from "material-table";

import Collapse from '@material-ui/core/Collapse';
import FilterListIcon from '@mui/icons-material/FilterList';
import Button from '@material-ui/core/Button';
import { Helmet } from "react-helmet";





class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snackMessage: null,
      errorMessage: null,
      openUpdatedButton: false,
      version: "init"
    }



    this.lastRequest = Date.now()
    this.search = this.search.bind(this)
    this.lastUpdateVersion = 0


  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval)
  }

  componentDidMount() {
    this.search(true, this.state.body)
    if (this.props.timeout) this.interval = setInterval(function () {
      this.search(false, this.state.body, true)
    }.bind(this), this.props.timeout)
  }


  componentDidUpdate() {



  }

  async search(force, body = {}, isCycle = false) {

    if (isCycle === false) {
      if (this.props.resetInit) this.props.resetInit()
    }
    if (force || Date.now() - this.lastRequest > 0.8 * this.props.timeout) {
      this.lastRequest = Date.now()
      this.lastUpdateVersion++
      setTimeout(function (lastUpdateVersion) {
        if (this.lastUpdateVersion != lastUpdateVersion) return
        request.call(this, body, this.lastUpdateVersion)

      }.bind(this, this.lastUpdateVersion), 1000)

    }


    function request(body, lastUpdateVersion) {
      _fetch(process.env.REACT_APP_URL + this.props.url, {
        "timeout": 10000,
        "nbRetry": 1,
        "method": "get",
        "headers": {},
        // "body": {
        //   filter: {
        //     ...this.props.filter,
        //     ...body
        //   },
        // }
      })
        .then(function (response) {
          if (this.lastUpdateVersion != lastUpdateVersion) return
          if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
          let data = response.json()
          this._onUpdate(data)
        }.bind(this))
        .catch(function (e) {
          if (this.lastUpdateVersion != lastUpdateVersion) return

          console.log("Error toolbar", e);
          this._onUpdate(undefined, { error: true })

        }.bind(this));
    }


  }

  _onUpdate = (data) => {
    if (data) {
      if (this.state.version != data.version) {
        // data.sort((a,b)=>{
        //   return a.balance.amount - b.balance.amount
        // })
        if (this.state.version === "init") {
          this.setState({ data: data.data, version: data.version, dateUpdate: Date.now(), countVersion: 0, newDataVersion: null, openUpdatedButton: false })
        }
        else {
          let newVersion = (data.version != this.state.lastVersionUpdated ? 1 : 0)
          let countVersion = this.state.countVersion + newVersion
          this.setState({
            openUpdatedButton: true, countVersion: newVersion ? countVersion : this.state.countVersion,
            lastVersionUpdated: newVersion ? data.version : this.state.lastVersionUpdated,
            newDataVersion: newVersion ? { data: data.data, version: data.version, dateUpdate: Date.now() } : this.state.newDataVersion
          })
          // this.setState({ newVersion:{data: data.data, version: data.version, dateUpdate: Date.now()} })
        }
      }
      else {
        // this.setState({ dateUpdate: Date.now() })
      }
    }
  }

  totalBalance = () => {
    let balance = 0
    if (this.state.data) {
      for (let l of this.state.data) {
        balance += l.balance.amount
      }
    }

    return Math.round(balance * 100) / 100
  }

  render() {



    const columns = [


      {
        title: "Title", field: "title", render: (rowData) => {
          return rowData.name || rowData._id
        }
      },
      {
        title: "Provider", field: "provider", render: (rowData) => {
          return rowData.provider
        }
      },
      {
        title: "Date", field: "date", render: (rowData) => {

          let deltaDate = (Date.now() - rowData.date) / 86400
          let color = "#33c"
          if (deltaDate > 1.5) color = "red"

          return <span style={{ color: color }}>{new Date(rowData.date).toISOString().substr(0, 19).replace("T", " ")}</span>
        },

      },
      {
        title: "typeData", field: "typeData", render: (rowData) => {
          return rowData.typeData
        },

      },
      {
        title: "price", field: "price", render: (rowData) => {
          let price = String(rowData.balance.amount).split(".")
          let priceString = String(rowData.balance.amount)
          if (price[0].length > 3) {
            priceString = price[0].substr(0, price[0].length - 3) + " " + price[0].substr(price[0].length - 3, 3) + (price[1] ? "." + price[1] : "")
          }
          priceString = priceString.replace("-", "- ")

          return <div style={{ color: rowData.balance.amount < 0 ? "red" : "rgb(51, 204, 51)" }}>{priceString + " " + rowData.balance.currency}</div>
        }
      },

    ]





    return <div className={"view-transaction"}>

      <div style={{ display: "flex" }}>

        <div className={"title-page"}> {"Finance Balance"}</div>
        {this.state.data ? <div className={"title-page"}> {"Balance : " + this.totalBalance() + " €"}</div> : null}
        <div>
          <Button
            // className="secret-button"
            onClick={() => {
              _fetch(process.env.REACT_APP_URL + "/crm/auto/relaunch_booking_processing?forceCRM=1", {
                "timeout": 10000,
                "nbRetry": 1,
                "method": "get",
                "headers": {},
              })
                .then(function (response) {
                  if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                  let data = response.json()
                  window.alert("OK GO RELAUNCH")
                  console.log("ok relaunch job", data);

                }.bind(this))
                .catch(function (e) {

                  console.log("Error relaunch job", e);
                  window.alert("NO CORRECT RELAUNCH")

                }.bind(this))

            }}>
            {"Secret Relaunch"}
          </Button>
        </div>
      </div>



      {this.state.data &&

        <MaterialTable
          columns={columns}
          data={this.state.data}
          title={this.state.title || ""}
          options={{
            pageSize: 15,
            pageSizeOptions: [15],
            grouping: false,
            exportButton: false,
            search: false,
            padding: "default"
            // filtering: true
          }}
        />}

      <CombigoUpdate
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        nb={this.state.countVersion}
        autoHideDuration={null}
        open={this.state.openUpdatedButton}
        // onClose={() => this.setState({ openUpdatedButton: false })}
        onClick={() => {
          let newDataBackup = this.state.newDataVersion
          this.setState({ openUpdatedButton: false, countVersion: 0 })
          this.setState({ ...newDataBackup, newDataVersion: null, lastVersionUpdated: null })
        }}
      />


    </div >
  }
}


export default withRouter(Body);

