
import OneTransaction from "./Homepage/OneTransaction";
import Login from "./Homepage/Login";
import AllTransaction from "./Homepage/AllTransaction";
// import AllLogs from "./Homepage/AllLogs";

// import EditMail from "./Homepage/EditMail";
import AllTransactionBatch from "./Homepage/AllTransactionBatch"
import AllTasks from "./Homepage/AllTasks"
import AllTicketDesk from "./Homepage/AllTicketDesk"
import TableLogs from "./Components/TableLogs/TableLogs"
import TableRealTime from "./Components/TableRealTime/TableRealTime"
import TableMail from "./Components/TableMail/TableMail"
import TableSms from "./Components/TableSms/TableSms"
import AllBill from "./Homepage/AllBill"
import AllFinanceTable from "./Homepage/AllFinanceTable"
import AllTableBankDetailsAdmin from "./Components/TableBankDetailsAdmin/TableMain"
import AllCalendar from "./Components/CalendarView/Calendar"
import AllTransactionBank from "./Homepage/AllTransactionBank"
import TableErrorUpdateCRM from "./Components/TableErrorUpdate/TableErrorUpdate"
import AllAccountingFlow from "./Components/TableFlowAccount/TableMain"
import Voucher from "./Homepage/Voucher"
import BookingTool from "./Homepage/BookingTool"
import Metrics from "./Homepage/Metrics"
import MetricsTrustpilot from "./Homepage/MetricsTrustpilot";
import MetricsBusiness from "./Homepage/MetricsBusiness"
import { Helmet } from "react-helmet";

import ComponentWithNavbar from "./Homepage/ComponentWithNavbar.js"
import { _fetch, isMobile } from './helpers';
import { setCarrier, setUsers } from './Store/actions/data';

import { setPage } from "./Store/actions/user"
import ConfigurationTech from "./ComponentsConfigurationTech/Home.js"
import ConfigurationTechEditConfig from "./ComponentsConfigurationTech/EditConfig.js"
import EditConfigHelpBots from "./ComponentsConfigurationTech/EditConfigHelpBots.js"
import React, { Component } from 'react';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';

import './App.scss';

import 'react-dates/initialize';
import { connect } from 'react-redux';

import { IntlProvider } from "react-intl";
import messages from "./lang/messages.json";
import moment from "moment";

require('moment-timezone')
moment.tz.setDefault('Africa/Bamako')


class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            readyUsers: false
        };
        this.apikey = localStorage.getItem("x-api-key")
        this._getPageUsers = this._getPageUsers.bind(this)





    }


    componentWillUnmount() {
        // clearInterval(this.interval)
    }

    // _notifTrustpilot(state_trustpilot){
    //     let ex_state_trustpilot
    //     try{
    //         ex_state_trustpilot=JSON.parse( localStorage.getItem("state_trustpilot"))
    //     }catch(e){}

    //     if (state_trustpilot.quantity!=ex_state_trustpilot.quantity){
    //         if (window.isTabActive){

    //         }
    //     }
    // }

    _getPageUsers(refresh = true) {

        if (refresh == true) {
            if (this.lastPathname == window.location.pathname) {
                return
            }
            this.props.setPage({ list: [] })
        }

        this.lastPathname = window.location.pathname
        _fetch(process.env.REACT_APP_URL + '/crm/users/page', {
            "timeout": 2000,
            "nbRetry": 2,
            "method": "post",
            "headers": {},
            "body": {

                url_pathname: window.location.pathname,
                url: window.location.url,
                url_search: window.location.search,
                userName: this.props.nameUser,
            }
        })
            .then(function (response) {
                if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                let list = response.json()

                if (list && list.pageUsers) {
                    list.pageUsers = list.pageUsers.map((line) => {
                        return this.usersMap.get(line.userName || line.id)
                    })
                    this.props.setPage({
                        pageUsers: list.pageUsers,
                        state_processing: list.state_processing,
                        state_processing_affected: list.state_processing_affected,

                        state_undermanagement: list.state_undermanagement,
                        state_disruptedconnection: list.state_disruptedconnection,
                        state_luggage: list.state_luggage,
                        state_trustpilot: list.state_trustpilot,
                        state_bill: list.state_bill,
                        lastUpdated: Date.now()
                    })

                    // this._notifTrustpilot(list.state_trustpilot)
                }
                else {
                    list = list.map((line) => {
                        return this.usersMap.get(line.userName || line.id)
                    })
                    this.props.setPage({ pageUsers: list })
                }



                // console.log("OK list users ", list)
            }.bind(this))
            .catch(function (e) {
                console.log("Error page users", e)
            }.bind(this));

        if (!refresh) {

            setTimeout(() => {
                this._getPageUsers(refresh)
            }, 15000)
        }
    }

    _loadUsers = () => {
        _fetch(process.env.REACT_APP_URL + '/crm/data/base?type=users', {
            "timeout": 12000,
            "nbRetry": 3,
            "method": "get",
            "headers": {}
        })
            .then(function (response) {
                if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                let data = response.json()
                if (data.users) this.props.setUsers(data.users);

                this.setState({ readyUsers: true })
            }.bind(this))
            .catch(function (e) {
                //  alert("Reload the application, if the problem persist contact the admin")
                console.log("Error users", e)
            }.bind(this));
    }



    componentDidMount() {

        if (window.location.href.indexOf("#iamiframe") != -1) {
            //console.log("I am an iframe")
            document.body.classList.add("iframe-hidden-navbar")
        }


        this._loadUsers()
    }

    componentWillMount() {

        // console.log("Mode debug avec ready=true dans app.js")
        //   this.setState({ ready: true })
        // let infos = window.store.getState()
        if (!this.props.nameUser || !this.apikey) return
        _fetch(process.env.REACT_APP_URL + '/crm/data/base?type=carriers', {
            "timeout": 12000,
            "nbRetry": 3,
            "method": "get",
            "headers": {}
        })
            .then(function (response) {
                if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                let data = response.json()
                if (data.carriers) this.props.setCarrier(data.carriers);
                // if (data.users) this.props.setUsers(data.users);

                this.setState({ ready: true })
            }.bind(this))
            .catch(function (e) {
                //  alert("Reload the application, if the problem persist contact the admin")
                console.log("Error carrier", e)
            }.bind(this));
    }


    componentDidUpdate(prevProps) {
        if (prevProps.users.length == 0 && this.props.users.length) {
            this.usersMap = new Map()
            for (let line of this.props.users) this.usersMap.set(line.id, line)
            this._getPageUsers(false)

            this.unlisten = this.props.history.listen((location, action) => {
                this._getPageUsers(true)
                //   console.log("on route change",location,action);
            });

        }

    }


    render() {

        let lang = this.props.locale.substr(0, 2)
        let user = this.props.nameUser
        return (

            <IntlProvider locale={lang} messages={messages[lang]}>
                {(!user || !this.apikey) && this.state.readyUsers && <Login />}
                {user && this.apikey && this.state.ready && this.state.readyUsers && <div className='app-container'>
                    <Switch>
                        <Route exact path="/oneTransaction/:bookingId"
                            render={(props) => {
                                return <ComponentWithNavbar displayAvatar={true}>

                                    <OneTransaction {...props}
                                        bookingId={props.match && props.match.params ? props.match.params.bookingId : undefined}
                                        className="" />
                                </ComponentWithNavbar>
                            }
                            }
                        />
                        <Route exact path="/oneTransaction"
                            render={(props) => {
                                return <ComponentWithNavbar displayAvatar={true}>
                                    <OneTransaction {...props}
                                        className="" />
                                </ComponentWithNavbar>
                            }
                            }
                        />

                        <Route exact path="/voucher"
                            render={(props) => {
                                return <ComponentWithNavbar>
                                    <Helmet>
                                        <title>{"Combigo Voucher"} </title>
                                    </Helmet>
                                    <Voucher {...props}

                                        className="" /></ComponentWithNavbar>
                            }
                            }
                        />
                        {/* <Route exact path="/bill_edit"
                            render={(props) => {
                                return <ComponentWithNavbar>
                                    <Bill {...props}

                                        className="" /></ComponentWithNavbar>
                            }
                            }
                        /> */}
                        <Route exact path="/private/configuration_technical/consul"
                            render={(props) => {
                                return <ComponentWithNavbar><ConfigurationTech {...props}

                                    className="" /></ComponentWithNavbar>
                            }
                            }
                        />

                        <Route exact path="/private/configuration_technical/providers"
                            render={(props) => {
                                return <ComponentWithNavbar><ConfigurationTechEditConfig
                                    {...props}
                                    // nameConf={"providers"}
                                    className="" /></ComponentWithNavbar>
                            }
                            }
                        />
                        <Route exact path="/private/configuration_technical/helpbots"
                            render={(props) => {
                                return <ComponentWithNavbar><EditConfigHelpBots
                                    {...props}
                                    // nameConf={"providers"}
                                    className="" /></ComponentWithNavbar>
                            }
                            }
                        />




                        <Route exact path="/transactions_calendar"
                            render={(props) => {
                                return <ComponentWithNavbar>
                                    <Helmet>
                                        <title>{"Combigo Calendar"} </title>
                                    </Helmet>
                                    <AllCalendar planning={true} {...props}

                                        className="" /></ComponentWithNavbar>
                            }
                            }
                        />
                        <Route exact path="/transactions_calendar_meta"
                            render={(props) => {
                                return <ComponentWithNavbar>
                                    <Helmet>
                                        <title>{"Combigo Calendar"} </title>
                                    </Helmet>
                                    <AllCalendar meta={true} {...props}
                                        nbDayFuture={65}
                                        graph={true}
                                        className="" /></ComponentWithNavbar>
                            }
                            }
                        />


                        <Route exact path="/view_all_booking_batch"
                            render={(props) => {
                                return <ComponentWithNavbar>
                                    <Helmet>
                                        <title>{"Combigo AllBooking"} </title>
                                    </Helmet>
                                    <AllTransactionBatch  {...props}

                                        className="" /></ComponentWithNavbar>
                            }
                            }
                        />

                        <Route exact path="/view_all_logs"
                            render={(props) => {
                                return <ComponentWithNavbar>
                                    <Helmet>
                                        <title>{"Combigo Logs"} </title>
                                    </Helmet>
                                    <TableLogs  {...props}
                                        activeToolbarFilter={true}
                                        openLink={true}
                                        className="" />
                                </ComponentWithNavbar>
                            }
                            }
                        />
                        <Route exact path="/view_all_mail"
                            render={(props) => {
                                return <ComponentWithNavbar>
                                    <Helmet>
                                        <title>{"Combigo Mail"} </title>
                                    </Helmet>
                                    <TableMail  {...props}
                                        activeToolbarFilter={true}
                                        openLink={true}
                                        className="" />
                                </ComponentWithNavbar>
                            }
                            }
                        />
                        <Route exact path="/view_all_sms"
                            render={(props) => {
                                return <ComponentWithNavbar>
                                    <Helmet>
                                        <title>{"Combigo Sms"} </title>
                                    </Helmet>
                                    <TableSms  {...props}
                                        activeToolbarFilter={true}
                                        openLink={true}
                                        className="" />
                                </ComponentWithNavbar>
                            }
                            }
                        />


                        <Route exact path="/view_all_metrics"
                            render={(props) => {
                                return <ComponentWithNavbar>
                                    <Helmet>
                                        <title>{"Combigo Metrics"} </title>
                                    </Helmet>
                                    <Metrics  {...props}
                                        activeToolbarFilter={true}
                                        className="" />
                                </ComponentWithNavbar>
                            }
                            }
                        />
                        <Route exact path="/view_all_metrics_business"
                            render={(props) => {
                                return <ComponentWithNavbar>
                                    <Helmet>
                                        <title>{"Combigo Metrics Business"} </title>
                                    </Helmet>
                                    <MetricsBusiness  {...props}
                                        activeToolbarFilter={true}
                                        className="" />
                                </ComponentWithNavbar>
                            }
                            }
                        />
                        <Route exact path="/view_all_metrics_trustpilot"
                            render={(props) => {
                                return <ComponentWithNavbar>
                                    <Helmet>
                                        <title>{"Combigo Trustpilot"} </title>
                                    </Helmet>
                                    <MetricsTrustpilot  {...props}
                                        activeToolbarFilter={true}
                                        className="" />
                                </ComponentWithNavbar>
                            }
                            }
                        />
                        <Route exact path="/view_all_realtime"
                            render={(props) => {
                                return <ComponentWithNavbar>
                                    <Helmet>
                                        <title>{"Combigo Realtime"} </title>
                                    </Helmet>
                                    <TableRealTime  {...props}
                                        activeToolbarFilter={true}
                                        openLink={true}
                                        className="" />
                                </ComponentWithNavbar>
                            }
                            }
                        />
                        <Route exact path="/view_all_ticket_desk"
                            render={(props) => {
                                return <ComponentWithNavbar>
                                    <Helmet>
                                        <title>{"Combigo Ticket Desk"} </title>
                                    </Helmet>
                                    <AllTicketDesk  {...props}

                                        className="" />
                                </ComponentWithNavbar>
                            }
                            }
                        />
                        <Route exact path="/view_all_tasks"
                            render={(props) => {
                                return <ComponentWithNavbar>
                                    <Helmet>
                                        <title>{"Combigo Tasks"} </title>
                                    </Helmet>
                                    <AllTasks  {...props}

                                        className="" />
                                </ComponentWithNavbar>
                            }
                            }
                        />
                        <Route exact path="/booking_tool"
                            render={(props) => {
                                return <ComponentWithNavbar>

                                    <BookingTool  {...props}

                                        className="" /></ComponentWithNavbar>
                            }
                            }
                        />

                        <Route exact path="/transactions"
                            render={(props) => {
                                return <ComponentWithNavbar>
                                    <Helmet>
                                        <title>{"Combigo Transactions"} </title>
                                    </Helmet>
                                    <AllTransaction
                                        {...props}
                                        updateRefresh={true}

                                        className="" /></ComponentWithNavbar>
                            }
                            }
                        />
                        <Route exact path="/bank/cards"
                            render={(props) => {
                                return <ComponentWithNavbar>
                                    <Helmet>
                                        <title>{"Combigo Cardbanks"} </title>
                                    </Helmet>
                                    <AllTransactionBank {...props}
                                        cards={true}
                                        className="" /></ComponentWithNavbar>
                            }
                            }
                        />

                        <Route exact path="/report/main"
                            render={(props) => {
                                return <ComponentWithNavbar>
                                    <Helmet>
                                        <title>{"Combigo EscapeGame"} </title>
                                    </Helmet>
                                    <TableErrorUpdateCRM {...props}
                                        activeTitle={true}
                                        activeToolbarFilter={true}
                                        className="" /></ComponentWithNavbar>
                            }
                            }
                        />


                        <Route exact path="/account/flow"
                            render={(props) => {
                                return <ComponentWithNavbar>
                                    <Helmet>
                                        <title>{"Flux Financier"} </title>
                                    </Helmet>
                                    <AllAccountingFlow {...props}
                                        activeTitle={true}
                                        activeToolbarFilter={true}
                                        className="" /></ComponentWithNavbar>
                            }
                            }
                        />

                        <Route exact path="/bank/transactions"
                            render={(props) => {
                                return <ComponentWithNavbar>
                                    <Helmet>
                                        <title>{"Combigo Cardbanks transactions"} </title>
                                    </Helmet>
                                    <AllTransactionBank {...props}
                                        transactions={true}
                                        className="" /></ComponentWithNavbar>
                            }
                            }
                        />

                        <Route exact path="/restricted/finance/balance"
                            render={(props) => {
                                return <ComponentWithNavbar>
                                    <Helmet>
                                        <title>{"Combigo Finance balance"} </title>
                                    </Helmet>
                                    <AllFinanceTable {...props}
                                        timeout={30000}
                                        url={"/crm/restricted/finance/balance"}

                                        className="" /></ComponentWithNavbar>
                            }
                            }
                        />
                        <Route exact path="/restricted/finance/bank_details"
                            render={(props) => {
                                return <ComponentWithNavbar>
                                    <Helmet>
                                        <title>{"Combigo Finance Details"} </title>
                                    </Helmet>
                                    <AllTableBankDetailsAdmin {...props}
                                        timeout={30000}
                                        url={"/crm/restricted/finance/bank_details"}

                                        className="" /></ComponentWithNavbar>
                            }
                            }
                        />


                        <Route exact path="/bill"
                            render={(props) => {
                                return <ComponentWithNavbar>
                                    <Helmet>
                                        <title>{"Combigo Bill"} </title>
                                    </Helmet>
                                    <AllBill {...props}
                                        transactions={true}
                                        className="" /></ComponentWithNavbar>
                            }
                            }
                        />


                        <Redirect from="/" to="/transactions" />

                        <Route exact path="/"
                            render={(props) => {

                                // return <ComponentWithNavbar><AllTransaction {...props}

                                //     className="" /></ComponentWithNavbar>
                            }
                            }
                        />
                    </Switch>

                </div>}
            </IntlProvider>
        );
    }
}

const mapStateToProps = (state) => {
    return { users: state.data.users, locale: state.user.locale, nameUser: state.user.name, page: state.user.page };
};

export default connect(mapStateToProps, { setCarrier, setUsers, setPage })(withRouter(App));
