import React from 'react';

import MaterialTable, { MTableToolbar } from "material-table";

class Body extends React.Component {
    constructor(props) {
        super(props);

        this.state = {


        }
        this.sizeTable = props.options && props.options.pageSize?props.options.pageSize:""
        
    }

    componentDidUpdate(prevProps) {
        let sizeBefore = prevProps.options && prevProps.options.pageSize?prevProps.options.pageSize:""
        let sizeAfter = this.props.options && this.props.options.pageSize?this.props.options.pageSize:""

        if (sizeAfter != sizeBefore) {
            this.sizeTable  = sizeAfter
            this.setState({ refresh: Math.random() })
        }
    }

    render() {
        if (this.sizeTable !=this.state.sizeTable) {
            this.setState({ sizeTable:  this.sizeTable  })
            return null
        }
        return <>
            <MaterialTable
          {  ...this.props}
            >

            </MaterialTable>
        </ >

    }
}

export default Body;
