
import * as React from 'react';
import { connect } from 'react-redux';
import { cloneObject } from '../../helpers';
import ModelTable from "./../Table/modelTable"
import './TableBooking.scss';
import moment from "moment";
import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';
import SelectHistory from "./../Table/select_history"
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';


const columns = [

    {
        name: "status",
        label: "status",
        custom: {
            type: "list",
            props: {
                disableClearable: true,
                style: { maxWidth: "80px" }
            },
            list: [
                { id: "ACTIVE", title: "ACTIVE" },
                { id: "PROBLEM", title: "PROBLEM" },
                { id: "WAIT_REFUND", title: "WAIT_REFUND" },
                { id: "REFUND", title: "REFUND" },
                { id: "TR_WAITING_VALIDE", "title": "TR_WAITING_VALIDE" },
                { id: "CANCEL", title: "CANCEL" },
                
                { id: "TR_WAITING_TICKET", "title": "TR_WAITING_TICKET" },

                { id: "CANCEL_VOLONTARY", title: "CANCEL_VOLONTARY" },
                { id: "CANCEL_OTHER", title: "CANCEL_OTHER" },
                { id: "CANCEL_TRANSPORTER", title: "CANCEL_TRANSPORTER" }
            ],
        },
        options: {
            sort: false
        }
    },

    {
        name: "transporter",
        label: "Vendeur",
        custom: {
            type: "autocomplete_create",
            props: {
                disableClearable: true
            },
            format: function (obj) {
                return obj && obj.title ? obj.title : ""
            },
         
            list: [],
        },
        options: {
            sort: false
        }
    },

 

    {
        name: "segments",
        label: "Segments",
        custom: {
            type: "list_checkbox",
            props: {
                style: { minWidth: "100px", maxWidth: "180px" },

                disableClearable: true
            },
            list: [

            ],
        },
        options: {
            sort: false
        }
    },
    {
        name: "booking_code",
        label: "Booking Number",
        custom: {
            type: "input",
        },
        options: {
            sort: false
        }
    },
    {
        name: "information_user",
        label: "Info User",
        custom: {
            type: "list_checkbox",
            props: {
                disableClearable: true,
                style: { maxWidth: "180px", minWidth: "100px" }
            },
            list: [
                // {id:"booking_code",title:"booking_code"},
                { id: "missing_ticket", title: "missing_ticket" },
                { id: "booking_problem", title: "booking_problem" },
                { id: "booking_mail", title: "booking_mail" },
                { id: "booking_password", title: "booking_password" },
                { id: "roundtrip_fictive", title: "roundtrip_fictive" },
                { id: "multidest_fictive", title: "multidest_fictive" },
                { id: "get_ticket_alone", title: "get_ticket_alone" },
                // { id: "client_must_add_bag_alone", title: "client_must_add_bag_alone" },


                { id: "baggage_need_check", title: "baggage_need_check" },
                { id: "baggage_hold_OK", title: "baggage_hold_OK" },
                { id: "baggage_hold_NOK", title: "baggage_hold_NOK" },
                { id: "baggage_cabin_OK", title: "baggage_cabin_OK" },
                { id: "baggage_cabin_NOK", title: "baggage_cabin_NOK" },
                { id: "baggage_refund_options", title: "baggage_refund_options" },
                { id: "info_bag_return_fictive", title: "info_bag_return_fictive" },

                { id: "bankcard_info", title: "bankcard_info" },
                { id: "hiddencity", title: "hiddencity" },

            ],
        },
        options: {
            sort: false
        }
    },
    {
        name: "booking_mail",
        label: "Booking Mail",
        custom: {
            type: "input",
        },
        options: {
            sort: false
        }
    },
    {
        name: "booking_mail_password",
        label: "Booking Password",
        custom: {
            type: "input",
        },
        options: {
            sort: false
        }
    },


    {
        name: "comment",
        label: "Comment.",
        custom: {
            type: "input",
            props: { multiline: true }
        },
        options: {
            sort: false
        }
    },
    {
        name: "passengers",
        label: "Passengers",
        custom: {
            type: "list_checkbox",
            props: {
                style: { maxWidth: "80px" },

                disableClearable: true
            },
            list: [],
        },
        options: {
            sort: true
        }
    },

    {
        name: "tickets",
        label: "Tickets",
        custom: {
            type: "files",
            props: {
                style: { maxWidth: "80px" }
            }
        },
        options: {
            sort: false
        }
    },
    {
        name: "files",
        label: "files",
        custom: {
            type: "files",
            props: {
                style: { maxWidth: "80px" }
            }
        },
        options: {
            sort: false
        }
    },
    {
        name: "dashboardLink",
        label: "Link",
        custom: {
            onlyView: true,
            render: (rowData) => {
                if (!rowData[11]) return null
                return <a href={rowData[11]} target="_blank">{"Link"}  </a>
            }
        },
        options: {
            sort: false
        }
    },
    {
        name: "",
        label: "",
        custom: {
            onlyEdit: true,
            type: "action_delete"
        },
        options: {
            sort: false
        }
    }

];


const options = {
    filterType: 'checkbox',
    print: false,
    display: false,
    rowsPerPage: 100,
    selectableRows: 'none',
    selectableRowsHideCheckboxes: true,
    download: false,
    filter: false,
    search: false,
    viewColumns: false,
};

function getName(fromName = "", fromCity = "", length = 10) {
    if (fromName && fromCity && fromName.length && fromCity.length) {
        if (fromName.length < fromCity.length) return fromName.substr(0, length)
        return fromCity.substr(0, length)
    }
    return (fromCity || fromName || "").substr(0, length)
}




class Account extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            refresh: false,
            errorMessage: null,
            snackMessage: null,
            changeModeView:Date.now()

        }
        this.dicoCarriers = {}
        this.id = this.props.booking.bookingId
        this.numVersion =this._createNumVersion()
        this._init = this._init.bind(this)
        this._init()

    }

    _createNumVersion = ()=>{
       return  this.props.data.numVersion + "-"+this.props.booking.segments.numVersion + "-"+this.props.booking.passengers.numVersion
    }
    componentDidUpdate() {
        let exVersion = this._createNumVersion()
        if (this.id != this.props.booking.bookingId || this.numVersion != exVersion) {
            this.numVersion =exVersion

            this._init()
            if (this.id != this.props.booking.bookingId ){
                this.setState({changeModeView:Math.random()})
            }
            this.id = this.props.booking.bookingId
            this.setState({refresh:true})


        }

    }


    _init() {
        let preselected = {}
        if (this.props.booking.segments && this.props.booking.segments.data) {
            for (let segment of this.props.booking.segments.data) {
                if (segment.transporter && segment.transporter.id) preselected[segment.transporter.id] = true
            }
        }
        let selected = []
        let list = []
        for (let line of this.props.base.carrier) {
            let o = { raw: line, title: line.name + (line.code && line.code.iata ? " (" + line.code.iata + ")" : ""), name: line.name, idSya: line.idSya, idP: line.idP }
            if (preselected[line.idSya]) selected.push(o)
            else list.push(o)
            this.dicoCarriers[line.idSya] = line.infos ? {manage_website:line.infos.manage_website,website: line.infos.website_checkin || line.infos.website }  : undefined
        }

        list.sort(function (a, b) {
            return a.title.localeCompare(b.title)
        })

        columns[1].custom.list = selected.concat(list)



        let list2 = []
        if (this.props.booking.segments) {
            for (let segment of this.props.booking.segments.data) {
                list2.push({
                    title: "N°" + segment.order + " " + (segment.sensTrip == "ONEWAY" ? "" : "Return ")
                        + getName(segment.from.name, segment.from.city) + "-(" + segment.type + ") "
                        + (segment.transporter ? getName(segment.transporter.name, "", 15) : "") + " " + (segment.trNum || "")

                        + " le " + moment(typeof segment.dTime == "string" ? new Date(segment.dTime).getTime() : (segment.dTime * 1000)).format("DD/MM/YY")

                        + "->" +
                        getName(segment.to.name, segment.to.city), id: segment.id
                })
            }
        }
        columns[2].custom.list = list2










        if (this.props.booking.passengers) {
            columns[8].custom.list = []
            for (let passenger of this.props.booking.passengers.data) {
                if (!passenger){
                    console.log("passenger is undefined",this.props.booking.passengers.data)
                }
                columns[8].custom.list.push({
                    title: passenger.lastName + " " + passenger.firstName,
                    id: passenger.id
                })
            }
            //console.log(columns,this.props.data)
            // for (let passenger of this.props.booking.passengers.data){
            //     columns[3].custom.list.push({title: passenger.lastName + " " + passenger.firstName ,id:passenger.id})
            // }
        }


        columns[1].custom.render=(rowData,obj) => {
            let title = obj && obj.title ? obj.title : ""
            if (!title) return null
            let website = this.dicoCarriers[obj.idP || obj.idSya || obj.id]
         //   console.log(rowData)

            return <><span>  {title }</span>
            {website?<IconButton aria-label="expand row" size="small" onClick={()=>{
                let lastName= (this.props.booking.passengers.data[0].lastName || "").toUpperCase().split(" ")[0]
                let firstSegment = undefined
                if (rowData[2]){
                    for (let l of rowData[2]){
                        if (this.props.booking.segments && this.props.booking.segments.data){
                            for (let seg of this.props.booking.segments.data){
                                if (seg.id===l.id){
                                    firstSegment=seg
                                    break
                                }
                            }
                        }
                        if (firstSegment) {
                            break
                        }
                    }
                }
              
                //console.log("firstSegment",firstSegment)

                let day =""
                let month=""
                let year =""
                let fromIATA =""
                let toIATA = ""
                if (firstSegment){
                    let array = String(firstSegment.dTime || "").split("T")[0].split("-")
                    day = String(Number(array[2]))
                    month = String(Number(array[1]))
                    year = array[0]
                    fromIATA = firstSegment.from?firstSegment.from.iata:""
                    toIATA= firstSegment.to?firstSegment.to.iata:""
                }

                let link = (website.manage_website || website.website || "")
                link = link.replace("{bookingMail}",rowData[5] || "")
                link = link.replace("{bookingReference}",rowData[3] || "")
                link = link.replace("{passengerName}",lastName)
                link = link.replace("{day}",day)
                link = link.replace("{month}",month)
                link = link.replace("{year}",year)
                link = link.replace("{fromIATA}",fromIATA)
                link = link.replace("{toIATA}",toIATA)

                // link = link.replace("{trNum}",fromIATA)






                window.open(link , '_blank');

            }}>
              <OpenInNewIcon />
            </IconButton>:null}
            </>

            // if (!rowData[11]) return null
            // return <a href={rowData[11]} target="_blank">{"Link"}  </a>
        }


    }

    componentDidMount() {

    }

    _checkIsValid = (data) => {
        for (let l of data) {
            if (!l.status) {
                return false
            }
        }
        return true
    }


    render() {




        if (this.state.refresh) {
            this.setState({ refresh: false })
        }


        let data = this.state.dataTemp || this.props.data


        for (let line of data.data) {
            if (line.booking_password && !line.booking_mail_password) line.booking_mail_password = line.booking_password
        }
        let dataTemp = []
        let nbMask = 0
        if (this.state.displayAll) {
            dataTemp = data.data
        }
        else {
            for (let line of data.data) {
                if (line.status === "ACTIVE" || line.status == "TR_WAITING_VALIDE" || line.status === "TR_FAILED" || line.status==="TR_WAITING_TICKET") {
                    dataTemp.push(line)
                }
                else {
                    nbMask++
                }
            }
        }




        return <>

            {this.state.refresh ? null : <ModelTable
                className={"table-booking"}
                data={{ ...data, data: dataTemp }}
                dataOriginal={data}
                name={"booking"}
                changeModeView={this.state.changeModeView}

                disabledModification={this.props.disabledModification || this.state.disabledModification}
                readonly={this.props.readonly}
                footerHistory={<SelectHistory
                    style={{ minWidth: "100px" }}
                    version={this.props.booking.bookingId}
                    filter={{ relationId: "bookingId:" + this.props.booking.bookingId, event: "booking" }}
                    historyData={(data, error, flagReset) => {
                        if (flagReset) {
                            return this.setState({ dataTemp: null, disabledModification: false })
                        }
                        if (!data) {
                            return this.setState({ disabledModification: true, dataTemp: [] })
                        }
                        if (data.rawData && typeof data.rawData === "string") data.rawData = JSON.parse(data.rawData)
                        if (data.rawData) this.setState({ dataTemp: data.rawData.booking, disabledModification: true })
                    }}
                />}
                footerOptions={nbMask || this.state.displayAll ? <><Button size="medium" onClick={() => {
                    this.setState({ displayAll: !this.state.displayAll })
                }}><Badge badgeContent={nbMask} color="primary">
                        {this.state.displayAll ? "Masquer" : "Afficher Tout"}
                    </Badge></Button><div style={{ width: "20px" }}></div> </> : null}
                columns={columns}
                options={options}
                modelLineAdd={{ status: "ACTIVE" }}

                // onListen={(data,ev)=>{
                //     if (ev.columnData && ev.columnData.name==="transporter"){
                //         if (ev.rowData[ev.columnIndex].raw && ev.rowData[ev.columnIndex].raw.infos){
                //             let infos = ev.rowData[ev.columnIndex].raw.infos
                //             if (infos.checkin){
                //                 for (let prop of ["booking_mail","booking_password","get_ticket_alone"]){
                //                     if (infos.checkin.indexOf(prop)!=-1){
                //                         if (! data[ev.columnIndex][4])  data[ev.columnIndex][4]=[]
                //                          let flagFind = data[ev.columnIndex][4].filter((o)=>{ return o.id===prop})[0]
                //                          if (!flagFind) data[ev.columnIndex][4].push({title:prop,id:prop})
                //                          console.log("OK add "+prop)
                //                      }
                //                 }
                //             }
                //         }
                //     }
                //     // console.log("data,ev",data,ev)
                //     return data
                // }}

                onChange={(data) => {
                    if (!this._checkIsValid(data.data)) {
                        return this.setState({ errorMessage: "Certaines lignes sont incomplètes" })
                    }


                    this.props.onChange(data)
                }}
                searchDataHistory={this.props.searchDataHistory}
            >

            </ModelTable>
            }
            <CombigoSnackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={this.state.errorMessage !== null || this.state.snackMessage !== null}
                onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
                variant={this.state.errorMessage != null ? 'error' : "success"}
                autoHideDuration={6000}
                message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
            />
        </>


    }
}




const mapStateToProps = (state) => {
    return { booking: state.booking, base: state.data };
};

export default connect(mapStateToProps, {})(Account);