import React, { Component, version } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { parseQuery, _fetch } from "./../../helpers"
import "./Calendar.scss"
import Toolbar from "./Toolbar"
import Planning from "./CalendarView.js"
import moment from 'moment'
import { Chart } from "react-google-charts";


class Body extends React.Component {
  constructor(props) {
    super(props);
    let dateSelection = this._getTime(props.dateSelection)

    this.state = {
      data: null,
      separatedByStatus: true,
      dateSelection: dateSelection,
      filter: {
        startDate: dateSelection.format("YYYY-MM-DDTHH:MM:SS"),
        endDate: moment(dateSelection.valueOf() + ((props.nbDayFuture || 1) * 24 * 60 * 60 * 1000)).format("YYYY-MM-DDTHH:MM:SS"),
      },
      version: "init",
    }
  }


  _getTime = (date = moment()) => {
    return moment(date.format("YYYY-MM-DD") + "T00:00:00+00:00")
    //return moment(Math.floor(date.valueOf() / (10 * 60 * 1000)) * (10 * 60 * 1000))
  }


  componentDidUpdate(prevProps) {


    if (prevProps.dateSelection != this.props.dateSelection) {
      let date = this._getTime(this.props.dateSelection)
      this.setState({
        dateSelection: date,
        filter: {
          startDate: date.format("YYYY-MM-DDTHH:MM:SS"),
          endDate: moment(date.valueOf() + ((this.props.nbDayFuture || 1) * 24 * 60 * 60 * 1000)).format("YYYY-MM-DDTHH:MM:SS")
        }
      })

    }
  }


  _getGraph = () => {
    if (!this.state.data) return null
    let data = [
    ];

    if (this.state.separatedByStatus) {
  
      let keys = {"NO_TRIP":1}
      for (let l of this.state.data) {
        for (let l2 of l.list) {
          let key = l2.summary.data.statusBooking
          if (l2.summary.data.statusBooking.indexOf("PROCESSING") != -1) key = "PROCESSING"
          if (l2.summary.data.statusBooking.indexOf("CANCEL") != -1) key = "CANCEL"
          keys[key]=1
        }
      }

      let listKey = Object.keys(keys)
      listKey.unshift("Day")
      data.push(listKey)
      for (let l of this.state.data) {
        let lineObj = new Array(listKey.length)
        for (let k = 0; k < lineObj.length; k++) lineObj[k] = null
        lineObj[0] = getLabel(l)

        for (let l2 of l.list) {
          let key = l2.summary.data.statusBooking
          if (l2.summary.data.statusBooking.indexOf("PROCESSING") != -1) key = "PROCESSING"
          if (l2.summary.data.statusBooking.indexOf("CANCEL") != -1) key = "CANCEL"
          let index = listKey.indexOf(key)
          if (!lineObj[index]) lineObj[index] = 0
          lineObj[index] += 1
        }
        
        if (l.list.length===0){
          let index = listKey.indexOf("NO_TRIP")
          if (!lineObj[index]) lineObj[index] = 0
          lineObj[index] += 0.1
        }

        data.push(lineObj)
      }

    }
    else {
      data = [
        ["Day", "", { role: 'annotation' }],
      ];
      for (let l of this.state.data) {
        data.push([getLabel(l), l.value, l.value])
      }
    }


    // console.log(data)


    const options = {
      chart: {},
      title: "Nombre de voyage par jour",
      // width: 600,
      height: 500,
      // vAxis:{
      //   direction: -1,
      // },
      hAxis: {
        // format:'MMM d, y',
        // direction: -1,
        slantedText: true,
        slantedTextAngle: 45 // here you can even use 180
      },
      isStacked: this.state.separatedByStatus ? true : false,
      bar: { groupWidth: "90%" },
     // legend: { position: "none" },
    };

    return <div >
      <Chart
      className={"chart-calendar"}
        chartType="ColumnChart"
        // width="100%"
        height="600px"
        data={data}
        options={options}
      />
    </div>

    function getLabel(l){
      return l.key
      return new Date(l.order * 1000).toISOString().substr(0, 10)
    }
  }



  render() {

    return <div className={"view-transaction"}>


      <Toolbar
        url={'/crm/transactions/search'}
        timeout={15 * 60 * 1000} //12000
        mode={"calendar"}
        aggregate={this.props.graph}
        filter={{
          ...this.state.filter,
          ...this.props.filter
        }}
        dateSelection={this.state.dateSelection}
        selectDate={(date) => {
          this.setState({
            dateSelection: date,
            filter: {
              startDate: date.format("YYYY-MM-DDTHH:MM:SS"),
              endDate: moment(date.valueOf() + ((this.props.nbDayFuture || 1) * 24 * 60 * 60 * 1000)).format("YYYY-MM-DDTHH:MM:SS")
            }
          })
        }}

        onUpdate={function (data, error) {
          if (data) {
            if (this.state.version != data.version) {
              this.setState({ data: data.data, version: data.version, dateUpdate: Date.now() })
            }
            else {
              this.setState({ dateUpdate: Date.now() })
            }
          }

        }.bind(this)}
      ></Toolbar>



      {this.props.graph ? this._getGraph() : null}

      {this.props.planning ? <Planning
        data={this.state.data}
        version={this.state.version}
        dateSelection={this.state.dateSelection}
      // onDate={(filterDate) => {

      //   this.setState({
      //     filter: {
      //       startDate: filterDate.date.format("YYYY-MM-DDTHH:MM:SS"),
      //       endDate: moment(filterDate.date.valueOf() + (filterDate.duration * 60 * 60 * 1000)).format("YYYY-MM-DDTHH:MM:SS")
      //     }
      //   })
      // }}

      //toolbar={}

      ></Planning > : null}

    </div>
  }
}


export default withRouter(Body);

