
import * as React from 'react';
import { connect } from 'react-redux';
import { cloneObject } from '../../helpers';
import ModelTable from "./../Table/modelTable"
import './Flexibility.scss';
import moment from "moment";
import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';
import SelectHistory from "./../Table/select_history"
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';






class Account extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            refresh: false,
            errorMessage: null,
            snackMessage: null,
            changeModeView:Date.now()

        }

        
        this.id = this.props.booking.bookingId
        this.numVersion =this._createNumVersion()

    }

    
    
    _createNumVersion = ()=>{
       return  this.props.version //.numVersion 
    }


    componentDidUpdate() {

        let exVersion = this._createNumVersion()
        if (this.id != this.props.booking.bookingId || this.numVersion != exVersion) {
            this.numVersion =exVersion

            if (this.id != this.props.booking.bookingId ){
                this.setState({changeModeView:Math.random()})
            }
            this.id = this.props.booking.bookingId
            this.setState({refresh:true})
        }
    }



    componentDidMount() {

    }



    render() {




        if (this.state.refresh) {
            this.setState({ refresh: false })
        }



        return <>

            {this.state.refresh ? null : 
            
            <>
            {!this.props.data.selectionOption?"Selection Basic : Il s'agit d'un tarif non échangeable et non remboursable!":""}
            
            {/* <div>{"COUCOU"}</div> */}
            
            </>
            }
            <CombigoSnackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={this.state.errorMessage !== null || this.state.snackMessage !== null}
                onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
                variant={this.state.errorMessage != null ? 'error' : "success"}
                autoHideDuration={6000}
                message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
            />
        </>


    }
}




const mapStateToProps = (state) => {
    return { booking: state.booking, base: state.data };
};

export default connect(mapStateToProps, {})(Account);