
import { 
    SET_CARRIER,SET_USERS
} from './types';

export const setCarrier = (data) => ({
    type: SET_CARRIER,
    payload: data
})


export const setUsers = (data) => ({
    type: SET_USERS,
    payload: data
})
