

 function createAffiliateCookie()
 {
     let l, str, query = {};
     for (str of window.location.search.substr(1,window.location.search.length).split("&"))
     {
         l = str.indexOf("=");
         if (l !==-1) query[str.substr(0,l)] = str.substr(l+1,str.length);
         else  query[str.substr(0,l)]  = ""
     }

     // pour le debug
     try{
        if (query["apiKey"]!=undefined) localStorage.setItem("x-api-key",query["apiKey"])
     }catch(e){}
   

     function setCookie(name, value, nbSeconds)
     {
         let expire = new Date(Date.now() + (nbSeconds*1000)).toUTCString()
         document.cookie = name + "=" + value + ";path=/;expires=" + expire + ";path=/"
     }
     
     function deleteCookie(name) { setCookie(name, '', -1); }

     function getCookie(name)
     {
         let v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
         return v ? v[2] : null;
     }
 }

 
export default  createAffiliateCookie