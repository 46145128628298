import { 
    SET_LOCALE,SET_USER,SET_PAGE
} from './types';

export const setLocale = (locale) => ({
    type: SET_LOCALE,
    payload: {
        locale,
    }
})



export const setUser = (name) => ({
    type: SET_USER,
    payload: {
        name,
    }
})


export const setPage = (data)=> ({
    type: SET_PAGE,
    payload: {
        data,
    }
})
