import * as React from 'react';

import { connect } from 'react-redux';

import Select_CheckBox from "./../Utils/Select_CheckBox"
import { parseQuery, _fetch } from "./../../helpers"
import History from "./../History/History"
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import RestoreIcon from '@material-ui/icons/Restore';


class Account extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            panelHistory: false,
            valueId: { id: "latest", title: "latest" }
        }
    }


    componentDidUpdate(prevProps) {
        
        if (prevProps.version != this.props.version || JSON.stringify(this.props.filter) != JSON.stringify(prevProps.filter)) {
            
            this.setState({
                valueId: { id: "latest", title: "latest" },
                list: null,
            })
            this.props.historyData(undefined, undefined, true)


            // this._request('/crm/logs/search', {
            //     filter: {
            //         ...this.props.filter
            //     }

            // }, (data, error) => {
            //     let list = [{ id: "latest", "title": "latest" }]
            //     if (data.data) {
            //         list = list.concat(data.data.map((line) => {

            //             let title = [createDate(line.date), line.user]

            //             if (!this.props.filter || !this.props.filter.event) {
            //                 title.push(line.event)
            //             }

            //             if (line.title) {
            //                 title = [line.title]
            //             }

            //             return {
            //                 _id: line._id,
            //                 title: title.join(", ")
            //             }
            //         }))
            //     }

            //     this.setState({ list: list })
            // })

        }

        function createDate(date) {
            return new Date(date).toISOString().substr(0, 16).replace("T", " ")

        }
    }



    _request = (url = '/crm/logs/search', body, cb) => {

        _fetch(process.env.REACT_APP_URL + url, {
            "timeout": 20000,
            "nbRetry": 2,
            "method": "post",
            "headers": {},
            "body": {
                ...body
            }
        })
            .then(function (response) {
                if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                let data = response.json()
                // console.log("Data list or no ", data)
                cb(data)
            }.bind(this))
            .catch(function (e) {
                cb(undefined, e)
                console.error("Error select history", e);
            }.bind(this));
    }



    render() {



        return (
            <div className={""} style={this.props.style}>




                <Button size="small"
                    style={{color:this.state.error?"red":(this.state.valueId.title==="latest"?"#3333cc":"#FF7F00")}}
                    // disabled={false}
                    startIcon={<RestoreIcon />}
                    onClick={() => {



                        this._request('/crm/logs/search', {
                            filter: {
                                ...this.props.filter
                            }

                        }, (data, error) => {
                            //return 
                            let list = [{ id: "latest", "title": "latest" }]
                            if (data && data.data) {
                                list = list.concat(data.data.map((line) => {

                                    let title = [createDate(line.date)]

                                    if (!this.props.filter || !this.props.filter.event) {
                                        title.push(line.event)
                                    }

                                    if (line.title) {
                                        title = [line.title]
                                    }

                                    return {
                                        _id: line._id,
                                        date:line.date,
                                        user:line.user,
                                        updateData:line.updateData,
                                        title: title.join(", ")
                                    }
                                }))
                            }
                          //  for (let k=0;k<100;k++) list.push({id:"test",title:"test"})
                            this.setState({ list: list, panelHistory: true,error:false })
                        })


                        function createDate(date) {
                            return new Date(date).toISOString().substr(0, 16).replace("T", " ")

                        }
                    }}>
                    {"Version : " + this.state.valueId.title + (this.state.error?" (Erreur not found data)":"")}
                </Button>
                {<Drawer anchor={"right"} className={"drawer-history"} open={this.state.panelHistory} onClose={() => {
                    this.setState({ panelHistory: false,error:false, list: [] })
                }}>
                    {<History
                        list={this.state.list}
                        onClick={async (obj) => {

                            this.setState({ valueId: obj ,panelHistory:false,error:false})

                            if (obj.id === "latest") {
                                return this.props.historyData(undefined, undefined, true)
                            }
    
                            this._request(undefined, {
                                filter: { _id: obj._id  || "empty" },
                                rawData: true,
                            }, (data, error) => {

                                if (data && data.data[0]) {
                                    this.props.historyData(data.data[0], error)
                                }
                                else {
                                    this.setState({error:true})
                                    this.props.historyData(undefined, error)
                                }
                            })

    

                        }}
                    />}
                </Drawer>}
                {/* 

                <Select_CheckBox
                    className={""}
                    styleSelect={{ width: "100%" }}
                    label={this.props.label || "Historique"}
                    list={this.state.list || []}
                    multiple={false}
                    chips={false}
                    value={this.state.valueId}
                    onChange={(value) => {
                        this.setState({ valueId: value })

                        if (value.id === "latest") {

                            return this.props.historyData(undefined, undefined, true)
                        }

                        this._request(undefined, {
                            filter: { _id: value._id },
                            rawData: true,
                        }, (data, error) => {
                            console.log("data selection ", data)
                            if (data && data.data) {
                                this.props.historyData(data.data[0], error)
                            }
                            else {
                                this.props.historyData(undefined, error)
                            }
                        })
                        // this.onChange({ typeVoucher: value })
                    }}
                /> */}


            </div>
        )
    }
}




const mapStateToProps = (state) => {
    return { locale: state.user.locale, nameUser: state.user.name };
};
export default connect(mapStateToProps, {})(Account);