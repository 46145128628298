
import * as React from 'react';
import { connect } from 'react-redux';
import { cloneObject } from '../../helpers';
import './TableBankingCard.scss';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';
import Toolbar from "./ToolbarCard"
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import Progress from './../Components/Progress';

import { parseQuery, _fetch } from "./../../helpers"


const columnsDetails = [{ "name": "date" },  ,{ "name": "merchant" }, { "name": "price" }, { "name": "notification" },
{ "name": "description" }, { "name": "currencyOrigin" },{ "name": "type" },
{ "name": "operationCode" }]


function getUser(one) {
    if (one.informations) {
        for (let line of one.informations) {
            if (line.user) {
                return line.user
            }
        }
    }
}

function Row(props) {
    const { row, details, open } = props;
    if (!row.data) return null


    // console.log("row=",row,"details",details)

    return (
        <React.Fragment>
            <TableRow className={""}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={props.onClick}>
                        {props.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell  >        {row.data.cardReference}       </TableCell>
                <TableCell >{row.data.status}</TableCell>

                <TableCell >{(row.amountLoaded || "") + " " + row.data.currency}</TableCell>
                {/* <TableCell >{(row.amountUnload || "") + " " + row.data.currency}</TableCell> */}
                <TableCell >{(row.amountUsed || "") + " " + row.data.currency}</TableCell>

                <TableCell >{row.data.properties.number}</TableCell>
                <TableCell >{row.data.properties.expMonth + "/" + row.data.properties.expYear}</TableCell>
                <TableCell >{row.data.properties.cvv}</TableCell>
                <TableCell >{row.request ? (row.provider || row.request.provider || row.data.provider || "") + "." + row.request.cardId : ""}</TableCell>


                <TableCell >{row.dateCreated ? new Date(row.dateCreated).toISOString().substr(0, 16).replace("T", " ") : ""}</TableCell>
                <TableCell >{getUser(row.data || row.request) || ""}</TableCell>
                <TableCell >{row.data.image.user ? <a download={"Cardbank_" + row.data.cardReference + ".png"} href={(row.data.image.user && row.data.image.user.indexOf("base64") == -1 ? "data:image/png;base64," : "") + row.data.image.user}>Image</a> : null}</TableCell>
                <TableCell >{row.data.properties.dashboardLink ? <a target="_blank" href={row.data.properties.dashboardLink} >{"here"}</a> : null}</TableCell>

                {props.allCards ? <TableCell ><a href={"/oneTransaction/" + row.bookingId} target="_blank"><IconButton onClick={() => {
                }}>
                    <FolderOpenIcon />
                </IconButton>
                </a></TableCell> : null}


            </TableRow>

            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={14}>
                    <Collapse in={props.open} timeout="auto" unmountOnExit>
                        <Box margin={1} style={{ background: "rgba(128,128,128,0.09)", margin: "10px 10px 10px 80px" }}>
                            {props.loading ? <Progress /> : <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        {columnsDetails && columnsDetails.map((detail, index) => {
                                            return <TableCell key={index}>{detail.name}</TableCell>
                                        })
                                        }

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {details && details.map((detail) => (
                                        <TableRow key={detail.date}>
                                            {/* <TableCell >{new Date(detail.date).toISOString().substr(0, 19).replace("T", " ")} </TableCell> */}
                                            <TableCell >{new Date(detail.date).toLocaleString('en-GB', { month: 'long', day: "numeric", year: "numeric", hour12: false, hour: "numeric", minute: "numeric", second: "numeric" })
                                            } </TableCell>
                                            <TableCell >{detail.merchant}</TableCell>
                                            <TableCell >{detail.amount + " €"}</TableCell>
                                            <TableCell >{detail.notification + (detail.status != "COMPLETED" ? "(" + detail.status + ")" : "")}</TableCell>
                                            <TableCell >{detail.description}</TableCell>
                                            <TableCell >{detail.currencyTransaction || ""}</TableCell>
                                            <TableCell>{detail.type}</TableCell>
                                            <TableCell >{detail.operationCode || ""}</TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>}


                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}





class Account extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            data: [],
            details: {},
            open: {},
            loading: {},
            errorMessage: null,
            snackMessage: null

        }

        this._refresh = this._refresh.bind(this)
        this._request = this._request.bind(this)
        this._update = this._update.bind(this)
        this.onLoadDetails = this.onLoadDetails.bind(this)
        // columns[2].custom.list= []
        // for (let line of this.props.base.carrier) {
        //     columns[2].custom.list.push({title:line.name + (line.code && line.code.iata?" ("+line.code.iata+")":""),name:line.name,idSya:line.idSya,idP:line.idP})
        // }
        // columns[2].custom.list.sort(function(a,b){
        //     return a.title.localeCompare(b.title)
        // })
    }


    _request(body, route = "bankcard") {
        return new Promise(function (resolve) {
            _fetch(process.env.REACT_APP_URL + '/crm/' + route, {
                "timeout": 60000,
                "nbRetry": 2,
                "method": "post",
                "headers": {},
                "body": body
            })
                .then(function (response) {
                    if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                    let data = response.json()
                    if (data.success) return resolve(data)
                    throw data
                }.bind(this))
                .catch(function (e) {
                    this.setState({ errorMessage: "Probleme pour actualiser les données des cartes bancaires virtuelles, veuillez réessayer" })
                    resolve(undefined)
                }.bind(this));
        }.bind(this))
    }


    _refresh() {
        if (this.props.allCard) return
        this._request({
            data: {

                bookingId: this.props.bookingId,
                sorting: this.props.sort,
                size: this.props.size,
                ...this.props.filter
            },
            action: "get"
        }, "bankcard").then((response) => {
            if (response && response.success) {

                if (this.props.updateParent) {
                    this.props.updateParent(response.data.length)
                }

                this.setState({
                    data: response.data || null,
                    details: {},
                    open: {}
                })
            }
        })
    }
    _update() {

        if (this.props.bookingId != this.bookingId || this.props.version != this.version) {
            this.setState({
                data: [],
                details: {},
                open: {},
                loading: {}
            })
            this.bookingId = this.props.bookingId
            this.version = this.props.version
            this._refresh()
        }

    }


    componentDidUpdate() {
        this._update()
    }

    componentDidMount() {
        this._update()

    }

    onLoadDetails(row) {
        if (!row || !row.data) return null

        // if (this.state.details && this.state.details[row.data.cardReference]) {
        //     return this.state.details[row.data.cardReference]
        // }

        this.valueToken = Math.random()
        let loading = { ...this.state.loading }
        loading[row.data.cardReference] = this.valueToken
        this.setState({ loading: loading })


        this._request({
            data: {
                cardReference: row.data.cardReference,
                provider: row.provider
            },
            action: "details"
        }, "bankcard").then((response) => {
            let loading = { ...this.state.loading }
            if (this.valueToken !== loading[row.data.cardReference]) return

            loading[row.data.cardReference] = false
            this.setState({ loading: loading })

            if (response && response.success) {
                let details = { ...this.state.details }
                details[row.data.cardReference] = response.data
                this.setState({
                    details: details
                })
            }
        })
    }

    render() {

        if (!this.state.data) return null
        // component={Paper}


        const columns = [
            { "name": "CardReference" },
            { "name": "Status" },
            { "name": "Amount Loaded" },
            { "name": "Amount Used" },

            // { "name": "Amount Unloaded" },


            { "name": "CardNumber" },
            { "name": "dateExp" },
            { "name": "CVV" },
            { "name": "Id" },
            { "name": "dCreate" },
            { "name": "user" },
            { "name": "image" },
            { "name": "link" },
        ]
        if (this.props.allCards) {
            columns.push({ "name": "bookingId" })
        }


        return (
            <>
                <Grid style={{ overflow: "auto" }}>
                    {this.props.activeToolbarFilter ? <Toolbar
                        url={'/crm/bankcard'}
                        timeout={this.props.timeout || 2 * 60000} //12000
                        filter={{
                            ...this.props.filter,

                        }}
                        version={{
                            ...this.state.filterStr
                        }}
                        onUpdate={function (data, error) {
                            if (data) {

                                if (this.state.version != data.version) {
                                    this.setState({ data: data.data, version: data.version, dateUpdate: Date.now() })
                                }
                                else {
                                    this.setState({ dateUpdate: Date.now() })
                                }
                            }

                        }.bind(this)}

                    /> : <Grid >
                        <Button onClick={this._refresh} style={{ color: '#33c' }} variant="outlined">{"Refresh"}</Button>
                    </Grid>}

                    <div>
                        <TableContainer>
                            <Table aria-label="collapsible table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        {columns.map((o, index) => {
                                            return <TableCell key={index}>{o.name}</TableCell>
                                        })}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {this.state.data && this.state.data.map((row) => {
                                        if (!row.data) {
                                            return null
                                        }
                                        return <Row key={row.data.cardReference}
                                            row={row}
                                            columns={columns}
                                            loading={this.state.loading[row.data.cardReference]}
                                            allCards={this.props.allCards}
                                            onClick={() => {
                                                let open = { ...this.state.open, }

                                                open[row.data.cardReference] = open[row.data.cardReference] ? false : true
                                                this.setState({ open: open })

                                                if (open[row.data.cardReference]) {
                                                    return this.onLoadDetails(row)
                                                }
                                                let details = { ...this.state.details }
                                                details[row.data.cardReference] = undefined
                                                this.setState({ details: details })

                                            }}
                                            open={this.state.open[row.data.cardReference]}
                                            details={this.state.details[row.data.cardReference]}
                                        />
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Grid>

                <CombigoSnackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.errorMessage !== null || this.state.snackMessage !== null}
                    onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
                    variant={this.state.errorMessage != null ? 'error' : "success"}
                    autoHideDuration={6000}
                    message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
                />
            </>
        )
    }
}




const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps, {})(Account);