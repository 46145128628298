import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Notes from "./Notes"
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import "./ListNotes.scss"
import moment from "moment";


function getDataVersion(data) {

  if (data && data.version) {
    return data.version
  }
  return ""
}

class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      note_index_edit: -1,
      dataEdit: null,
      notes: props.notes
    }
    this.id = props.id
    this.add = this.add.bind(this)
    this.save = this.save.bind(this)
  }



  componentDidUpdate(prevProps, prevState) {
    //console.log(this.text,this.props.value)
    // console.log("1",this.id,this.props.id)
    // console.log("2",this.note_index_edit,this.state.note_index_edit)
    // console.log("2",this.version,this.props.version)
    // console.log("length=",this.props.notes.data.length)
    if (this.id != this.props.id
      || this.note_index_edit != -1 && this.state.note_index_edit == -1
      || (this.state.note_index_edit == -1 && this.props.version != this.version)) { //&& this.props.mode=="view"
      // console.log("On update",this.props.notes)
      this.id = this.props.id
      this.note_index_edit = this.state.note_index_edit
      this.version = this.props.version

      // console.log("Refresh notes liste id=" + this.id + " props.id=" + this.props.id)

      this.setState({
        note_index_edit: -1,
        dataEdit: null,
        notes: this.props.notes
      });
    }
  }

  async add() {
    let _id = String(Date.now() + "-" + Math.random())
    let data = [{
      date: Date.now(),
      content: "",
      files: [],
      _id: _id
    }].concat(this.state.notes.data)
    this.setState({ note_index_edit: 0, dataEdit: { ...this.state.notes, data: data } })
    // await this.save(data)
  }

  async save(data) {
    // console.log("save", data)
    if (this.props.onChange) {
      if (await this.props.onChange({
        data: data,
        typeUpdate: "table_data",
        lastVersion: getDataVersion(this.state.dataEdit || this.state.notes)
      }) == true) {
        // console.log("reset dataEdit", data)
        this.setState({ note_index_edit: -1, dataEdit: null })
        return true
      }
    }
  }


  render() {

    if (!this.state.notes) return
    // console.log("this.state.dataEdit=", this.state.dataEdit)
    let dataNotes = (this.state.dataEdit || this.state.notes).data

    return (
      <Grid container item xs={12} direction="row" className={"container-notes " + this.props.className}>
        <Grid container item direction={"row"}>
          <Button color="secondary" size="small" onClick={(ev) => {
            this.add()
          }}  >
            {"Ajouter une note"}
          </Button>
          <div style={{ flexGrow: 1 }}></div>
          <span>{"Nombre de notes : " + dataNotes.length}</span>
        </Grid>


        <div className={"subcontainer-notes"}>
          {dataNotes.map(function (obj, index) {
            //console.log(index,obj)
            // console.log(obj)
            let _id = (obj._id || index)+this.props.version
            return <Notes
              note={obj}
              id={_id}
              idUser={this.props.nameUser}
              key={obj._id || index}
              mode={index == this.state.note_index_edit ? "edit" : "view"}
              activeEdit={() => {
                this.setState({ note_index_edit: index, dataEdit: this.state.notes })
              }}
              // suggestions={this.props.base.users.filter((o) => {
              //   if (o.role.includes("AUTO")) return false
              //   if (o.role.includes("DISABLED")) return false
              //   if (o.role.includes("INACTIVE")) return false
              //   return true
              // }).map(function (obj) {
              //   return { text: obj.name, value: obj.name, url: obj.id }
              // })
              // }
              disableEdit={() => {
                if (this.state.note_index_edit == index) this.setState({ note_index_edit: -1, dataEdit: null })
              }}
              onDelete={async () => {
                let flagOK = await this.save(dataNotes.filter(function (obj2, index2) {
                  return index2 == index ? false : true
                }))
                if (flagOK) this.setState({ note_index_edit: -1, dataEdit: null })
                return flagOK
              }}
              onChange={(newObj) => {
                let newData = []
                for (let k = 0; k < dataNotes.length; k++) {
                  if (dataNotes[k]._id && newObj._id == dataNotes[k]._id) {
                    if (newObj._id.indexOf("AUTO_") != -1) dataNotes[k]._id = String(Date.now() + "-" + Math.random())
                    newData.push(newObj)
                  }
                  else if (!dataNotes[k]._id && k == newObj._id) {
                    newData.push(newObj)
                  }
                  else {
                    if (!dataNotes[k]._id) dataNotes[k]._id = String(Date.now() + "-" + Math.random())
                    newData.push(dataNotes[k])
                  }
                }
                return this.save(newData)
              }}
            ></Notes>
          }.bind(this))}
        </div>

      </Grid>
    );
  }

}


const mapStateToProps = (state) => {
  return { booking: state.booking, base: state.data, nameUser: state.user.name };
};

export default connect(mapStateToProps, {})(Body);