//https://app.crisp.chat/website/84aa8019-28c1-432d-8395-92441fb57ec8/visitors/



import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';

import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Popper from '@material-ui/core/Popper';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import moment from "moment";
import { parseQuery, _fetch } from "./../../helpers"

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Edit from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

import TooltipCombigo from "./../Utils/TooltipCombigo"

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import CheckBoxCombigo from "./../Utils/Select_CheckBox"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SelectHistory from "./../Table/select_history"
import "./Chat.scss"
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

import { connect } from 'react-redux';


class Body extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            snackMessage: null
        }

    }



    async componentDidMount() {
        this._urlChat()
    }

    componentDidUpdate(prevProps) {
        this._urlChat()
    }


    _urlChat = () => {
        const encodedString = this.props.booking.bookingId //Buffer.from(this.props.booking.bookingId + "," + this.props.booking.informations.referenceBooking).toString('base64');
        const urlChat = "https://www.combigo.com/chat?id=" + this.props.booking.bookingId
        if (this.state.urlChat != urlChat) this.setState({ urlChat: urlChat })
    }


    /*

    summary:{
        data:{

        },
        numVersion:0
    },
    logs:{
        data:[],
        numVersion:0
    },
    messages:{
        data:[],
        numVersion:0
    },
    publication:{
        
    }

    */

    render() {

        if (!this.props.customerAccount) return null

        return (
            <div className='container-page-chat'>
                <Grid xs={12} sm={12} container item className={"line "} >

                    <Grid xs={1} sm={2} container item className={"block"}>
                        <FormControlLabel
                            control={<Checkbox
                                style={{ color: "#33c" }}
                                label={"Active Chat pour le client"}
                                checked={this.props.customerAccount.data.activeChat}
                                onChange={(ev, value_) => {

                                    this.props.onChange({
                                        data: {
                                            ...this.props.customerAccount.data,
                                            activeChat: value_
                                        },
                                        typeUpdate: "table_data",
                                        lastVersion: this.props.customerAccount.version
                                    })
                                }
                                }
                            />}
                            label={"Chat pour le client"}
                        />
                    </Grid>

                    {this.props.customerAccount.data.activeChat ? <>
                        <Button color="secondary"
                            // size="large"
                            startIcon={<ContentPasteIcon />}
                            onClick={() => {
                                navigator.clipboard.writeText(this.state.urlChat);
                                this.setState({ snackMessage: "Url est copié dans le presse papier!" })
                            }}  >
                            {""}
                        </Button>

                        <div style={{ display: "flex" }}>
                            <span style={{ "margin": "auto", "padding": "6px", "border": "1px solid grey", "border-radius": "4px" }}>
                                {this.state.urlChat}
                            </span>

                        </div></> : null}

                </Grid>
                <Grid xs={12} sm={12} container item className={"line border"}>
                    <Grid xs={1} sm={2} container item className={"block"}>
                        <Button size='small' color="secondary"
                            onClick={(ev) => {
                                window.open("https://app.crisp.chat/website/84aa8019-28c1-432d-8395-92441fb57ec8/visitors/", "_blank")
                            }}>{"Ouvrir le Chat Support"}</Button>

                    </Grid>

                    <div style={{ display: "flex", "flexDirection": "column" }}>
                        <span>{"Login : crisp@combigo.com"}</span>

                    </div>

                </Grid>

                <CombigoSnackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.errorMessage !== null || this.state.snackMessage !== null}
                    onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
                    variant={this.state.errorMessage != null ? 'error' : "success"}
                    autoHideDuration={6000}
                    message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
                />
            </div>
        );
    }
}




const mapStateToProps = (state) => {
    return { booking: state.booking };
};

export default connect(mapStateToProps, {})(Body);