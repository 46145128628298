import React, { Component, version } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { parseQuery, _fetch } from "./../../helpers"
import "./Table.scss"
import Table from "./Table.js"
import Toolbar from "./Toolbar"

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { CombigoSnackbar, CombigoUpdate } from './../Snackbar/Snackbar';
import Collapse from '@material-ui/core/Collapse';
import FilterListIcon from '@mui/icons-material/FilterList';
import Button from '@material-ui/core/Button';
import { Helmet } from "react-helmet";





class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      version: "init",
      countVersion: 0,
      expand_: false,
      openFilter: false
    }



  }

  componentDidUpdate(prevProps) {



  }



  render() {

    return <div className={"view-transaction"}>
     
    

      {this.props.hiddenToolbar?null:<div style={{ display: "flex" }}>
          <Button size='small' color="primary"
            endIcon={<FilterListIcon />}
            onClick={(ev) => {
              this.setState({ openFilter: this.state.openFilter ? false : true })
            }}>{"Filtre"}</Button>
          <div className={"title-page"}> {"Bank Details " + (this.state.dateUpdate ? new Date(this.state.dateUpdate).toISOString() : "")}</div>
      </div>}
      <div>
        {<Collapse in={this.state.openFilter} timeout="auto"    >
          {<Toolbar
            url={'/crm/restricted/finance/bank_details'}
            timeout={this.props.updateRefresh ? 5*60000 : 0} //12000
            version={this.props.version}
            filter={this.props.filter}
            filterForce={this.props.filterForce}
            resetInit={() => {
              this.setState({ version: "init" })
            }}

            onUpdate={function (data, error) {

              if (data) {
                if (this.state.version != data.version) {
                  if (this.props.updateParent){
                    this.props.updateParent(data.data.length,data.version)
                  }
                  if (this.state.version === "init") {
                    this.setState({ data: data.data, version: data.version, dateUpdate: Date.now(), countVersion: 0, newDataVersion: null, openUpdatedButton: false })
                  }
                  else {
                    let newVersion = (data.version != this.state.lastVersionUpdated ? 1 : 0)
                    let countVersion = this.state.countVersion + newVersion
                    this.setState({
                      openUpdatedButton: true, countVersion: newVersion ? countVersion : this.state.countVersion,
                      lastVersionUpdated: newVersion ? data.version : this.state.lastVersionUpdated,
                      newDataVersion: newVersion ? { data: data.data, version: data.version, dateUpdate: Date.now() } : this.state.newDataVersion
                    })
                    // this.setState({ newVersion:{data: data.data, version: data.version, dateUpdate: Date.now()} })
                  }
                }
                else {
                  // this.setState({ dateUpdate: Date.now() })
                }
              }


            }.bind(this)}


          ></Toolbar>}
        </Collapse>}
      </div>



      {this.state.data && <Table
        data={this.state.data}
        noDisplayBookingId={this.props.noDisplayBookingId}
        hiddenToolbar={this.props.hiddenToolbar}
      // titleHeader={"Réservations " + (this.state.dateUpdate ? new Date(this.state.dateUpdate).toISOString() : "")}

      ></Table>}

      <CombigoUpdate
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        nb={this.state.countVersion}
        autoHideDuration={null}
        open={this.state.openUpdatedButton}
        // onClose={() => this.setState({ openUpdatedButton: false })}
        onClick={() => {
          let newDataBackup = this.state.newDataVersion
          this.setState({ openUpdatedButton: false, countVersion: 0 })
          this.setState({ ...newDataBackup, newDataVersion: null, lastVersionUpdated: null })
        }}
      />


    </div >
  }
}


export default withRouter(Body);

