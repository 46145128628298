import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStylesFacebook = makeStyles({
    root: {
        position: 'relative',
    },
    top: {
        color: '#eef3fd',
    },
    bottom: {
        color: '#3333cc',
        animationDuration: '550ms',
        position: 'absolute',
        left: 'calc(50% - 12px)',

    },
});

export default function FacebookProgress(props) {
    const classes = useStylesFacebook();

    return (
        <div className={classes.root}>
            {/* <CircularProgress
                variant="determinate"
                value={100}
                className={classes.top}
                size={24}
                thickness={6}
                {...props}
            /> */}
            <CircularProgress
                variant="indeterminate"
                disableShrink
                className={classes.bottom}
                size={24}
                thickness={6}
                {...props}
            />
        </div>
    );
}
