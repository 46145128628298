import React, { Component, version } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { parseQuery, _fetch } from "./../helpers"
import "./Voucher.scss"
import Table from "./../Components/Voucher/Table"
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import ModalVoucher from "./../Components/Voucher/ModalVoucher"
import moment from 'moment';
import ToolbarVoucher from "./../Components/Voucher/Toolbar"

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Helmet } from "react-helmet";


class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      voucherSelected: null,
      openModal: false
    }


  }

  componentDidUpdate(prevProps) {


  }


  request = (body, endpoint, cb) => {
    _fetch(process.env.REACT_APP_URL + endpoint, {
      "timeout": 10000,
      "nbRetry": 1,
      "method": "post",
      "headers": {
      },
      "body": body
    })
      .then(function (response) {
        if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));

        cb(response, response.json())
      }.bind(this))
      .catch(function (e) {
        alert("request voucher")
        console.log("Error voucher", e);
      }.bind(this));
  }


  searchVoucher(voucherId) {
    this.request({
      filter: { "voucherId": voucherId }
    }, '/crm/voucher/search', (response, data) => {
      if (data.success == false || data.data.length == 0) return
      this.setState({ openModal: true, voucherSelected: data.data[0] })

    })
  }


  updateVoucher(voucher) {
    this.request({
      "voucher": voucher,
    }, '/crm/voucher/update', (response, data) => {
      console.log(data, response)
      if (!data || data.success == false) return
      this.toolbar.updateData()
      this.setState({ openModal: false, voucherSelected: null })
    })
  }


  createNew = () => {

    return {
      _searchIndex: {

      },
      voucherId: Math.round(Math.random() * 10000) + "-" + Date.now() + "-" + Math.round(Math.random() * 1000000),
      voucherCode: "",
      status: "ACTIVE", // ACTIVE , CANCEL,EXPIRED
      "typeVoucher": "",  // PROMO
      "description": "",
      "bookingReference": "",

      "amountStay": {
        "currency": "",  // EUR || %
        "amount": ""
      },
      "voucher": {
        "currency": "",  // EUR || %
        "amount": ""
      },
      "transactions": [
        //    {bookingId:12233,used:false,paymentId:,date:Date.now()}    

      ],
      "conditions_CREDIT": {

      },
      "conditions_PROMO": {
        "nbMax": 0,// nombre max utilisation
        "nbMaxByUser": 0,// // fonciton d'une boite mail
      },
      "conditionsCredit": {
      },
      "conditions": {
        "dExpired": moment().add(365, "days"), // vide ou 
        "dBegin": moment(), // vide ou 2020
        "mail": "",

        "cartMin": {
          "amount": "",
          "currency": ""
        },
        "listItems": [],
        "transporters": []
      },
      createdBy: {
        id: this.props.nameUser,
        date: Date.now(),
      },
      modifiedBy: {
        id: this.props.nameUser,
        date: Date.now(),
      }

    }

  }
  render() {

    return <div className={"view-transaction"}>



      <div style={{ display: "flex" }}>
        <Button size='small' color="primary"
          endIcon={<FilterListIcon />}
          onClick={(ev) => {
            this.setState({ openFilter: this.state.openFilter ? false : true })
          }}>{"Filtre"}</Button>
        <div className={"title-page"}> {"Vouchers " + (this.state.dateUpdate ? new Date(this.state.dateUpdate).toISOString() : "")}</div>
      </div>
      <div>
        {<Collapse in={this.state.openFilter} timeout="auto"    >
        {<ToolbarVoucher
              url={'/crm/voucher/search?all=true'}
              onRef={(ref) => {
                this.toolbar = ref
              }}
              timeout={0} //12000
              onUpdate={function (data, error) {

                if (data) {
                  if (this.state.version != data.version) {
                    this.setState({ data: data.data, version: data.version, dateUpdate: Date.now() })
                  }
                  else {
                    this.setState({ dateUpdate: Date.now() })
                  }
                }

              }.bind(this)}
            ></ToolbarVoucher>}
        </Collapse>}
      </div>

      <Grid style={{ marginBottom: "10px" }}>
          <Button color="primary" onClick={() => {
            this.setState({ openModal: true, voucherSelected: this.createNew() })
          }} >
            {"Créer un Avoir/Code Promo"}
          </Button>
        </Grid>

      {this.state.data && <Table data={this.state.data} onClick={(rowData) => {
        this.searchVoucher(rowData.voucherId)
      }}></Table>}


      <ModalVoucher
        voucher={this.state.voucherSelected}
        onClick={(voucher) => {
          voucher.modifiedBy = {
            date: Date.now(),
            id: this.props.nameUser
          }
          this.updateVoucher(voucher)
        }}

        onClose={() => {
          this.toolbar.updateData()
          this.setState({ openModal: false, voucherSelected: null })
        }}

        open={this.state.openModal}
      ></ModalVoucher>




    </div>
  }
}



const mapStateToProps = (state) => {
  return { locale: state.user.locale, nameUser: state.user.name, base: state.data };
};


export default connect(mapStateToProps, {

})(withRouter(Body));

