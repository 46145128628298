import * as React from 'react';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Delete from '@material-ui/icons/Delete';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import Drawer from '@material-ui/core/Drawer';
import Edit from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import DragAndDrop from './../../lib/DrapAndDropFile/DragAndDrop';
import { cloneObject } from "./../../helpers"
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker, DatePicker, DateTimePicker } from "@material-ui/pickers";
import DateTimeContainer from "./../Utils/DateTimeContainer"

import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import Chip from '@material-ui/core/Chip';
import moment from "moment";
import Popper from '@material-ui/core/Popper';
import Countries from "./../autocompleteCountry/listCountry"

import InputLabel from '@material-ui/core/InputLabel';
import { Input } from '@material-ui/core';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { _fetch } from '../../helpers';
import { getIconTypeTransport } from "./../Icon.js"


const filter = createFilterOptions();

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;





class AutocompleteRequest extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            list: this.props.custom.list
        }
    }



    getDataNode(query, cb) {
        _fetch(process.env.REACT_APP_URL + '/crm/data/autocomplete?' + query, {
            "timeout": 2000,
            "nbRetry": 2,
            "method": "get",
            "headers": {},
        })
            .then(async function (response) {

                if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                cb(true, response.json(), response)
            }.bind(this))
            .catch(function (e) {

                cb(false, undefined, e)
                //  alert("mettre un snackbar pour informer de l'erreur")
            }.bind(this));

    }


    render() {

        return <Autocomplete
            PopperComponent={function (props) {
                return <Popper {...props} style={{ width: "fit-content" }} placement="bottom-start" />;
            }}
            className={"disabled_clear"}
            options={this.state.list || []}
            getOptionLabel={this.props.getLabel}
            style={this.props.custom.style}
            inputValue={typeof this.props.value == "string" ? this.props.value : this.props.getLabel(this.props.value)}
            onChange={(event, newInputValue) => {

                return this.props.updateValue(newInputValue ? newInputValue : {})
            }}

            renderOption={(option, { inputValue }) => {
                let title = this.props.getLabel(option)
                const matches = match(title, inputValue);
                const parts = parse(title, matches);

                return (
                    <div>
                        {getIconTypeTransport(option.type, "", { width: "18px", height: "18px" })}
                        <>
                            {parts.map((part, index) => {
                                return <span key={index} style={{ fontWeight: part.highlight ? 700 : 400, fontSize: "0.875rem" }}>
                                    {part.text}
                                </span>
                            })}
                        </>
                    </div>
                );
            }}
            renderInput={function (params) {
                return <TextField
                    {...params}
                    variant="standard"
                    className={"text-input "}
                    onChange={function (event) {
                        this.getDataNode("str=" + event.target.value + "&data=nodes", function (status, data) {
                            if (!data) return
                            // data.results = data.results.map(function(l)  {
                            //     l.title = this.props.getLabel(l)
                            //     return l
                            // }.bind(this))
                            this.setState({ list: data.results })
                        }.bind(this))
                        this.props.updateValue(event.target.value)
                    }.bind(this)} aria-label={'description'} />
            }.bind(this)}
            {...this.props.custom.props}
        />
    }
}






export function createEditRow(columnsInput, updateTable, operations = []) {
    if (!columnsInput) return columnsInput
    let columns = cloneObject(columnsInput)
    for (let column of columns) {
        if (column.custom && column.custom.onlyView) continue

        column = getFunctionEdit(column, updateTable)
    }

    return columns
}





export function addCallback(columns, options) {
    for (let column of columns) {
        if (options.delete && column.custom && column.custom.type == "action_delete") {
            column.custom.onClick = options.delete
        }
        if (options.open && column.custom && column.custom.type == "action_open") {
            column.custom.onClick = options.open
        }
    }
}



export function noEditRow(columns) {
    const allColumns = []
    for (let column of columns) {
        let copy = Object.assign({}, column)

        // if (column.custom && column.custom.type=="files") continue
        if (column.custom && column.custom.onlyEdit) continue
        if (column.custom && column.custom.active && column.custom.active.includes("view") && !column.custom.onlyView) {

            copy = getFunctionEdit(copy)
        }
        else {

            copy = getFunctionRead(copy)
        }




        allColumns.push(copy)
    }
    return allColumns
}


function getFunctionRead(column, updateTable = function () { }) {

    if (!column.custom) return column
    let custom = column.custom || {}
    if (!column.options) column.options = {}
    if (column.custom.type == "files") {

        column.options.customBodyRender = function (valueInput, tableMeta, updateValue) {
            let value = custom.getValueRead?custom.getValueRead(valueInput,tableMeta.tableData):(custom.getValue? custom.getValue(valueInput,tableMeta.tableData):valueInput)
            // updateTable(tableMeta.tableData,value,tableMeta)
            if (tableMeta) updateTable(tableMeta.tableData, value, tableMeta, custom.type)

            return (
                <DragAndDrop
                    mode={"view"}
                    files={value}
                    onAdd={(files, addNewFiles) => {
                        updateValue(files)
                    }}
                    onDelete={(files, file, index) => {
                        updateValue(files)
                    }}
                    {...custom.props}
                >

                </DragAndDrop>
            );
        }
    }
    else if (column.custom.type == "checkbox") {

        column.options.customBodyRender = function (valueInput, tableMeta, updateValue) {
            let value = custom.getValueRead?custom.getValueRead(valueInput,tableMeta.tableData):(custom.getValue? custom.getValue(valueInput,tableMeta.tableData):valueInput)

            if (tableMeta) updateTable(tableMeta.tableData, value, tableMeta)

            return <Checkbox
                disabled
                style={{ color: "#33c" }}
                checked={value}
                onChange={(ev, value_) => {
                    updateValue(value_)
                }}
                inputProps={{ 'aria-label': 'primary checkbox' }}
                {...custom.props}

            />

        }.bind(this)

    }
    else if (column.custom.type == "list_checkbox") {


        column.options.customBodyRender = function (valueInput, tableMeta, updateValue) {
            let value = custom.getValueRead?custom.getValueRead(valueInput,tableMeta.tableData):(custom.getValue? custom.getValue(valueInput,tableMeta.tableData):valueInput)


            if (tableMeta) updateTable(tableMeta.tableData, value, tableMeta)

            let getLabel = custom.getOptionLabel || ((option) => {
                if (!option) return ""
                return (typeof option == "string" ? option : option.title) || ""
            })
            // console.log("value",value)

            return <div style={{ "display": "flex", "flexWrap": "wrap" }}>
                {(value || []).map(function (option, index) {
                    return <Chip key={index} variant="outlined" size="small" label={getLabel(option, "view")} />
                })}
            </div>

        }.bind(this)

    }

    else if (column.custom.render) {
        column.options.customBodyRender = function (valueInput, tableMeta, updateValue) {
            let value = custom.getValueRead?custom.getValueRead(valueInput,tableMeta.tableData):(custom.getValue? custom.getValue(valueInput,tableMeta.tableData):valueInput)

            return column.custom.render(tableMeta.rowData, value)

        }.bind(this)
    }
    else if (column.options && column.options.customBodyRender) {

    }
    else if (column.options && column.options.customBodyRenderLite) {

    }
    else {
        column.custom = {
            type: "label",
            typeEdit: column.custom.type,
            format: column.custom.format,
            getValue: column.custom.getValue,
            getValueRead: column.custom.getValueRead,

            props: column.custom.props
        }
        column = getFunctionEdit(column)
    }
    return column
}





function getFunctionEdit(column, updateTable = function () { }) {
    if (!column.custom) return column
    let custom = column.custom || {}
    if (!custom.props) custom.props = {}

    if (!column.options) column.options = {}


    else if (column.custom.type == "label") {
        column.options.customBodyRender = function (valueInput, tableMeta, updateValue) {
            // console.log("value",value)
            // console.log("tableMeta",tableMeta)
            // console.log("updateValue",updateValue)
            let value = custom.getValueRead?custom.getValueRead(valueInput,tableMeta.tableData):(custom.getValue? custom.getValue(valueInput,tableMeta.tableData):valueInput)

            if (tableMeta) updateTable(tableMeta.tableData, value, tableMeta)
            // operations.push(tableMeta.tableData[tableMeta.rowIndex][column.])

            if (custom.typeEdit == "date" && value) value = moment(value).format("DD/MM/yyyy")
            if (custom.typeEdit == "dateTime" && value) value = moment(value).format("DD/MM/YYYY HH:mm")
            if (custom.format) {
                value = custom.format(value)
            }
            return <Input
                {...custom.props}
                multiline={true}
                className={"label-input " + custom.props.className}
                disabled={true}
                // id={column.custom.id || "autocomplete"}
                value={value}
            />

        }.bind(this)
    }
    else if (column.custom.type == "list") {
        // let custom = column.valueInput
        column.options.customBodyRender = function (valueInput, tableMeta, updateValue) {
            // console.log("value",value)
            // console.log("tableMeta",tableMeta)
            // console.log("updateValue",updateValue)
            let value = custom.getValueRead?custom.getValueRead(valueInput,tableMeta.tableData):(custom.getValue? custom.getValue(valueInput,tableMeta.tableData):valueInput)
            
            let getLabel = custom.getOptionLabel || ((option) => {
                if (!option) return ""
                return (typeof option == "string" ? option : option.title) || ""
            })

            if (tableMeta) updateTable(tableMeta.tableData, value, tableMeta)
            // operations.push(tableMeta.tableData[tableMeta.rowIndex][column.])
            return <Autocomplete

                PopperComponent={function (props) {
                    return <Popper {...props} style={{ width: "fit-content" }} placement="bottom-start" />;
                }}
                // id={column.custom.id || "autocomplete"}
                className={"disabled_clear"}
                autoHighlight={true}
                options={custom.list || []}
                getOptionLabel={getLabel}
                style={custom.style}
                inputValue={value}
                filterOptions={(options, params) => {
                    let filtered = filter(options, params);
                    if (custom && custom.filterOptions) {
                        filtered = custom.filterOptions(filtered, options, params, tableMeta.tableData, value, tableMeta)
                    }

                    return filtered;
                }}
                onChange={(event, newInputValue) => {
                    const valueUpdated = custom.updateData && newInputValue ? custom.updateData(newInputValue) : (newInputValue ? newInputValue.title : "")
                    updateValue(valueUpdated)
                }}
                renderTags={function (value, getTagProps) {
                    //  console.log("3 value ", value)
                    return value.map((option, index) => (
                        <Chip variant="outlined" size="small" label={getLabel(option)} {...getTagProps({ index })} />
                    ))
                }}
                renderOption={(option, { inputValue }) => {
                    const matches = match(getLabel(option), inputValue);
                    const parts = parse(getLabel(option), matches);

                    return (
                        <div>
                            {parts.map((part, index) => (
                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400, fontSize: "0.875rem" }}>
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    );
                }}
                renderInput={(params) =>
                    <TextField {...params}
                        className={"text-input "}
                        variant="standard"
                        onChange={event => updateValue(event.target.value)}
                        aria-label={'description'} />
                }
                {...custom.props}

            />

        }.bind(this)

    }

    else if (column.custom.type == "list_obj") {

        column.options.customBodyRender = function (valueInput, tableMeta, updateValue) {
            // console.log("value",value)
            // console.log("tableMeta",tableMeta)
            // console.log("updateValue",updateValue)
            let value = custom.getValueRead?custom.getValueRead(valueInput,tableMeta.tableData):(custom.getValue? custom.getValue(valueInput,tableMeta.tableData):valueInput)

            let getLabel = custom.getOptionLabel || ((option) => {
                if (!option) return ""
                return (typeof option == "string" ? option : option.title) || ""
            })
            let updateDataCustom = custom.updateData
            if (tableMeta) updateTable(tableMeta.tableData, value, tableMeta)
            // operations.push(tableMeta.tableData[tableMeta.rowIndex][column.])
            return <Autocomplete

                PopperComponent={function (props) {
                    return <Popper
                        {...props}
                        style={{ width: "fit-content" }}
                        placement="bottom-start"
                    />;
                }}
                // id={column.custom.id || "autocomplete"}
                className={"disabled_clear"}
                autoHighlight={true}
                options={custom.list || []}
                getOptionLabel={getLabel}
                style={custom.style}
                value={value}
                filterOptions={(options, params) => {
                    let filtered = filter(options, params);
                    if (custom && custom.filterOptions) {
                        filtered = custom.filterOptions(filtered, options, params, tableMeta.tableData, value, tableMeta)
                    }

                    return filtered;
                }}
                onChange={function (event, newInputValue) {
                    const valueUpdated = custom.updateData && newInputValue ? custom.updateData(newInputValue) : (newInputValue ? newInputValue : "")
                    updateValue(valueUpdated)

                }}
                renderTags={function (value, getTagProps) {
                    //    console.log("value ", value)
                    return value.map((option, index) => (
                        <Chip variant="outlined" size="small" label={getLabel(option)} {...getTagProps({ index })} />
                    ))
                }}
                renderOption={(option, { inputValue }) => {
                    const matches = match(getLabel(option), inputValue);
                    const parts = parse(getLabel(option), matches);

                    return (
                        <div>
                            {parts.map((part, index) => (
                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400, fontSize: "0.875rem" }}>
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    );
                }}
                renderInput={(params) =>
                    <TextField {...params}
                        className={"text-input "}
                        variant="standard"
                        // onChange={
                        //     event => updateValue(event.target.value)
                        // }
                        aria-label={'description'} />
                }
                {...custom.props}

            />

        }.bind(this)

    }

    else if (column.custom.type == "list_checkbox") {

        column.options.customBodyRender = function (valueInput, tableMeta, updateValue) {
            let value = custom.getValueRead?custom.getValueRead(valueInput,tableMeta.tableData):(custom.getValue? custom.getValue(valueInput,tableMeta.tableData):valueInput)

            if (tableMeta) updateTable(tableMeta.tableData, value, tableMeta)
            // console.log("list_checkbox",value)
            let getLabel = custom.getOptionLabel || ((option) => {
                if (!option) return ""
                return (typeof option == "string" ? option : option.title) || ""
            })



            return <Autocomplete
                noOptionsText={''}
                autoHighlight={true}
                selectOnFocus={true}
                className={"disabled_clear"}
                PopperComponent={function (props) {
                    return <Popper {...props} style={{ width: "fit-content" }} placement="bottom-start" />;
                }}
                multiple
                disableCloseOnSelect
                value={value || []}
                options={custom.list || []}
                // options={value ? (custom.list || []).concat(value) : (custom.list || [])}

                onChange={(event, newInputValue) => {
                    updateValue(newInputValue && newInputValue.length ? newInputValue : [])
                }}
                getOptionSelected={(option, value) => {
                    if (typeof value !== "object") {
                        if (typeof option !== "object") {
                            if (value === option) return true
                        }
                        else if (option.id != undefined && option.id === value) {
                            return true
                        }
                    }
                    else if (typeof value === "object" && value.id != undefined) {
                        if (typeof option !== "object") {
                            if (value.id === option) return true
                        }
                        else if (option.id === value.id) {
                            return true
                        }
                    }
                    return false
                }}
                getOptionLabel={getLabel}
                renderTags={function (value, getTagProps) {
                    // console.log("1 value ", value)
                    return value.map((option, index) => {
                        return <Chip variant="outlined" size="small" label={getLabel(option, "chips")} {...getTagProps({ index })} />
                    })
                }}
                renderOption={(option, { selected }) => {
                    return <React.Fragment>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                            style={{ marginRight: 8, color: "#33c" }}
                        />
                        {getLabel(option, "list")}
                    </React.Fragment>
                }}
                style={custom.style}
                // inputValue={value}
                renderInput={(params) =>
                    <TextField {...params}
                        style={{ minWidth: "100px" }}
                        className={"text-input "}
                        variant="standard"
                        onChange={event => updateValue(event.target.value)} aria-label={'description'} />
                }
                {...custom.props}

            />

        }.bind(this)

    }

    else if (column.custom.type == "autocomplete_create") {

        column.options.customBodyRender = function (valueInput, tableMeta, updateValue) {
            let value = custom.getValueRead?custom.getValueRead(valueInput,tableMeta.tableData):(custom.getValue? custom.getValue(valueInput,tableMeta.tableData):valueInput)

            if (tableMeta) updateTable(tableMeta.tableData, value, tableMeta)
            let getLabel = custom.getOptionLabel || ((option) => {
                if (!option) return ""
                return (typeof option == "string" ? option : option.title) || ""
            })


            // console.log("value", value)
            return <Autocomplete

                noOptionsText={''}
                // open={true}
                // filterSelectedOptions={true}
                autoHighlight={true}
                // disableClearable={true}
                selectOnFocus={true}
                //className={"disabled_clear"}
                PopperComponent={function (props) {
                    return <Popper {...props} style={{ width: "fit-content" }} placement="bottom-start" />;
                }}
                options={value ? (custom.list || []).concat(value) : (custom.list || [])}
                onChange={(event, newValue) => {

                    let valueUpdated = custom.updateData && newValue ? custom.updateData(newValue) : (newValue ? newValue : "")
                    if (valueUpdated) delete newValue.titleDisplay
                    updateValue(newValue)
                }}
                value={value}
                filterOptions={(options, params) => {
                    let filtered = filter(options, params);
                    if (custom && custom.filterOptions) {
                        filtered = custom.filterOptions(filtered, options, params, tableMeta.tableData, value, tableMeta)
                    }
                    // Suggest the creation of a new value
                    if (params.inputValue !== '') {
                        filtered.push({
                            created: true,
                            title: params.inputValue,
                            name: params.inputValue,
                            titleDisplay: "Add '" + params.inputValue + "'",
                        });
                    }
                    return filtered;
                }}
                getOptionLabel={(option) => {

                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.created) {
                        return option.titleDisplay || getLabel(option);
                    }
                    // Regular option
                    return getLabel(option);
                }}

                renderInput={(params) => {
                    // console.log("params", params)
                    return <TextField
                        {...params}

                        inputProps={{
                            ...params.inputProps,
                            //value:value?getLabel(value):""
                        }}
                        variant="standard"
                        // label="filterSelectedOptions"
                        placeholder={custom.props.placeholder}
                    />
                }
                }
                {...custom.props}

            />

        }.bind(this)

    }

    else if (column.custom.type == "list_countries") {
        column.options.customBodyRender = function (valueInput, tableMeta, updateValue) {
            let value = custom.getValueRead?custom.getValueRead(valueInput,tableMeta.tableData):(custom.getValue? custom.getValue(valueInput,tableMeta.tableData):valueInput)

            if (tableMeta) updateTable(tableMeta.tableData, value, tableMeta)

            return <Countries
                value={value}
                {...custom.props}
                onChange={(event, newInputValue) => {
                    //  console.log("newInputValue", newInputValue)
                    updateValue(newInputValue && newInputValue.code ? newInputValue.code : "")
                }}

            />

        }.bind(this)

    }




    else if (column.custom.type == "autocomplete_request") {
        column.options.customBodyRender = function (valueInput, tableMeta, updateValue) {
            let value = custom.getValueRead?custom.getValueRead(valueInput,tableMeta.tableData):(custom.getValue? custom.getValue(valueInput,tableMeta.tableData):valueInput)

            if (tableMeta) updateTable(tableMeta.tableData, value, tableMeta)
            let getLabel = custom.getOptionLabel || ((option) => {
                if (!option) return ""
                return (typeof option == "string" ? option : option.title) || ""
            })


            return <AutocompleteRequest

                getLabel={getLabel}
                custom={custom}
                updateTable={updateTable}
                updateValue={updateValue}
                value={value}

            />
            //     <Autocomplete
            //     //   id="country-select"
            //     variant={this.props.variant || "standard"}
            //     style={{ width: 200 }}
            //     options={countries}
            //     // classes={{
            //     //     option: this.classes.option,
            //     // }}
            //     autoHighlight
            //     inputValue={this.props.value}
            //     onClick={(ev) => {
            //         console.log(ev)
            //     }}
            //     getOptionLabel={(option) => option.label}
            //     renderOption={(option) => (
            //         <React.Fragment>
            //             <span>{countryToFlag(option.code)}</span>
            //             {option.label} ({option.code}) +{option.phone}
            //         </React.Fragment>
            //     )}
            //     renderInput={(params) => (
            //         <TextField
            //             {...params}

            //             // label="Choose a country"
            //             variant="standard"
            //             inputProps={{
            //                 ...params.inputProps,
            //                 autoComplete: 'new-password', // disable autocomplete and autofill
            //             }}
            //         />
            //     )}
            // />


        }.bind(this)

    }
    else if (column.custom.type == "autocomplete") {

        column.options.customBodyRender = function (valueInput, tableMeta, updateValue) {
            let value = custom.getValueRead?custom.getValueRead(valueInput,tableMeta.tableData):(custom.getValue? custom.getValue(valueInput,tableMeta.tableData):valueInput)

            if (tableMeta) updateTable(tableMeta.tableData, value, tableMeta)
            let getLabel = custom.getOptionLabel || ((option) => {
                if (!option) return ""
                return (typeof option == "string" ? option : option.title) || ""
            })

            return <Autocomplete



                filterOptions={(options, params) => {
                    let filtered = filter(options, params);
                    if (custom && custom.filterOptions) {
                        filtered = custom.filterOptions(filtered, options, params, tableMeta.tableData, value, tableMeta)
                    }

                    return filtered;
                }}
                PopperComponent={function (props) {
                    return <Popper {...props} style={{ width: "fit-content" }} placement="bottom-start" />;
                }}
                className={"disabled_clear"}
                renderTags={function (value, getTagProps) {
                    //g("2 value ", value)
                    return value.map((option, index) => (
                        <Chip variant="outlined" size="small" label={getLabel(option)} {...getTagProps({ index })} />
                    ))
                }}
                // id={column.custom.id || "autocomplete"}
                options={custom.list || []}
                getOptionLabel={getLabel}
                style={custom.style}
                inputValue={value}
                onChange={(event, newInputValue) => {
                    const valueUpdated = custom.updateData && newInputValue ? custom.updateData(newInputValue) : (newInputValue ? newInputValue.title : "")
                    updateValue(valueUpdated)
                }}
                renderInput={(params) =>
                    <TextField {...params}
                        variant="standard"
                        className={"text-input "}
                        onChange={event => updateValue(event.target.value)} aria-label={'description'} />
                }
                {...custom.props}

            />

        }.bind(this)

    }
    else if (column.custom.type == "input") {
        column.options.customBodyRender = function (valueInput, tableMeta, updateValue) {
            let value = custom.getValueRead?custom.getValueRead(valueInput,tableMeta.tableData):(custom.getValue? custom.getValue(valueInput,tableMeta.tableData):valueInput)

            if (tableMeta) updateTable(tableMeta.tableData, value, tableMeta)

            return <TextField
                value={value}
                className={"text-input "}
                variant="standard"
                onChange={event => updateValue(event.target.value)}
                aria-label={'description'}
                {...custom.props}

            />

        }.bind(this)

    }

    else if (column.custom.type == "checkbox") {

        column.options.customBodyRender = function (valueInput, tableMeta, updateValue) {
            let value = custom.getValueRead?custom.getValueRead(valueInput,tableMeta.tableData):(custom.getValue? custom.getValue(valueInput,tableMeta.tableData):valueInput)

            if (tableMeta) updateTable(tableMeta.tableData, value, tableMeta)

            return <Checkbox
                style={{ color: "#33c" }}
                checked={value}
                onChange={(ev, value_) => {
                    updateValue(value_)
                }

                }
                inputProps={{ 'aria-label': 'primary checkbox' }}
                {...custom.props}
            />

        }.bind(this)

    }

    else if (column.custom.type == "date") {


        column.options.customBodyRender = function (valueInput, tableMeta, updateValue) {
            let value = custom.getValueRead?custom.getValueRead(valueInput,tableMeta.tableData):(custom.getValue? custom.getValue(valueInput,tableMeta.tableData):valueInput)

            // console.log("dateTime",value,updateValue)
            if (tableMeta) updateTable(tableMeta.tableData, value, tableMeta)
            if (!custom.props) custom.props = {}
            if (!custom.props.style) custom.props.style = {}
            // if (!custom.props.style.width) custom.props.style.width="150px"
            //KeyboardDatePicker
            return <DateTimeContainer
                DateObj={DatePicker}

                autoOk
                variant="dialog"

                invalidDateMessage={""}
                inputVariant="standard"
                // label="With keyboard"
                format="DD/MM/yyyy"
                value={value || ""}
                // TextFieldComponent={{variant:"standard"}}
                // keyboardIcon={(props)=>{
                //     return <EventIcon style={{width:"10px",height:"10px"}}></EventIcon>
                // }}
                TextFieldComponent={
                    (props) =>
                        <TextField {...props}
                            className={"text-input "}
                            variant={"standard"} />
                }
                InputAdornmentProps={{ position: "start" }}
                onChange={function (date, value) {
                    updateValue(date)
                }}
                {...custom.props}
            />




        }.bind(this)

    }
    else if (column.custom.type == "dateTime") {


        column.options.customBodyRender = function (valueInput, tableMeta, updateValue) {
            let value = custom.getValueRead?custom.getValueRead(valueInput,tableMeta.tableData):(custom.getValue? custom.getValue(valueInput,tableMeta.tableData):valueInput)

            if (tableMeta) updateTable(tableMeta.tableData, value, tableMeta)
            if (!custom.props) custom.props = {}
            if (!custom.props.style) custom.props.style = {}
            //if (!custom.props.style.width) custom.props.style.width="200px"
            // if (!custom.props.style.width) custom.props.style.width="100px"

            // console.log("dateTime",value,updateValue)
            return <DateTimeContainer
                DateObj={DateTimePicker}
                variant="dialog"

                ampm={false}
                inputVariant="standard"
                invalidDateMessage={""}

                // label="With keyboard"
                value={value || ""}
                // TextFieldComponent={
                //     (props) =>
                //       <TextField {...props} variant={"standard"} />
                // }
                onChange={function (date, value) {
                    updateValue(date)
                }}
                onError={console.log}
                TextFieldComponent={
                    (props) =>
                        <TextField {...props}
                            className={"text-input "}
                            variant={"standard"} />
                }
                format="DD/MM/YYYY HH:mm"
                {...custom.props}
            />



        }.bind(this)

    }
    else if (column.custom.type == "action_delete") {

        column.options.customBodyRenderLite = function (dataIndex, rowIndex) {
            return (

                <IconButton size="small" onClick={() => {
                    // console.log("Dataindex", dataIndex, "rowIndex", rowIndex)
                    custom.onClick(dataIndex)
                }}>
                    <Delete />
                </IconButton>
            );
        }
    }
    else if (column.custom.type == "action_open") {
        column.options.customBodyRenderLite = function (dataIndex, rowIndex) {
            return (
                <IconButton size="small" onClick={() => {
                    custom.onClick(dataIndex)
                }}>
                    <FolderOpenIcon />
                </IconButton>
            );
        }
    }

    else if (column.custom.type == "action_edit") {

        column.options.customBodyRenderLite = function (dataIndex, rowIndex) {
            return (
                <IconButton size="small" onClick={() => window.alert(`Clicked "Edit" for row ${rowIndex} with dataIndex of ${dataIndex}`)}>
                    <Edit />
                </IconButton>
            );
        }

    }

    else if (column.custom.type == "files") {

        column.options.customBodyRender = function (valueInput, tableMeta, updateValue) {
            // updateTable(tableMeta.tableData,value,tableMeta)
            updateTable(tableMeta.tableData, valueInput, tableMeta, custom.type)

            return (
                <DragAndDrop
                    files={valueInput}
                    onAdd={(files, addNewFiles) => {
                        updateValue(files)

                    }}
                    onDelete={(files, file, index) => {
                        updateValue(files)

                    }}
                    {...custom.props}

                >
                </DragAndDrop>
            );
        }

    }




    column.custom = undefined
    return column
}