
import * as React from 'react';
import { connect } from 'react-redux';
import ModelTable from "./../Table/modelTable"
import './TablePassenger.scss';
import moment from "moment";
import SelectHistory from "./../Table/select_history"
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import FlagIcon from '@material-ui/icons/Flag';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CakeIcon from '@material-ui/icons/Cake';
import EventBusyIcon from '@material-ui/icons/EventBusy';
const columns = [

    {
        name: "gender",
        label: "Sexe",
        custom: {
            type: "list",
            props: {
                disableClearable: true
            },
            list: [
                { id: "MAN", title: "MAN" },
                { id: "WOMAN", title: "WOMAN" }
            ],
        },
        options: {
            sort: false
        }
    },
    {
        name: "firstName",
        label: "Prénom",
        custom: {
            type: "input"
        },
        options: {
            sort: false
        }
    },
    {
        name: "lastName",
        label: "Nom",
        custom: {
            type: "input"
        },
        options: {
            sort: false
        }
    },
    {
        name: "birthDate",
        label: "Date Nais.",
        custom: {
            type: "date"
        },
        options: {
            customHeadLabelRender:()=>{
                return <><CalendarTodayIcon style={{width:"16px",height:"16px",marginRight:"5px"}}/><span>{"Date Nais."}</span></>
            },
            sort: false
        }
    },
    {
        name: "ageTrip",
        label: "Age",
        custom: {
            active: ["view"]
        },
        options: {
            customHeadLabelRender:()=>{
                return <><CakeIcon style={{width:"16px",height:"16px",marginRight:"5px"}}/><span>{"Age"}</span></>
            },
            sort: false
        }
    },
    {
        name: "documentIdentity",
        label: "Doc",
        custom: {
            type: "list",
            list: [
                { id: "PASSPORT", title: "PASSPORT" },
                { id: "ID_CARD", title: "ID_CARD" }
            ],
            props: {
                disableClearable: true
            },
        },
        options: {
         
            sort: false
        }
    },
    {
        name: "countryIdentity",
        label: "Pays Doc.",
        custom: {
            type: "list_countries"
        },
        options: {
            customHeadLabelRender:()=>{
                return <><FlagIcon style={{width:"16px",height:"16px",marginRight:"5px"}}/><span>{"PaysId"}</span></>
            },
            sort: false
        }
    },
    {
        name: "countryLabel",
        label: "Pays Doc.",
        custom: {
            type: "label",
            active: ["view"]
        },
        options: {
            customHeadLabelRender:()=>{
                return <><FlagIcon style={{width:"16px",height:"16px",marginRight:"5px"}}/><span>{"Pays Doc"}</span></>
            },
            sort: false
        }
    },
    {
        name: "numIdentity",
        label: "Numero",
        custom: {
            type: "input"
        },
        options: {
            customHeadLabelRender:()=>{
                return <><BrandingWatermarkIcon style={{width:"16px",height:"16px",marginRight:"5px"}}/><span>{"Numéro"}</span></>
            },
            sort: false
        }
    },
    {
        name: "expireIdentity",
        label: "Date Exp.",
        custom: {
            type: "date"
        },
        options: {
            customHeadLabelRender:()=>{
                return <><EventBusyIcon style={{width:"16px",height:"16px",marginRight:"5px"}}/><span>{"Expiration"}</span></>
            },
            sort: false
        }
    },
    {
        name: "comment",
        label: "Comment.",
        custom: {
            type: "input",
            props: { multiline: true }
        },
        options: {
            sort: false
        }
    },
    {
        name: "files",
        label: "files",
        custom: {
            type: "files"
        },
        options: {
            sort: false
        }
    }
    , {
        name: "",
        label: "",
        custom: {
            // type:"action",
            onlyEdit: true,
            type: "action_delete",
        },
        options: {
            sort: false
        }
    }

];

const options = {
    filterType: 'checkbox',
    print: false,
    display: false,
    rowsPerPage: 100,
    selectableRows: 'none',
    selectableRowsHideCheckboxes: true,
    download: false,
    filter: false,
    search: false,
    viewColumns: false,
};





class Account extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            refresh: false,
            changeModeView:Date.now()

        }
        this.numVersion = this.props.data.numVersion
        this.id = this.props.booking.bookingId
    }

    componentDidUpdate() {
        if (this.id != this.props.booking.bookingId || this.numVersion != this.props.data.numVersion) {
            if (this.id != this.props.booking.bookingId ){
                this.setState({changeModeView:Math.random()})
            }
            this.numVersion = this.props.data.numVersion
            this.id = this.props.booking.bookingId
            this.setState({refresh:true})

        }
    }

    componentDidMount() {

    }



    render() {


        if (this.state.refresh) {
            this.setState({ refresh: false })
        }


        let data = this.state.dataTemp || this.props.data


        let dataTemp = []
        let min = 0 //Date.now() * 2
        let minDate = 0 //Date.now() * 2
        if (this.props.booking && this.props.booking.segments && this.props.booking.segments.data) {
            for (let segment of this.props.booking.segments.data) {
                //console.log("segment.dTime "+segment.dTime)
                if (segment.status == "ACTIVE") min = Math.max(new Date(segment.dTime + "+00:00").getTime(), min)
                minDate = Math.max(new Date(segment.dTime + "+00:00").getTime(), minDate)
                minDate = Math.max(new Date(segment.aTime + "+00:00").getTime(), minDate)

            }
            if (data && data.data) {
                if (min >= Date.now() * 1.9) min = minDate
                for (let passenger of data.data) {
                    // console.log("birthdate " + passenger.birthDate + " " + min )
                    if (min >= Date.now() * 1.9) { }
                    else passenger.ageTrip = moment(min).diff(passenger.birthDate + "T00:00:00+00:00", 'years') + " ans"

                    // if (passenger.countryLabel) {
                        for (let l of this.props.base.countries) if (l.code == passenger.countryIdentity) { passenger.countryLabel = l.label; break }
//                    }
                    dataTemp.push(passenger)
                }
            }
        }





        return (this.state.refresh ? null : <ModelTable
            className={"table-passenger"}
            data={{ ...data, data: dataTemp }}
            name={"passenger"}
            changeModeView={this.state.changeModeView}

            disabledModification={this.props.disabledModification || this.state.disabledModification}

            readonly={this.props.readonly}
            footerHistory={<SelectHistory
                style={{ minWidth: "100px" }}
                version={this.props.booking.bookingId}
                filter={{ relationId: "bookingId:" + this.props.booking.bookingId, event: "passengers" }}
                historyData={(data, error, flagReset) => {
                    if (flagReset) {
                        return this.setState({ dataTemp: null, disabledModification: false })
                    }
                    if (!data) {
                        return this.setState({ disabledModification: true,dataTemp: [] })
                    }
                    if (data.rawData && typeof data.rawData === "string") data.rawData = JSON.parse(data.rawData)
                    if (data.rawData) this.setState({ dataTemp: data.rawData.passengers, disabledModification: true })
                }}
            />}
            columns={columns}
            options={options}
            onChange={this.props.onChange}
            searchDataHistory={this.props.searchDataHistory}

        >

        </ModelTable>
        )
    }
}




const mapStateToProps = (state) => {
    return { booking: state.booking, base: state.data };
};

export default connect(mapStateToProps, {})(Account);