import React, { Component, version } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from "material-table";
import WrapperMaterialTable from "./../Table/WrapperMaterialTable"

import Button from '@material-ui/core/Button';
import Delete from '@material-ui/icons/Delete';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import IconButton from '@material-ui/core/IconButton';

import Drawer from '@material-ui/core/Drawer';
import Edit from '@material-ui/icons/Edit';
import Chip from '@material-ui/core/Chip';
import "./Table.scss"
import CheckBoxCombigo from "./../Utils/Select_CheckBox"
import Grid from '@material-ui/core/Grid';
import TooltipCombigo from "./../Utils/TooltipCombigo"
import Modal from '@material-ui/core/Modal';
import LaunchIcon from '@material-ui/icons/Launch';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import { CombigoSnackbar, CombigoUpdate } from './../../Components/Snackbar/Snackbar';
import Dialog from '@material-ui/core/Dialog';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoneIcon from '@mui/icons-material/Done';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const IconByStatus = {
  PROCESSING: <TouchAppIcon style={{ color: "rgba(189, 171, 130,1)", fill: "rgba(189, 171, 130,1)", width: "27px", "height": "27px" }}></TouchAppIcon>,
  PROCESSING_AUTO: <TouchAppIcon style={{ color: "rgba(255, 204, 51,1)", fill: "rgba(255, 204, 51,1)", width: "27px", "height": "27px" }}></TouchAppIcon>,
  PROCESSING_MANAGE: <TouchAppIcon style={{ color: "rgba(255, 204, 51,1)", fill: "rgba(255, 204, 51,1)", width: "27px", "height": "27px" }}></TouchAppIcon>,
  WAIT_CONFIRMED: <AccessTimeIcon style={{ color: "rgba(255, 204, 51,1)", fill: "rgba(255, 204, 51,1)", width: "27px", "height": "27px" }}></AccessTimeIcon>,
  MODIFICATION: <AccessTimeIcon style={{ color: "rgba(255, 204, 51,1)", fill: "rgba(255, 204, 51,1)", width: "27px", "height": "27px" }}></AccessTimeIcon>,

  CONFIRMED: <DoneIcon style={{ color: "rgba(51,204,51,1)", fill: "rgba(51,204,51,1)", width: "27px", "height": "27px" }}></DoneIcon>,
  DONE: <DoneAllIcon style={{ color: "rgba(51,204,51,1)", fill: "rgba(51,204,51,1)", width: "27px", "height": "27px" }}></DoneAllIcon>,
  CANCEL_OTHER: <HighlightOffIcon style={{ color: "rgba(255,102,102,1)", fill: "rgba(255,102,102,1)", width: "27px", "height": "27px" }}></HighlightOffIcon>,
  CANCEL_COMBIGO: <HighlightOffIcon style={{ color: "rgba(255,102,102,1)", fill: "rgba(255,102,102,1)", width: "27px", "height": "27px" }}></HighlightOffIcon>,
  CANCEL_VOLUNTARY: <HighlightOffIcon style={{ color: "rgba(255,102,102,1)", fill: "rgba(255,102,102,1)", width: "27px", "height": "27px" }}></HighlightOffIcon>,

}



function getColumnLocal() {
  try {
    return JSON.parse(localStorage.getItem("selectColumnTableMain"))
  } catch (e) {

  }
}

class Table extends Component {
  constructor(props) {
    super(props)
    this.state = {
      snackMessage: null,
      errorMessage: null,
      openUpdatedButton: true,
      selectColumn: getColumnLocal() || this.props.base.columnTableRecap
    }
  }


  _search = (bookingId, step) => {
    if (!this.props.data) return null

    for (let k = 0; k < this.props.data.length; k++) {
      let l = this.props.data[k]
      if (l.bookingId === bookingId) {
        if (step === "position") {
          return { bookingId: bookingId, position: (k + 1) }
        }
        if (step === "+1") {
          return this.props.data[k + 1] ? { bookingId: this.props.data[k + 1].bookingId, position: k + 1 } : ""
        }
        else if (step === "-1") {
          return this.props.data[k - 1] ? { bookingId: this.props.data[k - 1].bookingId, position: k + 1 } : ""
        }
      }
    }


  }

  render() {

    let size = this.props.data ? Math.min(this.props.data.length, 100) : 100

    const columns = [
      {
        title: "", field: "", render: function (rowData) {
          return <a href={"/oneTransaction/" + rowData.bookingId} target="_blank"><IconButton onClick={() => {
            // this.props.history.push("/oneTransaction?bookingId="+rowData.bookingId)
          }}>
            <FolderOpenIcon />
          </IconButton>
          </a>
        }.bind(this)
      },
      {
        title: "", field: "TR", render: (rowData) => {
          return <div className={"item-logo logo-" + Math.min(rowData.listTransporter.length, 4)}>
            {rowData.listTransporter.map((transporter, i) => {
              if (!transporter || !transporter.id) return null
              return < TooltipCombigo title={transporter.name} >
                <img src={transporter.id ? "https://www.combigo.com/logos/transporters/24/" + transporter.id + ".png" : "https://www.combigo.com/images/default_tr.png"}
                  className={`l-${i}`}
                  onError={(e) => {
                    e.target.className = "nodisplay"
                    e.target.width = "0px"
                    e.target.height = "0px"
                    e.target.onerror = null;
                    e.target.src = ""
                  }} />

              </TooltipCombigo>


            })
            }
          </div >
          //listTransporter
        }
      },
      {
        title: "Title", field: "title", render: (rowData) => {
          return <span style={{}} >{rowData.title}</span>
        }
      },
      {
        title: "StatusBooking", field: "statusBooking", render: (rowData) => {
          return <div style={{ "display": "flex", maxWidth: "200px", "flexWrap": "nowrap" }}>
            <div style={{ margin: "auto", marginLeft: 0, marginRight: "5px" }}>{IconByStatus[rowData.statusBooking] || null}</div>
            <div style={{ ...getColorByStatus(rowData), margin: "auto", marginLeft: 0, marginRight: 0 }}> {rowData.statusBooking}</div>


          </div>
        }
      },
      {
        title: "StatusCombigo", field: "statusCombigo", render: (rowData) => {
          return <div style={{ "display": "flex", maxWidth: "200px", "flexWrap": "wrap" }}>
            {(rowData.statusCombigo || []).map(function (option, index) {
              return <Chip variant="outlined" size="small" label={option} />
            })}
          </div>
        }
      },
      {
        title: "managerBooking", field: "managerBooking", render: (rowData) => {

          if (!rowData.managerBooking) {
            return ""
          }

          let list = []
          for (let l of this.props.base.users) {
            if (rowData.managerBooking.includes(l.id)) list.push(l.name)
          }

          return <div style={{ ...getColorByStatus(rowData), "display": "flex", maxWidth: "300px", "flexWrap": "wrap" }}>{list.join(", ")}</div>
        }
      },
      {
        title: "Num Booking", field: "referenceBooking", render: (rowData) => {

          return <span style={{ ...getColorByStatus(rowData) }} >{rowData.referenceBooking}</span>
        }
      },
      { title: "Prix €", field: "amountEUR", type: "numeric" },
      { title: "Marge €", field: "amountMarginEUR", type: "numeric" },

      { title: "ratioMargin", field: "ratioMargin", type: "numeric" },

      { title: "Nom", field: "lastName" },
      { title: "dateRappel", field: "dateRappel", customSort: (a, b) => (a.dateRappel ? new Date(a.dateRappel.substr(0, 10) + "T00:00:00+00:00").getTime() : 0) - (b.dateRappel ? new Date(b.dateRappel.substr(0, 10) + "T00:00:00+00:00").getTime() : 0) },
      {
        title: "dTime", field: "dTime", customSort: (a, b) => {
          // if (typeof a.dTime != "string" || typeof b.dTime != "string") {
          //   console.log(a, b)
          // }
          // console.log(a.dTime)
          // console.log(new Date(a.dTime.substr(0, 10) + "T00:00:00+00:00").getTime() - new Date(b.dTime.substr(0, 10) + "T00:00:00+00:00").getTime())
          return new Date(a.dTime.substr(0, 10) + "T00:00:00+00:00").getTime() - new Date(b.dTime.substr(0, 10) + "T00:00:00+00:00").getTime()

        }
      },
      {
        title: "dTimeRT", field: "dTimeRT", customSort: (a, b) => {
          let a_dTimeRT = a.dTimeRT ? a.dTimeRT : "1990-01-01"
          let b_dTimeRT = b.dTimeRT ? b.dTimeRT : "1990-01-01"


          return new Date(a_dTimeRT.substr(0, 10) + "T00:00:00+00:00").getTime() - new Date(b_dTimeRT.substr(0, 10) + "T00:00:00+00:00").getTime()

        }
      },
      {
        title: "description", field: "description", render: (rowData) => {
          return (rowData.description || "").substr(0, 25) + "..."

          return <TooltipCombigo title={rowData.description} >
            {
              (rowData.description || "").substr(0, 25) + "..."
            }

          </TooltipCombigo>
        }
      },
      {
        title: "lastModified", field: "lastModified",
        render: (rowData) => {
          return rowData.lastModified.substr(0, 10)
        },
        customSort: (a, b) => {
          // console.log(a.lastModified)
          return new Date(a.lastModified + "+00:00").getTime() - new Date(b.lastModified + "+00:00").getTime()
        }
      },
      {
        title: "dateCreated", field: "dateCreated", render: (rowData) => {
          return rowData.dateCreated.substr(0, 10)
        },
        customSort: (a, b) => {
          // console.log(a.dateCreated )
          return new Date(a.dateCreated + "+00:00").getTime() - new Date(b.dateCreated + "+00:00").getTime()
        }
      },
      {
        title: "amountWaiting", field: "amountWaiting", type: "numeric"
      },
      {
        title: "modale", field: "", render: function (rowData) {

          return <IconButton onClick={() => {

            document.body.classList.add("hidden-bar");
            let val = this._search(rowData.bookingId, "position")
            // this.props.history.push("/oneTransaction?bookingId="+rowData.bookingId)
            this.setState({ openModal: true, modalBookingId: val.bookingId, positionBookingId: val.position })
          }}>
            <LaunchIcon />
          </IconButton>
        }.bind(this)
      },
    ]



    return (
      <div style={{ maxWidth: "100%" }}>

        <Dialog
          fullScreen
          style={{ background: "white" }}
          open={this.state.openModal}
          onClose={() => {
            this.setState({ openModal: true })
          }}
        >
          {<div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%", zIndex: 1000000000000 }}>
            <div style={{ "display": "flex" }}>
              <div style={{ display: "flex", padding: "5px", "margin": "auto" }}>
                {"BookingId = " + this.state.modalBookingId}
              </div>
              <div style={{ flexGrow: 1 }}></div>
              <IconButton onClick={() => {
                let newBookingId = this._search(this.state.modalBookingId, "-1")
                if (!newBookingId) {
                  return this.setState({ snackMessage: "Plus de réservation, vous avez fait le tour!" })
                }
                this.setState({ openModal: newBookingId ? true : false, modalBookingId: newBookingId ? newBookingId.bookingId : null, positionBookingId: newBookingId.position })


              }}>
                <ArrowBackIosIcon />
              </IconButton>
              <div style={{ display: "flex", padding: "5px", "margin": "auto", fontWeight: 500 }}>
                {this.state.positionBookingId + " / " + (this.props.data ? this.props.data.length : 0)}
              </div>


              <IconButton onClick={() => {
                let newBookingId = this._search(this.state.modalBookingId, "+1")
                if (!newBookingId) {
                  return this.setState({ snackMessage: "Plus de réservation, vous avez fait le tour!" })
                }
                this.setState({ openModal: newBookingId ? true : false, modalBookingId: newBookingId ? newBookingId.bookingId : null, positionBookingId: newBookingId.position })

              }}>
                <ArrowForwardIosIcon />
              </IconButton>

              <div style={{ flexGrow: 1 }}></div>

              <IconButton onClick={() => {
                document.body.classList.remove("hidden-bar");
                this.setState({ openModal: false, modalBookingId: null, positionBookingId: null })
              }}>
                <CloseIcon />
              </IconButton>
            </div>
            <div style={{ height: "100%" }}>
              <iframe width={"100%"} height={"100%"} className={"iframe"} src={window.location.origin + "/oneTransaction/" + this.state.modalBookingId + "#iamiframe"}></iframe>
            </div>
          </div>}
        </Dialog>





        <WrapperMaterialTable
          columns={columns.filter(function (obj) {
            if (!obj.title) return true
            if (obj.field === "TR") {
              return true
            }
            return this.state.selectColumn.includes(obj.title) || this.state.selectColumn.includes(obj.field)
          }.bind(this))}
          components={{
            Toolbar: function (props) {
              /*
    backgroundColor: '#e8eaf5'
              */
              return <div style={{ display: "flex", padding: "10px 20px" }}>

                {this.props.headerFilter}
                {<div style={{ flexGrow: 1, textAlign: "center", fontSize: "16px" }}><b>{this.props.titleHeader ? this.props.titleHeader : ""}</b></div>}
                <div className={"select-column2"} style={{ maxWidth: "150px" }}>
                  <CheckBoxCombigo
                    className={""}
                    style={{ width: "100%" }}
                    label={"Affichage Colonne"}
                    getLabel={(option) => { return option }}
                    // getMatching={(option) => { return option.code }}
                    list={this.props.base.columnTableRecap}
                    multiple={true}
                    chips={false}
                    value={this.state.selectColumn}
                    onChange={(value) => {
                      this.setState({ selectColumn: value })
                      localStorage.setItem("selectColumnTableMain", JSON.stringify(value))
                    }}
                  ></CheckBoxCombigo>
                </div>
                <MTableToolbar {...props} />
              </div>
            }.bind(this)


          }}
          data={this.props.data}
          title={this.props.title || ""}
          options={{
            pageSize: size,
            pageSizeOptions: [50, 100, 200, 400],
            grouping: false,
            exportButton: true,
            padding: "dense"
            // filtering: true
          }}
        />



        <CombigoSnackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.errorMessage !== null || this.state.snackMessage !== null}
          onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
          variant={this.state.errorMessage != null ? 'error' : "success"}
          autoHideDuration={6000}
          message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
        />
      </div>
    );
  }
}


function getColorByStatus(rowData) {
  const statusProcessing = { PROCESSING: 1, PROCESSING_MANAGE: 1, PROCESSING_AUTO: 1, PROCESSING_ERROR: 1 }
  const statusProcessingAll = { PROCESSING: 1, PROCESSING_MANAGE: 1, WAIT_CONFIRMED: 1, PROCESSING_AUTO: 1, PROCESSING_ERROR: 1 }

  if (statusProcessingAll[rowData.statusBooking]) {
    let diffTimeMinuteCreated = Math.round((Date.now() - (new Date(rowData.dateCreated.substr(0, 19) + "+00:00").getTime())) / 60000)
    let diffTimeMinuteModified = Math.round((Date.now() - (new Date(rowData.lastModified.substr(0, 19) + "+00:00").getTime())) / 60000)

    if (diffTimeMinuteModified < 60) {
      return
    }

    if (rowData.managerBooking.length && diffTimeMinuteModified > 60 && statusProcessing[rowData.statusBooking]) {
     
      if (diffTimeMinuteModified>150){
        return { color: "red", fontWeight: 500 }
      }
      return { color: "orange", fontWeight: 500 }
    }
    if (rowData.statusBooking === "PROCESSING_MANAGE") {
      if (diffTimeMinuteCreated > 24 * 60) return { color: "orange", fontWeight: 500 }
      return
    }
    
    if (diffTimeMinuteCreated > 20 * 60) return { color: "red", fontWeight: 500 }
    if (diffTimeMinuteCreated > 14 * 60) return { color: "orange", fontWeight: 500 }


  }

}
const mapStateToProps = (state) => {
  return { base: state.data };
};

export default connect(mapStateToProps, {})(Table);