import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import { withRouter } from 'react-router-dom';

import Slider from '@material-ui/core/Slider';
import "./Toolbar.scss"

import TextField from "@material-ui/core/TextField";

import { parseQuery, _fetch } from "./../../helpers"
import CheckBoxCombigo from "./../Utils/Select_CheckBox"
import IntervalleDate from "./../Utils/IntervalleDate"
import SelectCombigo from "./../Utils/Select"



const initialStateBody = {
    startDate: "",
    endDate: "",
     users: [],
    relationId: "",
    event:"",
    to:"",
    subject:"",
    sorting: "CREATED_DATE_DEC"
}



class Body extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            body: {
                ...initialStateBody,
                ...props.filter,

            } //getHistory() ||
        }
        this.lastRequest = Date.now()
        this.search = this.search.bind(this)
        this.onChangeBody = this.onChangeBody.bind(this)
        this.lastUpdateVersion = 0


        this.search(true, this.state.body)


        if (this.props.timeout) this.interval = setInterval(function () {
            this.search(false, this.state.body)
        }.bind(this), this.props.timeout)

    }
    componentWillUnmount(){
        if (this.interval) clearInterval(this.interval)
    }

    componentDidMount(){
       
        let users = []
        for (let l of this.props.base.users) {
         users.push({ id: l.id, title: l.name || l.title })
        }
        this.setState({ listUsers: users })
    }

    _initBody = ()=>{
        
    }

    componentDidUpdate() {

        if (this.version != this.props.version) {
            this.version = this.props.version

            this.setState({
                body: {
                    ...this.state.body,
                    ...this.props.filter,
                }

            })
        }
    }

    async search(force, body = {}) {

        if (force || Date.now() - this.lastRequest > 0.8 * this.props.timeout) {
            this.lastRequest = Date.now()
            this.lastUpdateVersion++
            setTimeout(function (lastUpdateVersion) {
                if (this.lastUpdateVersion != lastUpdateVersion) return
                request.call(this, body, this.lastUpdateVersion)

            }.bind(this, this.lastUpdateVersion), 1500)

        }


        function request(body, lastUpdateVersion) {
            _fetch(process.env.REACT_APP_URL + this.props.url, {
                "timeout": 30000,
                "nbRetry": 1,
                "method": "post",
                "headers": {},
                "body": {
                    filter: body
                }
            })
                .then(function (response) {
                    if (this.lastUpdateVersion != lastUpdateVersion) return
                    if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                    let data = response.json()

                    if (this.props.onUpdate) this.props.onUpdate(data)
                }.bind(this))
                .catch(function (e) {
                    if (this.lastUpdateVersion != lastUpdateVersion) return

                    console.error("Error toolbar", e);
                    if (this.props.onUpdate) this.props.onUpdate(undefined, { error: true })

                }.bind(this));
        }

    }



    onChangeBody(obj) {
        let body = Object.assign({}, this.state.body)
        for (let prop in obj) body[prop] = obj[prop]

        this.setState({ body: body })
        if (this.props.onChange) this.props.onChange(body)
        this.search(true, body)
        // console.log("body",body,"bodyStr",this.bodyStr)
        // this.bodyStr = JSON.stringify(body)
        // sessionStorage.setItem("toolbar-filter3", this.bodyStr)
        // this.urlSearch =  "?bodyRequest=" + encodeURI(this.bodyStr)

        // this.props.history.push({
        //   pathname: window.location.pathname,
        //   //URLSearchParams
        //   search:this.urlSearch
        // })
    }
   


    render() {

        if (!this.state.listUsers) return null
      
        return (
            <Grid container item xs={12} direction="row" className={"toolbar-container-search " + this.props.className} spacing={3}>
                <Grid container item xs={12} direction="row" className={" " + this.props.className} spacing={3}>
                    <Grid container item direction={"row"} xs={2} sm={2} spacing={3} className={"block"}>
                        {/* <label>{"Date "}</label> */}
                        <IntervalleDate
                            label_start={"Date Start"}
                            label_end={"Date End"}
                            format={"DD/MM/yyyy"}
                            type={"date"}
                            style={{ width: "100px" }}
                            startDate={this.state.body.startDate}
                            endDate={this.state.body.endDate}

                            onChange={(value) => {
                                // console.log(value)
                                this.onChangeBody({ startDate: value[0], endDate: value[1] })
                            }}
                        />
                    </Grid>



                    <Grid container item direction={"row"} xs={1} sm={1} spacing={3} className={"block"}>
                        <TextField label="event" variant="standard" value={this.state.body.event} onChange={(ev) => {
                            this.onChangeBody({ event: ev.target.value })
                        }} />
                    </Grid>

                    <Grid container item direction={"row"} xs={1} sm={1} spacing={3} className={"block"}>
                        <TextField label="content" variant="standard" value={this.state.body.subject} onChange={(ev) => {
                            this.onChangeBody({ content: ev.target.value })
                        }} />
                    </Grid>
                    <Grid container item direction={"row"} xs={1} sm={1} spacing={3} className={"block"}>
                        <TextField label="to" variant="standard" value={this.state.body.to} onChange={(ev) => {
                            this.onChangeBody({ to: ev.target.value })
                        }} />
                    </Grid>

                    <Grid container item direction={"row"} xs={1} spacing={3} className={"block"}>
                        <CheckBoxCombigo
                            style={{ minWidth: "100%" }}
                            label={"User"}
                            multiple={true}
                            chips={false}
                            getLabel={(option) => { return option.title }}

                            value={this.state.listUsers.filter((o) => {
                                return this.state.body.users.includes(o.id)?true:false
                            })}

                            list={this.state.listUsers}
                            onChange={(value) => {
                                let newValue = value.map((val) => { return val.id })
                                this.onChangeBody({ users: newValue })
                            }}
                        ></CheckBoxCombigo>
                    </Grid>



                    <Grid container item direction={"row"} xs={1} spacing={3} className={"block"}>
                        <Button onClick={function () {
                              this.setState({
                                body: {
                                    ...initialStateBody,
                                    ...this.props.filter,
                                }
                            }, () => {

                                this.search(true,this.state.body)
                            })
                        }.bind(this)}>{"Reset"}</Button>
                    </Grid>



                </Grid>





            </Grid>
        );
    }

}


const mapStateToProps = (state) => {
    return { base: state.data };
};

export default connect(mapStateToProps, {})(withRouter(Body));