/* eslint-disable no-use-before-define */
import React from 'react';
import { connect } from 'react-redux';
//https://ourcodeworld.com/articles/read/1065/top-15-best-rich-text-editor-components-wysiwyg-for-reactjs
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import CheckBoxCombigo from "./../Utils/Select_CheckBox"

import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';

import { parseQuery, _fetch } from "./../../helpers"
import MyEditor from "./../EditorText/EditorText.js"
import SmsForm from "./../EmailForm/SmsForm.js"

import Label from "./../Label/Label"
import "./ModalSms.scss"


function getContactDefault(doc) {
  
    for (let line of doc.contacts.data) {
        if (line.mode == "USER_DEFAULT") return line
    }
    return doc.contact.data[0]
}



class Body extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            textModel: this.props.textModel,
            listModel: [],
            locale: {},
            snackMessage: null,
            errorMessage: null,
            listLocale: [{ id: "en", name: "Anglais" }, { id: "fr", name: "Français" }, { id: "es", name: "Espagnol" }],
            mail: {
                from: [{ "title": "Combigo.com", name: "Combigo.com" }],
                to: [],
                content: "",
                ...this.props.smsData
            }
        }
        this._request = this._request.bind(this)
        this._loadModel = this._loadModel.bind(this)
        this._init = this._init.bind(this)


    }

    _init() {

        let infoContact = getContactDefault(this.props.booking)
        let localeObj = this.state.listLocale.filter(function (obj) {
            return obj.id == (infoContact.locale || this.props.locale)
        })[0]
        let phone = (infoContact.phoneCallsign +  infoContact.phone ).trim()
        this.setState({
            textModel: this.props.textModel,
            locale: localeObj || {},
            infoContact: infoContact,
            mail: {
                from: [{ "title": "Combigo.com", name: "Combigo.com" }],
                to: infoContact ? [{ "title": phone, name: phone }] : undefined,
                content: "",
                ...this.props.smsData
            }
        })
    }

    async componentDidMount() {
        this._init()

        let data = await this._request({ action: "list" }, "/crm/sms/model")
        if (data) this.setState({ listModel: data })
    }

    componentDidUpdate(prevProps) {

        if (prevProps.bookingId != this.props.bookingId || prevProps.version != this.props.version || prevProps.id != this.props.id) {
            this._init()
        }
    }

    async _sendMail() {

        let sms = {
            content: this.state.mail.content,
            to: convertSend(this.state.mail.to),
            from: convertSend(this.state.mail.from)[0],
            category: this.state.idModel || "",

        }
        this.setState({disabledButtonSend:true})
        this._request({
            mail: sms,
            bookingId: this.props.data.bookingId,
            user: this.props.nameUser,
            locale: this.state.locale.id
        }, "/crm/sms/send").then((response) => {
            if (response) {
                this.setState({ snackMessage: "Message envoyé !" })
                setTimeout(()=>{
                    this.setState({ disabledButtonSend:false })
                    this.props.onClose()
                },2000)

            }
            else {
                this.setState({ errorMessage: "Problème, Message pas envoyé !",disabledButtonSend:false })
            }
        })

        function convertSend(arr = []) {
            return arr.map(function (obj) {
                return  obj.title || obj.email || obj.mail || obj.Email
            })
        }

    }


    async _loadModel(textModel, idModel, locale) {
        if (!locale || !textModel) {
            return this.setState({ model: {} })
        }

        let data = await this._request({
            action: "get",
            "name": textModel,
            "idModel": idModel,
            "locale": locale,
            bookingId: this.props.booking ? this.props.booking.bookingId : undefined
        }, "/crm/sms/model")
        if (!data.sms) {
            return alert("Error retry")
        }
        let model = data.sms
        let mail = Object.assign({}, this.state.mail)
        
        mail["content"] = model.content
        mail["from"] = model.from || this.state.mail.from
        mail["to"] = model.to || this.state.mail.to

        this.setState({ mail: mail, model: model })
        if (model.errors && model.errors.length) {
            setTimeout(() => {
                alert("Error with booking and template sms : " + model.errors.join(" ; "))
            }, 2000)
        }
    }


    _request(body, route) {
        return new Promise(function (resolve) {
            _fetch(process.env.REACT_APP_URL + route, {
                "timeout": 8000,
                "nbRetry": 1,
                "method": "post",
                "headers": {},
                "body": body
            })
                .then(function (response) {
                    if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));

                    // this.setState({snackMessage:"Message envoyé"})
                    resolve(response.json())
                }.bind(this))
                .catch(function (e) {
                    console.error("Error modal sms", e);

                    this.setState({errorMessage:"Erreur to send sms, may be the number is incorrect or is not a mobile phone ?"})
                    resolve(undefined)
                }.bind(this));
        }.bind(this))
    }





    render() {

        return (
            <div className={"action-sms"} style={{ ...this.props.style, width: "100%" }}>

                <div className={"toolbar"}>
                    <Label >{"Pour charger un modèle :"}</Label>
                    <div className={"space-toolbar model-sms"}>
                        {this.state.listModel && this.state.listModel.length && <Autocomplete
                            options={this.state.listModel || []}
                            getOptionLabel={(option) => option.name}
                            inputValue={this.state.textModel}
                            onChange={async (event, newInputValue) => {
                                this.setState({ textModel: newInputValue ? newInputValue.name : "", idModel: newInputValue ? newInputValue.id : "" })
                                if (newInputValue && newInputValue.name) {
                                    this._loadModel(newInputValue.name, newInputValue.id, this.state.locale.id)
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params}
                                    variant="standard"
                                    onChange={(event) => {
                                        this.setState({ textModel: event.target.value })
                                    }}
                                    aria-label={'description'} />
                            }
                        />}
                    </div>
                    <div className={"space-toolbar select-langue"}>
                        <CheckBoxCombigo
                            className={"select-status"}
                            style={{ minWidth: "100%" }}
                            label={"Langue"}
                            list={this.state.listLocale}
                            multiple={false}
                            chips={false}
                            getMatching={(option) => { return option.id }}
                            getLabel={(option) => { return option ? option.name : option }}
                            value={this.state.locale}
                            onChange={(value) => {
                                this.setState({ locale: value })
                                this._loadModel(this.state.textModel, this.state.idModel, value.id)
                            }}
                        ></CheckBoxCombigo>

                    </div>
                    <Button className={""}
                        onClick={() => {
                            this._loadModel(this.state.textModel, this.state.idModel, this.state.locale.id)


                        }} >
                        {"Load/Update"}

                    </Button>
                    <div style={{ flexGrow: 1 }}></div>

                </div>

                <div className={"sms"}>





                    <SmsForm
                        onChange={(prop, value) => {
                            let mail = Object.assign({}, this.state.mail)
                            mail[prop] = value
                            this.setState({ mail: mail })
                        }}
                        listFrom={[{ "title": "Combigo.com", name: "Combigo.com" }]}
                        listTo={this.state.mail.to}
                     
                        content={this.state.mail.content}
                        from={this.state.mail.from}
                        to={this.state.mail.to}
                        id={this.props.id}
                        version={this.props.version}

                    ></SmsForm>
                </div>


                <div className={"footer"}>
                    <div style={{ flexGrow: 1 }}></div>
                    <Button
                        disabled={this.state.disabledButtonSend}
                        className={""}
                        style={{ marginRight: "50px" }}
                        onClick={() => {
                            this._sendMail()
                        }} >
                        {"Envoyer"}
                    </Button>

                </div>

            


                <CombigoSnackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.errorMessage !== null || this.state.snackMessage !== null}
                    onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
                    variant={this.state.errorMessage != null ? 'error' : "success"}
                    autoHideDuration={6000}
                    message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
                />

            </div>

        );
    }
}




const mapStateToProps = (state) => {
    return { booking: state.booking, nameUser: state.user.name };
};

export default connect(mapStateToProps, {})(Body);