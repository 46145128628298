
import React from 'react';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import SelectCheckBoxCombigo from "./../Utils/Select_CheckBox"
import Grid from '@material-ui/core/Grid';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';

import { parseQuery, _fetch } from "./../../helpers"
import Label from "./../Label/Label"
import "./ModalDeskMail.scss"

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

class Body extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            view: "init",
            errorMessage: null,
            snackMessage: null,

            disabledButton: false,

        }


    }



    async componentDidMount() {

    }

    componentDidUpdate(prevProps) {

        if (this.props.version != prevProps.version) {

            this.props.onClose()
        }

    }






    onClose() {

        this.props.onClose()
    }


    render() {

        if (!this.props.data) return null

        return (
            <>
                <Dialog
                    className={(this.props.className || "") + " modalDialog modal-ticket"}
                    open={this.props.open || false}
                    onClose={() => {
                        this.onClose()
                    }}
                    keepMounted
                >
                    <Grid className={"title-container"}>

                        <Grid className={"text-container"}>
                            <span className={""}>{"From : " + this.props.data.from}</span>
                            <span className={""}>{"To : " + this.props.data.to.join(", ")}</span>
                            <span className={""}>{"Subject : " + this.props.data.subject}</span>
                            <span className={""}>{"Date : " + new Date(this.props.data.dateCreated).toISOString().substr(0, 20)}</span>
                            <span className={""}>{"Status : " + this.props.data.status}</span>
                            <span className={""}>{"Group : " + this.props.data.group}</span>


                        </Grid>
                        <div style={{ flexGrow: 1 }}></div>
                        <Grid>
                            <IconButton size="small" onClick={this.props.onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>


                    <DialogContent>
                        {this.props.open &&
                            <>

                                <div className={"body-mail"}><div className={"body-content"} dangerouslySetInnerHTML={{ __html: this.props.data.body }} ></div></div>
                            </>
                        }
                    </DialogContent>

                    <DialogActions>
                        <div>

                        </div>
                        <div style={{ flexGrow: 1 }}></div>
                        <IconButton onClick={() => {
                            this.props.last()
                        }}>
                            <ArrowBackIosIcon />
                        </IconButton>
                        <div style={{ display: "flex", padding: "5px", "margin": "auto", fontWeight: 500 }}>
                            {(this.props.position+1) + " / " + this.props.nbTotal}
                        </div>


                        <IconButton onClick={() => {
                            this.props.next()
                        }}>
                            <ArrowForwardIosIcon />
                        </IconButton>

                        <div style={{ flexGrow: 1 }}></div>

                        <Button onClick={this.onClose} color="primary">
                            {"Exit"}
                        </Button>
                    </DialogActions>
                </Dialog>
                <CombigoSnackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.errorMessage !== null || this.state.snackMessage !== null}
                    onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
                    variant={this.state.errorMessage != null ? 'error' : "success"}
                    autoHideDuration={6000}
                    message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
                />
            </>
        );
    }
}




const mapStateToProps = (state) => {
    return { booking: state.booking, nameUser: state.user.name };
};

export default connect(mapStateToProps, {})(Body);