import React, { Component, version } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { parseQuery, _fetch } from "./../helpers"
import "./AllTransaction.scss"

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { CombigoSnackbar } from './../Components/Snackbar/Snackbar';
import { Chart } from "react-google-charts";
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import { Helmet } from "react-helmet";






class Body extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            version: "init",
            modeVisu: "day",
            countVersion: 0,
            expand_0: true,
            expand_1: true,
            expand_2: true,
            expand_3: true,
            expand_4: true,
            expand_5: true,
            expand_6: true,
            expand_7: true,
            expand_8: true,
            expand_9: true,
            expand_10: true,
            expand_11: true,
            expand_12: true,
            expand_13: true,
            displayInit: false,
            splitByCategory: true
        }
    }


    initData = () => {
        this.request(undefined, (data) => {
            if (data) {

                let map = {}
                for (let l of data.data) map[l.key] = l

                this.setState({ data: map, dateUpdate: Date.now() })
            }
        })
    }

    componentDidMount() {
        this.initData()
    }

    componentDidUpdate(prevProps) {




    }


    request = (body, cb) => {
        _fetch(process.env.REACT_APP_URL + "/crm/metrics/business", {
            "timeout": 10000,
            "nbRetry": 1,
            "method": "post",
            "headers": {},
            "body": {

            }
        })
            .then(function (response) {
                if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                let data = response.json()
                cb(data)
            }.bind(this))
            .catch(function (e) {
                cb(undefined, e)

                console.error("Error metrics", e);

            }.bind(this));
    }



    render() {
        if (!this.state.data) return null

        let list
        list = {
            "margin_by_month": (line) => {

                let data = [
                ];

                let keys = {}
                for (let l of line.data.buckets) {
                    for (let l2 of l.status.buckets) {
                        if (l2.key === "INIT" && !this.state.categoryFilter) continue
                        if (this.state.categoryFilter && l2.key != this.state.categoryFilter) continue
                        if (!this.state.splitByCategory) l2.key = ""

                        keys[l2.key] = 1
                    }
                }
                let listKey = Object.keys(keys)
                listKey.unshift("Month")
                data.push(listKey)
                for (let l of line.data.buckets) {
                    let lineObj = new Array(listKey.length)
                    for (let k = 0; k < lineObj.length; k++) lineObj[k] = null
                    lineObj[0] = l.key_as_string.substr(0, 7)
                    for (let l2 of l.status.buckets) {
                        let index = listKey.indexOf(l2.key)
                        if (!lineObj[index]) lineObj[index] = 0

                        lineObj[index] += l2.gmv.value
                    }
                    data.push(lineObj)
                }

                const options = {
                    chart: {},
                    bar: { groupWidth: "70%" },
                    // legend: { position: "top", maxLines: 2 },
                };

                return {
                    render: <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />,
                    name: "Marge Brute par MOIS"
                }
            },
            "margin_by_day": (line) => {

                let data = [
                ];

                let keys = {}
                for (let l of line.data.buckets) {
                    for (let l2 of l.status.buckets) {
                        if (l2.key === "INIT" && !this.state.categoryFilter) continue
                        if (this.state.categoryFilter && l2.key != this.state.categoryFilter) continue
                        if (!this.state.splitByCategory) l2.key = ""

                        keys[l2.key] = 1
                    }
                }
                let listKey = Object.keys(keys)
                listKey.unshift("Day")
                data.push(listKey)
                for (let l of line.data.buckets) {
                    let lineObj = new Array(listKey.length)
                    for (let k = 0; k < lineObj.length; k++) lineObj[k] = null
                    lineObj[0] = l.key_as_string.substr(0, 10)
                    for (let l2 of l.status.buckets) {
                        let index = listKey.indexOf(l2.key)
                        if (!lineObj[index]) lineObj[index] = 0

                        lineObj[index] += l2.gmv.value
                    }
                    data.push(lineObj)
                }

                const options = {
                    chart: {},
                    bar: { groupWidth: "70%" },
                    // legend: { position: "top", maxLines: 2 },
                };

                return {
                    render: <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />,
                    name: "Marge Brute par JOUR"
                }
            },
            "waiting_by_month": (line) => {

                let data = [
                ];

                let keys = {}
                for (let l of line.data.buckets) {
                    for (let l2 of l.status.buckets) {
                        // if (l2.key === "INIT" && !this.state.categoryFilter) continue
                        if (this.state.categoryFilter && l2.key != this.state.categoryFilter) continue
                        if (!this.state.splitByCategory) l2.key = ""

                        keys[l2.key] = 1
                    }
                }
                let listKey = Object.keys(keys)
                listKey.unshift("Month")
                data.push(listKey)
                for (let l of line.data.buckets) {
                    let lineObj = new Array(listKey.length)
                    for (let k = 0; k < lineObj.length; k++) lineObj[k] = null
                    lineObj[0] = l.key_as_string.substr(0, 7)
                    for (let l2 of l.status.buckets) {
                        let index = listKey.indexOf(l2.key)
                        if (!lineObj[index]) lineObj[index] = 0
                        lineObj[index] += l2.gmv.value
                    }
                    data.push(lineObj)
                }

                const options = {
                    chart: {},
                    bar: { groupWidth: "70%" },
                    // legend: { position: "top", maxLines: 2 },
                };

                return {
                    render: <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />,
                    name: "Amount Waiting par MOIS"
                }
            },
            "waiting_by_day": (line) => {

                let data = [
                ];

                let keys = {}
                for (let l of line.data.buckets) {
                    for (let l2 of l.status.buckets) {
                        // if (l2.key==="INIT" && !this.state.displayInit) continue
                        if (this.state.categoryFilter && l2.key != this.state.categoryFilter) continue
                        if (!this.state.splitByCategory) l2.key = ""

                        keys[l2.key] = 1
                    }
                }
                let listKey = Object.keys(keys)
                listKey.unshift("Day")
                data.push(listKey)
                for (let l of line.data.buckets) {
                    let lineObj = new Array(listKey.length)
                    for (let k = 0; k < lineObj.length; k++) lineObj[k] = null
                    lineObj[0] = l.key_as_string.substr(0, 10)
                    for (let l2 of l.status.buckets) {
                        let index = listKey.indexOf(l2.key)
                        if (!lineObj[index]) lineObj[index] = 0

                        lineObj[index] += l2.gmv.value
                    }
                    data.push(lineObj)
                }

                const options = {
                    chart: {},
                    bar: { groupWidth: "70%" },
                    // legend: { position: "top", maxLines: 2 },
                };

                return {
                    render: <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />,
                    name: "Amount Waiting par JOUR"
                }
            },

            "qty_by_month": (line) => {

                let data = [
                ];

                let keys = {}
                for (let l of line.data.buckets) {
                    for (let l2 of l.status.buckets) {
                        if (l2.key === "INIT" && !this.state.categoryFilter) continue
                        if (this.state.categoryFilter && l2.key != this.state.categoryFilter) continue
                        if (!this.state.splitByCategory) l2.key = ""

                        keys[l2.key] = 1
                    }
                }
                let listKey = Object.keys(keys)
                listKey.unshift("Month")
                data.push(listKey)
                for (let l of line.data.buckets) {
                    let lineObj = new Array(listKey.length)
                    for (let k = 0; k < lineObj.length; k++) lineObj[k] = null
                    // console.log("l.key_as_string",l.key_as_string,l)
                    lineObj[0] = l.key_as_string.substr(0, 7)
                    for (let l2 of l.status.buckets) {
                        let index = listKey.indexOf(l2.key)
                        if (!lineObj[index]) lineObj[index] = 0

                        lineObj[index] += l2.doc_count
                    }
                    data.push(lineObj)
                }
                const options = {
                    chart: {},
                    bar: { groupWidth: "70%" },

                    // legend: { position: "top", maxLines: 2 },
                };

                return {
                    render: <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />,
                    name: "Nombre par MOIS"
                }
            },


            "qty_by_day": (line) => {

                let data = [
                ];

                let keys = {}
                for (let l of line.data.buckets) {
                    for (let l2 of l.status.buckets) {
                        if (l2.key === "INIT" && !this.state.categoryFilter) continue
                        if (this.state.categoryFilter && l2.key != this.state.categoryFilter) continue
                        if (!this.state.splitByCategory) l2.key = ""

                        keys[l2.key] = 1
                    }
                }
                let listKey = Object.keys(keys)
                listKey.unshift("Day")
                data.push(listKey)
                for (let l of line.data.buckets) {
                    let lineObj = new Array(listKey.length)
                    for (let k = 0; k < lineObj.length; k++) lineObj[k] = null
                    lineObj[0] = l.key_as_string.substr(0, 10)
                    for (let l2 of l.status.buckets) {
                        let index = listKey.indexOf(l2.key)
                        if (!lineObj[index]) lineObj[index] = 0

                        lineObj[index] += l2.doc_count
                    }
                    data.push(lineObj)
                }


                const options = {
                    chart: {},
                    bar: { groupWidth: "70%" },

                    // legend: { position: "top", maxLines: 2 },
                };

                return {
                    render: <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />,
                    name: "Nombre par JOUR"
                }
            },
            "gmv_by_month": (line) => {


                let data = [
                ];

                let keys = {}
                for (let l of line.data.buckets) {
                    for (let l2 of l.status.buckets) {
                        if (l2.key === "INIT" && !this.state.categoryFilter) continue
                        if (this.state.categoryFilter && l2.key != this.state.categoryFilter) continue
                        if (!this.state.splitByCategory) l2.key = ""

                        keys[l2.key] = 1
                    }
                }
                let listKey = Object.keys(keys)
                listKey.unshift("Month")
                data.push(listKey)
                for (let l of line.data.buckets) {
                    let lineObj = new Array(listKey.length)
                    for (let k = 0; k < lineObj.length; k++) lineObj[k] = null
                    lineObj[0] = l.key_as_string.substr(0, 7)
                    for (let l2 of l.status.buckets) {
                        let index = listKey.indexOf(l2.key)
                        if (!lineObj[index]) lineObj[index] = 0

                        lineObj[index] += l2.gmv.value
                    }
                    data.push(lineObj)
                }



                const options = {
                    chart: {},
                    bar: { groupWidth: "70%" },
                    // legend: { position: "top", maxLines: 2 },
                };

                return {
                    render: <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />,
                    name: "GMV par MOIS"
                }
            },
            "gmv_by_day": (line) => {


                let data = [
                ];

                let keys = {}
                for (let l of line.data.buckets) {
                    for (let l2 of l.status.buckets) {
                        if (l2.key === "INIT" && !this.state.categoryFilter) continue
                        if (this.state.categoryFilter && l2.key != this.state.categoryFilter) continue
                        if (!this.state.splitByCategory) l2.key = ""

                        keys[l2.key] = 1
                    }
                }
                let listKey = Object.keys(keys)
                listKey.unshift("Day")
                data.push(listKey)
                for (let l of line.data.buckets) {
                    let lineObj = new Array(listKey.length)
                    for (let k = 0; k < lineObj.length; k++) lineObj[k] = null
                    lineObj[0] = l.key_as_string.substr(0, 10)
                    for (let l2 of l.status.buckets) {
                        let index = listKey.indexOf(l2.key)
                        if (!lineObj[index]) lineObj[index] = 0

                        lineObj[index] = l2.gmv.value
                    }
                    data.push(lineObj)
                }




                const options = {
                    chart: {},
                    bar: { groupWidth: "70%" },
                    // legend: { position: "top", maxLines: 2 },
                };

                return {
                    render: <Chart

                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />,
                    name: "GMV par JOUR"
                }
            },
            "kpi_support_month_day": (line) => {


                let data = [
                ];


                let listKey = ["Total"]
                listKey.unshift("Month")
                data.push(listKey)
                for (let l of line.data.buckets) {
                    let lineObj = new Array(listKey.length)
                    for (let k = 0; k < lineObj.length; k++) lineObj[k] = null
                    lineObj[0] = l.key_as_string.substr(0, 7)
                    for (let l2 of l.status.buckets) {
                        if (l2.key === "INIT") continue
                        if (l2.key === "BANK") {
                            l2.gmv.value = l2.gmv.value / 2
                        }

                        if (!lineObj[1]) lineObj[1] = 0
                        lineObj[1] += l2.gmv.value
                    }
                    data.push(lineObj)
                }




                const options = {
                    chart: {},
                    bar: { groupWidth: "70%" },
                    // legend: { position: "top", maxLines: 2 },
                };

                return {
                    render: <Chart

                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />,
                    name: "KPI Support"
                }
            },


        }


        let orderList = ["kpi_support_month_day"]
        orderList = orderList.concat(Object.keys(list))

        let already = {}

        return <div className={"view-transaction"}>
            <div className={"title-page"}> {"WOUAAHHHHH les metrics.... " + (this.state.dateUpdate ? new Date(this.state.dateUpdate).toISOString() : "")}</div>

            {
                <div>
                    <FormControl className={""}>
                        <InputLabel htmlFor="age-native-simple">{"Visualisation Day/Month"}</InputLabel>
                        <Select
                            native
                            value={this.state.modeVisu}
                            onChange={(ev) => {
                                this.setState({ modeVisu: ev.target.value })
                            }}

                        >
                            <option value={"day"}>{"Day"}</option>
                            <option value={"month"}>{"Month"}</option>
                        </Select>
                    </FormControl>
                    <FormControl className={""} style={{ "marginLeft": "10px" }}>
                        <InputLabel htmlFor="age-native-simple">{"Display Category"}</InputLabel>
                        <Select
                            native
                            value={this.state.splitByCategory}
                            onChange={(ev) => {
                                this.setState({ splitByCategory: ev.target.value === "false" ? false : true })
                            }}

                        >
                            <option value={"true"}>{"Oui"}</option>
                            <option value={"false"}>{"Non"}</option>
                        </Select>
                    </FormControl>
                    <FormControl className={""} style={{ "marginLeft": "10px" }}>
                        <InputLabel htmlFor="age-native-simple">{"Select Category"}</InputLabel>
                        <Select
                            nati
                            ve
                            value={this.state.categoryFilter}
                            onChange={(ev) => {
                                this.setState({ categoryFilter: ev.target.value })
                            }}

                        >
                            <option value={""}>{""}</option>
                            <option value={"INIT"}>{"INIT"}</option>
                            <option value={"CANCEL_VOLUNTARY"}>{"CANCEL_VOLUNTARY"}</option>
                            <option value={"CANCEL_COMBIGO"}>{"CANCEL_COMBIGO"}</option>
                            <option value={"OPTIONS"}>{"OPTIONS"}</option>
                            <option value={"MODIFICATION"}>{"MODIFICATION"}</option>
                            <option value={"DISRUPTED_CONNECTION"}>{"DISRUPTED_CONNECTION"}</option>
                            <option value={"DENIED_BOARDED"}>{"DENIED_BOARDED"}</option>
                            <option value={"OTHER"}>{"OTHER"}</option>

                        </Select>
                    </FormControl>
                </div>
            }
            {orderList.map((key, index) => {
                if (already[key]) return null
                already[key] = true
                let obj = this.state.data[key]
                if (!list[key] || !obj) return null
                const value = list[key](obj, this.state.data)
                if (key.indexOf(this.state.modeVisu) === -1) return null
                if (!value) return null

                return <Accordion expanded={this.state["expand_" + index]} key={index} className={"filtre-bandeau"}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        onClick={() => {
                            let obj_ = {}
                            obj_["expand_" + index] = !this.state["expand_" + index]
                            this.setState(obj_)
                        }}
                    >
                        <Typography className={""}>{value.name || key}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>

                        {value.render}
                    </AccordionDetails>
                </Accordion>
            })
            }




        </div>
    }
}


export default withRouter(Body);

