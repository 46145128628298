import React, { Component, version } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from "material-table";
import WrapperMaterialTable from "./../Table/WrapperMaterialTable"

import Button from '@material-ui/core/Button';
import Delete from '@material-ui/icons/Delete';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import IconButton from '@material-ui/core/IconButton';

import Drawer from '@material-ui/core/Drawer';
import Edit from '@material-ui/icons/Edit';
import Chip from '@material-ui/core/Chip';
import "./Table.scss"
import CheckBoxCombigo from "./../Utils/Select_CheckBox"
import Grid from '@material-ui/core/Grid';
import TooltipCombigo from "./../Utils/TooltipCombigo"
import Modal from '@material-ui/core/Modal';
import LaunchIcon from '@material-ui/icons/Launch';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import { CombigoSnackbar, CombigoUpdate } from './../../Components/Snackbar/Snackbar';
import Dialog from '@material-ui/core/Dialog';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoneIcon from '@mui/icons-material/Done';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
const IconByStatus = {
  TICKET: <ConfirmationNumberIcon style={{ width: "27px", "height": "27px" }} ></ConfirmationNumberIcon>,
  REFUND: <SettingsBackupRestoreIcon style={{ color: "rgba(51,204,51,1)", fill: "rgba(51,204,51,1)", width: "27px", "height": "27px" }}></SettingsBackupRestoreIcon>,
  DEPOSIT: <LocalAtmIcon style={{ color: "rgba(255, 204, 51,1)", fill: "rgba(255, 204, 51,1)", width: "27px", "height": "27px" }}></LocalAtmIcon>,

  // PROCESSING: <TouchAppIcon style={{ color: "rgba(189, 171, 130,1)", fill: "rgba(189, 171, 130,1)", width: "27px", "height": "27px" }}></TouchAppIcon>,
  // PROCESSING_AUTO: <TouchAppIcon style={{ color: "rgba(255, 204, 51,1)", fill: "rgba(255, 204, 51,1)", width: "27px", "height": "27px" }}></TouchAppIcon>,
  // PROCESSING_MANAGE: <TouchAppIcon style={{ color: "rgba(255, 204, 51,1)", fill: "rgba(255, 204, 51,1)", width: "27px", "height": "27px" }}></TouchAppIcon>,
  // WAIT_CONFIRMED: <AccessTimeIcon style={{ color: "rgba(255, 204, 51,1)", fill: "rgba(255, 204, 51,1)", width: "27px", "height": "27px" }}></AccessTimeIcon>,
  // MODIFICATION: <AccessTimeIcon style={{ color: "rgba(255, 204, 51,1)", fill: "rgba(255, 204, 51,1)", width: "27px", "height": "27px" }}></AccessTimeIcon>,

  // CONFIRMED: <DoneIcon style={{ color: "rgba(51,204,51,1)", fill: "rgba(51,204,51,1)", width: "27px", "height": "27px" }}></DoneIcon>,
  // DONE: <DoneAllIcon style={{ color: "rgba(51,204,51,1)", fill: "rgba(51,204,51,1)", width: "27px", "height": "27px" }}></DoneAllIcon>,
  // CANCEL_OTHER: <HighlightOffIcon style={{ color: "rgba(255,102,102,1)", fill: "rgba(255,102,102,1)", width: "27px", "height": "27px" }}></HighlightOffIcon>,
  // CANCEL_COMBIGO: <HighlightOffIcon style={{ color: "rgba(255,102,102,1)", fill: "rgba(255,102,102,1)", width: "27px", "height": "27px" }}></HighlightOffIcon>,
  // CANCEL_VOLUNTARY: <HighlightOffIcon style={{ color: "rgba(255,102,102,1)", fill: "rgba(255,102,102,1)", width: "27px", "height": "27px" }}></HighlightOffIcon>,

}



// function getColumnLocal() {
//   try {
//     return JSON.parse(localStorage.getItem("selectColumnTableMain"))
//   } catch (e) {

//   }
// }

class Table extends Component {
  constructor(props) {
    super(props)
    this.state = {
      snackMessage: null,
      errorMessage: null,
      openUpdatedButton: true,
      // selectColumn: getColumnLocal() || this.props.base.columnTableRecap
    }
  }

  // componentDidUpdate(){

  // }

  _search = (bookingId, step) => {
    if (!this.props.data) return null

    for (let k = 0; k < this.props.data.length; k++) {
      let l = this.props.data[k]
      if (l.bookingId === bookingId) {
        if (step === "position") {
          return { bookingId: bookingId, position: (k + 1) }
        }
        if (step === "+1") {
          return this.props.data[k + 1] ? { bookingId: this.props.data[k + 1].bookingId, position: k + 1 } : ""
        }
        else if (step === "-1") {
          return this.props.data[k - 1] ? { bookingId: this.props.data[k - 1].bookingId, position: k + 1 } : ""
        }
      }
    }


  }

  render() {

    let size = this.props.data ? Math.min(this.props.data.length, 100) : 100
    let conversionType = {}
    const columns = [
      // {
      //   title: "", field: "", render: function (rowData) {
      //     return rowData.bookingId ? <a href={"/oneTransaction/" + rowData.bookingId} target="_blank"><IconButton onClick={() => {
      //       // this.props.history.push("/oneTransaction?bookingId="+rowData.bookingId)
      //     }}>
      //       <FolderOpenIcon />
      //     </IconButton>
      //     </a> : ""
      //   }.bind(this)
      // },
      // {
      //   title: "", field: "", render: (rowData) => {
      //     return <div style={{ "display": "flex", width: "30px", "flexWrap": "nowrap" }}>
      //       <div style={{ margin: "auto", marginLeft: 0, marginRight: 0 }}>{IconByStatus[rowData.type] || null}</div>
      //     </div>
      //   }
      // },
      {
        title: "date", field: "date", render: (rowData) => {
          return new Date(rowData.date).toISOString().substr(0, 19).replace("T", " ")
        }
      },
      {
        title: "name", field: "name", render: (rowData) => {
          return rowData.name
        }
      },

      {
        title: "Price", field: "Price", render: (rowData) => {
          let strPrice = rowData.price.amount + " " + (rowData.price.currency === "EUR" ? "€" : rowData.price.currency)
          if (rowData.type === "DEPOSIT") {
            return strPrice
          }
          if (rowData.price.amount >= 0) {
            return <span style={{ fontWeight: "600", color: "rgba(51,204,51,1)", fill: "rgba(51,204,51,1)", }}>{strPrice}</span>
          }
          return <span style={{ fontWeight: "600", color: "rgba(255,102,102,1)", fill: "rgba(255,102,102,1)", }}>{strPrice}</span>

        }
      },
    
      {
        title: "Category", field: "Category", render: (rowData) => {
          return rowData.category
        }
      },
      {
        title: "idMerchant", field: "idMerchant", render: (rowData) => {
          return rowData.idMerchant
        }
      },
   
   
      {
        title: "accountId", field: "accountId", render: (rowData) => {
          return rowData.accountId + " " + rowData.typeData
        }
      },

      {
        title: "PNR", field: "PNR", render: (rowData) => {
          return rowData.idTransporter || ""
        }
      },
      {
        title: "_id", field: "_id", render: (rowData) => {
          return rowData._id || ""
        }
      },


    ]

    /*
.filter(function (obj) {
            if (!obj.title) return true
            if (obj.field === "TR") {
              return true
            }
            return this.state.selectColumn.includes(obj.title) || this.state.selectColumn.includes(obj.field)
          }.bind(this))
    */



    

      


    return (
      <div style={{ maxWidth: "100%" }}>

        <WrapperMaterialTable
          columns={columns.filter((o,index)=>{
            if (index===0 && this.props.noDisplayBookingId) return false
            return true
          })}
          components={{
            Toolbar:  function (props) {
              if (this.props.hiddenToolbar) return null
              /*
    backgroundColor: '#e8eaf5'
              */
              return <div style={{ display: "flex", padding: "10px 20px" }}>

                {this.props.headerFilter}
                {<div style={{ flexGrow: 1, textAlign: "center", fontSize: "16px" }}><b>{this.props.titleHeader ? this.props.titleHeader : ""}</b></div>}
                {/* <div className={"select-column2"} style={{ maxWidth: "150px" }}>
                  <CheckBoxCombigo
                    className={""}
                    style={{ width: "100%" }}
                    label={"Affichage Colonne"}
                    getLabel={(option) => { return option }}
                    // getMatching={(option) => { return option.code }}
                    list={this.props.base.columnTableRecap}
                    multiple={true}
                    chips={false}
                    value={this.state.selectColumn}
                    onChange={(value) => {
                      this.setState({ selectColumn: value })
                      localStorage.setItem("selectColumnTableMain", JSON.stringify(value))
                    }}
                  ></CheckBoxCombigo>
                </div> */}
                <MTableToolbar {...props} />
              </div>
            }.bind(this)


          }}
          data={this.props.data}
          title={this.props.title || ""}
          options={{
             pageSize:size,
            pageSizeOptions: [50, 100, 200, 400],
            grouping: false,
            exportButton: this.props.hiddenToolbar ? false : true,
            padding: "dense",
            search: this.props.hiddenToolbar ? false : true
          }}
        />



        <CombigoSnackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.errorMessage !== null || this.state.snackMessage !== null}
          onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
          variant={this.state.errorMessage != null ? 'error' : "success"}
          autoHideDuration={6000}
          message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
        />
      </div>
    );
  }
}



const mapStateToProps = (state) => {
  return { base: state.data };
};

export default connect(mapStateToProps, {})(Table);