import React, { Component, version } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from "material-table";
import WrapperMaterialTable from "./../Table/WrapperMaterialTable"
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import Button from '@material-ui/core/Button';
import Delete from '@material-ui/icons/Delete';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import IconButton from '@material-ui/core/IconButton';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import Drawer from '@material-ui/core/Drawer';
import Edit from '@material-ui/icons/Edit';
import Chip from '@material-ui/core/Chip';
import "./Table.scss"
import CheckBoxCombigo from "./../Utils/Select_CheckBox"
import Grid from '@material-ui/core/Grid';
import TooltipCombigo from "./../Utils/TooltipCombigo"
import Modal from '@material-ui/core/Modal';
import LaunchIcon from '@material-ui/icons/Launch';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import { CombigoSnackbar, CombigoUpdate } from './../../Components/Snackbar/Snackbar';
import Dialog from '@material-ui/core/Dialog';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoneIcon from '@mui/icons-material/Done';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ModalDeskMail from "./ModalDeskMail"
import { parseQuery, _fetch } from "./../../helpers"

import ForwardIcon from '@material-ui/icons/Forward';

// function getColumnLocal() {
//   try {
//     return JSON.parse(localStorage.getItem("selectColumnTableMain"))
//   } catch (e) {

//   }
// }

class Table extends Component {
  constructor(props) {
    super(props)
    this.state = {
      snackMessage: null,
      errorMessage: null,
      openUpdatedButton: true,
      // selectColumn: getColumnLocal() || this.props.base.columnTableRecap
    }
  }

  // componentDidUpdate(){

  // }



  render() {

    let size = this.props.data ? Math.min(this.props.data.length, 100) : 100

    const colorByStatus = {
      "Closed": {color:"#aaa"},
      "Open":{"fontWeight":700,color:"rgb(255, 165, 0)"}
    }

    const columns = [
      {
        title: "", field: "", render: (rowData) => {


          return <IconButton onClick={() => {
            let indexModal = 0
            for (let i = 0; i < this.props.data.length; i++) {
              if (this.props.data[i].id === rowData.id) {
                indexModal = i
                break
              }
            }


            this.setState({ dataModal: rowData, openModal: true, indexModal: indexModal })
          }}>
            <VisibilityIcon />
          </IconButton>

        }
      },

      {
        title: "date", field: "date", render: (rowData) => {
          return <span style={colorByStatus[rowData.status]}>{new Date(rowData.dateCreated).toISOString().substr(0, 19).replace("T", " ")}</span>
        }, customSort: (a, b) => new Date(a.dateCreated).getTime() - new Date(b.dateCreated).getTime()
      },
      {
        title: "Group", field: "", render: (rowData) => {
          return <span style={colorByStatus[rowData.status]}>{rowData.group}</span>
        }
      },
      {
        title: "Status", field: "status", render: (rowData) => {
          return <span style={colorByStatus[rowData.status]}>{rowData.status}</span>
        }
      },

      {
        title: "from", field: "from", render: (rowData) => {
          return <span style={colorByStatus[rowData.status]}>{rowData.from}</span>
        }
      },

      {
        title: "to", field: "to", render: (rowData) => {
          return <span style={colorByStatus[rowData.status]}>{rowData.to.join(";")}</span>
        }
      },



      {
        title: "Subject", field: "subject", render: (rowData) => {
          return <span style={colorByStatus[rowData.status]}>{(rowData.subject || "").substr(0, 50)}</span>
        }
      },

      {
        title: "Description", field: "description", render: (rowData) => {
          return <span style={colorByStatus[rowData.status]}>{(rowData.resume || "").substr(0, 50)}</span>
        }
      },
      {
        title: "Tags", field: "tags", render: (rowData) => {
          return <span style={colorByStatus[rowData.status]}>{(rowData.tags || []).join(";")}</span>
        }
      },
      {
        title: "FreshDesk", field: "", render: function (rowData) {
          return rowData.id ? <IconButton onClick={() => {
            window.open("https://newaccount1603196282781.freshdesk.com/a/tickets/" + rowData.id, "_blank")

          }}>
            <QuestionAnswerIcon />
          </IconButton>
            : ""
        }.bind(this)
      },
      {
        title: "ForwardMail", field: "", render: function (rowData) {
          return rowData.id ? <IconButton onClick={() => {


            _fetch(process.env.REACT_APP_URL + "/crm/mail/forward?method=forward", {
              "timeout": 20000,
              "nbRetry": 2,
              "method": "post",
              "headers": {},
              "body": {
                bookingId: this.props.booking.bookingId,
                idFreshdesk: rowData.id,
                metadata: {
                  user: this.props.nameUser
                },
                mail: {
                  subject: rowData.subject,
                  from: "noreply@combigo.com",
                  to: this.props.booking.contacts.data.map((l) => {
                    return l.mail
                  }).filter((l) => {
                    return l ? true : false
                  }),
                }

              }

            })
              .then(function (response) {
                if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                let data = response.json()

                this.setState({ snackMessage: "Transfer mail OK!" })


              }.bind(this))
              .catch(function (e) {
                this.setState({ errorMessage: "Erreur to transfer mail" })
                console.error("Error modal cancel", e);
              }.bind(this));

          }}>
            <ForwardIcon />
          </IconButton>
            : ""
        }.bind(this)
      }

    ]






    return (
      <div style={{ maxWidth: "100%" }}>

        <WrapperMaterialTable
          columns={columns}
          components={{
            Toolbar: function (props) {
              if (this.props.hiddenToolbar) return null
              /*
    backgroundColor: '#e8eaf5'
              */
              return <div style={{ display: "flex", padding: "10px 20px" }}>

                {this.props.headerFilter}
                {<div style={{ flexGrow: 1, textAlign: "center", fontSize: "16px" }}><b>{this.props.titleHeader ? this.props.titleHeader : ""}</b></div>}
                {/* <div className={"select-column2"} style={{ maxWidth: "150px" }}>
                  <CheckBoxCombigo
                    className={""}
                    style={{ width: "100%" }}
                    label={"Affichage Colonne"}
                    getLabel={(option) => { return option }}
                    // getMatching={(option) => { return option.code }}
                    list={this.props.base.columnTableRecap}
                    multiple={true}
                    chips={false}
                    value={this.state.selectColumn}
                    onChange={(value) => {
                      this.setState({ selectColumn: value })
                      localStorage.setItem("selectColumnTableMain", JSON.stringify(value))
                    }}
                  ></CheckBoxCombigo>
                </div> */}
                <MTableToolbar {...props} />
              </div>
            }.bind(this)


          }}
          data={this.props.data}
          title={this.props.title || ""}
          options={{
            pageSize: size,
            pageSizeOptions: [50, 100, 200, 400],
            grouping: false,
            exportButton: this.props.hiddenToolbar ? false : true,
            padding: "dense",
            search: this.props.hiddenToolbar ? false : true
          }}
        />

        <ModalDeskMail
          data={this.state.dataModal}
          open={this.state.openModal}
          nbTotal={this.props.data.length}
          position={this.state.indexModal}
          next={() => {
            let newValue = this.props.data[this.state.indexModal + 1]
            if (newValue) {
              this.setState({ dataModal: newValue, indexModal: this.state.indexModal + 1 })
            }


          }}
          last={() => {
            let newValue = this.props.data[this.state.indexModal - 1]
            if (newValue) {
              this.setState({ dataModal: newValue, indexModal: this.state.indexModal - 1 })
            }
          }}
          onClose={() => {
            this.setState({ openModal: false, dataModal: null, indexModal: null })
          }}
        />


        <CombigoSnackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.errorMessage !== null || this.state.snackMessage !== null}
          onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
          variant={this.state.errorMessage != null ? 'error' : "success"}
          autoHideDuration={6000}
          message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
        />
      </div>
    );
  }
}



const mapStateToProps = (state) => {
  return { base: state.data, booking: state.booking, nameUser: state.user.name };
};

export default connect(mapStateToProps, {})(Table);