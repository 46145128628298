import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { Helmet } from "react-helmet";

import { setBooking, resetBooking } from './../Store/actions/booking';
import ActionTransaction from "./../Components/ActionTransaction/ActionTransaction"
import VisionTransaction from "./../Components/Recap/VisionTransaction"
import VisionTransactionCustomer from "./../Components/Recap/VisionTransactionCustomer"
import TablePassenger from "./../Components/TablePassenger/TablePassenger"
import TableSegments from "./../Components/TableSegments/TableSegments"
import TableAccount2 from "./../Components/TableAccount2/TableAccount2"

import TableOptions from "./../Components/TableOptions/TableOptions"
import TableLogs from "./../Components/TableLogs/TableLogs"
import TableMail from "./../Components/TableMail/TableMail"
import TableSms from "./../Components/TableSms/TableSms"

import TableRealTime from "./../Components/TableRealTime/TableRealTime"

import TableTasks from "./../Components/Tasks/TableTasks"
import BookingHelp from "./../Components/BookingHelp/BookingHelp"
import MenuLeft from "../Components/MenuObjet/MenuTransaction"
import ListNotes from "./../Components/ListNotes/ListNotes"

import TableBooking from "./../Components/TableBooking/TableBooking"
import TableContact from "./../Components/TableContact/TableContact"
import TableFlowAccount from "./../Components/TableFlowAccount/TableMain"
import TableDeskMail from "./../Components/TableDeskMail/TableMain"
import TableForwardMail from "./../Components/TableForwardMail/TableForwardMail"
import { CombigoSnackbar } from './../Components/Snackbar/Snackbar';

import ModalMail from "./../Components/ModalMail/ModalMailMain"
import ModalSms from "./../Components/ModalSms/ModalSmsMain"

import ModalTicket from "./../Components/ModalTicket/ModalTicket"

import ModalCardBank from "./../Components/ModalCardBank/ModalCardBankMain"
import ModalTasks from "./../Components/Tasks/ModalTasks"
import FloatMenu from "./../Components/FloatMenu/FloatMenu"


import Box from '@material-ui/core/Box';
import TableBankingCard from "./../Components/TableBankingCard/TableBankingCard"
import TableBookingProvider from "./../Components/TableBookingProvider/TableBookingProvider"
import ToolChat from "./../Components/Chat/Chat"

import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import PaymentIcon from '@material-ui/icons/Payment';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PersonIcon from '@material-ui/icons/Person';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import EmailIcon from '@material-ui/icons/Email';
import SmsIcon from '@material-ui/icons/Sms';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import HomeIcon from '@material-ui/icons/Home';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AndroidIcon from '@material-ui/icons/Adb';
import ExploreIcon from '@material-ui/icons/Explore';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import TableBill from "./../Components/TableBill/TableBill"

import TablePaymentInformation from "./../Components/TablePaymentInformation/TablePaymentInformation"
import { _fetch, parseQuery, stringifyQuery } from '../helpers';
import './onglet.scss';
import Flexibility from '../Components/Flexibility/Flexibility';
import CancelModify from "../Components/CancelModify/CancelModify"
const indexToMode = { 0: "general", "1": "booking", "2": "notes", "3": "logs", "4": "tasks" }



const backgroundColor = {
  PROCESSING: "rgba(189, 171, 130,1)",
  "WAIT_CONFIRMED": "rgba(255, 204, 51,1)",
  "PROCESSING_AUTO": "rgba(255, 204, 51,1)"
  , "MODIFICATION": "rgba(255, 204, 51,1)", "PROCESSING_MANAGE": "rgba(255, 204, 51,1)", "CANCEL_OTHER": "rgba(255,102,102,1)",
  "CANCEL_VOLUNTARY": "rgba(255,102,102,1)", "CANCEL_COMBIGO": "rgba(255,102,102,1)", CONFIRMED: "rgba(51,204,51,1)", DONE: "rgba(51,204,51,1)"
}








class Body extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      openModalTask: false,
      openModalMail: false,
      openModalSms: false,
      openModalCardBank: false,
      openModalTicket: false,

      errorMessage: null,
      snackMessage: null,
      nbTasks: null,
      mode: "general",
      titleExtend: {

      }

    }
    this.already = {}

    this.handleChange = this.handleChange.bind(this)
    this.getHistory = this.getHistory.bind(this)
    this.listRecapBooking = []

  }


  componentDidMount() {

    let query = (parseQuery(window.location.search) || {})
    if (query && query.mode) {
      if (query.mode != this.state.mode) this._changeMode(query.mode)
    }
    else {
      this._changeMode("general")
    }

  }


  _onChangeBookingId = () => {
    /*
    let listRecapBooking = []
    for (let mode of ["general", "booking", "notes", "tasks", "logs"]) {
      listRecapBooking.push({ mode: mode, listRecap: this._getRecap(mode) })
    }
    this.listRecapBooking = listRecapBooking
    */
    this.listRecap = this._getRecap(this.state.mode)

    this.setState({
      disabledModification: false,
      dataTemp: null,
      titleExtend: {},
      versionUpdatedRecap: Math.random()
    })
  }


  componentDidUpdate(prevProps) {
    // console.log(this.props.booking, this.props.bookingId)

    // if ( prevProps.bookingId != this.props.bookingId){
    //   this._onChangeBookingId()
    // } else
    if (this.props.booking && (prevProps.booking ? prevProps.booking.bookingId : "") != this.props.booking.bookingId) {
      this._onChangeBookingId()
    }
    else if (this.props.booking && (prevProps.booking ? prevProps.booking._hashVersion : "") != this.props.booking._hashVersion) {
      this._onChangeBookingId()
    }
  }


  handleChange(actions) {

    if (this.state.disabledModification) {
      this.setState({ errorMessage: "Vous n'avez pas la version latest, donc impossible de modifier" })

      return
    }
    if (this.props.dateLastModification && Math.abs(Date.now() - this.props.dateLastModification) < 2000) {
      this.setState({ errorMessage: "Attention, il y a une modification récente, nous vous invitons à prendre le temps de voir si cette modification pourrait avoir un impact avec vos modifications" })

      return false
    }

    if (!actions.user) actions.user = this.props.nameUser

    return new Promise(function (resolve) {
      _fetch(process.env.REACT_APP_URL + '/crm/transactions/update', {
        "timeout": 8000,
        "nbRetry": 1,
        "method": "post",
        "headers": {},
        "body": {
          bookingId: this.props.booking.bookingId,
          actions: actions
        }
      })
        .then(function (response) {
          if (response.status == 202) return resolve(true)
          if (response.status == 224) {
            this.setState({ errorMessage: "Erreur lors de la sauvegarde de la modification Mutex" })

            return resolve(false)
          }
          if (response.status == 225) {
            this.setState({ errorMessage: "Conflit, quelqu'un a modifié la réservation en parrallèle, donc erreur lors de la sauvegarde de la modification" })

            return resolve(false)
          }

          if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
          let data = response.json()

          this.props.setBooking(data)
          resolve(true)
        }.bind(this))
        .catch(function (e) {
          resolve(false)
          this.setState({ errorMessage: "Erreur lors de la sauvegarde de la modification" })
          // alert("mettre un snackbar pour informer de l'erreur")
          console.error("Error conflit save doc", e);
        }.bind(this));

    }.bind(this))


  };



  getHistory(prop, version) {

    return new Promise(function (resolve) {
      _fetch(process.env.REACT_APP_URL + '/crm/transactions/version_document', {
        "timeout": 8000,
        "nbRetry": 2,
        "method": "post",
        "headers": {

        },
        "body": {
          bookingId: this.props.booking.bookingId,
          typeData: prop,
          version: version
        }
      })
        .then(function (response) {
          if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
          let data = response.json()
          return resolve(data)
        }.bind(this))
        .catch(function (e) {
          resolve(false)
          console.error("Error onglet version document", e);
          this.setState({ errorMessage: "Erreur to get version document" })

        }.bind(this));

    }.bind(this))


  };

  updateUrl = (newData) => {
    if (newData.search) {
      let query = (parseQuery(this.props.history.location.search) || {})
      newData.search = {
        ...query,
        ...newData.search
      }
      newData.search = stringifyQuery(newData.search)
    }
    this.props.history.push({ ...this.props.history.location, ...newData })

  }


  _changeMode = (mode) => {
    //
    this.updateUrl({ search: { mode: mode } })

    if (mode == "general") {
      let objModel = this.already[mode] ? {} : {
        open_recap: true,
        open_notes: false,
        open_actions: false,
        open_trip: false,
        open_booking: true,

        open_account: true,

        open_cardvirtual: false,
        open_transactions_bank: false,
        open_passengers: true,
        open_contact: true,
        open_options: true,
      }
      let objState = {}
      for (let key in objModel) objState[key + "_" + mode] = objModel[key]
      this.setState({
        ...objState,
        mode: "reset"
      }, () => {
        this.setState({ mode: mode })
      })
    }
    else if (mode == "booking") {
      let objModel = this.already[mode] ? {} : {
        open_recap: true,
        open_notes: false,
        open_actions: false,
        open_trip: false,
        open_booking: true,
        open_booking_auto: true,
        open_account: true,
        open_cardvirtual: false,
        open_transactions_bank: false,
        open_passengers: true,
        open_contact: true,
        open_options: true,
      }
      let objState = {}
      for (let key in objModel) objState[key + "_" + mode] = objModel[key]
      this.setState({
        ...objState,
        mode: "reset"
      }, () => {
        this.setState({ mode: mode })
      })
    }
    else if (mode == "notes") {
      let objModel = this.already[mode] ? {} : {
        open_notes: true,
      }
      let objState = {}
      for (let key in objModel) objState[key + "_" + mode] = objModel[key]
      this.setState({
        ...objState,
        mode: "reset"
      }, () => {
        this.setState({ mode: mode })
      })
    }
    else if (mode == "logs") {
      let objModel = this.already[mode] ? {} : {
        open_logs: true,
      }
      let objState = {}
      for (let key in objModel) objState[key + "_" + mode] = objModel[key]
      this.setState({
        ...objState,
        mode: "reset"
      }, () => {
        this.setState({ mode: mode })
      })
    }
    else if (mode == "tasks") {
      let objModel = this.already[mode] ? {} : {
        open_tasks: true,
      }
      let objState = {}
      for (let key in objModel) objState[key + "_" + mode] = objModel[key]
      this.setState({
        ...objState,
        mode: "reset"
      }, () => {
        this.setState({ mode: mode })
      })
    }
    else if (mode == "mail") {
      let objModel = this.already[mode] ? {} : {
        open_mail_tracking: true,
        open_sms_tracking: true
      }
      let objState = {}
      for (let key in objModel) objState[key + "_" + mode] = objModel[key]
      this.setState({
        ...objState,
        mode: "reset"
      }, () => {
        this.setState({ mode: mode })
      })
    }
    else if (mode == "realtime") {
      let objModel = this.already[mode] ? {} : {
        open_realtime: true,
      }
      let objState = {}
      for (let key in objModel) objState[key + "_" + mode] = objModel[key]
      this.setState({
        ...objState,
        mode: "reset"
      }, () => {
        this.setState({ mode: mode })
      })
    }
    else if (mode == "accountCustomer") {
      let objModel = this.already[mode] ? {} : {
        open_chat: true,
      }
      let objState = {}
      for (let key in objModel) objState[key + "_" + mode] = objModel[key]
      this.setState({
        ...objState,
        mode: "reset"
      }, () => {
        this.setState({ mode: mode })
      })
    }

    this.already[mode] = true

  }


  _phoneCombigo() {
    return "+33 636122495"
  }
  _mailCombigo() {
    return "romain." + this.props.booking.informations.referenceBooking.toLowerCase() + (this.props.booking.summary.data.dateCreated > 1655720640 ? "@webtripgo.me" : "@syamail.fr")
  }

  _getRecap = (mode = this.state.mode) => {
    if (!this.props.booking || !this.props.booking.bookingId) return []

    if (mode == "general" || mode == "booking") {

      return [
        {
          tag: "recap",
          // picto:<HomeIcon />,
          title: this.props.booking.informations.referenceBooking + ", " + this.props.booking.informations.lastName + " : " + this.props.booking.informations.title.join(", ")
          , render: <VisionTransaction
            mode={this.state.mode}
            openTabMail={() => {
              this.setState({ "open_desk_mail": true })
            }}
            mailCombigo={this._mailCombigo()}
            data={(this.state.dataTemp || this.props.booking).summary}
            disabledModification={this.state.disabledModification}
            bookingId={this.props.booking.bookingId}

            // version={this.props.booking.bookingId+"-"+this.props.booking._hashVersion}

            onUpdateMode={this._changeMode}
            onChange={async (data) => {
              data.updateProp = "summary"
              return this.handleChange(data)
            }}

            historyData={(data, error, flagReset) => {
              if (flagReset) {
                return this.setState({ dataTemp: null, disabledModification: false })
              }
              if (!data) {
                return this.setState({ disabledModification: true })
              }
              this.setState({ dataTemp: data, disabledModification: true })
            }}

          />
        },
        // {
        //   tag: "recap_customer",
        //   title: "Infos Suivi Customer"
        //   , render: <VisionTransactionCustomer
        //     mode={this.state.mode}
        //     data={this.props.booking.summary}
        //     onChange={async (data) => {
        //       data.updateProp = "summary"
        //       return this.handleChange(data)
        //     }} />
        // },
        {
          tag: "notes",
          picto: <MenuBookIcon />,
          title: "Notes (" + this.props.booking.notes.data.length + ")",
          render: <ListNotes
            notes={this.props.booking.notes}
            id={this.props.booking.bookingId}
            version={this.props.booking.bookingId + "-" + this.props.booking._hashVersion}
            onChange={async (data) => {
              data.updateProp = "notes"
              return this.handleChange(data)
            }} />
        },

        {
          tag: "desk_mail",
          picto: <EmailIcon />,
          title: "DeskMail " + (this.state.titleExtend.nb_desk_mail ? " (" + this.state.titleExtend.nb_desk_mail + ")" : ""),
          render: <TableDeskMail
            // bookingId={this.props.booking.bookingId}
            // hiddenToolbar={true}
            updateParent={(nbDeskMail) => {
              this.setState({
                titleExtend: {
                  ...this.state.titleExtend,
                  nb_desk_mail: nbDeskMail
                }
              })
            }}
            filterForce={{
              referenceBooking: this.props.booking.informations.referenceBooking,
              email: this.props.booking.contacts.data.map((l) => { return l.mail }).join(";"),

              bookingId: this.props.booking.bookingId,
            }}
            version={this.props.booking.bookingId + "-" + this.props.booking._hashVersion}
          />
        },
        // {
        //   tag: "tasks",
        //   picto: <AssignmentIcon />,
        //   title: "Tasks" + (this.state.nbTasks ? " (" + this.state.nbTasks + ")" : ""), render: <TableTasks
        //     activeToolbarFilter={true}
        //     version={this.props.booking.bookingId + "-" + this.props.booking._hashVersion}
        //     filter={{ relationId: "bookingId:" + this.props.booking.bookingId }}
        //     timeout={10000}
        //     onChange={(data) => {
        //       this.setState({ nbTasks: data.length })
        //     }}
        //     updateParent={(nbTasks) => {
        //       this.setState({
        //         nbTasks: nbTasks
        //       })
        //     }}

        //   />
        // },
        {
          tag: "actions",
          title: "Actions", render: <ActionTransaction

            version={this.props.booking.bookingId + "-" + this.props.booking._hashVersion}
            bookingId={this.props.booking.bookingId}

          />
        },
        {
          tag: "trip",
          picto: <FlightTakeoffIcon />,
          title: "Trajet", render: <TableSegments
            data={(this.state.dataTemp || this.props.booking).segments}
            bookingId={this.props.booking.bookingId}
            version={this.props.booking.bookingId + "-" + this.props.booking._hashVersion}
            disabledModification={this.state.disabledModification}
            onChange={async (data) => {
              data.updateProp = "segments"
              return this.handleChange(data)
            }}
            searchDataHistory={(obj) => {
              return this.getHistory("segments", obj.version)
            }}

          />
        },
        {
          tag: "booking",
          picto: <ExploreIcon />,
          title: "Booking Information (" + (this.state.dataTemp || this.props.booking).booking.data.length + ")", render: <TableBooking
            data={(this.state.dataTemp || this.props.booking).booking}
            disabledModification={this.state.disabledModification}
            //  version={this.props.booking.bookingId+"-"+this.props.booking._hashVersion}
            bookingId={this.props.booking.bookingId}

            onChange={async (data) => {
              data.updateProp = "booking"
              return this.handleChange(data)
            }}
            searchDataHistory={(obj) => {
              return this.getHistory("booking", obj.version)
            }}

          />
        },


        {
          tag: "account",
          picto: <EuroSymbolIcon />,
          title: "Comptabilité",
          render: <TableAccount2
            data={(this.state.dataTemp || this.props.booking).account2}
            disabledModification={this.state.disabledModification}
            // version={this.props.booking.bookingId+"-"+this.props.booking._hashVersion}
            bookingId={this.props.booking.bookingId}

            onChange={async (data) => {
              data.updateProp = "account2"
              return this.handleChange(data)
            }}
            searchDataHistory={(obj) => {
              //  return this.getHistory("account", obj.version)
            }}

          />
        },
        {
          tag: "accounting_flow",
          picto: <ShuffleIcon />,
          title: "Accounting Flow" + (this.state.titleExtend.accounting_flow ? " (" + this.state.titleExtend.accounting_flow + ")" : ""),
          render: <TableFlowAccount
            // bookingId={this.props.booking.bookingId}
            hiddenToolbar={true}
            noDisplayBookingId={true}
            updateParent={(nbAccountingFlow) => {
              this.setState({
                titleExtend: {
                  ...this.state.titleExtend,
                  accounting_flow: nbAccountingFlow
                }
              })
            }}
            filterForce={{
              bookingId: this.props.booking.bookingId,
            }}
            version={this.props.booking.bookingId + "-" + this.props.booking._hashVersion}
          />
        },
        {
          tag: "cardvirtual",
          picto: <PaymentIcon />,
          title: "Transactions Card Virtual" + (this.state.titleExtend.card_vitual ? " (" + this.state.titleExtend.card_vitual + ")" : ""),
          render: <TableBankingCard
            version={this.props.booking.bookingId + "-" + this.props.booking._hashVersion}
            updateParent={(card_vitual) => {
              this.setState({
                titleExtend: {
                  ...this.state.titleExtend,
                  card_vitual: card_vitual
                }
              })
            }}
            bookingId={this.props.booking.bookingId}
          />
        },
        {
          tag: "contact",
          picto: <LocalPhoneIcon />,
          title: "Contact Customer", render: <TableContact
            data={(this.state.dataTemp || this.props.booking).contacts}
            disabledModification={this.state.disabledModification}
            bookingId={this.props.booking.bookingId}
            mailCombigo={this._mailCombigo()}
            phoneCombigo={this._phoneCombigo()}
            // version={this.props.booking.bookingId+"-"+this.props.booking._hashVersion}
            onChange={async (data) => {
              data.updateProp = "contacts"
              return this.handleChange(data)
            }}
            searchDataHistory={(obj) => {
              return this.getHistory("contacts", obj.version)
            }}
          />
        },


        {
          tag: "passengers",
          picto: <PersonIcon />,
          title: "Passengers (" + ((this.state.dataTemp || this.props.booking).passengers.data.length) + ")", render: <TablePassenger
            data={(this.state.dataTemp || this.props.booking).passengers}
            disabledModification={this.state.disabledModification}
            // version={this.props.booking.bookingId+"-"+this.props.booking._hashVersion}
            bookingId={this.props.booking.bookingId}

            onChange={async (data) => {
              data.updateProp = "passengers"
              return this.handleChange(data)
            }}
            searchDataHistory={(obj) => {
              return this.getHistory("passengers", obj.version)
            }}

          />
        },

        {
          tag: "options",
          picto: <LocalMallIcon />,
          title: "Options",
          render: <TableOptions
            data={(this.state.dataTemp || this.props.booking).options}
            disabledModification={this.state.disabledModification}
            bookingId={this.props.booking.bookingId}

            // version={this.props.booking.bookingId+"-"+this.props.booking._hashVersion}
            onChange={async (data) => {
              data.updateProp = "options"
              return this.handleChange(data)
            }}
            searchDataHistory={(obj) => {
              return this.getHistory("options", obj.version)
            }}

          />
        },
        {
          tag: "cancel",
          picto: <VerifiedUserIcon />,
          title: "Annulation/Modification",
          render: <CancelModify
            data={this.props.booking.bundle || { data: {} }}
            bookingId={this.props.booking.bookingId}
            version={this.props.booking.bookingId + "-" + this.props.booking._hashVersion}

          />
        },
        // {
        //   tag: "flexibility",
        //   picto: <VerifiedUserIcon />,
        //   title: "Conditions Tarifaires du Billet",
        //   render: <Flexibility
        //     data={this.props.booking.bundle || { data: {} }}
        //     bookingId={this.props.booking.bookingId}
        //     version={this.props.booking.bookingId + "-" + this.props.booking._hashVersion}

        //   />
        // },
        {
          tag: "booking_auto",
          title: "Billet Booking Auto" + (this.state.titleExtend.booking_auto ? " (" + this.state.titleExtend.booking_auto + ")" : ""),
          render: <TableBookingProvider
            filter={{ bookingId: this.props.booking.bookingId }}
            bookingId={this.props.booking.bookingId}
            version={this.props.booking.bookingId}
            updateParent={(booking_auto) => {
              this.setState({
                titleExtend: {
                  ...this.state.titleExtend,
                  booking_auto: booking_auto
                }
              })
            }}
          />
        },


        {
          tag: "booking_help",
          picto: <AndroidIcon />,
          title: "Au secours j'ai booking....", render: <BookingHelp
          statusBooking={this.props.booking.summary.data.statusBooking}
            bookingId={this.props.booking.bookingId}
          

          ></BookingHelp>
        },



        {
          tag: "transactions_bank",
          picto: <AccountBalanceIcon />,
          title: "Transactions bancaires" + (this.state.titleExtend.nbPaymentBank ? " (" + this.state.titleExtend.nbPaymentBank + ")" : ""),
          render: <TablePaymentInformation
            updateParent={(nbPaymentBank) => {
              this.setState({
                titleExtend: {
                  ...this.state.titleExtend,
                  nbPaymentBank: nbPaymentBank
                }
              })
            }}
            bookingId={this.props.booking.bookingId}
            version={this.props.booking.bookingId + "-" + this.props.booking._hashVersion}
            account2={this.props.booking.account2}
          />
        },
        {
          tag: "bill",
          picto: <RequestQuoteIcon />,
          title: "Bill" + (this.state.nbBillPay ? " (" + this.state.nbBillPay + ")" : ""), render: <TableBill
            mode={"booking"}
            referenceBooking={this.props.booking.informations.referenceBooking}
            bookingId={this.props.booking.bookingId}
            locale={this.props.booking.contacts.data[0].locale}
            account2={this.props.booking.account2}
            mail={this.props.booking.contacts.data[0].mail}
            relationId={"bookingId:" + this.props.booking.bookingId}
            filter={{ relationId: "bookingId:" + this.props.booking.bookingId }}
            version={this.props.booking.bookingId + "-" + this.props.booking._hashVersion}
            hiddenToolbar={true}
            activeToolbarFilter={true}
            timeout={20000}
            onData={(data) => {
              this.setState({ nbBillPay: data.length })
            }}

          />
        },
        {
          tag: "customer_access",
          picto: <AccountBoxIcon />,
          title: "Page Espace client", render: <div className={"parent-iframe"}><iframe
            id={"iframe-combigo-booking"}
            key={"iframe-combigo-booking"}
            style={{ position: "relative !important", "left": "0px !important" }}
            width="100%"
            className={"iframe-combigo-page-booking"}
            height="650px"
            src={"https://" + (window.location.origin.indexOf("beta") == -1 && window.location.origin.indexOf("localhost") == -1 ? "www" : "beta") + ".combigo.com/detail_booking?action=secret_booking&bookingId=" + this.props.booking.bookingId + "&bookingRef=" + this.props.booking.informations.referenceBooking
              + "&debug=bookingCombigo&locale=fr&currency=EUR&crm=1&root=" + JSON.stringify({
                admin: 1,
                user: this.props.nameUser,
                crm: 1,
                "mode": "bookingdetail"
              }) + "&backofficecombigo=1"}
          ></iframe></div>
        },
        {
          tag: "booking_page",
          picto: <ConfirmationNumberIcon />,
          title: "Page Booking Combigo", render: <div className={"parent-iframe"}><iframe
            id={"iframe-combigo-booking"}
            key={"iframe-combigo-booking"}
            style={{ position: "relative !important", "left": "0px !important" }}
            width="100%"
            className={"iframe-combigo-page-booking"}
            height="950px"
            src={"https://" + (window.location.origin.indexOf("beta") == -1 && window.location.origin.indexOf("localhost") == -1 ? "www" : "beta") + ".combigo.com/deeplink?bookingId=" + this.props.booking.bookingId +
              "&debug=bookingCombigo&locale=fr&currency=EUR&crm=1&root=" + JSON.stringify({ admin: 1, user: this.props.nameUser, crm: 1, "mode": "deeplink" }) + "&backofficecombigo=1"
            }
          ></iframe></div>
        }


      ]
    }

    else if (mode == "notes") {

      return [

        {
          tag: "notes",
          picto: <MenuBookIcon />,
          title: "Notes", render: <ListNotes notes={this.props.booking.notes}
            id={this.props.booking.bookingId}
            version={this.props.booking.bookingId + "-" + this.props.booking._hashVersion}
            bookingId={this.props.booking.bookingId}

            onChange={async (data) => {
              data.updateProp = "notes"
              return this.handleChange(data)
            }} />
        }


      ]
    }
    else if (mode == "logs") {

      return [

        {
          tag: "logs",

          title: "Logs", render: <TableLogs
            activeToolbarFilter={true}
            version={this.props.booking.bookingId + "-" + this.props.booking._hashVersion}
            filter={{ relationId: "bookingId:" + this.props.booking.bookingId }}
            bookingId={this.props.booking.bookingId}


          />
        }


      ]
    }
    else if (false && mode == "tasks") {

      return [

        {
          tag: "tasks",
          picto: <AssignmentIcon />,
          title: "Tasks", render: <TableTasks
            activeToolbarFilter={true}
            filter={{ relationId: "bookingId:" + this.props.booking.bookingId }}
            version={this.props.booking.bookingId + "-" + this.props.booking._hashVersion}
            bookingId={this.props.booking.bookingId}

            timeout={10000}
            onChange={(data) => {
              this.setState({ nbTasks: data.length })
            }}

          />
        }


      ]
    }
    else if (mode == "mail") {

      return [

        {
          tag: "mail_tracking",
          picto: <EmailIcon />,

          title: "Tracking Mail", render: <TableMail
            activeToolbarFilter={true}
            filter={{ relationId: "bookingId:" + this.props.booking.bookingId, mail: this.props.booking.contacts.data ? this.props.booking.contacts.data[0].mail : undefined, customId: "" }}
            timeout={10000}
            bookingId={this.props.booking.bookingId}
            version={this.props.booking.bookingId + "-" + this.props.booking._hashVersion}

          />
        },
        {
          tag: "sms_tracking",
          picto: <SmsIcon />,
          title: "Tracking Sms", render: <TableSms
            activeToolbarFilter={true}
            filter={{ relationId: "bookingId:" + this.props.booking.bookingId, mail: this.props.booking.contacts.data ? this.props.booking.contacts.data[0].mail : undefined, customId: "" }}
            timeout={10000}
            bookingId={this.props.booking.bookingId}
            version={this.props.booking.bookingId + "-" + this.props.booking._hashVersion}

          />
        },
        {
          tag: "conf_mail",
          picto: <EmailIcon />,
          title: "Configuration Forward Mail",
          render: <TableForwardMail
            data={(this.state.dataTemp || this.props.booking).mail_forward}
            disabledModification={this.state.disabledModification}
            // version={this.props.booking.bookingId+"-"+this.props.booking._hashVersion}
            bookingId={this.props.booking.bookingId}

            onChange={async (data) => {
              data.updateProp = "mail_forward"
              return this.handleChange(data)
            }}
            searchDataHistory={(obj) => {
              return this.getHistory("mail_forward", obj.version)
            }}

          />
        },

        {
          tag: "desk_mail",
          picto: <EmailIcon />,
          title: "DeskMail " + (this.state.titleExtend.nb_desk_mail ? " (" + this.state.titleExtend.nb_desk_mail + ")" : ""),
          render: <TableDeskMail
            // bookingId={this.props.booking.bookingId}
            // hiddenToolbar={true}
            updateParent={(nbDeskMail) => {
              this.setState({
                titleExtend: {
                  ...this.state.titleExtend,
                  nb_desk_mail: nbDeskMail
                }
              })
            }}
            filterForce={{
              referenceBooking: this.props.booking.informations.referenceBooking,

              bookingId: this.props.booking.bookingId,
            }}
            version={this.props.booking.bookingId + "-" + this.props.booking._hashVersion}
          />
        },





      ]
    }
    else if (mode == "realtime") {

      return [

        {
          tag: "realtime",
          picto: <QueryBuilderIcon />,
          title: "Realtime", render: <TableRealTime
            activeToolbarFilter={true}
            filter={{ bookingId: this.props.booking.bookingId }}
            bookingId={this.props.booking.bookingId}
            timeout={4 * 60000}
            version={this.props.booking.bookingId + "-" + this.props.booking._hashVersion}

          />
        }
        ,
        {
          tag: "trip",
          picto: <FlightTakeoffIcon />,
          title: "Trajet (ACCEPTED BY Customer)", render: <TableSegments
            data={(this.state.dataTemp || this.props.booking).segments}
            disabledModification={this.state.disabledModification}
            // version={this.props.booking.bookingId + "-" + this.props.booking._hashVersion}
            bookingId={this.props.booking.bookingId}
            // data={this.props.booking.segments}
            onChange={async (data) => {
              data.updateProp = "segments"
              return this.handleChange(data)
            }}
            searchDataHistory={(obj) => {
              return this.getHistory("segments", obj.version)
            }}

          />
        }


      ]
    }
    else if (mode == "accountCustomer") {
      return [

        {
          tag: "chat",
          picto: <VoiceChatIcon />,
          title: "Chat",

          render: <ToolChat
            customerAccount={this.props.booking.customerAccount}
            onChange={async (data) => {
              data.updateProp = "customerAccount"
              return this.handleChange(data)
            }}
            bookingId={this.props.booking.bookingId}
          />

        }



      ]
    }

  }


  _isChargeBack() {

    if (this.props.booking.account2) {
      for (let l of this.props.booking.account2.data) {
        if (l.type === "LITIGE") {
          return true
        }
      }
    }
  }




  render() {
    if (this.state.mode == "reset") return null
    if (!this.props.booking || !this.props.booking.bookingId) return null

    //200px
    // console.log(this.listRecapBooking)

    const colorSelected = backgroundColor[this.props.booking.summary.data.statusBooking]
    const listRecap = this._getRecap()
    // console.log("this.props.booking.bookingId="+this.props.booking.bookingId)
    return (<div>

      <Helmet>
        <title>{"Combigo " + this.props.booking.informations.referenceBooking} </title>
      </Helmet>

      {colorSelected ? <div className={"bar-status-transaction"} style={{ backgroundColor: colorSelected }} ></div> : null}

      <MenuLeft
        booking={this.props.booking}
        onClick={(mode) => {
          this._changeMode(mode)
        }}
        nbTaskOpen={this.state.nbTasks}
        nbNotes={this.props.booking.notes.data.length}
      >
        {

          <div className={"container-main "} >
            {this._isChargeBack() ? <div className={"panel-chargeback"} >{"Attention : LITIGE BANCAIRE"}</div> : null}
            {this.props.booking && this.props.booking.bundle.data.id !== "LIGHT" && this.props.booking.bundle.data.id !== "BASIC" ?
              <div className={"panel-plus"} >{"Client Combigo " + this.props.booking.bundle.data.id}</div> : null}

            {listRecap.map(function (obj, index) {

              let nameTag = "open_" + (obj.tag || index) + "_" + this.state.mode

              // console.log(nameTag,this.state,this.state[nameTag])
              return <Accordion expanded={this.state[nameTag]} key={index} style={{ width: "100%" }}   >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  onClick={() => {
                    let obj = {}
                    obj[nameTag] = !this.state[nameTag]
                    this.setState(obj)
                  }}
                >
                  {obj.picto ? obj.picto : null}
                  <Typography className={""} style={{ marginLeft: obj.picto ? "10px" : undefined }}>{obj.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {obj.render}
                </AccordionDetails>
              </Accordion>
            }.bind(this))}
          </div>

        }
        {/*         
        {
          this.listRecapBooking.map((objMode, index2) => {

            return <div className={"container-main " + (this.state.mode != objMode.mode ? "container-hidden" : "")} >
              {objMode.listRecap.map(function (obj, index) {

                let nameTag = "open_" + (obj.tag || index) + "_" + this.state.mode

                // console.log(nameTag,this.state,this.state[nameTag])
                return <Accordion expanded={this.state[nameTag]} key={index} style={{ width: "100%" }}   >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    onClick={() => {
                      let obj = {}
                      obj[nameTag] = !this.state[nameTag]
                      this.setState(obj)
                    }}
                  >
                    <Typography className={""}>{obj.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {obj.render}
                  </AccordionDetails>
                </Accordion>
              }.bind(this))}
            </div>
          })
        } */}

      </MenuLeft>


      <ModalMail
        data={this.props.booking}
        open={this.state.openModalMail}
        id={this.props.booking.bookingId}
        version={this.props.booking.bookingId + "-" + this.props.booking._hashVersion}

        onClose={() => {
          this.setState({ openModalMail: false })
        }}
      />

      <ModalSms
        data={this.props.booking}
        open={this.state.openModalSms}
        id={this.props.booking.bookingId}
        version={this.props.booking.bookingId + "-" + this.props.booking._hashVersion}

        onClose={() => {
          this.setState({ openModalSms: false })
        }}
      />

      <ModalTicket
        version={this.props.booking.bookingId + "-" + this.props.booking._hashVersion}
        open={this.state.openModalTicket}
        bookingId={this.props.booking.bookingId}
        bookingReference={this.props.booking.informations.referenceBooking}
        onClose={() => {
          this.setState({ openModalTicket: false })
        }}
      />

      <ModalCardBank
        version={this.props.booking.bookingId + "-" + this.props.booking._hashVersion}
        data={this.props.booking}
        listPropertiesAdd={{
          mail: this._mailCombigo(),

          phoneCustomer: this.props.booking.contacts.data[0].phoneCallsign + " " + this.props.booking.contacts.data[0].phone,
          phoneCombigo: this._phoneCombigo(),
        }}
        open={this.state.openModalCardBank}
        bookingId={this.props.booking.bookingId}
        bookingReference={this.props.booking.informations.referenceBooking}
        amountMinimum={this.props.booking.summary.data.amountEUR}
        cardHolderName={this.props.booking.passengers ? this.props.booking.passengers.data[0].firstName.split(" ")[0] + " " + this.props.booking.passengers.data[0].lastName : ""}
        onClose={() => {
          this.setState({ openModalCardBank: false })
        }}
      />
      <ModalTasks
        version={this.props.booking.bookingId + "-" + this.props.booking._hashVersion}
        booking={this.props.booking}
        relationId={"bookingId:" + this.props.booking.bookingId}

        open={this.state.openModalTask}
        onClose={() => {
          this.setState({ openModalTask: false })
        }}
        onUpdate={(valid) => {
          if (valid) this.setState({ openModalTask: false })
        }}
        onMinimize={() => {
          this.setState({ openModalTask: false })
        }}
      />

      <FloatMenu
        mailOnClick={() => {
          this.setState({ openModalMail: true })
        }}
        smsOnClick={() => {
          this.setState({ openModalSms: true })
        }}
        cardBankOnClick={() => {
          this.setState({ openModalCardBank: true })
        }}
        taskOnClick={() => {
          this.setState({ openModalTask: true })
        }}
        bookingCombigoClick={() => {
          this.setState({ openModalTicket: true })

        }}

      ></FloatMenu>

      <CombigoSnackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={this.state.errorMessage !== null || this.state.snackMessage !== null}
        onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
        variant={this.state.errorMessage != null ? 'error' : "success"}
        autoHideDuration={6000}
        message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
      />
    </div>)




  }
}




const mapStateToProps = (state) => {
  return { booking: state.booking, locale: state.user.locale, nameUser: state.user.name };
};


export default connect(mapStateToProps, {
  setBooking
})(withRouter(Body));






function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
          {/* <Typography>{children}</Typography> */}
        </Box>
      )}
    </div>
  );
}