
import * as React from 'react';
import { connect } from 'react-redux';
import { cloneObject } from '../../helpers';
import './TableBookingProvider.scss';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Progress from './../Components/Progress';
import { Buffer } from "buffer"

import { parseQuery, _fetch, fileDownload } from "./../../helpers"
import moment from 'moment';


const columns = [
    { "name": "id" },
    { "name": "Status Job" },
    { "name": "Status Booking" },
    { "name": "date" },
    { "name": "type" }, { "name": "source" }, { "name": "user" },
    { "name": "amountEUR" }, { "name": "message" },{"name":"action"}
]
const columnsDetails = [{ "name": "Provider" }, { "name": "amountEUR" }, { "name": "status" }, { "name": "Ref. Customer" }, { "name": "Id provider" },
{ "name": "dashboard" }, { "name": "message" }, { "name": "logs" }, { "name": "passengers" }, { "name": "segments" }, { "name": "debug" },]



function Row(props) {
    const { row, open } = props;


    let otherStatus = {}
    if (row.batch) {
        for (let detail of row.batch) {
            if (!detail.response) continue
            if (detail.response.status) otherStatus[detail.response.status] = 1
            let statusTemp = (detail.response.status || (detail.response.data ? detail.response.data.status : "") || detail.response.success || "")
            if (statusTemp) otherStatus[statusTemp] = 1
        }
    }
    // console.log("row ", row)
    // console.log(row,open)
    let dateString
    try {
        dateString = row.date ? new Date(row.date).toISOString().substr(0, 19) : ""
    } catch (e) {
        console.error("Erreur date row ", row)
    }


    let statusFinalFixed = undefined
    if (row.batch) {
        for (let detail of row.batch) {
            if (detail && detail.response) {
                if (!detail.response.data) detail.response.data = {}
                const dashboardLink = detail.response.dashboardLink || detail.response.data.dashboardLink
                const refId = detail.response.id || detail.response.data.id || ""

                if (detail.response.referenceBookingCustomer || refId || dashboardLink) {
                    statusFinalFixed = "ok"
                }
            }
        }
    }

    let colorConversion = {
        "ok": "#33c"
    }

    return (
        <React.Fragment>
            <TableRow className={""}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={props.onClick}>
                        {props.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell style={{ color: colorConversion[statusFinalFixed] }} >        {row.transactionId}       </TableCell>
                <TableCell style={{ color: colorConversion[statusFinalFixed] }}>{row.status == "started" ? <><div>{row.status + " / " + row.success}</div><Progress /></> : row.status + " / " + row.success}</TableCell>
                <TableCell style={{ color: colorConversion[statusFinalFixed] }}>{row.status == "started" ? <></> : Object.keys(otherStatus).join(", ")}</TableCell>

                <TableCell style={{ color: colorConversion[statusFinalFixed] }}>{dateString}</TableCell>
                <TableCell style={{ color: colorConversion[statusFinalFixed] }}>{row.type}</TableCell>

                <TableCell style={{ color: colorConversion[statusFinalFixed] }} >{row.source}</TableCell>
                <TableCell style={{ color: colorConversion[statusFinalFixed] }}>{row.user}</TableCell>

                <TableCell >{""}</TableCell>
                <TableCell >{row.message || ""}</TableCell>
                <TableCell >{row.status == "started" ?<Button color="primary" onClick={() => {
                                                if (props.onClickStop) props.onClickStop(row)
                                            }}>{"STOP PROCESSING"}</Button>:null}</TableCell>
                {/* <TableCell >{row.price.amount + " " + row.price.currency}</TableCell> */}

                {/* 
                <TableCell >{row.data.amountLoaded + " " + row.data.currency}</TableCell>

                <TableCell >{row.data.properties.number}</TableCell>
                <TableCell >{row.data.properties.expMonth + "/" + row.data.properties.expYear}</TableCell>
                <TableCell >{row.data.properties.cvv}</TableCell>
                <TableCell >{row.request ? row.request.cardId : ""}</TableCell>
                <TableCell >{row.dateCreated ? new Date(row.dateCreated).toISOString().substr(0, 16).replace("T", " ") : ""}</TableCell>
                <TableCell ><a download={"Cardbank_" + row.data.cardReference + ".png"} href={"data:image/png;base64," + row.data.image.user}>Image</a></TableCell> */}

            </TableRow>

            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={props.open} timeout="auto" unmountOnExit>
                        <Box margin={1} style={{ background: "rgba(51, 51, 204, 0.06)", padding: "6px 6px 6px 12px" }}>
                            <Table size="small"  >
                                <TableHead>
                                    <TableRow>
                                        {columnsDetails && columnsDetails.map((detail, index) => {
                                            return <TableCell key={index}>{detail.name}</TableCell>
                                        })
                                        }

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.open && row.batch && row.batch.map((detail, index) => {

                                        let price = 0
                                        if (!detail.response) detail.response = {}
                                        if (!detail.order) detail.order = {}
                                        if (!detail.ticket) detail.ticket = {}
                                        if (!detail.passengers) detail.passengers = []

                                        if (!detail.response.data) detail.response.data = {}

                                        if (detail.order.cart) {
                                            for (let line of detail.order.cart) {
                                                if (line.priceComplete) {
                                                    price += line.priceComplete.amount
                                                }
                                                else {
                                                    price += line.price.amount
                                                }
                                            }
                                        }

                                        const logId = detail.response.logId || detail.response.screenId || detail.response.data.logId || detail.response.data.screenId

                                        const dashboardLink = detail.response.dashboardLink || detail.response.data.dashboardLink
                                        const refId = detail.response.id || detail.response.data.id || ""
                                        let stepStr = []
                                        if (detail.response.steps) {
                                            for (let step of detail.response.steps) {
                                                let errorMessage = (step.errorMessage || "")
                                                let error = (step.error || "")
                                                if (error == errorMessage) errorMessage = ""
                                                stepStr.push(step.step_name + " : " + (error || "") + " " + (errorMessage || ""))
                                            }
                                        }

                                        stepStr = stepStr.join(",")

                                        let segments = []
                                        if (detail.ticket.legs) for (let leg of detail.ticket.legs) segments.push(createLabel(leg))
                                        if (detail.ticket.legs_rT) for (let leg of detail.ticket.legs_rT) segments.push(createLabel(leg) + " (R)")
                                        // segments.push(segments[0])
                                        return <TableRow key={index}>
                                            <TableCell >{detail.order.provider || "?"}</TableCell>
                                            <TableCell >{price}</TableCell>
                                            <TableCell >{(detail.response.status || (detail.response.data ? detail.response.data.status : "") || detail.response.success || "?")}</TableCell>



                                            <TableCell >{detail.response.referenceBookingCustomer}</TableCell>
                                            <TableCell >{refId}</TableCell>

                                            <TableCell >{dashboardLink ? <a target="_blank" href={dashboardLink}>{"Lien"}</a> : ""}</TableCell>
                                            <TableCell >{(detail.response.message || detail.message || "")}</TableCell>

                                            <TableCell >{logId ? <Button onClick={() => {


                                                _fetch(process.env.REACT_APP_URL + '/crm/files/booking_logs', {
                                                    "timeout": 20000,
                                                    "nbRetry": 1,
                                                    "method": "post",
                                                    "headers": {},
                                                    "body": {
                                                        jobId: logId,
                                                        provider: detail.order.provider
                                                    }
                                                })
                                                    .then(async function (response) {
                                                        if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                                                        let data = response.json()
                                                        fileDownload(new Buffer(data.data, "base64"), data.filename)
                                                    }.bind(this))
                                                    .catch(function (e) {
                                                        console.error("Error get file", e);
                                                    }.bind(this));


                                            }}>{"link"}</Button> : null}</TableCell>

                                            <TableCell >{<div style={{ display: "flex", flexDirection: "column" }}>{detail.passengers.map((passenger) => {
                                                return <li>{passenger.firstName + " " + passenger.lastName}</li>
                                            })}</div>}</TableCell>
                                            <TableCell ><div style={{ display: "flex", flexDirection: "column" }}>{segments.map((segment) => {
                                                return <li>{segment}</li>
                                            })}</div></TableCell>
                                            <TableCell >{stepStr}</TableCell>
                                            

                                            {/* <TableCell >{new Date(detail.date).toISOString().substr(0, 16).replace("T", " ")} </TableCell> */}
                                            {/* <TableCell>{detail.type}</TableCell> */}
                                            {/* <TableCell >{detail.status}</TableCell>
                                            <TableCell >{detail.notification}</TableCell>

                                            <TableCell >{detail.description}</TableCell>
                                            <TableCell >{detail.amount}</TableCell>
                                            <TableCell >{detail.balance}</TableCell> */}
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}


function createLabel(leg) {

    try {
        return (leg.fromIATA || leg.fromCityHub || leg.fromCity || leg.fromName)
            //+ (leg.fromIATA ? " (" + leg.fromIATA + ")" : "")
            + " " + moment(new Date(leg.dTime * 1000).toISOString().substr(0, 19) + "+00:00").utc().format("DD MMM HH:mm") + " "
            + "--(" + leg.type + ")-->"
            + (leg.toIATA || leg.toCityHub || leg.toCity || leg.toName)
            //+ (leg.toIATA ? " (" +leg.toIATA + ")" : "")
            + " " + moment(new Date(leg.aTime * 1000).toISOString().substr(0, 19) + "+00:00").utc().format("DD MMM HH:mm")
    } catch (e) {
        console.error("Erreur date", leg)
    }

}




class TableBookingProvider extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            data: [],
            open: {}

        }
        this.nbTry = 0
        this._refresh = this._refresh.bind(this)
        this._request = this._request.bind(this)
        this._update = this._update.bind(this)
        this._interval = this._interval.bind(this)
        this.version = props.version
        this.timeInterval = 8000
        // setInterval(() => {
        //     this._update(true)
        // }, 30000)
        this._interval()
    }

    async _interval() {
        await this._update(true)
        this.timeout = setTimeout(() => {
            this._interval()
        }, this.timeInterval)
    }

    componentWillUnmount() {
        try {
            clearTimeout(this.timeout)
        } catch (e) { }
    }


    _request(body, route = "booking_auto", cb) {
        return new Promise(function (resolve) {
            _fetch(process.env.REACT_APP_URL + '/crm/' + route, {
                "timeout": 10000,
                "nbRetry": 1,
                "method": "post",
                "headers": {},
                "body": body
            })
                .then(function (response) {
                    if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                    let data = response.json()
                    this.nbTry = 0
                    if (cb) cb(data)
                    if (data.success) return resolve(data,true)
                    throw data
                }.bind(this))
                .catch(function (e) {
                    if (++this.nbTry > 40) {
                        console.error("Error tablebooking provider", e);
                        //alert("error table booking provider")
                        this.nbTry = 0
                    }
                    if (cb) cb(undefined,false)
                    resolve(undefined)
                }.bind(this));
        }.bind(this))
    }


    _refresh() {
        let filter = { ...this.props.filter }
        let options = { ...this.props.optionsRequest }
        this._request({
            filter: {
                "bookingId": filter.bookingId,
            },
            options: options,
            action: "list_batch"
        }, "booking_auto").then((response) => {
            //console.log("Response ", response)
            if (response && response.success) {
                if (this.props.updateParent) {
                    this.props.updateParent(response.data.length)
                }

                this.setState({
                    data: response.data || null,
                })
            }
        })
    }



    _update(forceRefresh = false) {

        let flagNeedRefresh = this.props.version != this.version
        if (forceRefresh || flagNeedRefresh) {
            if (flagNeedRefresh) {
                this.setState({
                    data: [],
                    open: {}
                })
            }

            this.version = this.props.version
            this._refresh()
        }

    }


    componentDidUpdate() {
        this._update()
    }

    componentDidMount() {
        this._refresh()

    }



    render() {

        if (!this.state.data) return null



        return (
            <Grid style={{ overflow: "auto" }} >
                <Grid >
                    <Button onClick={this._refresh} style={{ color: '#33c' }} variant="outlined">{"Refresh"}</Button>
                </Grid>
                <div>
                    <TableContainer>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    {columns.map((o, index) => {
                                        return <TableCell key={index}>{o.name}</TableCell>
                                    })}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {this.state.data && this.state.data.map((row) => {
                                    // console.log("table provider", row)
                                    if (!row) {

                                        return null
                                    }
                                    return <Row key={row._id}
                                        row={row}
                                        onClickStop={(row) => {
                                            this._request({
                                                action:"stop_processing",
                                                user:this.props.nameUser,
                                                transactionId:row.transactionId,
                                            }, undefined, (response,flagSuccess) => {
                                                console.log(response,flagSuccess)
                                            })
                                        }}
                                        onClick={() => {
                                            let open = { ...this.state.open, }

                                            open[row._id] = open[row._id] ? false : true
                                            this.setState({ open: open })
                                        }}
                                        open={this.state.open[row._id]}
                                    />
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Grid>

        )
    }
}




const mapStateToProps = (state) => {
    return { booking: state.booking, nameUser: state.user.name };
};

export default connect(mapStateToProps, {})(TableBookingProvider);