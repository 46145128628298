import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { Component, version } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { parseQuery, _fetch } from "./../../helpers"
// export default function AlertDialog(props) {
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ModelTable from "./../Table/modelTable"
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';

import "./BillModal.scss"
import moment from 'moment';

class Body extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeReplace: false,
            errorMessage:null,
            customer: {

            },

        }
    }

    componentDidUpdate(prevProps) {

        if (this.props.version != prevProps.version) {
            this.setState({ name: "", mail: "", vat: "", street: "", street2: "", company_name: "" ,products:[],activeReplace:false})
        }
        // this.setState({
        //     mail:props.mail
        // })
    }

    render() {

        return <div>
            <Dialog
                //   style={{width:"60%"}}
                open={this.props.open}
                onClose={() => {
                    //  this.setState({name:"",mail:"",vat:"",street:"",street2:""})
                    this.props.onClose()
                }}
                className={"bill-modal"}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Création d'une facture"}
                </DialogTitle>
                <DialogContent>
                    <Grid>
                        {"Tous les champs ne sont pas obligatoires"}
                    </Grid>
                    <Grid className='grid'>
                        <TextField
                            variant={"outlined"}
                            label="Name Customer "
                            value={this.state.name}
                            onChange={(ev) => {
                                this.setState({ name: ev.target.value })
                            }}
                        />
                    </Grid>
                    <Grid className='grid'>
                        <TextField
                            variant={"outlined"}
                            label="Company Name "
                            value={this.state.company_name}
                            onChange={(ev) => {
                                this.setState({ company_name: ev.target.value })
                            }}
                        />
                    </Grid>
                    <Grid className='grid'>
                        <TextField
                            variant={"outlined"}
                            label="VAT Customer"
                            value={this.state.vat}
                            onChange={(ev) => {
                                this.setState({ vat: ev.target.value })
                            }}
                        />
                    </Grid>
                    <Grid className='grid'>
                        <TextField
                            variant={"outlined"}
                            label="Street Customer"
                            value={this.state.street}
                            onChange={(ev) => {
                                this.setState({ street: ev.target.value })
                            }}
                        />
                    </Grid>
                    <Grid className='grid'>
                        <TextField
                            variant={"outlined"}
                            label="Street 2 Customer"
                            value={this.state.street2}
                            onChange={(ev) => {
                                this.setState({ street2: ev.target.value })
                            }}
                        />
                    </Grid>
                    <Grid className='grid'>
                        <TextField
                            variant={"outlined"}
                            label="Mail Customer"
                            value={this.state.mail}
                            onChange={(ev) => {
                                this.setState({ mail: ev.target.value })
                            }}
                        />
                    </Grid>
                    <Grid className='grid'>
                        <TextField
                            variant={"outlined"}
                            label="Notes"
                            value={this.state.notes}
                            multiline={true}
                            onChange={(ev) => {
                                this.setState({ notes: ev.target.value })
                            }}
                        />
                    </Grid>
                    <Grid className='grid'>
                        <FormControlLabel
                            label={"Personalize content"}
                            control={
                                <Checkbox
                                    checked={this.state.activeReplace}
                                    onChange={() => {
                                        this.setState({ activeReplace: !this.state.activeReplace,modeProducts:"edit",products:[] })
                                    }}
                                />
                            }
                        />

                    </Grid>
                    {this.state.activeReplace ? <>
                        <div style={{ maxWidth: "100%" }}>
                            <ModelTable
                                className={"table-booking"}
                                data={{ data: this.state.products, version: "" }}
                                name={"bill-modal"}
                                mode={this.state.modeProducts}

                                columns={[
                                    {
                                        name: "description",
                                        label: "description",
                                        custom: {
                                            type: "input",
                                            props: {     
                                                multiline:true                                          
                                            }
                                        },
                                        options: {
                                            sort: false
                                        }
                                    },
                                    {
                                        name: "date",
                                        label: "date",
                                        custom: {
                                            type: "dateTime",
                                            props: {                                               
                                            }
                                        },
                                        options: {
                                            sort: false
                                        }
                                    },
                                    {
                                        name: "quantity",
                                        label: "quantity",
                                        custom: {
                                            type: "input",
                                            props: {                                               
                                            }
                                        },
                                        options: {
                                            sort: false
                                        }
                                    },
                                    {
                                        name: "amountUnit",
                                        label: "amountUnit",
                                        custom: {
                                            type: "input",
                                            props: {                                               
                                            }
                                        },
                                        options: {
                                            sort: false
                                        }
                                    },
                                    {
                                        name: "currency",
                                        label: "currency",
                                        custom: {
                                            type: "list",
                                            list: [
                                               {id:"EUR","title":"EUR"},
                                               {id:"USD","title":"USD"},


                                            ],

                                            props: {                                               
                                            }
                                        },
                                        options: {
                                            sort: false
                                        }
                                    }
                                   
                                ]}
                                options={{
                                    filterType: 'checkbox',
                                    print: false,
                                    display: false,
                                    rowsPerPage: 100,
                                    selectableRows: 'none',
                                    selectableRowsHideCheckboxes: true,
                                    download: false,
                                    filter: false,
                                    search: false,
                                    viewColumns: false,
                                }}
                                modelLineAdd={{
                                    currency: "EUR",
                                    quantity: 1,
                                    date:moment()

                                }}
                                onChange={(data) => {
                                    this.setState({ products: data.data,modeProducts:"view" })
                                    return true
                                }}
                            >

                            </ModelTable>
                        </div>


                    </> : null}



                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        // this.setState({name:"",mail:"",vat:"",street:"",street2:""})
                        this.props.onClose()
                    }} color="primary">
                        {"Annuler"}
                    </Button>
                    <Button onClick={() => {

                        if (this.state.products.length===0 && this.state.activeReplace){

                            this.setState({errorMessage:"You must to save before"})
                            return 
                        }



                        this.props.onClick({
                            notes:this.state.notes,
                            products:this.state.activeReplace && this.state.products.length?this.state.products.map((o)=>{
                                return {
                                    ...o,
                                    currency:o.currency.id || "EUR"
                                }
                            }):undefined,
                            customer: {
                                mail: this.state.mail,
                                name: this.state.name,
                                street2: this.state.street2,
                                street: this.state.street,
                                vat: this.state.vat,
                                company_name: this.state.company_name
                            }
                        })
                    }} color="primary" autoFocus>
                        {"Facture"}
                    </Button>
                </DialogActions>
            </Dialog>
            <CombigoSnackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={this.state.errorMessage !== null || this.state.snackMessage !== null}
                onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
                variant={this.state.errorMessage != null ? 'error' : "success"}
                autoHideDuration={10000}
                message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
            />

        </div >

    }
}

export default withRouter(Body);
