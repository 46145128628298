import React, { Component, version } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { parseQuery ,_fetch} from "./../helpers"
import "./AllTransaction.scss"
import Table from "./../Components/TableBookingProvider/TableBookingProvider.js"

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';


class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data:null,
      version:"init",
      expand_:true
    }
  }

  componentDidUpdate(prevProps) {
    

  }



  render() {

    return <div className={"view-transaction"}>
      <div className={"title-page"}> {"Toutes les Commandes " + (this.state.dateUpdate?new Date(this.state.dateUpdate).toISOString():"")}</div>
      
        <Accordion expanded={this.state["expand_"]} key={"1"} className={"filtre-bandeau"}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              onClick={() => {
                let obj = {}
                obj["expand_" ] = !this.state["expand_" ]
                this.setState(obj)
              }}
            >
              <Typography className={""}>{"Filtre"}</Typography>
            </AccordionSummary>
            <AccordionDetails>
           
            </AccordionDetails>
          </Accordion>
     
      {<Table filter={this.state.filter} version={JSON.stringify(this.state.filter)}></Table>}

    </div>
  }
}


export default withRouter(Body);

