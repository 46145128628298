
import * as React from 'react';
import { connect } from 'react-redux';
import ModelTable from "./../Table/modelTable"
import './TableSegments.scss';
import { _fetch } from '../../helpers';

import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';
import SelectHistory from "./../Table/select_history"
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import ModalFastChange from '../ModalFastChange/ModalFastChange';
const options = {
    filterType: 'checkbox',
    print: false,
    display: false,
    rowsPerPage: 100,
    selectableRows: 'none',
    selectableRowsHideCheckboxes: true,
    download: false,
    filter: false,
    search: false,
    viewColumns: false,
};

const columns = [
    // {
    //     name: "",
    //     label: "Action",
    //     custom: {
    //         active: ["edit", "view"],
    //         // type:"action",
    //         // onlyEdit:true,
    //         type: "action_open",
    //     },
    //     options: {
    //         sort: false
    //     }
    // },
    {
        name: "order",
        label: "Ordre",
        custom: {
            type: "input",
            props: {
                className: "small-input",
                style: {
                    maxWidth: "20px !important",
                    width: "20px !important"
                }
            }
        },
        options: {
            sort: true
        }
    },
    {
        name: "sensTrip",
        label: "sensTrip",
        custom: {
            type: "list",
            list: [
                { id: "ONEWAY", "title": "ONEWAY" },
                { id: "RETURN", "title": "RETURN" },
                // {id:"RETURN_FICTIVE","title":"RETURN_FICTIVE"}
            ],

        },
        options: {
            sort: false
        }
    },
    {
        name: "insuranceAfter",
        label: "insuranceAfter",
        custom: {
            type: "list",
            list: [
                { id: "NONE", "title": "NONE" },
                { id: "COMBIGO", "title": "COMBIGO" },
                { id: "TRANSPORTER", "title": "TRANSPORTER" }
            ],


        },
        options: {
            sort: false
        }
    }


    ,
    {
        name: "from",
        label: "From",
        custom: {
            type: "autocomplete_request",
            list: [],
            format: function (obj) {
                return obj ? (obj.iata ? obj.iata + " - " : "") + obj.name + (obj.country ? " (" + obj.country + ")" : obj.country) : ""
            },
            getOptionLabel: function (obj) {
                return obj ? (obj.iata ? obj.iata + " - " : "") +
                    obj.name
                    + (obj.city ? ", " + obj.city : "")
                    + (obj.country ? " (" + obj.country + ")" : obj.country) : ""
            }
        },
        options: {
            sort: false
        }
    },
    {
        name: "to",
        label: "To",
        custom: {
            format: function (obj) {
                return obj ? (obj.iata ? obj.iata + " - " : "") + obj.name + (obj.country ? " (" + obj.country + ")" : obj.country) : ""
            },
            getOptionLabel: function (obj) {
                return obj ? (obj.iata ? obj.iata + " - " : "") +
                    obj.name
                    + (obj.city ? ", " + obj.city : "")
                    + (obj.country ? " (" + obj.country + ")" : obj.country) : ""
            },
            type: "autocomplete_request"
        },
        options: {
            sort: false
        }
    },
    {
        name: "dTime",
        label: "date Departure",
        custom: {
            type: "dateTime"
        },
        options: {
            sort: false
        }
    },
    {
        name: "aTime",
        label: "date Arrival",
        custom: {
            type: "dateTime"
        },
        options: {
            sort: false
        }
    },
    {
        name: "duration",
        label: "duration (sec)",
        custom: {
            onlyView: true,
            type: "input",
            active: ["view"]
        },
        options: {
            sort: false
        }
    },
    {
        name: "transporter",
        label: "Transporteur",
        custom: {
            type: "autocomplete_create",
            props: {
                disableClearable: true
            },
            format: function (obj) {
                return obj ? obj.title || obj.name : ""
            },
            getOptionLabel: function (obj) {
                return obj ? obj.title || obj.name : ""
            },
            list: [],
        },
        options: {
            sort: false
        }
    },
    {
        name: "trNum",
        label: "trNum",
        custom: {
            type: "input",
            props: {
                disableClearable: true
            },
        },
        options: {
            sort: false
        }
    },



    {
        name: "type",
        label: "typeTransport",
        custom: {
            type: "list",
            list: [
                { id: "PLANE", "title": "PLANE" },
                { id: "SEAPLANE", "title": "SEAPLANE" },
                { id: "HELICOPTER", "title": "HELICOPTER" },
                { id: "TRAIN", "title": "TRAIN" },
                { id: "BUS", "title": "BUS" },
                { id: "BOAT", "title": "BOAT" },
                { id: "CARPOOL", "title": "CARPOOL" },
                { id: "TAXI", "title": "TAXI" },
                { id: "VTC", "title": "VTC" }
            ]
        },
        options: {
            sort: false
        }
    },
    {
        name: "comment",
        label: "Comment.",
        custom: {
            type: "input",
            props: { multiline: true }
        },
        options: {
            sort: false
        }
    },
    {
        name: "status",
        label: "Active",
        custom: {
            type: "list",
            list: [
                { id: "ACTIVE", "title": "ACTIVE" },
                { id: "CANCEL_BEFORE_DEPARTURE", "title": "CANCEL_BEFORE_DEPARTURE" },
                { id: "CANCEL_LASTMINUTE", "title": "CANCEL_LASTMINUTE" },
                { id: "WAIT_VALIDE_USER", "title": "WAIT_VALIDE_USER" },
                { id: "WAITING_VALIDE_TR", "title": "WAITING_VALIDE_TR" },
                { id: "MODIFIED", "title": "MODIFIED" },
                { id: "DELAYED", "title": "DELAYED" },
                { id: "PROBLEM", "title": "PROBLEM" },
                { id: "AUTO_PROBLEM", "title": "AUTO_PROBLEM" },
                { id: "AUTO_DELAYED", "title": "AUTO_DELAYED" },

            ]

        },
        options: {
            sort: false
        }
    },
    {
        name: "statusRealtime",
        label: "realtime",
        custom: {
            onlyView: true,
            render: (rowData) => {
                let url = ""
                // console.log("rowData", rowData, "2")
                if (rowData[10] === "PLANE") {
                    let transporter = (rowData[8] || {}).iata
                    if (transporter) {
                        try {
                            let date = new Date(rowData[5]).toISOString().split("T")[0].split("-")

                            const codeFlightStats = { "NO": "NBT", "F8": "F8*", "V7": "VOE" }


                            url = "https://www.flightstats.com/v2/flight-tracker/" + (codeFlightStats[transporter] || transporter) + "/" + rowData[9] + "?year=" + date[0] + "&month=" + Number(date[1]) + "&date=" + Number(date[2])

                        } catch (e) {

                        }
                    }

                }


                if (url) {
                    return <a href={url} target="_blank">{rowData[13] || "?"}  </a>

                }
                return rowData[13]

                // if (!rowData[13]) return null
                // 
            }
        },
        options: {
            sort: false
        }
    },



    {
        name: "",
        label: "",
        custom: {
            onlyEdit: true,
            type: "action_delete"
        },
        options: {
            sort: false
        }
    }
];




class Account extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            listFrom: [],
            refresh: false,
            errorMessage: null,
            snackMessage: null,
            changeModeView: Date.now(),
            openModalFastChange: false
        }



        this.numVersion = this.props.booking.segments.numVersion
        this.id = this.props.booking.bookingId
        this._init = this._init.bind(this)
        this.getDataNode = this.getDataNode.bind(this)
        this._init()

    }





    _init() {

        columns[8].custom.list = []
        for (let line of this.props.base.carrier) {
            columns[8].custom.list.push({
                title: line.name + (line.code && line.code.iata ? " (" + line.code.iata + ")" : "")
                , name: line.name,
                iata: line.iata,
                id: line.idSya,
                idP: line.idP
            })
        }
        columns[8].custom.list.sort(function (a, b) {
            return a.title.localeCompare(b.title)
        })

    }

    componentDidUpdate() {
        if (this.id != this.props.booking.bookingId || this.numVersion != this.props.data.numVersion) {
            if (this.id != this.props.booking.bookingId) {
                this.setState({ changeModeView: Math.random() })
            }
            this.numVersion = this.props.data.numVersion
            this.id = this.props.booking.bookingId
            this.setState({ refresh: true })

        }

    }

    componentDidMount() {

    }


    getDataNode(query, cb) {
        _fetch(process.env.REACT_APP_URL + '/crm/data/autocomplete?' + query, {
            "timeout": 8000,
            "nbRetry": 1,
            "method": "get",
            "headers": {},
        })
            .then(async function (response) {

                if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));

                cb(true, response.json(), response)

            }.bind(this))
            .catch(function (e) {

                cb(false, undefined, e)
                // alert("mettre un snackbar pour informer de l'erreur")

            }.bind(this));

    }


    _checkIsValid = (data) => {
        for (let l of data) {
            if (!l.sensTrip || !l.from || !l.to || !l.dTime || !l.aTime || !l.status || !l.type || !l.transporter) {
                console.error("DATA CHECK VALID is not ", data)

                return false
            }
        }
        return true
    }

    render() {

        if (this.state.refresh) {
            this.setState({ refresh: false })
        }
        let data = this.state.dataTemp || this.props.data


        let dataTemp = []
        let nbMask = 0
        if (this.state.displayAll) {
            dataTemp = data.data
        }
        else {
            for (let line of data.data) {

                if (line.status === "ACTIVE" || line.status === "DELAYED") {
                    dataTemp.push(line)
                }
                else {
                    nbMask++
                }
            }
        }





        return <>

            {this.state.refresh ? null : <ModelTable
                className={"table-segments"}
                data={{ ...data, data: dataTemp }}
                changeModeView={this.state.changeModeView}
                disabledModification={this.props.disabledModification || this.state.disabledModification}
                columns={columns}
                dataOriginal={data}
                editMode={(viewMode)=>{
                    this.setState({viewMode:viewMode})
                }}
                maskFooter={this.props.maskFooter}
                footerHistory={<SelectHistory
                    style={{ minWidth: "100px" }}
                    version={this.props.booking.bookingId}
                    filter={{ relationId: "bookingId:" + this.props.booking.bookingId, event: "segments" }}
                    historyData={(data, error, flagReset) => {
                        if (flagReset) {
                            return this.setState({ dataTemp: null, disabledModification: false })
                        }
                        if (!data) {
                            //   return this.setState({ dataTemp: [] })
                            return this.setState({ disabledModification: true, dataTemp: [] })

                        }
                        if (data.rawData && typeof data.rawData === "string") data.rawData = JSON.parse(data.rawData)
                        if (data.rawData) this.setState({ dataTemp: data.rawData.segments, disabledModification: true })
                    }}
                />}

                footerOptions={
                    <div style={{ marginRight: "6px", display: "flex" }} >
                        <Button size="small"
                        disabled={this.state.viewMode==="edit"?true:false}
                        onClick={() => {
                            this.setState({ openModalFastChange: true })
                        }}  > {"Fast Change"}</Button>
                        {nbMask || this.state.displayAll ? <><Button size="medium" onClick={() => {
                            this.setState({ displayAll: !this.state.displayAll })
                        }}><Badge badgeContent={nbMask} color="primary">
                                {this.state.displayAll ? "Masquer" : "Afficher Tout"}
                            </Badge></Button><div style={{ width: "20px" }}></div> </> : null}
                    </div>


                }
                options={options}
                modelLineAdd={{ status: "ACTIVE", duration: -1 }}
                name={"segments"}
                readonly={this.props.readonly}
                onChange={(data) => {
                    return new Promise((resolve, reject) => {
                        if (data) {
                            for (let k = 0; k < data.data.length; k++) {
                                for (let prop of ["dTime", "dTimeUTC", "aTime", "aTimeUTC"]) {
                                    if (data.data[k][prop] && data.data[k][prop]._isAMomentObject) {
                                        data.data[k][prop] = data.data[k][prop].format().substr(0, 19)
                                    }
                                }
                            }
                        }
                        if (!this._checkIsValid(data.data)) {
                            return this.setState({ errorMessage: "Certaines lignes sont incomplètes" })
                        }

                        console.log("data change", data)
                        this.props.onChange(data)
                            .then(() => {
                                resolve(true)
                            })
                            .catch(() => {
                                resolve(false)
                            })
                    })

                }}
                searchDataHistory={this.props.searchDataHistory}

            >

            </ModelTable>
            }

            <ModalFastChange
                open={this.state.openModalFastChange}
                onClose={() => {
                    this.setState({ openModalFastChange: false })
                }}
                version={data.version + " " + this.state.changeModeView}
                dataInput={data}
                onChange={(dataChange) => {
                    return new Promise((resolve, reject) => {
                        this.props.onChange({

                           ...dataChange,
                        //    data:dataChange.concat(data.data),
                         
                           
                        })
                            .then(() => {
                                resolve(true)
                            })
                            .catch(() => {
                                resolve(false)
                            })
                    })

                }}
            />



            <CombigoSnackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={this.state.errorMessage !== null || this.state.snackMessage !== null}
                onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
                variant={this.state.errorMessage != null ? 'error' : "success"}
                autoHideDuration={6000}
                message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
            />
        </>
    }
}




const mapStateToProps = (state) => {
    return { booking: state.booking, base: state.data,nameUser: state.user.name };
};

export default connect(mapStateToProps, {})(Account);