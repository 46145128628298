import React, { Component, version } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { parseQuery, _fetch } from "./../helpers"
import "./AllTasks.scss"
import TableTasks from "./../Components/Tasks/TableTasks.js"
import Toolbar from "./../Components/Tasks/Toolbar"

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { CombigoSnackbar, CombigoUpdate } from './../Components/Snackbar/Snackbar';
import Collapse from '@material-ui/core/Collapse';
import FilterListIcon from '@mui/icons-material/FilterList';
import Button from '@material-ui/core/Button';






class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      version: "init",
      countVersion: 0,
      expand_: false
    }



  }

  componentDidUpdate(prevProps) {



  }



  render() {

    return <div className={"view-transaction"}>
  

      <div style={{ display: "flex" }}>
          <Button size='small' color="primary"
            endIcon={<FilterListIcon />}
            onClick={(ev) => {
              this.setState({ openFilter: this.state.openFilter ? false : true })
            }}>{"Filtre"}</Button>
          <div className={"title-page"}> {"Task " + (this.state.dateUpdate ? new Date(this.state.dateUpdate).toISOString() : "")}</div>
      </div>
      <div>
        {<Collapse in={this.state.openFilter} timeout="auto"    >
          {<Toolbar
             url={'/crm/tasks/search'}
            timeout={this.props.updateRefresh ? 60000 : 0} //12000
            resetInit={() => {
              this.setState({ version: "init" })
            }}
            mode={this.props.planning ? "calendar" : undefined}

            onUpdate={function (data, error) {

              if (data) {
                if (this.state.version != data.version) {
                  if (this.state.version === "init") {
                    this.setState({ data: data.data, version: data.version, dateUpdate: Date.now(), countVersion: 0, newDataVersion: null, openUpdatedButton: false })
                  }
                  else {
                    let newVersion = (data.version != this.state.lastVersionUpdated ? 1 : 0)
                    let countVersion = this.state.countVersion + newVersion
                    this.setState({
                      openUpdatedButton: true, countVersion: newVersion ? countVersion : this.state.countVersion,
                      lastVersionUpdated: newVersion ? data.version : this.state.lastVersionUpdated,
                      newDataVersion: newVersion ? { data: data.data, version: data.version, dateUpdate: Date.now() } : this.state.newDataVersion
                    })
                    // this.setState({ newVersion:{data: data.data, version: data.version, dateUpdate: Date.now()} })
                  }
                }
                else {
                  // this.setState({ dateUpdate: Date.now() })
                }
              }


            }.bind(this)}


          ></Toolbar>}
        </Collapse>}
      </div>



      <TableTasks
        dense={true}
        activeToolbarFilter={true}
        openLink={true}
        dataParent={this.state.data}
      />

      <CombigoUpdate
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        nb={this.state.countVersion}
        autoHideDuration={null}
        open={this.state.openUpdatedButton}
        // onClose={() => this.setState({ openUpdatedButton: false })}
        onClick={() => {
          let newDataBackup = this.state.newDataVersion
          this.setState({ openUpdatedButton: false , countVersion: 0})
          this.setState({ ...newDataBackup, newDataVersion: null, lastVersionUpdated: null })
        }}
      />


    </div>
  }
}


export default withRouter(Body);

