import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import "./menu.scss"
import { setUser } from '../../Store/actions/user';
import SearchAutocomplete from "./../toolbarSearchTransaction/searchAutocomplete"
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import StarBorder from '@material-ui/icons/StarBorder';
import Avatar from '@material-ui/core/Avatar';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AvatarList from "../Avatar/Avatar"
import "../Avatar/Avatar.scss"
import Badge from '@material-ui/core/Badge';
import TodayIcon from '@material-ui/icons/Today';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';
import { _fetch, parseQuery } from '../../helpers';
import RestoreIcon from '@material-ui/icons/Restore';
import AppsIcon from '@material-ui/icons/Apps';
import BuildIcon from '@material-ui/icons/Build';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import BarChartIcon from '@material-ui/icons/BarChart';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import LuggageIcon from '@mui/icons-material/Luggage';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import StarRateIcon from '@mui/icons-material/StarRate';
import trustpilot_img from "./../../assets/img/trustpilot.svg"
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import WifiTetheringErrorIcon from '@mui/icons-material/WifiTetheringError';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import RadarIcon from '@mui/icons-material/Radar';
// import RadarIcon from '@mui/icons-material/MilitaryTech';

class Body extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            panelMenu: false,
            version: undefined,
            open_0: true,
            open_1: false,
            open_2: false
        }
        // this.classes = useStyles();

    }

    // routine = () => {
    //     _fetch(process.env.REACT_APP_URL + '/crm/meta/bar', {
    //         "timeout": 10000,
    //         "nbRetry": 1,
    //         "method": "post",
    //         "headers": {},
    //         "body": {

    //             bookingId: this.props.booking.bookingId
    //         }
    //     })
    //         .then(function (response) {
    //             if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
    //             let data = response.json()
    //             window.open(data.url, '_blank');
    //         }.bind(this))
    //         .catch(function (e) {
    //             console.log("Error", e)
    //         }.bind(this));
    // }

    componentDidMount() {
        for (let l of this.props.base.users) {
            if (l.id == this.props.nameUser) {
                this.setState({ userActive: l })
                break
            }
        }
        

    }




    readNotification = () => {
        let date = localStorage.getItem("crm_notification_qd")
        if (date === new Date().toISOString().substr(0, 10)) {
            return null
        }
        return true
    }



    render() {

        let nbProcessing = this.props.page && this.props.page.state_processing ? this.props.page.state_processing.length : undefined
        let nbProcessingAffected = this.props.page && this.props.page.state_processing_affected ? this.props.page.state_processing_affected.length : undefined

        let nbLuggage = this.props.page && this.props.page.state_luggage ? this.props.page.state_luggage.length : undefined
        let trustpilot = this.props.page && this.props.page.state_trustpilot && this.props.page.state_trustpilot.note ? this.props.page.state_trustpilot : undefined
        let nbUnderManagement = this.props.page && this.props.page.state_undermanagement ? this.props.page.state_undermanagement.length : undefined
        let nbBill = this.props.page && this.props.page.state_bill ? this.props.page.state_bill.length : undefined
        let nbDisruptedConnection = this.props.page && this.props.page.state_disruptedconnection ? this.props.page.state_disruptedconnection.length : undefined

        
        return <div>
            <div className={"space-navbar"}></div>
            <div className={"navbar-menu"}>
                <div>
                    <IconButton size="medium" onClick={() => {
                        this.setState({ panelMenu: true })
                    }}>
                        <MenuIcon style={{ color: "white" }} />
                    </IconButton>
                </div>

                {this.props.displayAvatar && <AvatarList list={this.props.page ? this.props.page.pageUsers : undefined} max={5} />}


                {/* {this.props.nbProcessing} */}

                <div style={{ flexGrow: 1 }}></div>



                {<div style={{ marginRight: "5px" }}> <IconButton size="medium" onClick={() => {

                    window.open('https://newaccount1603196282781.freshdesk.com/a/tickets/filters/374516?orderBy=appointment_start_time&orderType=desc&page=1', '_blank')

                }}> <Badge badgeContent={0}
                    color="secondary">
                        <SupportAgentIcon style={{ color: "white" }} />
                    </Badge>  </IconButton></div>}


                {<div style={{ marginRight: "5px" }}> <IconButton size="medium" onClick={() => {


                    this.props.history.push("/bill?bodyRequest=" + encodeURI(JSON.stringify({ sorting: "CREATED_DATE_DEC", "status": ["ACTIVE", "EXPIRED", "PAID"] })))
                    this.setState({ version: Math.random() })

                }}> <Badge badgeContent={nbBill}
                    color="secondary">
                        <RequestQuoteIcon style={{ color: "white" }} />
                    </Badge>  </IconButton></div>}


                {this.readNotification() ? <div style={{ marginRight: "0px" }}> <IconButton size="medium" onClick={() => {

                    window.open('https://www.notion.so/Suivi-Nouveaut-Equipe-2c692ce23ab54531b53090fcb5beb1f9', '_blank');
                    localStorage.setItem("crm_notification_qd", new Date().toISOString().substr(0, 10))
                    this.setState({ version: Math.random() })
                }}> <Badge badgeContent={"1"}
                    className={"notification-badge"}
                    variant="dot"
                    color="secondary"
                >
                        <NotificationsIcon style={{ color: "white" }} />
                    </Badge>  </IconButton></div> : null}




                {<div style={{ marginRight: "5px" }}> <IconButton size="medium" onClick={() => {


                    this.props.history.push("/transactions?bodyRequest=" + encodeURI(JSON.stringify({ sorting: "DEPART_CRO", "startDate": new Date(Date.now() - 2 * 86400000).toISOString(), "endDate": new Date(Date.now() + 30 * 86400000).toISOString(), "typeFilterDate": "TRAVEL", statusBooking: [], "statusCombigo": ["AUTO_NEED_CHECK"] })))
                    this.setState({ version: Math.random() })

                }}> <Badge badgeContent={nbLuggage}
                    color="secondary">
                        <LuggageIcon style={{ color: "white" }} />
                    </Badge>  </IconButton></div>}



                {/* {<div style={{ marginRight: "5px" }}> <IconButton size="medium" onClick={() => {
                    this.props.history.push("/transactions?bodyRequest=" + encodeURI(JSON.stringify({ sorting: "DEPART_CRO", "startDate": new Date(Date.now() - 2 * 86400000).toISOString(), "endDate": "", "typeFilterDate": "TRAVEL", statusBooking: [], "statusCombigo": ["UNDER_MANAGEMENT"] })))
                    this.setState({ version: Math.random() })

                }}> <Badge badgeContent={nbUnderManagement}
                    color="secondary">
                        <PriorityHighIcon style={{ color: "white" }} />
                    </Badge>  </IconButton></div>} */}


                {<div style={{ marginRight: "5px" }}> <IconButton size="medium" onClick={() => {
                    this.props.history.push("/transactions?bodyRequest=" + encodeURI(JSON.stringify({ sorting: "DEPART_CRO", "startDate": new Date(Date.now() - 2 * 86400000).toISOString(), "endDate": "", "typeFilterDate": "TRAVEL", statusBooking: [], "statusCombigo": ["DISRUPTED_CONNECTION_TO_MANAGE"] })))
                    this.setState({ version: Math.random() })

                }}> <Badge badgeContent={nbDisruptedConnection}
                    color="secondary">
                        <  PriorityHighIcon style={{ color: "white" }} />
                    </Badge>  </IconButton></div>}



               


                {<div style={{ marginRight: "5px" }}> <IconButton size="medium" onClick={() => {
                    this.props.history.push("/transactions?bodyRequest=" + encodeURI(JSON.stringify({ endDate: "", startDate: "", "sorting": "SCORE",
                     statusBooking: ["PROCESSING", "PROCESSING_AUTO",
                      "PROCESSING_MANAGE", "PROCESSING_ERROR", "WAIT_CONFIRMED"], statusCombigo: [] })))
                    this.setState({ version: Math.random() })

                }}> <Badge badgeContent={nbProcessing }
                    color="secondary">
                        <Badge badgeContent={nbProcessingAffected }
                        className={"badge-orange"}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                    color="warning">
                        <ShoppingCartIcon style={{ color: "white" }} />
                    </Badge>
                    </Badge>  </IconButton></div>}

                {(this.props.searchTransaction || true) && <div>

                    <SearchAutocomplete onClick={(option) => {
                        this.props.history.push("/oneTransaction/" + option.id)
                    }} />
                </div>}

                {trustpilot && false ? <div className={"btn-trust"}> <Button size="medium"
                    startIcon={
                        <img src={trustpilot_img} width="22px" height="22px" />
                        // <StarRateIcon style={{ color: "#33c" }} />
                    }

                    onClick={() => {
                        this.props.history.push("/view_all_metrics_trustpilot")

                    }}> <div className={"div-text-trust"}><span className={"text-trust"}>
                        {trustpilot.note} {" - "}  {trustpilot.quantity}
                    </span></div>
                </Button></div> : null}



                {/* {this.state.userActive ? <div className={"avatar-container"} style={{ marginRight: "5px" }}> <Avatar className={"avatar-combigo"}>{this.state.userActive.symbol} </Avatar></div> : null} */}



                <Drawer anchor={"left"} open={this.state.panelMenu} onClose={() => {
                    this.setState({ panelMenu: false })
                }}>
                    <List className={"panel-menu"} component="nav" aria-label="mailbox folders">
                        <IconButton small="medium" onClick={() => {
                            this.setState({ panelMenu: false })
                        }}>
                            <CloseIcon />
                        </IconButton>

                        <Divider />
                        <ListItem button onClick={() => { this.setState({ open_0: this.state.open_0 ? false : true }) }}>
                            <ListItemIcon>
                                <AirplaneTicketIcon />
                            </ListItemIcon>
                            <ListItemText primary="Réservations" />
                            {this.state.open_0 ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={this.state.open_0} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button className={"sub-item"} onClick={() => {
                                    this.setState({ panelMenu: false })
                                    this.props.history.push("/")
                                }}>
                                    <ListItemText primary="Toutes" />
                                </ListItem>
                                {/* <ListItem button className={"sub-item"} onClick={() => {
                                    this.setState({ panelMenu: false })
                                    this.props.history.push("/transactions?bodyRequest=" + encodeURI(JSON.stringify({ statusBooking: ["PROCESSING", "PROCESSING_AUTO", "PROCESSING_MANAGE", "WAIT_CONFIRMED"], sorting: "SCORE", startDate: "", endDate: "", statusCombigo: [] })))
                                }}>
                                    <ListItemText primary="Processing" />
                                </ListItem> */}

                                <ListItem button className={"sub-item"} onClick={() => {
                                    this.setState({ panelMenu: false })

                                    this.props.history.push("/transactions?bodyRequest=" + encodeURI(JSON.stringify({ typeFilterDate: "RAPPEL", endDate: new Date().toISOString().substr(0, 19), sorting: "SCORE", startDate: "", statusCombigo: [] })))
                                }}>
                                    <ListItemText primary="Rappel" />
                                </ListItem>
                                {/* <ListItem button className={"sub-item"} onClick={() => {
                                    this.setState({ panelMenu: false })
                                    this.props.history.push("/planning")
                                }}>
                                    <ListItemText primary="Calendrier" />
                                </ListItem> */}
                            </List>
                        </Collapse>

                        <Divider />


                        <ListItem button onClick={() => { this.setState({ open_1: this.state.open_1 ? false : true }) }}>
                            <ListItemIcon>
                                <TodayIcon />
                            </ListItemIcon>
                            <ListItemText primary="Calendrier Voyage" />
                            {this.state.open_1 ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={this.state.open_1} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button onClick={() => {
                                    this.setState({ panelMenu: false })
                                    this.props.history.push("/transactions_calendar?")
                                }} >

                                    <ListItemText primary="Jour" />
                                </ListItem>
                                <ListItem button onClick={() => {
                                    this.setState({ panelMenu: false })
                                    this.props.history.push("/transactions_calendar_meta?")
                                }} >

                                    <ListItemText primary="Général" />
                                </ListItem>

                            </List>
                        </Collapse>



                        <ListItem button onClick={() => {
                            this.setState({ panelMenu: false })
                            this.props.history.push("/view_all_realtime")
                        }} >
                            <ListItemIcon>
                                <FlightTakeoffIcon />
                            </ListItemIcon>
                            <ListItemText primary="Realtime Voyage" />
                        </ListItem>


                        <Divider />
                        <ListItem button divider onClick={() => {
                            this.setState({ panelMenu: false })
                            this.props.history.push("/view_all_logs")
                        }}>
                            <ListItemIcon>
                                <RestoreIcon />
                            </ListItemIcon>
                            <ListItemText primary="Logs" />
                        </ListItem>
                        <Divider />
                        <ListItem button divider onClick={() => {
                            this.setState({ panelMenu: false })
                            this.props.history.push("/report/main")
                        }}>
                            <ListItemIcon>
                                <RadarIcon />
                            </ListItemIcon>
                            <ListItemText primary="EscapeGame" />
                        </ListItem>
                        <Divider />
                        <ListItem button divider onClick={() => {
                            this.setState({ panelMenu: false })
                            this.props.history.push("/view_all_tasks")
                        }}>
                            <ListItemIcon>
                                <AssignmentIcon />
                            </ListItemIcon>
                            <ListItemText primary="Tâches" />
                        </ListItem>
                        <Divider />
                        <ListItem button divider onClick={() => {
                            this.setState({ panelMenu: false })
                            this.props.history.push("/voucher")
                        }}>
                            <ListItemIcon>
                                <MonetizationOnIcon />
                            </ListItemIcon>
                            <ListItemText primary="Voucher" />
                        </ListItem>
                        <Divider />

                       

                        <ListItem button divider onClick={() => {
                            this.setState({ panelMenu: false })
                            this.props.history.push("/bill")
                        }}>
                            <ListItemIcon>
                                <RequestQuoteIcon />
                            </ListItemIcon>
                            <ListItemText primary="Bill" />
                        </ListItem>


                        <Divider />

                        <ListItem button onClick={() => { this.setState({ open_2: this.state.open_2 ? false : true }) }}>
                            <ListItemIcon>
                                <MailIcon />
                            </ListItemIcon>
                            <ListItemText primary="Mail/SMS" />
                            {this.state.open_2 ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={this.state.open_2} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button className={"sub-item"} onClick={() => {
                                    this.setState({ panelMenu: false })
                                    this.props.history.push("/view_all_mail")
                                }}>
                                    <ListItemText primary="Tracking Mail" />
                                </ListItem>
                                <ListItem button className={"sub-item"} onClick={() => {
                                    this.setState({ panelMenu: false })
                                    this.props.history.push("/view_all_sms")
                                }}>
                                    <ListItemText primary="Tracking Sms" />
                                </ListItem>

                            </List>
                        </Collapse>
                        <Divider />



                        <ListItem button onClick={() => { this.setState({ open_3: this.state.open_3 ? false : true }) }}>
                            <ListItemIcon>
                                <AccountBalanceIcon />
                            </ListItemIcon>
                            <ListItemText primary="Bank" />
                            {this.state.open_3 ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={this.state.open_3} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                            <ListItem button className={"sub-item"} onClick={() => {
                                    this.setState({ panelMenu: false })
                                    this.props.history.push("/account/flow")
                                }}>
                                    <ListItemText primary="Flux Financiers" />
                                </ListItem>

                                <ListItem button className={"sub-item"} onClick={() => {
                                    this.setState({ panelMenu: false })
                                    this.props.history.push("/bank/cards")
                                }}>
                                    <ListItemText primary="Cards Virtuals" />
                                </ListItem>
                                <ListItem button className={"sub-item"} onClick={() => {
                                    this.setState({ panelMenu: false })
                                    this.props.history.push("/bank/transactions")
                                }}>
                                    <ListItemText primary="Transactions Cards Virtuals" />
                                </ListItem>


                              

                            </List>
                        </Collapse>

                        <ListItem button onClick={() => { this.setState({ open_4: this.state.open_4 ? false : true }) }}>
                            <ListItemIcon>
                                <TrendingUpIcon />
                            </ListItemIcon>
                            <ListItemText primary="Metrics" />
                            {this.state.open_4 ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={this.state.open_4} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button className={"sub-item"} onClick={() => {
                                    this.setState({ panelMenu: false })
                                    this.props.history.push("/view_all_metrics")
                                }}>
                                    <ListItemText primary="General" />
                                </ListItem>
                                <ListItem button className={"sub-item"} onClick={() => {
                                    this.setState({ panelMenu: false })
                                    this.props.history.push("/view_all_metrics_business")
                                }}>
                                    <ListItemText primary="Business" />
                                </ListItem>
                                <ListItem button className={"sub-item"} onClick={() => {
                                    this.setState({ panelMenu: false })
                                    this.props.history.push("/view_all_metrics_trustpilot")
                                }}>
                                    <ListItemText primary="Trustpilot" />
                                </ListItem>


                            </List>
                        </Collapse>

                        {this.state.userActive && this.state.userActive.role.includes("FINANCE")?<><ListItem button onClick={() => { this.setState({ open_5: this.state.open_5 ? false : true }) }}>
                            <ListItemIcon>
                                <TrendingUpIcon />
                            </ListItemIcon>
                            <ListItemText primary="Finance" />
                            {this.state.open_5 ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={this.state.open_5} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button className={"sub-item"} onClick={() => {
                                    this.setState({ panelMenu: false })
                                    this.props.history.push("/restricted/finance/balance")
                                }}>
                                    <ListItemText primary="Balance" />
                                </ListItem>
                               



                               {this.state.userActive && this.state.userActive.role.includes("ADMIN")? <ListItem button className={"sub-item"} onClick={() => {
                                    this.setState({ panelMenu: false })
                                    this.props.history.push("/restricted/finance/bank_details")
                                }}>
                                    <ListItemText primary="Bank Details" />
                                </ListItem>:null}
                            </List>



                            </Collapse>
                            </>
                        :null}
                    
                    {this.state.userActive && this.state.userActive.role.includes("TECHNICAL")?<><ListItem button onClick={() => { this.setState({ open_6: this.state.open_6 ? false : true }) }}>
                            <ListItemIcon>
                                <TrendingUpIcon />
                            </ListItemIcon>
                            <ListItemText primary="Technical" />
                            {this.state.open_6 ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={this.state.open_6} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button className={"sub-item"} onClick={() => {
                                    this.setState({ panelMenu: false })
                                    this.props.history.push("/private/configuration_technical/providers")
                                }}>
                                    <ListItemText primary="Providers" />
                                </ListItem>
                                <ListItem button className={"sub-item"} onClick={() => {
                                    this.setState({ panelMenu: false })
                                    this.props.history.push("/private/configuration_technical/helpbots")
                                }}>
                                    <ListItemText primary="helpbots" />
                                </ListItem>


                            </List>



                            </Collapse>
                            </>
                        :null}
                        {/* <ListItem button divider onClick={() => {
                            this.setState({ panelMenu: false })
                            this.props.history.push("/bill_edit")
                        }}>
                            <ListItemText primary="Facturation" />
                        </ListItem>
                        <Divider /> */}
                        {/* <ListItem button divider onClick={() => {
                            this.setState({ panelMenu: false })
                            this.props.history.push("/editMail")
                        }}>
                            <ListItemText primary="editMail" />
                        </ListItem>
                        <Divider /> */}
                        {/* <ListItem button divider onClick={() => {
                            this.setState({ panelMenu: false })
                            this.props.history.push("/account")
                        }}>
                            <ListItemText primary="Comptabilité" />
                        </ListItem>
                        <Divider /> */}



                        <ListItem button divider onClick={() => {
                            this.setState({ panelMenu: false })
                            this.props.history.push("/oneTransaction?bookingId=test")
                        }}>
                            <ListItemText primary="Réservation Test" />
                        </ListItem>
                        <Divider />

                        <ListItem divider button onClick={() => {
                            localStorage.setItem("user3", "")
                            localStorage.setItem("x-api-key", "")

                            this.props.setUser("")
                            this.props.history.push("/login")
                        }}>
                            <ListItemText primary="Configuration" />
                        </ListItem>
                        <Divider />

                    </List>


                    <div className="footer-burger">
                        <div style={{ flexGrow: 1 }}></div>
                        <Divider />

                        {"Version : " + (process.env.CI_COMMIT_SHA || "?")}
                    </div>
                    {/* <div >
                        {this.props.nameUser}
                    </div> */}
                </Drawer>
            </div>

        </div>
    }
}


const mapStateToProps = (state) => {
    return { nameUser: state.user.name, page: state.user.page, base: state.data };
};

export default connect(mapStateToProps, {
    setUser
})(withRouter(Body));

