


import React from 'react';

import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker, DatePicker, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';



class Body extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }

        this.anchorElRef = React.createRef();
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {

    }


    render() {

        return <div
            ref={this.anchorElRef}
        >

            <MuiPickersUtilsProvider utils={MomentUtils}>
                <this.props.DateObj
                    {...this.props}
                    DateObj={undefined}
                    PopoverProps={{
                        // anchorOrigin: {
                        //     vertical: "top",
                        //     horizontal: "right",
                        //   },
                        // anchorReference:'anchorPosition',
                        // anchorOrigin: {
                        //      vertical: "center",
                        //     horizontal: "center",
                        //   },
                        //   anchorPosition:{
                        //       vertical:"-132px",
                        //       horizontal:0
                        //   },
                        // anchorOrigin: {
                        //     vertical: "top",
                        //     horizontal: "right",
                        //   },
                        anchorEl: () => this.anchorElRef.current
                    }}
                />


            </MuiPickersUtilsProvider>
        </div>




    }

}



export default Body;