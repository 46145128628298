import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import "./TableMaterial.scss"
const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});


function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root + " line-table"}>
                {props.columns.map((obj, index) => {
                    if (obj.render) return <TableCell key={index}>{obj.render(props.row, obj.field)}</TableCell>
                    if (obj.collapse) return <TableCell key={index}>{<IconButton aria-label="expand row" size="small" onClick={async() =>{
                        if (props.onCollapseClick){
                            await props.onCollapseClick(props.row, obj.field)
                        }
                        setOpen(!open)
                    }}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>}</TableCell>
                    return <TableCell key={index} component="th" scope="row">
                        {props.row[obj.field]}
                    </TableCell>
                })}
            </TableRow>
            {
                open ? <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6} className={"subTable"}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={1} className={"zone"}>
                                {/* <Typography variant="h6" gutterBottom component="div">
                                    History
                                </Typography> */}
                                <Table size="small" aria-label="purchases">
                                    <TableHead className={"subtable-header"}>

                                        <TableRow>
                                            {props.subColumn.map((obj, index) => {
                                                if (obj.renderColumnHeader) return obj.renderColumnHeader(obj)
                                                if (obj.collapse) return <TableCell />
                                                if (obj.name) return <TableCell key={index} component="th" scope="row">
                                                    {obj.name}
                                                </TableCell>
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.headerCollapse ? props.headerCollapse(row) : null}
                                        {props.functionCollapse && props.functionCollapse(row).map((subData, index) => {




                                            return <TableRow key={index} className={'line-table'}>
                                                {props.subColumn.map((obj, index) => {
                                                    if (obj.render) return <TableCell key={index}>{obj.render(subData, obj.field)}</TableCell>

                                                    return <TableCell key={index} component="th" scope="row">
                                                        {subData[obj.field]}
                                                    </TableCell>
                                                })}
                                            </TableRow>
                                        })}
                                        {props.footerCollapse ? props.footerCollapse(row) : null}


                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow> : null
            }
        </React.Fragment >
    );
}


function descendingComparator(a, b, orderBy,column) {
  //  console.log(column)
    if (column && column.getValue){
        let bValue = column.getValue(b)
        let aValue = column.getValue(a)
       // console.log("avlue="+aValue + " bValue="+bValue)
        if (bValue < aValue) {
            return -1;
        }
        if (bValue > aValue) {
            return 1;
        }
        return 0;
    }

    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }

    return 0;
}
function getComparator(order, orderBy,column) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy,column)
        : (a, b) => -descendingComparator(a, b, orderBy,column);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}




function EnhancedTableHead(props) {
    const { className, order, orderBy, columns, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={className}>
            <TableRow>
                {/* <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell> */}

                {columns.map((headCell, index) => {
                    if (headCell.renderColumnHeader) return headCell.renderColumnHeader(headCell)
                    if (headCell.collapse) return <TableCell />
                    if (headCell.noSort) {
                        return <TableCell
                            key={index}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                        >
                            {headCell.name}
                        </TableCell>
                    }

                    return <TableCell
                        key={index}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.field ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.field}
                            direction={orderBy === headCell.field ? order : 'asc'}
                            onClick={createSortHandler(headCell.field || headCell.name)}
                        >
                            {headCell.name}
                            {/* {orderBy === index ? (
                                <span className={""}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null} */}
                        </TableSortLabel>
                        
                    </TableCell>
                })}
            </TableRow>
        </TableHead>
    );
}












export default function CollapsibleTable(props) {
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [page, setPage] = React.useState(0);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState(props.columns ? props.columns[0].field : "");

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangeRowsPerPage = (event) => {
        // console.log(event.target.value, rowsPerPage,parseInt(event.target.value, rowsPerPage))
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };
    
    const dataTemp = stableSort(props.data, getComparator(order, orderBy,props.columns.filter((column)=>{
        return (column.field || column.name)===orderBy
    })[0]))
    let data = dataTemp.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    return (
        <>
            <TableContainer component={Paper} className={"material-table-ui"}>
                <Table
                    // stickyHeader 
                    size={props.dense ? 'small' : 'medium'}
                    aria-label="collapsible table">
                    {/* <TableHead className={"table-header"}>
                        <TableRow>
                            EnhancedTableHead
                            {props.columns.map((obj, index) => {
                                if (obj.renderColumnHeader) return obj.renderColumnHeader(obj)
                                if (obj.collapse) return <TableCell />
                                if (obj.name) return <TableCell key={index} component="th" scope="row">
                                    {obj.name}
                                </TableCell>
                            })}
                        </TableRow>
                    </TableHead> */}
                    <EnhancedTableHead
                        className={"table-header"}
                        order={order}
                        orderBy={orderBy}
                        columns={props.columns}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {data.map((row, index) => (
                            <Row key={index} row={row} columns={props.columns} subColumn={props.subColumn} functionCollapse={props.functionCollapse} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[100, 200, 500]}
                component="div"
                count={dataTemp.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />

        </>
    );
}