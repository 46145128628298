
import React from 'react';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { parseQuery, _fetch } from "./../../helpers"

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Popper from '@material-ui/core/Popper';
import CircularProgress from '@material-ui/core/CircularProgress';

// import ModalMail from "./ModalMail"
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Select_CheckBox from "./../Utils/Select_CheckBox"
import "./ModalFastChange.scss"
import TableSegments from "./../TableSegments/TableSegments"
import MinimizeIcon from '@mui/icons-material/Minimize';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@material-ui/core/IconButton';
import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const columns = [
    {
        name: "order",
        label: "Ordre",
    },

    {
        name: "sensTrip",
        label: "sensTrip",
    },
    {
        name: "insuranceAfter",
        label: "insuranceAfter",
    },

    {
        name: "from",
        label: "From",
        render: (rowData) => {
            let obj = rowData.from

            return obj ? (obj.iata ? obj.iata + " - " : "") +
                obj.name
                + (obj.city ? ", " + obj.city : "")
                + (obj.country ? " (" + obj.country + ")" : obj.country) : ""

        }
    },
    {
        name: "to",
        label: "To",
        render: (rowData) => {
            let obj = rowData.to
            return obj ? (obj.iata ? obj.iata + " - " : "") +
                obj.name
                + (obj.city ? ", " + obj.city : "")
                + (obj.country ? " (" + obj.country + ")" : obj.country) : ""

        }
    },

    // { title: "Details Aller", field: "departureDetail" },
    // { title: "Provider", field: "provider" },
    {
        name: "dTime",
        label: "date Departure",
    },
    {
        name: "aTime",
        label: "date Arrival",
    },
    {
        name: "transporter",
        label: "Transporteur",
        render: (rowData) => {
            let obj = rowData.transporter

            return obj ? obj.title || obj.name : ""

        }
    },
    {
        name: "trNum",
        label: "trNum",
    },
    {
        name: "type",
        label: "typeTransport",
    },
]


class Body extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            snackMessage: null,
            disabledButtonSend: false,
            errorMessage: null,

            modeSelected: null,
            step: 1,
            ticketId: ""
        }

    }







    update = () => {

        this.setState({ isRunning: true, segments: null, modeSelected: null,disabledButtonSend:true })
        this._request('/crm/actions/fastchange', {
            step: this.state.step,
            ticketId: this.state.ticketId
        }, (data, error) => {
            //return 
            setTimeout(()=>{
                if (data && data.segments) {
                    return this.setState({ isRunning: false, segments: data.segments,disabledButtonSend:false })
                }
                this.setState({ isRunning: false, errorMessage: "Problème, veuillez réessayer dans quelques minutes",disabledButtonSend:false })
    
            },1000)
            // console.log("data = ", data)
           
        })

    }


    componentDidUpdate(prevProps) {

        if (this.props.version && this.props.version != prevProps.version) {
            // console.log("Difference version " + this.props.version + " vs " + prevProps.version)
            this.setState({ ticketId: "", segments: null, modeSelected: null, step: 1 })
        }
        else if (this.props.open != prevProps.open) {
            // console.log("Diff list " + this.state.isRunning + " " + this.props.open + " ")
            this.setState({ ticketId: "", segments: null, modeSelected: null, step: 1 })

            //  this.setState({ticketId:""})
        }

    }



    _request = (url = '/crm/actions/fastchange', body, cb) => {

        _fetch(process.env.REACT_APP_URL + url, {
            "timeout": 20000,
            "nbRetry": 2,
            "method": "post",
            "headers": {},
            "body": {
                ...body
            }
        })
            .then(function (response) {
                if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
                let data = response.json()
                cb(data)
            }.bind(this))
            .catch(function (e) {
                cb(undefined, e)
                console.error("Error modal modification" + url, e);
            }.bind(this));
    }



    onClick = () => {
        this.setState({isRunning:true})

        if (this.state.step === 1) {
            setTimeout(()=>{
                this.setState({ step: 2, modeSelected: null,isRunning:false })
            },1000)
         
        }
        else if (this.state.step === 2) {
            this.setState({disabledButtonSend:true,isRunning:false})
            let dataNew = this.state.segments.slice(0)
            if (this.state.modeSelected === "add") {
                dataNew = this.state.segments.concat(this.props.dataInput.data || [])
            }
            
            this.props.onChange({
                data: dataNew,
                lastVersion: this.props.dataInput.version,
                typeUpdate: "table_data",
                updateProp: "segments",
                user: this.props.nameUser
            }).then((flagOK) => {
                this.setState({disabledButtonSend:false})

                if (flagOK === true) {
                    this.props.onClose()
                    this.setState({ ticketId: "", segments: null, modeSelected: null })
                }
                else {
                    this.setState({ errorMessage: "Erreur to do the change" })
                }
            })
        }


    }

    render() {
        return (
            <>
                <Dialog
                    className={(this.props.className || "") + " modal-fastchange"}
                    open={this.props.open || false}
                    onClose={() => {
                        this.props.onClose()
                    }}

                >
                    <div style={{ display: "flex", "flewDirection": "row", borderBottom: "1px solid grey" }}>
                        <DialogTitle>{"Changement d'un Trajet à partir d'un trajet"}</DialogTitle>

                        <div style={{ flexGrow: 1 }}></div>


                        <IconButton onClick={() => {
                            this.setState({ segments: null })
                            if (this.props.onClose) this.props.onClose()

                        }}>
                            <CloseIcon />
                        </IconButton>
                    </div>

                    <>
                        <DialogContent>

                            <div  style={{display:"flex",justifyContent:"center",flexDirection:"column"}} >
                                {this.state.step === 1 ? <Grid container item style={{ marginTop: "10px", minWidth: "100px", backgroundColor: "#eff6fb" }} className={""} >
                                    <TextField
                                        style={{ width: "100%", margin: "8px" }}
                                        id="ticket"
                                        label="TicketId"
                                        variant="standard"
                                        value={this.state.ticketId}
                                        onChange={(ev) => {
                                            let ticketId
                                            if (ev.target.value && ev.target.value.indexOf("https://") != -1) {
                                                const url = new URL(ev.target.value);
                                                ticketId = url.searchParams.get('ticket_id')
                                            }
                                            ticketId = ticketId || ev.target.value


                                            this.setState({ ticketId: ticketId })
                                            setTimeout(() => {
                                                if (ev.target.value === ticketId) {
                                                    this.update()
                                                }
                                            }, 500)
                                        }}
                                    />
                                </Grid> : null}

                                {this.state.segments && !this.state.isRunning ? <TableContainer >
                                    <Table aria-label="collapsible table">
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((o, index) => {
                                                    return <TableCell style={{}} key={index} >{o.title || o.label}</TableCell>
                                                })}

                                            </TableRow>
                                        </TableHead>
                                        <TableBody style={{ padding: "10px" }}>
                                            {this.state.segments && this.state.segments.map((row, index) => {
                                                //   console.log("row data", row)

                                                return <TableRow>
                                                    {columns.map((o, index) => {
                                                        return <TableCell key={index}>{o.render ? o.render(row) : row[o.field || o.name]}</TableCell>
                                                    })}
                                                </TableRow>
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer> : null}
                                {this.state.step === 2 ? <Select
                                style={{marginTop:"12px",width:"100px",border:!this.state.modeSelected?"1px solid red":undefined}}
                                    value={this.state.modeSelected}
                                    PopperComponent={function (props) {
                                        return <Popper {...props} style={{ width: "fit-content" }} placement="bottom-start" />;
                                    }}
                                    onChange={(ev, value) => {

                                        this.setState({ modeSelected: ev.target.value })
                                    }}        >
                                    <MenuItem key={"add"} value={"add"}>{"Add"}</MenuItem>
                                    <MenuItem key={"replace"} value={"replace"}>{"Replace"}</MenuItem>


                                </Select> : null}
                                {this.state.isRunning?<CircularProgress />:null}
                            </div>



                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                if (this.state.step == 2) {
                                    this.setState({ step: 1, modeSelected:null })
                                    return
                                }
                                if (this.props.onClose) this.props.onClose()
                            }} color="primary">
                                {(this.state.step == 1 ? "Fermer" : "Précédent")}
                            </Button>

                            <Button onClick={() => {
                                if (!this.state.segments || this.state.segments.length===0) {
                                    return this.setState({ errorMessage: "Le segment n'est pas selectionne" })
                                }
                                if (this.state.step === 2) {
                                    if (!this.state.modeSelected) {
                                        return this.setState({ errorMessage: "Le mode de fusion n'est pas selectionne" })
                                    }
                                }
                                this.onClick()
                            }}
                                disabled={this.state.disabledButtonSend || (!this.state.segments || this.state.segments.length===0)}

                                color="primary" >
                                {this.state.step == 1 ? "Next" : "Load"}
                            </Button>
                        </DialogActions>
                    </>
                </Dialog>
                <CombigoSnackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.errorMessage !== null || this.state.snackMessage !== null}
                    onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
                    variant={this.state.errorMessage != null ? 'error' : "success"}
                    autoHideDuration={10000}
                    message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
                />

            </>
        );
    }
}




const mapStateToProps = (state) => {
    return { booking: state.booking, nameUser: state.user.name };
};

export default connect(mapStateToProps, {})(Body);