import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import moment from "moment";
import DragAndDrop from "./../../lib/DrapAndDropFile/DragAndDrop"
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from "@material-ui/core/Button"
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import EditorText from "./../EditorText/EditorText"
import ModalYesNo from "./../dialogConfirm/ModalYesNo"
const heightMax = 120

class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: props.mode || "view", // "edit",view
      textEdit: "",
      files: [],
      noteId: props.note._id,

      deletePopup: false,
      displayButtonMore: false,
      forceOpenNote: false
    }
    this.edit = this.edit.bind(this)
    this.delete_ = this.delete_.bind(this)
    this._reset = this._reset.bind(this)
    this._activeHeight = this._activeHeight.bind(this)
    this.myDivView = React.createRef()

    this._startActiveHeight = this._startActiveHeight.bind(this)
    this.id = props.id

  }


  _reset() {
    this.setState({
      mode: this.props.mode || "view", // "edit",view
      textEdit: "",
      noteId: this.props.note._id,
      files: [],
      deletePopup: false,
      displayButtonMore: false,
      forceOpenNote: false
    });
  }


  _activeHeight() {

    if (this.myDivView && this.myDivView.current) {
      if (this.myDivView.current.clientHeight > heightMax) {
        if (!this.state.displayButtonMore) this.setState({ displayButtonMore: true, forceOpenNote: false })
      }
      else {
        if (this.state.displayButtonMore) this.setState({ displayButtonMore: false, forceOpenNote: false })
      }
    }



  }

  _startActiveHeight() {
    if (this.state.mode == "edit") return
    setTimeout(() => {
      this._activeHeight()
      setTimeout(() => {
        this._activeHeight()
      }, 800)
    }, 800)
  
  }
  componentDidMount() {
    this._startActiveHeight()
  }

  componentDidUpdate(prevProps, prevState,) {

    if (this.id != this.props.id) {
      this.id = this.props.id
      this._reset()
      this._startActiveHeight()
    }
    // this.myDivView.current.offsetWidth
  }


  edit() {
    this.setState({
      noteId: this.props.note._id || ("n-"+String(Date.now() + "-" + Math.random())),
      textEdit: this.props.note.content,
      files: this.props.note.files,
      created: this.props.note.created,
      mode: "edit"
    })
  }



  async save() {

    if (await this.props.onChange({

      content: this.state.textEdit,
      files: this.state.files,
      created: this.state.created || { date: Date.now(), idUser: this.props.idUser },
      modified: { date: Date.now(), idUser: this.props.idUser },
      _id: this.state.noteId,
    }) == true) {
      // this.setState({mode:"view"})
      this.props.disableEdit()
      this._reset()
    }

  }

  async delete_() {
    if (await this.props.onDelete() == true) {
      this.setState({ deletePopup: false, mode: "view" })
      this.props.disableEdit()
      this._reset()

    }
  }


  _replaceLink = (str)=>{

    if (!str) return str

    let array = str.split("<a ")
    for (let k=0;k<array.length;k++){
      array[k] = array[k]+ '<a target="_blank" '
    }

    return array.join("")
  }


  render() {

    //console.log(this.props.note)

    // if (!this.props.note) return

    let classHeight = this.state.displayButtonMore
      && this.props.mode != "edit" && !this.state.forceOpenNote
      ? " max-height" : ""


      


    // console.log("classHeight=" + classHeight
    //   + " displayButtonMore=" + this.state.displayButtonMore
    //   + " forceOpenNote=" + this.state.forceOpenNote)

    return (

      <>
        <div
          className={"notes-div " + classHeight}
        // style={{ maxHeight: classHeight + " !important" }}
        >
          {this.state.deletePopup && <ModalYesNo
            btnValid={"OK"}
            btnCancel={"Annuler"}
            open={this.state.deletePopup}
            content={"Voulez-vous supprimer la note ?"}
            title={"Suppression de la note"}
            onClick={async () => {
              if (await this.delete_() == true) {

              }
            }}
            onCancel={() => {
              this._reset()
              this.setState({ deletePopup: false })
            }}
            onClose={() => {
              this._reset()
              this.setState({ deletePopup: false })
            }}
          />}

          {true && <div className={"toolbar"}>

            {this.props.mode == "view" && <IconButton size="small" onClick={() => {
              this.edit()
              this.props.activeEdit()
            }}>
              <Edit />
            </IconButton>}
            {this.props.mode == "edit" && <IconButton size="small" onClick={() => {
              // this.setState({mode:"view"})
              this._reset()
              this.props.disableEdit()
            }}>
              <CancelIcon />
            </IconButton>}
            {<IconButton size="small" onClick={() => {
              this._reset()
              this.setState({ deletePopup: true })

            }}>
              <Delete />

            </IconButton>}
            {this.props.mode == "edit" && <IconButton size="small" onClick={() => {
              this.save()
            }}>
              <SaveIcon />
            </IconButton>}

          </div>}

          {this.props.mode == "view" &&
            <div style={{ flexDirection: "row", display: "flex",width:"100%" }}>
              <div className={"notes-paper"}

              // onMouseLeave={() => this.setState({editButton:false})}    
              // onMouseEnter={() => this.setState({editButton:true})}
              >

                <div
                  className={"text-view"}
                  dangerouslySetInnerHTML={{ __html: this._replaceLink(this.props.note.content )}}
                  ref={this.myDivView}
                ></div>
                {this.state.displayButtonMore?<div style={{height:"25px"}}></div>:null}
                {/* <TextField ></TextField> */}
                <DragAndDrop
                  dropzoneText={"Pièces jointes"}
                  // className={"mail-attachment"}
                  mode={"view"}
                  files={this.props.note.files}

                >
                </DragAndDrop>
              </div>





            </div>
          }

          {this.props.mode == "edit" && <div className={"notes-paper"}>

            <EditorText
              id={this.state.noteId}
              configEditor={{
                maxHeight:"300px",
                height:"300px"
              }}
              className={"text-mail"}
              // toolbarOnFocus={true}
              editorClassName={"text-editor"}
              // editorMention={}
              // editorMention={{
              //   separator: ' ',
              //   trigger: '@',
              //   suggestions: this.props.suggestions

              //   // [
              //   //   // { text: 'Nicolas', value: 'Nicolas', url: 'Nicolas' },
              //   //   // { text: 'Romain', value: 'Romain', url: 'Romain' },
              //   //   // { text: 'Vincent', value: 'Vincent', url: 'Vincent' }

              //   // ],
              // }}
              value={this.state.textEdit}
              onChange={(html) => {
                // console.log("html",html)
                this.setState({ textEdit: html })
              }}
            >

            </EditorText>

            <div style={{ marginTop: "10px" }}></div>

            <DragAndDrop
              dropzoneText={"Pièces jointes"}
              mode={"edit"}

              files={this.state.files}
              onAdd={(files, addNewFiles) => {
                this.setState({ files: files })
              }}
              onDelete={(files, file, index) => {
                this.setState({ files: files })
              }}
            >

            </DragAndDrop>
          </div>}

        </div>
        {
          this.props.mode != "edit" && this.state.displayButtonMore && <div className={"div-display-more"}>
            <Button className={"display-more"} onClick={() => {
              this.setState({ forceOpenNote: !this.state.forceOpenNote })
            }}> {this.state.forceOpenNote == false ? "Afficher Plus" : "Masquer"}</Button>
          </div>
        }
 
        {this.props.mode == "view" && this.props.note.created && <div className={"name-modified"}>
          <span>{"Crée par " + this.props.note.created.idUser + " (" + moment(this.props.note.created.date).format("DD MMM YY HH:mm")+
          ") , modifié "+this.props.note.modified.idUser + " (" + moment(this.props.note.modified.date).format("DD MMM YY HH:mm")+")"}</span>
        </div>}
      </>
    );
  }

}

export default Body;
