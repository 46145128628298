
import * as React from 'react';
import { connect } from 'react-redux';
import { cloneObject } from '../../helpers';
import ModelTable from "./../Table/modelTable"
import './CancelModify.scss';
import moment from "moment";
import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';
import SelectHistory from "./../Table/select_history"
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import ModalCancelCustomer from './ModalCancelCustomer.js';






class Account extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            refresh: false,
            errorMessage: null,
            snackMessage: null,
            changeModeView: Date.now(),
            openModalCancelCustomer: false,
        }


        this.id = this.props.booking.bookingId
        this.numVersion = this._createNumVersion()

    }



    _createNumVersion = () => {
        return this.props.version //.numVersion 
    }


    componentDidUpdate() {

        let exVersion = this._createNumVersion()
        if (this.id != this.props.booking.bookingId || this.numVersion != exVersion) {
            this.numVersion = exVersion

            if (this.id != this.props.booking.bookingId) {
                this.setState({ changeModeView: Math.random() })
            }
            this.id = this.props.booking.bookingId
            this.setState({ refresh: true })
        }
    }



    componentDidMount() {

    }



    render() {




        if (this.state.refresh) {
            this.setState({ refresh: false })
        }


        let bundleId = this.props.booking.bundle && this.props.booking.bundle.data ? this.props.booking.bundle.data.id : "BASIC"

        return <>

            {this.state.refresh ? null :

                <div className={"container-cancelmodify"}>
                    <div>
                        <span>
                            {"Tarif Sélectionné par le client : "}
                        </span>
                        <span className='category-bundle'>
                            {bundleId}
                        </span>
                        <div style={{marginLeft:"10px"}}>
                        {this.props.booking.bundle && this.props.booking.bundle.data 
                            && this.props.booking.bundle.data.details?this.props.booking.bundle.data.details.map((line)=>{
                                return <span>
                                    {line.id + " = " + (line.amountEUR || "") + " €"}
                                </span>
                            }):null}
                        </div>

                    </div>

                    {bundleId == "PLUS" || bundleId === "MAX" ? <div className='list-buttons'>
                        <Button size='small'
                            color="primary"
                            onClick={(ev) => {

                                this.setState({ openModalCancelCustomer: true })
                            }}>{
                                <span style={{ color: "#f66" }}>{"Cancel Customer"}</span>
                            }</Button>
                    </div> : null}


                </div>
            }

            <ModalCancelCustomer
                open={this.state.openModalCancelCustomer}
                data={this.props.booking}
                onClose={() => {
                    this.setState({ openModalCancelCustomer: false })
                }}
                onCancel={() => {
                    this.setState({ openModalCancelCustomer: false })
                }}
                booking={this.props.booking}
                id={this.props.booking.bookingId}
                bookingId={this.props.booking.bookingId}
                onSave={(data, callback) => {

                    // this._request({
                    //     ...data,
                    //     bookingId: this.bookingId,

                    // }, "payment/refund")
                    //     .then((response) => {
                    //         this._refresh()
                    //         setTimeout(() => {
                    //             this._refresh()
                    //         }, 5000)
                    //         if (!response || response.error) {
                    //             this.setState({ errorMessage: (response ? response.error : "") || "erreur " })
                    //         }
                    //         else {
                    //             this.setState({ snackMessage: "Votre action a bien ete effectuée" })
                    //             this.setState({ displayModalRefund: false })
                    //         }
                    //         if (callback) callback()

                    //     }).catch((e) => {
                    //         console.error("erreur tablepayment refund", e)
                    //         this.setState({ snackMessage: "Erreur : Attendre 2 minutes, vérifier la comptabilité et le fournisseur de paiement" })
                    //         if (callback) callback()
                    //     })
                }}
            />
            <CombigoSnackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={this.state.errorMessage !== null || this.state.snackMessage !== null}
                onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
                variant={this.state.errorMessage != null ? 'error' : "success"}
                autoHideDuration={6000}
                message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
            />
        </>


    }
}




const mapStateToProps = (state) => {
    return { booking: state.booking, base: state.data };
};

export default connect(mapStateToProps, {})(Account);