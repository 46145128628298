
import * as React from 'react';
import { connect } from 'react-redux';
import { cloneObject } from '../../helpers';
import ModelTable from "./../Table/modelTable"
import './TableTasks.scss';
// import { DataGrid } from '@material-ui/data-grid';
import Toolbar from "./Toolbar"
import TableMaterial from "./../Table/TableMaterial"
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import SelectCheckBoxCombigo from "./../Utils/Select_CheckBox"
import Checkbox from '@material-ui/core/Checkbox';

import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import IconButton from '@material-ui/core/IconButton';





class Account extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            data: [],
            version: "",
            dateUpdate: 0
        }

    }

    componentDidUpdate() {
        let filterString = JSON.stringify(this.props.filter || {}) +"-"+this.props.version
        if (this.state.filterStr != filterString) {
            this.setState({ filterStr: filterString,data:[],version:"" })
        }

    }

    componentDidMount() {

    }


    _resumeTask = (data = "") => {

        let resumeTask = data.replace(/<[^>]+>/g, '').trim()
        while (resumeTask.indexOf("&nbsp;") != -1) resumeTask = resumeTask.replace("&nbsp;", " ")
        while (resumeTask.indexOf("  ") != -1) resumeTask = resumeTask.replace("  ", " ").trim()

        if (resumeTask > 30) {
            resumeTask = resumeTask.substr(0, 30) + "..."
        }
        return resumeTask
    }



    render() {


        const columns = [
            { collapse: true },
            {
                field: "status", name: "Status", render: (obj) => {
                    return obj.status

                    return <SelectCheckBoxCombigo
                        className={""}
                        style={{ width: "" }}

                        multiple={false}
                        getLabel={(option) => { return option.title }}
                        getMatching={(option) => { return option.id }}
                        value={obj.status}
                        list={this.props.base.task.status}
                        onChange={(value) => {

                            // on modifie le task




                            //    this.onChange({ status: value.id })
                        }}
                    />
                }
            },
            {
                field: "Tags", name: "Tags", render: (obj) => {
                    return obj.tags.join(",")
                }
            },
            {
                field: "Resume", name: "Resume", render: (obj) => {
                    return obj.resumeTask || this._resumeTask(obj.content)
                }
            },
          
            {
                field: "created", name: "Create Date", render: (obj) => {

                    return moment(obj.created.date).format("DD/MM/YYYY HH:mm:ss")
                }
            },
            {
                field: "modified", name: "Modified Date", render: (obj) => {

                    return moment(obj.modified.date).format("DD/MM/YYYY HH:mm:ss")
                }
            },

        ]

        if (this.props.openLink) {
            columns.push({
                field: "", noSort: true, name: "Link", render: (rowData) => {
                    let url = ""
                    if (rowData.relationId && rowData.relationId.indexOf("bookingId") != -1) url = "/oneTransaction/" + rowData.relationId.split(":")[1]
                    return <a href={url} target="_blank"><IconButton onClick={() => {
                    }}>
                        <FolderOpenIcon />
                    </IconButton>
                    </a>
                }
            })
        }



        return <div className={"container-table-tasks"}>
            {this.props.activeToolbarFilter ? <Toolbar
                url={'/crm/tasks/search'}
                timeout={this.props.timeout || 0} //12000
                filter={this.props.filter}
                version={this.state.filterStr}
                onUpdate={function (data, error) {
                    if (data) {
                        if (this.state.version != data.version) {
                            if (this.props.updateParent) this.props.updateParent(data.data.length)
                            if (this.props.onChange) this.props.onChange(data.data)
                            this.setState({ data: data.data, version: data.version, dateUpdate: Date.now() })
                        }
                        else {
                            this.setState({ dateUpdate: Date.now() })
                        }
                    }
                }.bind(this)}
            /> : null}

            <TableMaterial
                className={"table-tasks"}

                columns={columns}
                functionCollapse={(row) => {
                    return []
                 
                }}
                subColumn={[
                    // {field:"difference",name:"difference"},
                    // {field:"key",name:"key"},
                    // {field:"before",name:"before"},
                    // {field:"after",name:"after"},

                ]}
                dense={this.props.dense}
                data={this.props.dataParent || this.state.data}
            />

        </div>



    }
}




const mapStateToProps = (state) => {
    return { booking: state.booking, base: state.data };
};

export default connect(mapStateToProps, {})(Account);