import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import { withRouter } from 'react-router-dom';

import Slider from '@material-ui/core/Slider';
import "./Toolbar.scss"

import TextField from "@material-ui/core/TextField";

import { parseQuery, _fetch } from "./../../helpers"
import CheckBoxCombigo from "./../Utils/Select_CheckBox"
import IntervalleDate from "./../Utils/IntervalleDate"
import SelectCombigo from "./../Utils/Select"


const initialStateBody = {
  startDate: "",
  endDate: "",

  sorting: "CREATED_DATE_DEC",
}



class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      body: initialStateBody //getHistory() ||
    }
    this.lastRequest = Date.now()
    this.search = this.search.bind(this)
    this.onChangeBody = this.onChangeBody.bind(this)
    this.lastUpdateVersion = 0


  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval)
  }

  componentDidMount() {
    this.search(true, this.state.body)
    if (this.props.timeout) this.interval = setInterval(function () {
      this.search(false, this.state.body, true)
    }.bind(this), this.props.timeout)
  }


  componentDidUpdate(prevProps) {

    //this.urlSearch
    if (this.urlSearch != window.location.search) {
      let o = parseQuery(window.location.search)
      if (o.bodyRequest) {
        // o.bodyRequest = decodeURI(o.bodyRequest)
        this.onChangeBody(JSON.parse(decodeURI(o.bodyRequest)))
      }

    }
    if (prevProps.version!=this.props.version) {
      this.search(true, this.state.body)
    }
    if (prevProps.refresh!=this.props.refresh) {
      this.search(true, this.state.body)
    }
    // console.log(window.location)

  }

  async search(force, body = {}, isCycle = false) {

    if (isCycle === false) {
      if (this.props.resetInit) this.props.resetInit()
    }
    if (force || Date.now() - this.lastRequest > 0.8 * this.props.timeout) {
      this.lastRequest = Date.now()
      this.lastUpdateVersion++
      setTimeout(function (lastUpdateVersion) {
        if (this.lastUpdateVersion != lastUpdateVersion) return
        request.call(this, body, this.lastUpdateVersion)

      }.bind(this, this.lastUpdateVersion), 1000)

    }


    function request(body, lastUpdateVersion) {
      _fetch(process.env.REACT_APP_URL + this.props.url, {
        "timeout": 15000,
        "nbRetry": 1,
        "method": "post",
        "headers": {},
        "body": {
          filter: {
            ...this.props.filter,
            ...body,
            ...this.props.filterForce,
          },
          //mode: this.props.mode || "alltransactions"
        }
      })
        .then(function (response) {
          if (this.lastUpdateVersion != lastUpdateVersion) return
          if (response.status !== 200) throw new Error(JSON.stringify({ msg: "Erreur status", "status": response.status, "body": response.body }));
          let data = response.json()
          if (this.props.onUpdate) this.props.onUpdate(data)
        }.bind(this))
        .catch(function (e) {
          if (this.lastUpdateVersion != lastUpdateVersion) return

          console.log("Error toolbar", e);
          if (this.props.onUpdate) this.props.onUpdate(undefined, { error: true })

        }.bind(this));
    }


  }

  onChangeBody(obj) {
    let body = Object.assign({}, this.state.body)
    for (let prop in obj) body[prop] = obj[prop]

    this.setState({ body: body })
    if (this.props.onChange) this.props.onChange(body)
    this.search(true, body)

    // if (this.noRoutingUrl) return 
    this.bodyStr = JSON.stringify(body)
    this.urlSearch = "?bodyRequest=" + encodeURI(this.bodyStr)

    this.props.history.push({
      pathname: window.location.pathname,
      //URLSearchParams
      search: this.urlSearch
    })
  }

  render() {


    return (
      <Grid container item xs={12} direction="row" className={"toolbar-container-search " + this.props.className} spacing={3}>
        <Grid container item xs={12} direction="row" className={" " + this.props.className} spacing={3}>
{/*       
          <Grid container item direction={"row"} xs={2} sm={2} spacing={3} className={"block"}>

            <IntervalleDate
              label_start={"Start"}
              label_end={"End"}
              format={"DD/MM/yyyy"}
              type={"date"}
              style={{ width: "100px" }}
              startDate={this.state.body.startDate}
              endDate={this.state.body.endDate}

              onChange={(value) => {
                // console.log(value)
                this.onChangeBody({ startDate: value[0], endDate: value[1] })
              }}
            />
          </Grid>


          <Grid container item direction={"row"} xs={1} spacing={3} className={"block"} >
            <TextField label="Id" variant="standard" value={this.state.body.id} onChange={(ev) => {
              this.onChangeBody({ id: ev.target.value })
            }} />
          </Grid>
         
          <Grid container item direction={"row"} xs={1} spacing={3} className={"block"} >
            <TextField label="bookingId" variant="standard" value={this.state.body.bookingId} onChange={(ev) => {
              this.onChangeBody({ bookingId: ev.target.value })
            }} />
          </Grid> */}
       



        </Grid>





        <Grid container item xs={12} direction="row" className={" " + this.props.className} spacing={3} style={{ marginTop: "10px" }}>


          <Grid container item direction={"row"} xs={1} spacing={3} className={"block"}>
            <Button onClick={function () {

              this.setState({
                body: {
                  ...initialStateBody,
                  ...this.props.filter,
                }
              }, () => {

                this.search(true, this.state.body)
              })
            }.bind(this)}>{"Reset"}</Button>
          </Grid>

        </Grid>



      </Grid>
    );
  }

}


const mapStateToProps = (state) => {
  return { base: state.data };
};

export default connect(mapStateToProps, {})(withRouter(Body));