
import React from 'react';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import SelectCheckBoxCombigo from "./../Utils/Select_CheckBox"
import Grid from '@material-ui/core/Grid';

import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';

import { parseQuery, _fetch } from "./../../helpers"
import Label from "./../Label/Label"
import "./ModalTicket.scss"

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

class Body extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            view: "init",
            errorMessage: null,
            snackMessage: null,

            disabledButton: false,

        }


    }



    async componentDidMount() {

    }

    componentDidUpdate(prevProps) {

        if (this.props.version != prevProps.version) {

            this.props.onClose()
        }

    }






    onClose() {

        this.props.onClose()
    }


    render() {



        return (
            <>
                <Dialog
                    className={(this.props.className || "") + " modalDialog modal-ticket"}
                    open={this.props.open || false}
                    onClose={() => {
                        this.onClose()
                    }}
                    keepMounted
                >
                    <Grid className={"title-container"}>

                        <Grid className={"text-container"}>
                            <span className={"title"}>{"Page Espace Client"}</span>
                        </Grid>
                        <div style={{ flexGrow: 1 }}></div>
                        <Grid>
                            <IconButton size="small" onClick={this.props.onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>


                    <DialogContent>
                        {this.props.open &&
                            <>
                                <div className={"parent-iframe"}><iframe
                                    id={"iframe-combigo-booking"}
                                    key={"iframe-combigo-booking"}
                                    style={{ position: "relative !important", "left": "0px !important" }}
                                    width="100%"
                                    className={"iframe-combigo-page-booking"}
                                    height="650px"
                                    src={"https://" + (window.location.origin.indexOf("beta") == -1 && window.location.origin.indexOf("localhost") == -1 ? "www" : "beta") 
                                    + ".combigo.com/detail_booking?action=secret_booking&bookingId=" + this.props.bookingId + "&bookingRef=" + this.props.bookingReference
                                        + "&debug=bookingCombigo&locale=fr&currency=EUR&crm=1&root=" + JSON.stringify({
                                            admin: 1,
                                            user: this.props.nameUser,
                                            crm: 1,
                                            "mode": "bookingdetail"
                                        }) + "&backofficecombigo=1&ticketOnly=1"}
                                ></iframe></div>
                            </>
                        }
                    </DialogContent>

                    <DialogActions>
                    <Button onClick={this.onClose} color="primary">
                            {"Cancel"}
                        </Button>
                    </DialogActions>
                </Dialog>
                <CombigoSnackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.errorMessage !== null || this.state.snackMessage !== null}
                    onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
                    variant={this.state.errorMessage != null ? 'error' : "success"}
                    autoHideDuration={6000}
                    message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
                />
            </>
        );
    }
}




const mapStateToProps = (state) => {
    return { booking: state.booking, nameUser: state.user.name };
};

export default connect(mapStateToProps, {})(Body);