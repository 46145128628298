

import React from 'react';

import {
    DirectionsBus, DirectionsRailway, DirectionsCar, DirectionsBike, DirectionsBoat, Directions
    , DirectionsSubway, DirectionsWalk, Flight, LocalTaxi, Subway
    , Tram, TransferWithinAStation, TripOrigin
} from '@material-ui/icons';



export function getIconTypeTransport(typeInput, className, style = {}) {
    const type = typeInput.toUpperCase()


    switch (type) {
        case 'BUS':
            return <DirectionsBus style={style} className={className} />;
        case 'BUS_CITY':
            return <DirectionsBus style={style} className={className} />;


        case 'TRAIN':
            return <DirectionsRailway style={style} className={className} />;
        case 'SUBURBAN_TRAIN':
            return <DirectionsRailway style={style} className={className} />;
        case 'BOAT':
            return <DirectionsBoat style={style} className={className} />;
        case 'CAR':
            return <DirectionsCar style={style} className={className} />;
        case 'WALK':
            return <DirectionsWalk style={style} className={className} />;
        case 'BIKE':
            return <DirectionsBike style={style} className={className} />;
        case 'TAXI':
            return <LocalTaxi style={style} className={className} />;
        case 'VTC':
            return <LocalTaxi style={style} className={className} />;
        case 'TRAM':
            return <Tram style={style} className={className} />;
        case 'SUBWAY':
            return <DirectionsSubway style={style} className={className} />;
        case 'TRANSFERT':
            return <TransferWithinAStation style={style} className={className} />;
        case 'ORIGIN':

            return <TripOrigin style={style} className={className} />;

        case 'CARPOOL':
            return <DirectionsRailway style={style} />;

        case 'PLANE':
            if (!style["transform"]) style["transform"] = "rotate(180deg)"
            return <Flight style={style} className={className} />;
        case 'SEAPLANE':
            if (!style["transform"]) style["transform"] = "rotate(180deg)"
            return <Flight style={style} className={className} />;
        case 'HELICOPTER':
            if (!style["transform"]) style["transform"] = "rotate(180deg)"
            return <Flight style={style} className={className} />;
        case 'PUBLICTRANSPORT':
            return <DirectionsBus style={style} className={className} />;
        default:
            return null
    }
}

