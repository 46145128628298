
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import React, { Component, version } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { parseQuery, _fetch } from "./../../helpers"
import "./Normal.scss"
import MaterialTable, { MTableToolbar } from "material-table";
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import IconButton from '@material-ui/core/IconButton';


import Ajv from 'ajv';
const ajv = new Ajv({ allErrors: true, verbose: true });



class Body extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            _id:null,
            json: null,
            name:""
        }
    }

    componentDidMount() {
        this._update(this.props._id)
    }

    _update(_id){
        console.log("Updated " + _id)
        this.props._request({action:"get",_id:_id},(success,data)=>{
            console.log("Response " ,data)
            if (success) this.setState({json:data.data,name:data.name})
        })
    }

    componentDidUpdate(prevProps) {

        if (this.props._id!=prevProps._id){
            this._update(this.props._id)
            // this.setState({
            //     _id:prevProps._id
            // })            
        }
        
    }


    handleChange(val) {
        this.setState({json:val})
    }


    render() {

        if (!this.state.json) return null
        /*
            name => text field
            description => text field
            lastmodified => 
            bouton save, bouton cancel, remove
        */
        return <div className={"view-transaction"}>
            <div className={"title-page"}> {"Configuration " + this.state.name}</div>


            <div style={{ maxWidth: "100%" }}>
                <Editor
                    value={this.state.json}
                    ajv={ajv}
                    allowedModes={['code', 'form', 'text', 'tree', 'view', 'preview']}
                    onChange={this.handleChange}
                />
            </div>


        </div>
    }
}


export default withRouter(Body);


