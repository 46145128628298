
import * as React from 'react';
import { connect } from 'react-redux';
import ModelTable from "./../Table/modelTable"
import './TableContact.scss';
import SelectHistory from "./../Table/select_history"
import PhoneIcon from '@material-ui/icons/Phone';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';

const columns = [

    {
        name: "mode",
        label: "Mode",
        custom: {
            type: "list",
            props: {
                disableClearable: true
            },
            list: [
                { id: "USER_DEFAULT", title: "USER_DEFAULT" },
                { id: "USER", title: "USER" },
                { id: "ACCOUNT", title: "ACCOUNT" }
            ],
        },
        options: {
            sort: false
        }
    },
    {
        name: "firstName",
        label: "Prénom",
        custom: {
            type: "input"
        },
        options: {
            sort: false
        }
    },
    {
        name: "lastName",
        label: "Nom",
        custom: {
            type: "input"
        },
        options: {
            sort: false
        }
    },
    {
        name: "mail",
        label: "Mail",
        custom: {
            type: "input"
        },
        options: {
            sort: false
        }
    },
    {
        name: "phoneCountry",
        label: "phCountry",
        custom: {
            type: "input"
        },
        options: {
            sort: false
        }
    },
    {
        name: "phoneCallsign",
        label: "Indicatif",
        custom: {
            type: "input"
        },
        options: {
            sort: false
        }
    },

    // {
    //     name: "phoneCountryLabel",
    //     label: "phCountryLabel",
    //     custom: {
    //         type: "label",
    //         active: ["view"]
    //     },
    //     options: {
    //         sort: false
    //     }
    // },
    {
        name: "phone",
        label: "Phone ",


        custom: {
            type: "input",

        },
        options: {
            // customBodyRender:(value)=>{
            //     return <><PhoneIcon style={{width:"16px",height:"16px",marginRight:"5px"}}/><span>{value}</span></>
            // },
            sort: false,
            customHeadLabelRender: () => {
                return <><PhoneIcon style={{ width: "16px", height: "16px", marginRight: "5px" }} /><span>{"Phone"}</span></>
            },
        }
    },
    {
        name: "locale",
        label: "locale",
        custom: {
            type: "input"
        },
        options: {
            sort: false
        }
    },


    {
        name: "comment",
        label: "Comment.",
        custom: {
            type: "input",
            props: { multiline: true }
        },
        options: {
            sort: false
        }
    }
    , {
        name: "",
        label: "",
        custom: {
            // type:"action",
            onlyEdit: true,
            type: "action_delete",
        },
        options: {
            sort: false
        }
    }

];

const options = {
    filterType: 'checkbox',
    print: false,
    display: false,
    rowsPerPage: 100,
    selectableRows: 'none',
    selectableRowsHideCheckboxes: true,
    download: false,
    filter: false,
    search: false,
    viewColumns: false,
};





class Account extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            refresh: false,
            changeModeView: Date.now()

        }
        this.numVersion = this.props.booking.contacts.numVersion
        this.id = this.props.booking.bookingId
    }

    componentDidUpdate() {
        if (this.id != this.props.booking.bookingId || this.numVersion != this.props.booking.contacts.numVersion) {
            this.numVersion = this.props.booking.contacts.numVersion
            this.id = this.props.booking.bookingId
            this.setState({ refresh: true, changeModeView: Math.random() })
        }
    }

    componentDidMount() {

    }



    render() {

        if (this.state.refresh) {
            this.setState({ refresh: false })
        }
        let data = this.state.dataTemp || this.props.data
        let dataTemp = []
        if (data && data.data) {
            for (let contact of data.data) {
                if (!contact.phoneCountryLabel) {
                    if (contact.phoneCountry) for (let l of this.props.base.countries) if (l.code == contact.phoneCountry) { contact.phoneCountryLabel = l.label; break }
                    if (!contact.phoneCountryLabel && contact.phoneCallsign) {
                        let phoneCallsign = contact.phoneCallsign.replace("+", "").trim()
                        for (let l of this.props.base.countries) if (l.phone == phoneCallsign) {
                            contact.phoneCountryLabel = l.label;
                            if (!contact.phoneCountry) contact.phoneCountry = l.code
                            break
                        }
                    }
                }
                dataTemp.push(contact)
            }
        }


        

        return (this.state.refresh ? null : <ModelTable
            className={"table-contact"}
            data={{ ...data, data: dataTemp }}
            name={"contact"}
            disabledModification={this.props.disabledModification || this.state.disabledModification}
            changeModeView={this.state.changeModeView}

            readonly={this.props.readonly}
            footerHistory={<SelectHistory
                style={{ minWidth: "100px" }}
                version={this.props.booking.bookingId}
                filter={{ relationId: "bookingId:" + this.props.booking.bookingId, event: "contacts" }}
                historyData={(data, error, flagReset) => {
                    if (flagReset) {
                        return this.setState({ dataTemp: null, disabledModification: false })
                    }
                    if (!data) {
                        return this.setState({ disabledModification: true, dataTemp: [] })
                    }
                    if (data.rawData && typeof data.rawData === "string") data.rawData = JSON.parse(data.rawData)
                    if (data.rawData) this.setState({ dataTemp: data.rawData.contacts, disabledModification: true })
                }}
            />}
            
            footerOptions={<div style={{ marginLeft: "10px", flex: 1,display:"flex" }}>
                <div  style={{ display: "block" }}>
                    <FormControl className={"select-status"}>
                        <InputLabel id="demo-simple-select-label">{"Mail Combigo"}</InputLabel>
                        <Input id="demo-simple-select-label" labelId="demo-simple-select-label"
                            value={this.props.mailCombigo}
                            disabled={true}
                        />
                    </FormControl>
                    </div>

                    <div  style={{marginLeft: "10px",  display: "block" }}>
                    <FormControl className={"select-status"}>
                        <InputLabel id="demo-simple-select-label">{"Telephone Combigo"}</InputLabel>
                        <Input id="demo-simple-select-label" labelId="demo-simple-select-label"
                            value={this.props.phoneCombigo}
                            disabled={true}
                        />
                    </FormControl>
                </div>
            </div>}
            columns={columns}
            options={options}
            onChange={this.props.onChange}
            searchDataHistory={this.props.searchDataHistory}
        >

        </ModelTable>
        )
    }
}




const mapStateToProps = (state) => {
    return { booking: state.booking, base: state.data };
};

export default connect(mapStateToProps, {})(Account);