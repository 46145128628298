import * as React from 'react';

import MUIDataTable from "mui-datatables";
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';

import Delete from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import Edit from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import AddBox from '@material-ui/icons/AddBox';
import History from "./../History/History"
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { createEditRow, noEditRow, addCallback } from "./../Table/helpersTable"
import ModalComment from "./ModalComment"
import moment from "moment";
import { cloneObject, isMobile } from '../../helpers';
import "./table.scss"
import { CombigoSnackbar } from './../../Components/Snackbar/Snackbar';

import Select_CheckBox from "./../Utils/Select_CheckBox"




function getDataTable(data) {

    if (data && data.data) {
        return data.data
    }
    return []
}

function getDataVersion(data) {

    if (data && data.version) {
        return data.version
    }
    return ""
}

function searchDataHistory(id, data) {
    return []
    // if (data.table && data.table[0] && data.table[0]){
    //     for (let line of data.table){
    //         if (line.version==id) return line.data
    //     }
    // }
    // return []
}








class Account extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: props.mode || "view",
            panelHistory: false,
            openModalComment: false,
            dataEdit: null,
            panelOpenRow: false,
            snackMessage: null,
            errorMessage: null,
            dataView: null,
            orderColumn: undefined, //localStorage.get("columnTable_"+ this.props.name)
        }


        this.deleteLine = this.deleteLine.bind(this)
        this.addLine = this.addLine.bind(this)
        this.edit = this.edit.bind(this)
        this.save = this.save.bind(this)
        this.addComment = this.addComment.bind(this)
        this.openFile = this.openFile.bind(this)

        this.options = props.options
        this.dataCopy = null

        addCallback(this.props.columns, { delete: this.deleteLine, open: this.openFile })

        if (props.mode==="edit"){
            this.state={
                ...this.state,
                ...this._createObjEdit(props)
            }
        }

        this.columnsNoEdit = noEditRow(this.props.columns)
        this.columnsEdit = createEditRow(this.props.columns, (data, value, ev, type) => {
            let value1 = ev.tableData[ev.rowIndex][ev.columnData.name]
            let value2 = ev.rowData[ev.columnIndex]
            if (typeof value1 == "object") value1 = JSON.stringify(value1)
            if (typeof value2 == "object") value2 = JSON.stringify(value2)

            if (value1 != value2) {
                // console.log("edit row",this.state.dataEdit)

                this.state.dataEdit[ev.rowIndex][ev.columnData.name] = ev.rowData[ev.columnIndex]
                //if (this.props.onListen)   this.props.onListen(this.state.dataEdit,ev)
                this.setState({ dataEdit: this.state.dataEdit })
            }
        })
        this.columnsEditPanel = createEditRow(this.props.columns)
        this.addOptions()

    }

    componentDidUpdate(prevProps) {
        if (this.props.changeModeView && this.props.changeModeView != prevProps.changeModeView) {
            this.setState({ mode: "view" })
        }
    }

    componentDidMount(prevProps) {
        //  this.edit()

    }


    addOptions() {
        const denseTable = true
        this.options.responsive = "simple"
        this.options.setTableProps = function () {
            return {
                padding: denseTable ? 'none' : 'default',

                // material ui v4 only
                size: denseTable ? 'small' : 'medium',
            };
        }
        return
        this.options.draggableColumns = {
            enabled: true
        }
        this.options.draggable = true
        if (this.options.draggable && !this.options.onColumnOrderChange) {
            this.options.onColumnOrderChange = function (newColumnOrder, columnIndex, newPosition) {
                localStorage.set("columnTable_" + this.props.name, newColumnOrder)
                this.setState({ orderColumn: newColumnOrder })
            }.bind(this)
        }
        return
        this.options = Object.assign(this.options, {
            // expandableRows: true,
            expandableRowsHeader: false,
            expandableRowsOnClick: false,
            isRowExpandable: (dataIndex, expandedRows) => {
                if (dataIndex === 3 || dataIndex === 4) return false;

                // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
                if (expandedRows.data.length > 4 && expandedRows.data.filter(d => d.dataIndex === dataIndex).length === 0) return false;
                return true;
            },
            // rowsExpanded: [0, 1],
            renderExpandableRow: (rowData, rowMeta, coucou) => {
                const colSpan = rowData.length + 1;
                return (
                    <TableRow>
                        <TableCell colSpan={colSpan}>
                            Custom expandable row option. Data: {JSON.stringify({ data: getDataTable(this.props.data)[rowMeta.dataIndex], rowData, rowMeta })}
                        </TableCell>
                    </TableRow>
                );
            }
        })
    }

    deleteLine(rowIndex) {

        //   console.log("this.state.dataEdit=",this.state.dataEdit,"rowIndex",rowIndex)
        let dataEdit = this.state.dataEdit.filter(function (obj, index) {
            return index == rowIndex ? false : true
        })
        // console.log("dataEdit",dataEdit)

        this.setState({
            dataEdit: dataEdit
        })
    }

    openFile(rowIndex) {

        this.setState({ panelOpenRow: true, panelEditRowIndex: rowIndex })

    }

    async addComment(value) {
        if (this.props.onChange) {

            if (await this.props.onChange({
                data: {
                    user: this.props.nameUser,
                    content: value,
                }, typeUpdate: "table_comment"
            }) == true) {
                this.setState({ openModalComment: false })
            }
        }
    }

    _createObjEdit = (props)=>{
        return {
            dataEdit:cloneObject(getDataTable(props.dataOriginal || props.data)),
            versionEdit : getDataVersion(props.dataOriginal || props.data)
        }
    }
    edit() {
       
        this.setState({...this._createObjEdit(this.props)})
        if (this.props.onChangeMode) this.props.onChangeMode("edit")
    
    }

    addLine(type) {
        let line
        if (type == "empty") {
            line = Object.assign({}, this.props.modelLineAddEmpty || {})
        }
        else line = Object.assign({}, this.props.modelLineAdd || {})


        line.id = (Date.now() - 1614014467825) + "." + String(Math.round(Math.random() * 1000000))

        this.setState({ dataEdit: this.state.dataEdit.concat([line]) })
    }


    async save(data) {

        if (this.props.onChange) {
            if (getDataVersion(this.props.dataOriginal || this.props.data) != this.state.versionEdit) {
                console.log("Probleme version " + getDataVersion(this.props.dataOriginal || this.props.data) + " vs " + this.state.versionEdit)
                this.setState({ errorMessage: "Impossible de sauvegarder, malheureusement quelqu'un a modifié ces données et vous devez sauvegarder ce que vous avez saisi, puis faire annuler et de nouveau editer et sauvegarder afin de voir les dernièeres informations saisies." })
                return
            }
            if ((await this.props.onChange({
                data: data,
                typeUpdate: "table_data",

                lastVersion: this.state.versionEdit,//getDataVersion(this.props.data)
            })) == true) {
                if (this.props.onChangeMode) this.props.onChangeMode("view")
                this.setState({ mode: "view", panelOpenRow: false, dataEdit: null, versionEdit: null })
            }
        }
    }

    render() {
        if (!this.props.data) {
            return null
        }
        let data = this.state.mode == "edit" ? this.state.dataEdit : getDataTable(this.props.data)
        let columns = this.state.mode == "edit" ? this.columnsEdit : this.columnsNoEdit

        // pour le versionning
        if (this.state.dataView != null && this.state.dataView) {
            data = this.state.dataView //.slice()
            columns = this.columnsNoEdit //.slice()
        }
        // else{
        //     data = data?data.slice():[]
        //     columns = columns.slice()
        // }


        if (this.state.orderColumn) {

        }

        // console.log("data",this.props.data)

        // console.log(data,columns)
        // this.options.responsive ="simple"// isMobile()?"simple":undefined
        // console.log(this.options.responsive)
        // console.log(this.state.dataEdit ,getDataTable(this.props.data),this.state.panelEditRowIndex)
        return (
            <div className={"container-table"}>

                {<MUIDataTable

                    className={this.props.className}
                    data={data}
                    columns={columns}
                    options={this.options}
                />}



                {/* <ModalComment
                    title={"Commentaire"}
                    text={"Quel est votre commentaire ?"}
                    open={this.state.openModalComment}
                    onClose={() => {
                        this.setState({ openModalComment: false })
                    }}
                    onSave={async function (text) {
                        this.addComment(text)
                    }.bind(this)}
                /> */}

                {this.props.readonly ? null : <div className={"footer-table"} >

                    {this.props.disabledModification ? null : <IconButton onClick={() => {
                        this.edit()
                        this.setState({ mode: "edit" })
                        if (this.props.editMode) this.props.editMode("edit")
                    }}
                        size="small"
                        disabled={this.state.mode != "edit" ? false : true}>
                        <Edit />
                    </IconButton>}

                    {this.props.disabledModification ? null : <IconButton size="small"
                        onClick={() => {
                            if (this.state.mode == "edit") {
                                this.addLine()

                            }
                            else {
                                this.edit()
                                this.setState({ mode: "edit" }, function () {
                                    this.addLine()
                                })
                            }
                            if (this.props.editMode) this.props.editMode("edit")

                        }}>

                        <AddBox />
                    </IconButton>}

                    {this.props.disabledModification ? null : (this.props.modelLineAddEmpty ? <IconButton size="small"
                        text="Empty"
                        onClick={() => {
                            if (this.state.mode == "edit") {
                                this.addLine("empty")
                            }
                            else {
                                this.edit()
                                this.setState({ mode: "edit" }, function () {
                                    this.addLine("empty")
                                })
                            }
                            if (this.props.editMode) this.props.editMode("edit")

                        }}>
                        <AddBox />
                        {"empty"}
                    </IconButton> : null)}

                    {this.props.disabledModification ? null : this.state.mode == "edit" && <Button size="small" onClick={() => {
                        this.save(this.state.dataEdit)
                        if (this.props.editMode) this.props.editMode("view")

                    }}>
                        {"Sauvegarde"}
                    </Button>}
                    {this.props.disabledModification ? null : this.state.mode == "edit" && <Button size="small" onClick={() => {
                        if (this.props.onChangeMode) this.props.onChangeMode("view")
                        this.setState({ mode: "view", dataEdit: null, versionEdit: null })
                        if (this.props.editMode) this.props.editMode("view")

                    }}>
                        {"Annuler"}
                    </Button>}
                    {this.props.footerModification}

                    <div style={{ flexGrow: 1 }}></div>

                    {/* {<Button size="small"
                        disabled={this.state.mode == "edit" || data.length == 0 ? true : false}
                        onClick={() => {
                            //this.setState({ openModalComment: true })
                        }}>
                        {""}
                    </Button>} */}


                    {this.props.footerOptions}
                    <div style={{}}  >
                        {
                            this.props.footerHistory
                        }
                        {/* <Select_CheckBox
                            className={""}
                            styleSelect={{ width: "100%" }}
                            label={"Historique"}
                            list={["CREDIT", "PROMO"]}
                            multiple={false}
                            chips={false}
                            value={""}
                            onChange={(value) => {
                                this.onChange({ typeVoucher: value })
                            }}
                        /> */}
                    </div>


                    {/* {<Button size="small"
                        disabled={this.state.mode == "edit" || data.length == 0 ? true : false}
                        onClick={() => {
                            this.setState({ openModalComment: true })
                        }}>
                        {"Add Comment"}
                    </Button>} */}
                    {/* <Button size="small"
                        disabled={((this.props.data ? this.props.data.comments : []) || []).length == 0 ? true : false}
                        onClick={() => {
                            this.setState({ panelHistory: true })
                        }}>
                        {"Historique"}
                    </Button> */}



                    {/* <Drawer anchor={"right"} open={this.state.panelHistory} onClose={() => {
                        this.setState({ panelHistory: false, dataView: null })
                    }}>
                        {<History
                            list={(this.props.data ? this.props.data.comments : [])}
                            onClick={async (obj) => {
                                if (obj.version == this.props.data.version) {
                                    this.setState({ dataView: this.props.data.data })
                                }
                                else {
                                    let docHistory = await this.props.searchDataHistory(obj)
                                    console.log(docHistory)
                                    if (docHistory) this.setState({ dataView: docHistory.data })
                                }

                            }}
                        />}
                    </Drawer> */}
                </div>}
                <CombigoSnackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.errorMessage !== null || this.state.snackMessage !== null}
                    onClose={() => this.setState({ errorMessage: null, snackMessage: null })}
                    variant={this.state.errorMessage != null ? 'error' : "success"}
                    autoHideDuration={6000}
                    message={<span id="message-id"> {this.state.errorMessage || this.state.snackMessage}</span>}
                />
            </div>
        )
    }
}




const mapStateToProps = (state) => {
    return { locale: state.user.locale, nameUser: state.user.name };
};
export default connect(mapStateToProps, {})(Account);