
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import "./Autocomplete.scss"
// import OutsideClickHandler from './OutsideClick'
import OutsideClickHandler from 'react-outside-click-handler';
import moment from "moment";


const backgroundColor = {
  PROCESSING: "rgba(189, 171, 130,1)",
  "WAIT_CONFIRMED": "rgba(255, 204, 51,1)",
  "PROCESSING_AUTO": "rgba(255, 204, 51,1)",
  "PROCESSING_ERROR": "rgba(255, 204, 51,1)"
  , "MODIFICATION": "rgba(255, 204, 51,1)", "PROCESSING_MANAGE": "rgba(255, 204, 51,1)", "CANCEL_OTHER": "rgba(255,102,102,1)",
  "CANCEL_VOLUNTARY": "rgba(255,102,102,1)", "CANCEL_COMBIGO": "rgba(255,102,102,1)", CONFIRMED: "rgba(51,204,51,1)", DONE: "rgba(51,204,51,1)"
}






class Body extends React.Component {
  constructor(props) {
    super(props);

    
    this.state = {
        value:"",
        open:false,
        list:[],
    }
    this.lastValue=""
    this.onChange = this.onChange.bind(this)

  }
  
  async onChange(ev,val){  
 
    this.lastValue=ev.target.value

    this.setState({list:[],open:true,value:ev.target.value},async ()=>{
      if ( this.props.onChange)  this.props.onChange(   this.lastValue)
      let data = await this.props.options(   this.lastValue)
      if (this.lastValue!=this.state.value){
      console.log(this.lastValue + " != " + this.state.value)
         
      }
     // console.log("Data",data)
  
      if (data.length==0){
          this.setState({list:[{
              id:"none",
              title:""
          }]})
      }
      else this.setState({list:data})
    })
    
  
  }

  _formatDate = (date)=>{
    //moment( obj.dateCreated  +"T00:00:00+00:00").format('ll')
    let d = date.split("-")
    return d[2] + "/"+d[1] + "/"+d[0].substr(2,2)
  }

  render() {

    return <div className={"autocomplete "+this.props.className} style={this.props.style}> 
       
                <TextField fullWidth
                     label={this.props.label} 
                     placeholder={this.props.placeholder} 
                     variant="standard" 
                     onChange={ this.onChange}  
                     value={this.state.value} 
                     onClick={(ev)=>{
                       if (this.state.value) {
                        this.setState({open:true,list:[]},()=>{
                          this.onChange({target:{value:this.state.value}})
                        })
                       }
                     }}/>
                {this.state.open && <OutsideClickHandler onOutsideClick={()=>{this.setState({open:false})} }>

                <div className={"window-autocomplete"}  style={this.props.style}>
                        {this.state.list.map(function(obj){
                            // <div style={{backgroundColor:backgroundColor[obj.statusBooking],width:"100%",height:"2px"}}></div>
                            if (obj.id==="none") return null
                            return <div className={"menu " +this.props.classNameMenu}  style={{borderLeft:"5px solid "+backgroundColor[obj.statusBooking]}}onClick={(ev)=>{

                                if (obj.id=="none") return
                                if (this.props.onClick) this.props.onClick(obj)

                                this.setState({value:obj.title,open:false})

                            }}> 
                            <div className={"title"}>
                              <div style={{minWidth:"90px",fontWeight:500}}> {obj.referenceBooking}</div>
                              
                              <div style={{width:"8px"}}></div>
                              { obj.lastName }
                              <div style={{flex:1,minWidth:"4px"}}></div>
                              { obj.amountEUR + "€" } 

                              </div>
                            {obj.subTitle?<div className={"subTitle"}>
                              <div >{(obj.subTitle || "").replace(" pass."," p.").substr(0,68)}  </div>
                              <div style={{flex:1,minWidth:"4px"}}></div>
                              {  this._formatDate(obj.dateCreated) } 

                            </div>:null}

                            </div>
                        }.bind(this))}
                </div>
                </OutsideClickHandler>
                }
            </div>
           

    
  }
}

export default Body;
