import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props) {

  return (
    <div>
      <Dialog
      style={props.styleDialog}
        open={props.open}
        onClose={props.onClose}
        className={props.className}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"     className={props.classNameTitle} style={props.styleTitle}>
          {props.title}

        </DialogTitle>
        <DialogContent     className={props.classNameContent} style={props.styleContent}>
          <DialogContentText id="alert-dialog-description">
            {props.content}
            
          </DialogContentText>
        </DialogContent>
        <DialogActions className={props.classNameActions} style={props.styleActions}>
          <Button onClick={props.onCancel} disabled={props.disabledButtonNo} color="primary">
            {props.btnCancel}
          </Button>
          <Button onClick={props.onClick} disabled={props.disabledButtonYes}  color="primary" autoFocus >
            {props.btnValid}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}